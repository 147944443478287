import { useBlockAllKeyboardShortcuts, useEffectOnce } from '@core/logic';
import type { AppDispatch } from '@core/redux/store';
import { DotFilled, Path, TrashcanOutline, XOutline } from '@deepup/icons';
import { elementThunks, elementTypeSelectors } from '@elements/redux';
import type {
  PartialSplineEl,
  SplineElement,
  SplineElementKind,
  SplineElementType,
} from '@elements/types';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { setEditMode } from '@projects/edit-modes';
import { EditMode } from '@projects/edit-modes/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { cloneDeep, isEqual, set } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DIALOG_WIDTH, ElementDialogProps, ParentSelectAutocomplete } from './ElementDialog';

const initialElement: PartialSplineEl = {
  name: '',
  splineElementTypeId: '',
  parentPointElementId: null,
  address: null,
};
const initialElementKind: SplineElementKind = 'SPEEDPIPE_BUNDLE';

const kindTabs: [string, SplineElementKind][] = [
  ['Bundle', 'SPEEDPIPE_BUNDLE'],
  ['Single Pipe', 'SPEEDPIPE'],
  ['Other', 'OTHER'],
];

const useConnect = ({ onClose, elementToUpdate }: ElementDialogProps<SplineElement>) => {
  const dispatch: AppDispatch = useDispatch();

  const projectId = useSelector(viewer3dSelectors.selectedProjectId);

  const [element, setElement] = useState<PartialSplineEl>(elementToUpdate ?? initialElement);
  const selectedElementType = useSelector(
    elementTypeSelectors.selectById(element.splineElementTypeId),
  );
  const [selectedKind, setSelectedKind] = useState<SplineElementKind>(
    selectedElementType?.kind ?? initialElementKind,
  );
  const elementTypesBySelectedKind = useSelector(
    elementTypeSelectors.selectByKind(selectedKind),
  ).sort((a, b) => b.name.localeCompare(a.name));

  const updateProp = (path: string, value: unknown) => {
    setElement((e) => set(cloneDeep(e), path, value));
  };

  const addElement = async () => {
    const { payload } = await dispatch(
      elementThunks.post({
        path: ['projects', projectId!, 'splineElements'],
        body: {
          projectId,
          ...element,
        },
      }),
    );
    return payload as SplineElement;
  };

  const updateElement = async () => {
    const { payload } = await dispatch(
      elementThunks.patch({
        path: ['projects', projectId!, 'splineElements', elementToUpdate!.id],
        body: element,
      }),
    );
    return payload as SplineElement;
  };

  const onSubmit = async () => {
    if (element === initialElement) return;
    const nextElement = await (elementToUpdate ? updateElement : addElement)();

    // reselect element to navigate to it again (dirty hack!)
    dispatch(viewer3dActions.setSelectedElementIds([]));
    dispatch(viewer3dActions.setSelectedElementIds([nextElement.id]));
    onClose();
  };

  const isCurrentNameAutoGenerated = elementTypesBySelectedKind.some(
    (t) => t.name === element.name,
  );
  const setElementType = (nextTypeId: string) => {
    if (!element.name || isCurrentNameAutoGenerated) {
      // name prop is empty or autofilled and unmodified by User
      const nextType = elementTypesBySelectedKind.find((t) => t.id === nextTypeId);
      updateProp('name', nextType?.name);
    }
    updateProp('splineElementTypeId', nextTypeId);
  };

  const handleChangeKind = (_: unknown, kind: SplineElementKind) => {
    setSelectedKind(kind);
    isCurrentNameAutoGenerated && setElement(initialElement);
  };

  return {
    element,
    updateProp,
    onSubmit,
    selectedKind,
    handleChangeKind,
    setElementType,
    elementTypesBySelectedKind,
  };
};

export const SplineElementDialog = ({ onDelete, ...props }: ElementDialogProps<SplineElement>) => {
  useBlockAllKeyboardShortcuts();
  useEffectOnce(() => {
    setEditMode(EditMode.Element);
  });
  const { elementToUpdate, onClose } = props;
  const nameInputRef = useRef<HTMLInputElement>(null);

  const {
    selectedKind,
    handleChangeKind,
    element,
    updateProp,
    setElementType,
    elementTypesBySelectedKind,
    onSubmit,
  } = useConnect(props);

  return (
    <Dialog
      open
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{ sx: { width: DIALOG_WIDTH }, elevation: 4 }}
    >
      <DialogTitle variant="h6" gap={2} display="flex" alignItems="center">
        <Path fill="currentColor" fontSize={24} />
        {elementToUpdate ? 'Edit Spline Element' : 'Add Spline Element'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
          })}
          size="large"
        >
          <XOutline fontSize={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs value={selectedKind} centered onChange={handleChangeKind} variant="fullWidth">
              {kindTabs.map(([label, kind]) => (
                <Tab value={kind} label={label} key={kind} iconPosition="start" />
              ))}
            </Tabs>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              key={selectedKind + 'type'} // empties input when the selectedKind changes
              value={
                elementTypesBySelectedKind.find((t) => t.id === element?.splineElementTypeId) ||
                null
              }
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Element Type"
                  required
                  fullWidth
                />
              )}
              onChange={(e, value, reason) => {
                if (reason === 'selectOption') setElementType((value as SplineElementType).id);
                if (reason === 'clear') {
                  setElementType('');
                  updateProp('name', '');
                }
              }}
              options={elementTypesBySelectedKind}
              componentsProps={{ paper: { elevation: 24 } }}
              renderOption={(props, option) => (
                <ListItem
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  dense
                  key={option.id}
                >
                  <Grid
                    container
                    fontSize={(theme) => theme.typography.body2.fontSize}
                    lineHeight={1}
                    alignItems="center"
                  >
                    <Grid item xs={9}>
                      {option.name}
                    </Grid>
                    <Grid item xs={1}>
                      <DotFilled fill={option.color} height={24} width={24} />
                    </Grid>
                    <Grid item xs={2}>
                      {option.color}
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              inputProps={{ 'aria-label': 'Name' }}
              label="Element Name"
              value={element.name}
              onChange={(e) => updateProp('name', e.target.value)}
              InputProps={{
                ref: nameInputRef,
                endAdornment: (
                  <IconButton
                    size="medium"
                    edge="end"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateProp('name', '');
                      // set the cursor in the text field in order for the User to start typing
                      nameInputRef.current?.click();
                    }}
                  >
                    <XOutline />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ParentSelectAutocomplete elementToUpdate={elementToUpdate} updateProp={updateProp} />
          </Grid>
          {selectedKind === 'SPEEDPIPE' && (
            <>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  inputProps={{ 'aria-label': 'Street' }}
                  label="Street"
                  value={element?.address?.street}
                  onChange={(e) => updateProp('address.street', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label="Nr."
                  inputProps={{ 'aria-label': 'Number' }}
                  value={element?.address?.number || ''}
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value))) return;
                    updateProp('address.number', Number(e.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  inputProps={{ 'aria-label': 'Addition' }}
                  label="Addition"
                  value={element?.address?.addition}
                  onChange={(e) => updateProp('address.addition', e.target.value)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing>
        <Stack direction="row" gap={2} p={2} flexGrow={1}>
          {onDelete && (
            <Button variant="text" color="error" startIcon={<TrashcanOutline />} onClick={onDelete}>
              Delete element
            </Button>
          )}
          <Box component="div" flexGrow={1} />
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={
              isEqual(element, elementToUpdate ?? initialElement) ||
              !element.name ||
              !element.splineElementTypeId
            }
          >
            {elementToUpdate ? 'Update Spline Element' : 'Add Spline Element'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
