import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { Project } from '@projects/types';
import type { Point } from '../types';

export const {
  reducer: pointReducer,
  apiThunks: pointApiThunks,
  actions: pointActions,
  selectors: pointSelectors,
} = crudEntityFactory<Point>({
  entity: ReduxEntities.Points,
  parentEntities: [ReduxEntities.SplineElements, ReduxEntities.Trenches],
  keysInParent: [EntityRelationKey.Points],
  removeKeys: [EntityRelationKey.CreatedAt, EntityRelationKey.UpdatedAt],
});

export const pointThunks = {
  ...pointApiThunks,
  movePoint: pointApiThunks.create<Pick<Project, 'utmZone'> & Pick<Point, 'x' | 'y' | 'z'>>(
    'movePoint',
    'PATCH',
  ),
};
