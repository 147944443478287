import { processingResultSelectors } from '@processingResults/redux';
import { scanActions, scanSelectors } from '@scans/redux';
import { jsonToEuler, jsonToVector } from '@viewer3D/helper';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { Euler, Vector3 } from 'three';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export const useConnect = (scanId: string) => {
  const dispatch = useDispatch();
  const initialCameraSet = useSelector(viewer3dSelectors.initialCameraSet);
  const isSelected = useSelector(viewer3dSelectors.scanIdIsSelected(scanId));
  const scan = useSelector(scanSelectors.selectById(scanId))!;
  const { selectedProcessingResultId, disableUserTransformation } = scan;
  const processingResult = useSelector(
    processingResultSelectors.selectById(selectedProcessingResultId),
  );
  const texturedMesh = processingResult?.texturedMeshes && processingResult.texturedMeshes[0];
  const theme = useTheme();
  const userTransformation = disableUserTransformation
    ? {
        helperColor: theme.palette.primary.main,
        position: jsonToVector(texturedMesh?.mesh.offset),
        rotation: new Euler(),
      }
    : {
        helperColor: processingResult?.userTranslation
          ? theme.palette.warning.main
          : theme.palette.primary.main,
        position: jsonToVector(processingResult?.userTranslation || texturedMesh?.mesh.offset),
        rotation: jsonToEuler(processingResult?.userRotation),
      };

  return {
    scan,
    initialCameraSet,
    isSelected,
    userTransformation,
    onScanLoadedToScene: () =>
      dispatch(
        scanActions.updateOne({
          id: scanId,
          loaded: true,
        }),
      ),
    moveCameraTo: ({ x, y, z }: Vector3) =>
      dispatch(
        viewer3dActions.cameraGoto({
          x,
          y,
          z,
          initial: true,
        }),
      ),
  };
};
