import { alpha, Box, styled } from '@mui/material';

export const CustomScrollContainer = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: theme.spacing(1 / 2),
    background: alpha(theme.palette.grey[300], 0.8),
  },
  '&::-webkit-scrollbar-thumb:hover': {
    cursor: 'grab',
    background: theme.palette.grey[500],
  },
}));
