import { useKeys } from '@core/logic';
import type { ThreeEvent } from '@react-three/fiber';
import type { Point } from '@trenches/types';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import {
  MAX_VISIBLE_ELEMENT_ENDPOINT_RADIUS,
  MAX_VISIBLE_ELEMENT_POINT_RADIUS,
} from '../../helper';
import { connectComponent } from './connect';
import { ElementPoint } from './ElementPoint';
import type { MergedProps } from './types';

const ElementPointsBase: FC<MergedProps> = ({
  curve,
  selectPoint,
  selectedPointId,
  radius,
  pointTrenchMap,
  addSelectedElementToTrench,
  selectedElementIds,
  isSelectedElementInPoint,
  extendSelectedElements,
  shrinkSelectedElements,
  getElementCount,
  selectFirstElementInPoint,
  selectedElementType,
  isSelectedElementSinglePoint,
  labelSelectedPointElement,
  hasPointElement,
  hasPipeFitting,
  getPointIdByElementId,
  hasConnector,
  hasPassedPlusAndIsToPoint,
  hasMarker,
}) => {
  const [addKeyPressed, removeKeyPressed] = useKeys('a', 'r');
  const handleShrinkOrRemoveElement = (point: Point) => async (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    if (isSelectedElementSinglePoint) {
      selectedPointId === point.id &&
        getPointIdByElementId(selectedElementIds[0]) === selectedPointId &&
        (await labelSelectedPointElement(null));
      return;
    }
    if (selectedPointId) {
      await shrinkSelectedElements(selectedPointId, point.id);
    }
    selectPoint(point.id);
  };

  const handleAddOrExtendElement = (point: Point) => async (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    if (isSelectedElementSinglePoint) {
      await labelSelectedPointElement(point.id);
      selectPoint(point.id);
      return;
    }
    if (selectedPointId && selectedPointId === point.id && selectedElementType?.kind !== 'OTHER') {
      // adding or extending an element with the same start and end point is only possible for usage type "OTHER" elements
      return;
    }

    if (selectedPointId) {
      // User already set a start point (the selected point) for the element labeling
      if (isSelectedElementInPoint(selectedPointId)) {
        await extendSelectedElements(point.id);
      } else {
        await addSelectedElementToTrench(selectedPointId, point.id);
      }
    }

    selectPoint(point.id);
  };

  const handleSelectPoint = (point: Point) => (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (selectedPointId === point.id) {
      // performance optimization: do not reselect the same point as it has rerendering side effects
      return;
    }
    selectPoint(point.id);
    if (!isSelectedElementInPoint(point.id)) {
      selectFirstElementInPoint(point.id);
    }
  };

  return (
    <group
      name={Object3dNames.ElementPoints}
      renderOrder={getRenderOrder(Object3dNames.ElementPoints)}
    >
      {curve.points.map((vec, i) => {
        const point = curve.pointsData[i];
        const trenchPoint = pointTrenchMap[point.id] ?? [];
        const isTrenchEndPoint = trenchPoint.filter(({ isEndpoint }) => isEndpoint).length > 0;

        const clickHandler =
          removeKeyPressed && selectedElementIds.length
            ? handleShrinkOrRemoveElement
            : addKeyPressed && selectedElementIds.length
            ? handleAddOrExtendElement
            : handleSelectPoint;

        if (
          radius >
          (isTrenchEndPoint
            ? MAX_VISIBLE_ELEMENT_ENDPOINT_RADIUS
            : MAX_VISIBLE_ELEMENT_POINT_RADIUS)
        )
          return null;
        const elementCount = getElementCount(point.id);
        const isPointSelected = selectedPointId === point.id;
        const hasPointMarker = hasMarker(point.id);

        return (
          <ElementPoint
            hasConnector={hasConnector(point.id)}
            hasMarker={hasPointMarker}
            hasSinglePointElement={hasPointElement(point.id)}
            hasPipeFitting={hasPipeFitting(point.id)}
            hasPassedPlusAndIsToPoint={hasPassedPlusAndIsToPoint(selectedElementIds[0], point.id)}
            isTrenchEndPoint={isTrenchEndPoint}
            trenchCountInPoint={trenchPoint.length}
            key={point.id}
            pointId={point.id}
            isPointSelected={isPointSelected}
            removeKeyPressed={removeKeyPressed && !!selectedElementIds.length}
            size={Math.min(
              radius * (1 + elementCount * 0.5 + (hasPointMarker ? 1 : 0)),
              MAX_VISIBLE_ELEMENT_ENDPOINT_RADIUS,
            )}
            position={vec}
            onClick={clickHandler(point)}
          />
        );
      })}
    </group>
  );
};

export const ElementPoints = connectComponent(ElementPointsBase);
