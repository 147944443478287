import type { DateFilterType } from '@projects/pages/detail/hooks';
import { proxy } from 'valtio';

export const scanGroupingState = proxy<{ scanGrouping: DateFilterType }>({
  scanGrouping: 'recordedAt',
});

export const setScanGrouping = (scanGrouping: DateFilterType): void => {
  scanGroupingState.scanGrouping = scanGrouping;
};
