import type { AttributeType, Depth, LayingType, SurfaceType, Width } from '@trenches/types';
import { proxy } from 'valtio';

export type AttributeState = {
  layingType: LayingType | null;
  surfaceType: SurfaceType | null;
  depth: Depth | null;
  width: Width | null;
  selectedAttributeType: AttributeType;
  showOnlyCurrentDepth: boolean;
  showOnlyCurrentWidth: boolean;
};

export const attributeState = proxy<AttributeState>({
  layingType: 'SOIL_DISPLACEMENT',
  surfaceType: null,
  selectedAttributeType: 'LAYING_TYPE',
  depth: null,
  width: null,
  showOnlyCurrentDepth: false,
  showOnlyCurrentWidth: false,
});

export const setLayingType = (layingType: LayingType | null): void => {
  attributeState.layingType = layingType;
};

export const setSurfaceType = (surfaceType: SurfaceType | null): void => {
  attributeState.surfaceType = surfaceType;
};

export const setDepth = (depth: Depth | null): void => {
  attributeState.depth = depth;
};

export const setWidth = (width: Width | null): void => {
  attributeState.width = width;
};

export const setSelectedAttributeType = (attributeType: AttributeType): void => {
  attributeState.selectedAttributeType = attributeType;
};

export const toggleShowOnlyCurrentDepth = (): void => {
  attributeState.showOnlyCurrentDepth = !attributeState.showOnlyCurrentDepth;
};

export const toggleShowOnlyCurrentWidth = (): void => {
  attributeState.showOnlyCurrentWidth = !attributeState.showOnlyCurrentWidth;
};
