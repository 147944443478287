import { CustomScrollContainer } from '@core/components';
import { PinFilled, PinOutline } from '@deepup/icons';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { projectsHistoryActions, projectsHistorySelectors } from '@menu/redux/projectsHistory';
import type { ProjectItem } from '@menu/redux/projectsHistory/types';
import type { NavButtons } from '@menu/types';
import type { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const VISIBLE_LIST_LENGTH = 12;
// to keep it simple we set the item height based on MUI spacing and font styles
const PROJECT_LIST_ITEM_HEIGHT = 34;

export const ProjectsMenuList = ({
  id,
  items,
  goTo,
  headerIcon,
  headerTitle,
  showClearButton,
}: {
  id: string;
  items: NavButtons[];
  goTo: (href: string) => void;
  headerIcon: ReactNode;
  headerTitle: string;
  showClearButton: boolean;
}) => {
  const dispatch = useDispatch();
  const pinnedProjects = useSelector(projectsHistorySelectors.selectPinnedProjects);
  const isProjectPinned = (id: string) => pinnedProjects.find((p) => p.id === id);

  const togglePin = (item: ProjectItem) => {
    if (isProjectPinned(item.id)) {
      dispatch(projectsHistoryActions.dropFromPinned(item.id));
    } else {
      dispatch(projectsHistoryActions.addToPinned(item));
    }
  };

  return (
    <List id={id} disablePadding dense>
      <ListItem>
        <ListItemIcon>{headerIcon}</ListItemIcon>
        <ListItemText primary={headerTitle} />
        {showClearButton && (
          <Button
            color="secondary"
            onClick={() => dispatch(projectsHistoryActions.cleanRecentProjects())}
          >
            Clear all
          </Button>
        )}
      </ListItem>
      {items.length === 0 && (
        <ListItem>
          <Typography variant="body2" color="secondary">
            No projects opened recently
          </Typography>
        </ListItem>
      )}
      <CustomScrollContainer maxHeight={VISIBLE_LIST_LENGTH * PROJECT_LIST_ITEM_HEIGHT}>
        {items.map((item) => (
          <ListItemButton
            key={item.id}
            onClick={() => goTo(`/projects/${item.id}`)}
            sx={{
              '& > button': { visibility: 'hidden' },
              '&:hover > button': {
                visibility: 'visible',
              },
            }}
          >
            <Typography noWrap textOverflow="ellipsis" flexGrow={1}>
              {item.title}
            </Typography>
            <IconButton
              size="small"
              edge="end"
              onClick={(e) => {
                e.stopPropagation();
                togglePin({ id: item.id!, title: item.title });
              }}
            >
              {isProjectPinned(item.id!) ? <PinFilled fill="white" /> : <PinOutline fill="white" />}
            </IconButton>
          </ListItemButton>
        ))}
      </CustomScrollContainer>
    </List>
  );
};
