import { toHumanReadable } from '@core/logic';
import { CircleInformationFilled, EyeOutline, EyeSlashedOutline } from '@deepup/icons';
import {
  Checkbox,
  Chip,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ProcessingIndicator } from '@processingResults/components';
import { scanThunks } from '@scans/redux';
import type { QAState, ReworkCategory, Scan } from '@scans/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';
import { useScanQaColors } from '../../../hooks';
import { checkboxSelectionState, toggleCheckedScans } from '../../checkboxSelectionState';
import { categoryTitles } from '../../ReworkStatus';
import { scanGroupingState } from '../../scanGroupingState';

const QAStateIndicator = ({ state, problems }: { state: QAState; problems: ReworkCategory[] }) => {
  const { qaLabelColors } = useScanQaColors();
  const text = toHumanReadable(state);

  return (
    <Tooltip
      arrow
      title={problems.map((category) => categoryTitles[category]).join(', ')}
      placement="top"
      hidden={problems.length === 0}
    >
      <Chip
        icon={
          problems.length > 0 ? (
            <CircleInformationFilled
              data-testid="to-rework-icon"
              fill={qaLabelColors[state].color}
              fontSize={16}
            />
          ) : undefined
        }
        size="small"
        sx={{
          backgroundColor: qaLabelColors[state].backgroundColor,
          color: qaLabelColors[state].color,
        }}
        label={text}
      />
    </Tooltip>
  );
};

export const ScanListItem = ({ scan }: { scan: Scan }) => {
  const { checkedScans } = useSnapshot(checkboxSelectionState);
  const isChecked = checkedScans.some(({ id }) => id === scan.id);
  const onToggleChecked = useCallback(() => toggleCheckedScans([scan]), [scan]);
  const ref = useRef<HTMLDivElement>(null);
  const isScanSelected = useSelector(viewer3dSelectors.scanIdIsSelected(scan.id));
  useEffect(() => {
    isScanSelected && ref.current?.scrollIntoView({ block: 'center' });
  }, [isScanSelected]);
  const dispatch = useDispatch();

  const setSelectedScanId = () => {
    if (isScanSelected) return;
    dispatch(viewer3dActions.setSelectedScanId(scan.id));
  };

  const patchScan = useCallback(
    (updatedScan: Partial<Scan>) => {
      dispatch(
        scanThunks.patch({
          path: ['scans', scan.id],
          body: { ...updatedScan, id: scan.id },
        }),
      );
    },
    [scan.id, dispatch],
  );

  const toggleVisibility = () => patchScan({ visible: !scan.visible });

  const { scanGrouping } = useSnapshot(scanGroupingState);
  const scanDate = DateTime.fromISO(
    scanGrouping === 'recordedAt' ? scan.metadata?.beginDate ?? scan.createdAt : scan.uploadedAt!,
  );

  return (
    <ListItemButton
      role="menuitem"
      ref={ref}
      sx={{
        opacity: scan.visible ? 1 : 0.5,
        backgroundColor: isChecked ? 'secondary.dark' : 'inherit',
      }}
      key={scan.id}
      dense
      disableGutters
      disableRipple
      divider
      selected={isScanSelected}
      onClick={(e) => e.stopPropagation()}
    >
      <ListItemIcon>
        <Checkbox checked={isChecked} onChange={onToggleChecked} />
      </ListItemIcon>
      <ListItemText
        onClick={setSelectedScanId}
        tabIndex={0}
        primary={
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="subtitle2">
              {scanDate.isValid ? scanDate.toLocaleString(DateTime.TIME_24_SIMPLE) : 'Unknown Time'}
            </Typography>
            <ProcessingIndicator
              uploadedAt={scan.uploadedAt}
              size={16}
              processingResultId={scan.selectedProcessingResultId}
            />
            <QAStateIndicator state={scan.qaState} problems={scan.reworkCategories} />
          </Stack>
        }
        secondary={
          <Typography variant="body2" color="text.secondary" noWrap title={scan.location?.place}>
            {scan.location?.place}
          </Typography>
        }
      />
      <IconButton onClick={toggleVisibility} color="secondary">
        {scan.visible ? <EyeOutline /> : <EyeSlashedOutline />}
      </IconButton>
    </ListItemButton>
  );
};
