import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { RootState } from '@core/redux/interface';
import { createSelector } from '@reduxjs/toolkit';
import { pointSelectors, trenchSelectors } from '@trenches/redux';
import type { Point } from '@trenches/types';
import type { BoundingBox } from '@viewer3D/types';
import type { Selector } from 'react-redux';
import type { Project } from '../types';

export const {
  reducer: projectReducer,
  apiThunks: projectThunks,
  actions: projectActions,
  selectors: projectBaseSelectors,
} = crudEntityFactory<Project>({
  entity: ReduxEntities.Projects,
  parentEntities: [ReduxEntities.Scans],
  keysInParent: [EntityRelationKey.Project],
});

export const projectSelectors = {
  ...projectBaseSelectors,
  boundingBox: (projectId: string, tolerance = 100): Selector<RootState, BoundingBox | undefined> =>
    createSelector(
      [trenchSelectors.selectByProps({ projectId }), pointSelectors.selectEntities],
      (trenchArray, pointEntities) => {
        const bbox: BoundingBox = {
          minX: Infinity,
          minY: Infinity,
          maxX: 0,
          maxY: 0,
        };
        if (trenchArray.length === 0) return;
        for (let e = 0; e < trenchArray.length; e += 1) {
          const { pointIds } = trenchArray[e];
          for (let p = 0; p < pointIds.length; p += 1) {
            const { x, y } = pointEntities[pointIds[p]] as Point;
            if (x > 1000) {
              bbox.minX = Math.min(x - tolerance, bbox.minX);
              bbox.maxX = Math.max(x + tolerance, bbox.maxX);
            }
            if (y > 1000) {
              bbox.minY = Math.min(y - tolerance, bbox.minY);
              bbox.maxY = Math.max(y + tolerance, bbox.maxY);
            }
          }
        }
        if (bbox.minX === Infinity || bbox.minY === Infinity) return;
        return bbox;
      },
    ),
};
