import { processingResultSelectors } from '@processingResults/redux';
import type { TexturedMesh } from '@processingResults/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useTexturedMesh = (processingResultId: string): TexturedMesh | undefined => {
  const [texturedMesh, setTexturedMesh] = useState<TexturedMesh | undefined>();
  const processingResult = useSelector(processingResultSelectors.selectById(processingResultId));

  useEffect(() => {
    if (!processingResult) return;
    const { texturedMeshes } = processingResult;
    if (!texturedMeshes) return;
    setTexturedMesh(texturedMeshes[0]);
  }, [processingResult]);

  return texturedMesh;
};
