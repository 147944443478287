import { splitArray } from '@core/logic';
import { elementSelectors, elementTypeSelectors } from '@elements/redux';
import { pipeFittingSelectors, pipeFittingThunks } from '@elements/redux/pipe-fitting.slice';
import { BundleType, SplineElement } from '@elements/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PipeColor, pipeColors } from './pipeColors';
import type { Connection, Pipe, PipeFitting } from './types';

export const shortenName = (name: string) => name.replace(/SNRVe|\(|\)/g, '').trim();

export const usePipeFittingsRendering = () => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const splineElementsInPoint = useSelector(elementSelectors.selectByPointId(selectedPointId));
  const allElementTypes = useSelector(elementTypeSelectors.selectAll);
  const pipeFittingsInPoint = useSelector(
    pipeFittingSelectors.selectByProps({ pointId: selectedPointId }),
  );
  const [outgoingElements, incomingElements] = splitArray(
    splineElementsInPoint,
    ({ fromPoint }) => !!fromPoint?.isFinished && fromPoint?.id === selectedPointId,
  );

  const toSpeedPipeView = (speedPipeTypeId: string, i: number): Pipe => {
    const [name, color] = pipeColors[i];
    return {
      id: speedPipeTypeId,
      name,
      color,
      ref: createRef<HTMLElement>(),
    };
  };

  /**
   * Maps an element to a pipe Viewmodel to be rendered in the node graph.
   */
  const toPipeView = (e: SplineElement): Pipe => {
    const { name, color, children } = allElementTypes.find(
      (t) => t.id === e.splineElementTypeId,
    ) as BundleType; // casting to BundleType is important here for children to be defined and for readability

    return {
      id: e.id,
      name: shortenName(name),
      color,
      ref: createRef<HTMLElement>(),
      children: !children?.length ? undefined : children?.map(toSpeedPipeView),
    };
  };

  const incomingPipes = [
    ...incomingElements.map(toPipeView),
    {
      id: null,
      name: 'Unknown',
      color: PipeColor.gr,
      ref: createRef<HTMLElement>(),
    },
  ];

  const outgoingPipes = [
    ...outgoingElements.map(toPipeView),
    {
      id: null,
      name: 'Unknown',
      color: PipeColor.gr,
      ref: createRef<HTMLElement>(),
    },
  ];

  const toConnectionsView = (pf: PipeFitting): Connection => {
    const inElementIndex = incomingElements.findIndex((e) => e.id === pf.incomingSplineElementId);
    const outElementIndex = outgoingElements.findIndex((e) => e.id === pf.outgoingSplineElementId);
    // note: indices can be -1 which will take the last pipe in the array which is the Unknown pipe
    const inPipe = incomingPipes.at(inElementIndex)!;
    const outPipe = outgoingPipes.at(outElementIndex)!;
    return {
      id: pf.id,
      inPipe: inPipe.children?.find((c) => c.id === pf.incomingColor) ?? inPipe,
      outPipe: outPipe.children?.find((c) => c.id === pf.outgoingColor) ?? outPipe,
    };
  };

  const connections = pipeFittingsInPoint.map(toConnectionsView);

  return {
    incomingPipes,
    connections,
    outgoingPipes,
  };
};

export const useCreateFitting = () => {
  const selectedProjectId = useSelector(viewer3dSelectors.selectedProjectId)!;
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);

  const dispatch = useDispatch();

  return ({
    outgoingSplineElementId,
    outgoingColor,
    incomingSplineElementId,
    incomingColor,
  }: Partial<PipeFitting>) => {
    dispatch(
      pipeFittingThunks.post({
        path: ['projects', selectedProjectId, 'splineElementConnections'],
        body: {
          projectId: selectedProjectId,
          pointId: selectedPointId,
          outgoingSplineElementId,
          outgoingColor,
          incomingSplineElementId,
          incomingColor,
        },
      }),
    );
  };
};

export const useDeleteFitting = () => {
  const selectedProjectId = useSelector(viewer3dSelectors.selectedProjectId)!;
  const dispatch = useDispatch();

  return (id: Connection['id']) => {
    dispatch(
      pipeFittingThunks.delete({
        id,
        path: ['projects', selectedProjectId, 'splineElementConnections', id],
      }),
    );
  };
};
