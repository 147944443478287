import { Checkbox, FormControlLabel } from '@mui/material';
import { proxy, useSnapshot } from 'valtio';

export const trenchToggleState = proxy({ areTrenchesVisible: true });

export const toggleVisibleTrenches = (): void => {
  trenchToggleState.areTrenchesVisible = !trenchToggleState.areTrenchesVisible;
};

export const TrenchToggle = () => {
  const { areTrenchesVisible } = useSnapshot(trenchToggleState);
  return (
    <FormControlLabel
      label="Trenches"
      control={
        <Checkbox color="primary" checked={areTrenchesVisible} onChange={toggleVisibleTrenches} />
      }
    />
  );
};
