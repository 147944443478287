import { Stack, Typography } from '@mui/material';
import { FC, SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

export const ProjectListSection = ({
  primaryText,
  secondaryText,
  Icon,
}: {
  primaryText: string;
  secondaryText: string | number;
  Icon: FC<SVGProps<SVGSVGElement>>;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" gap={2} padding={2} alignItems="center">
      <Icon fill={theme.palette.secondary.main} fontSize={24} />
      <Typography variant="h6" color="text.primary">
        {primaryText}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {secondaryText}
      </Typography>
    </Stack>
  );
};
