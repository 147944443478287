import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { PipeFitting } from '@projects/pages/detail/PipeFittings/types';

export const {
  reducer: pipeFittingReducer,
  apiThunks: pipeFittingThunks,
  actions: pipeFittingActions,
  selectors: pipeFittingSelectors,
} = crudEntityFactory<PipeFitting>({
  entity: ReduxEntities.PipeFittings,
  removeKeys: [],
  keysInParent: [EntityRelationKey.PipeFittings],
});
