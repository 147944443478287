import type { ThreeEvent } from '@react-three/fiber';
import { PointColors, PointOpacity } from '@trenches/colors';
import type { ElementPointProps } from '@trenches/components/Trench/Points/ElementPoints/types';
import { enableLayerOne, getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import {
  boxGeometry,
  donutGeometry,
  icosahedronGeometry,
  pyramidGeometry,
  sphereGeometry,
} from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { FC, useState } from 'react';
import type { BufferGeometry } from 'three';

const getPointColor = (
  containsTrenchEnd: boolean,
  removeKeyPressed: boolean,
  mouseOver: boolean,
  isPointSelected: boolean,
  hasMarker: boolean,
): string => {
  if ((mouseOver || isPointSelected) && removeKeyPressed) return PointColors.Delete;
  if (isPointSelected) return PointColors.Selected;
  if (mouseOver) return PointColors.Hover;
  if (containsTrenchEnd) return PointColors.Endpoint;
  if (hasMarker) return PointColors.HasMarker;
  return PointColors.Default;
};

const getOpacity = (
  isMouseOver: boolean,
  isPointSelected: boolean,
  numberOfTrenches: number,
  hasMarker: boolean,
): number => {
  const opacity = hasMarker
    ? 1
    : isPointSelected || isMouseOver
    ? PointOpacity.Hover
    : PointOpacity.Default;
  return opacity / numberOfTrenches;
};

const getGeometry = (
  hasNvt: boolean,
  hasPipeFitting: boolean,
  hasConnector: boolean,
  hasPassedPlusAndIsToPoint: boolean,
): BufferGeometry => {
  if (hasNvt) return boxGeometry;
  if (hasPipeFitting) return pyramidGeometry;
  if (hasConnector) return donutGeometry;
  if (hasPassedPlusAndIsToPoint) return icosahedronGeometry;
  return sphereGeometry;
};

export const ElementPoint: FC<ElementPointProps> = ({
  isTrenchEndPoint,
  trenchCountInPoint,
  pointId,
  isPointSelected,
  removeKeyPressed,
  size,
  position,
  onClick,
  hasSinglePointElement,
  hasPipeFitting,
  hasConnector,
  hasMarker,
  hasPassedPlusAndIsToPoint,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const mouseOverHandler = (e: ThreeEvent<MouseEvent>) => {
    if (removeKeyPressed) e.stopPropagation();
    setIsMouseOver(true);
  };

  const pointColor = getPointColor(
    isTrenchEndPoint || trenchCountInPoint > 1,
    removeKeyPressed,
    isMouseOver,
    isPointSelected,
    hasMarker,
  );
  const opacity = getOpacity(isMouseOver, isPointSelected, trenchCountInPoint, hasMarker);
  const material = getCachedMaterial(pointColor, opacity);
  const geometry = getGeometry(
    hasSinglePointElement,
    hasPipeFitting,
    hasConnector,
    hasPassedPlusAndIsToPoint,
  );

  return (
    <mesh
      name={`${Object3dNames.ElementPoint}:${pointId}`}
      scale={[size, size, size]}
      matrixWorldNeedsUpdate={true}
      position={position}
      geometry={geometry}
      material={material}
      onClick={onClick}
      onPointerOver={mouseOverHandler}
      onPointerOut={() => setIsMouseOver(false)}
      onUpdate={enableLayerOne}
      renderOrder={getRenderOrder(Object3dNames.ElementPoint)}
    />
  );
};
