import { trackUserEvent, UserEvent } from '@core/analytics';
import { distanceBetweenCoordinates } from '@core/logic';
import type { AppDispatch } from '@core/redux/store';
import { patchTransformScan, ScanTransformRequest } from '@scans/components/Scans/helper';
import { scanActions } from '@scans/redux';
import type { Scan } from '@scans/types';
import { isEndPoint } from '@trenches/components/Trench/helper';
import { pointSelectors, trenchSelectors, trenchThunks } from '@trenches/redux';
import type { NewPoint, Point, Trench } from '@trenches/types';
import { eulerToJson, vectorToJson } from '@viewer3D/helper';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import type { VisibleScans } from '@viewer3D/types';
import { useDispatch, useSelector } from 'react-redux';
import { Event, Vector3 } from 'three';
import type { TransformControls } from 'three/TransformControls';
import { transformTrenchPointsWithScanState } from '../ScanDetailPanel/state';

interface UserData {
  processingResultId: string;
  scanId: string;
}

export interface Props {
  selectedPoint?: Point;
  selectedTrenchId?: string;
  selectedScan?: Scan;
  visibleScans: VisibleScans;
  selectScansUnderMouseCursor: (e: globalThis.MouseEvent, visibleScanIds: string[]) => void;
  transformScan: (event: Event) => void;
  resetUserTransformation: (
    scanId: string,
    processingResultId: string,
    body: ScanTransformRequest,
  ) => void;
  resetScanToUserTransformation: (scanId: string) => void;
  createTrench: (
    fromPoint: NewPoint,
    toPoint: NewPoint,
    prelabelId?: string,
    prelabeledWidth?: number,
  ) => Promise<unknown>;
  extendOrBranchTrench: (
    point: NewPoint,
    prelabelId?: string,
    prelabeledWidth?: number,
  ) => Promise<unknown>;
}

export const useConnect = (): Props => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const selectedPoint = useSelector(pointSelectors.selectById(selectedPointId));
  const selectedTrenchId = useSelector(viewer3dSelectors.selectedTrenchId);
  const selectedScan = useSelector(viewer3dSelectors.selectedScan);
  const selectedTrench = useSelector(trenchSelectors.selectById(selectedTrenchId));
  const projectId = useSelector(viewer3dSelectors.selectedProjectId)!;
  const visibleScans = useSelector(viewer3dSelectors.visibleScans);
  const isSelectedPointEndpoint = isEndPoint(selectedPointId, selectedTrench);
  const isSelectedPointFirstPoint = selectedTrench?.pointIds[0] === selectedPointId;
  const dispatch = useDispatch<AppDispatch>();

  const createTrenchForProject = async (
    projectId: string,
    fromPoint: NewPoint,
    toPoint: NewPoint,
    prelabelId?: string,
    prelabeledWidth?: number,
  ) => {
    const { payload } = await dispatch(
      trenchThunks.createTrench({
        path: ['projects', projectId, 'trenches'],
        body: { projectId, fromPoint, toPoint, prelabelId, width: prelabeledWidth },
      }),
    );

    return payload as Trench;
  };

  const extendTrench = async (
    selectedTrenchId: string,
    fromPointId: string,
    newPoint: NewPoint,
    prelabelId?: string,
    prelabeledWidth?: number,
  ) => {
    const { payload } = await dispatch(
      trenchThunks.extendTrench({
        path: ['projects', projectId, 'trenches', selectedTrenchId, 'extend'],
        body: {
          fromPointId,
          newPoint,
          prelabelId,
          width: prelabeledWidth,
        },
      }),
    );
    return payload as Trench;
  };

  const branchFromTrench = async (
    projectId: string,
    fromPointId: string,
    toPoint: NewPoint,
    prelabelId?: string,
    prelabeledWidth?: number,
  ) => {
    const { payload } = await dispatch(
      trenchThunks.createTrench({
        path: ['projects', projectId, 'trenches'],
        body: {
          fromPointId,
          toPoint,
          projectId,
          prelabelId,
          width: prelabeledWidth,
        },
      }),
    );
    return payload as Trench;
  };

  const selectTrench = (trenchId: string) =>
    dispatch(viewer3dActions.setSelectedTrenchId(trenchId));

  const selectPoint = (pointId: string) => dispatch(viewer3dActions.setSelectedPointId(pointId));

  return {
    selectedPoint,
    selectedScan,
    selectedTrenchId,
    visibleScans,

    resetUserTransformation: async (
      scanId: string,
      processingResultId: string,
      body: ScanTransformRequest,
    ) => {
      // has backend request
      await patchTransformScan(dispatch, scanId, projectId, processingResultId, body);
    },

    transformScan: async ({ target }) => {
      const transformObject = (target as TransformControls).object;
      if (!transformObject) return;
      const [scan] = transformObject.children;
      if (!scan) return;
      const { processingResultId, scanId } = (scan.userData as UserData) ?? {};
      if (!processingResultId) return;

      const userTranslation = transformObject.position.clone().add(scan.position.clone());
      const userRotation = transformObject.rotation.clone();

      const {
        rotationAngle: angle,
        rotationAxis: axis,
        worldPosition,
        worldPositionStart,
        mode,
      } = target as TransformControls;

      const processingResult = {
        userTranslation: vectorToJson(userTranslation),
        userRotation: eulerToJson(userRotation),
      };
      const points = {
        userTranslation: vectorToJson(worldPosition),
        userRotation:
          mode === 'rotate'
            ? {
                angle,
                axis: vectorToJson(axis),
              }
            : {
                angle: 0,
                axis: vectorToJson(new Vector3(0, 0, 0)),
              },
        transformOrigin: vectorToJson(worldPositionStart),
      };
      const { applyTransformation: transformAllPointsCheckbox } =
        transformTrenchPointsWithScanState;
      const body = transformAllPointsCheckbox ? { processingResult, points } : { processingResult };
      await patchTransformScan(dispatch, scanId, projectId, processingResultId, body);
    },

    selectScansUnderMouseCursor: ({ clientX, clientY }, visibleScanIds) => {
      const scanId = visibleScanIds[0];
      dispatch(viewer3dActions.clearSelection());
      dispatch(viewer3dActions.setSelectedScanId(scanId));
      if (visibleScanIds.length < 2) return;
      dispatch(
        viewer3dActions.setScanSelection({
          left: clientX,
          top: clientY,
          scanIds: visibleScanIds,
        }),
      );
    },

    resetScanToUserTransformation: (scanId) => {
      /** just client, no persistence */
      dispatch(
        scanActions.updateOne({
          id: scanId,
          disableUserTransformation: false,
        }),
      );
    },

    extendOrBranchTrench: async (point, prelabelId, prelabeledWidth) => {
      if (!selectedTrenchId || !selectedPointId || !selectedPoint) {
        throw new Error('No trench or point selected!');
      }
      const distanceExtendedOrBranched = distanceBetweenCoordinates(selectedPoint, point);
      const { pointIds, id } = isSelectedPointEndpoint
        ? await extendTrench(selectedTrenchId, selectedPointId, point, prelabelId, prelabeledWidth)
        : await branchFromTrench(projectId, selectedPointId, point, prelabelId, prelabeledWidth);

      trackUserEvent(UserEvent.TrenchMeterLabeled, {
        labeledMeters: distanceExtendedOrBranched,
      });

      const nextSelectedPointId = pointIds[isSelectedPointFirstPoint ? 0 : pointIds.length - 1];
      selectPoint(nextSelectedPointId);
      if (nextSelectedPointId) selectTrench(id);
    },

    createTrench: async (fromPoint, toPoint, prelabelId, prelabeledWidth) => {
      const { pointIds, id } = await createTrenchForProject(
        projectId,
        fromPoint,
        toPoint,
        prelabelId,
        prelabeledWidth,
      );
      const [, secondPointId] = pointIds;
      selectPoint(secondPointId);
      selectTrench(id);
      trackUserEvent(UserEvent.TrenchMeterLabeled, {
        labeledMeters: distanceBetweenCoordinates(fromPoint, toPoint),
      });
    },
  };
};
