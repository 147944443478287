import { errorBox } from '@core/components/AlertBox';
import { hasArrayDuplicates, pickFromArray } from '@core/logic';
import type { SplineElement } from '@elements/types';
import type { Trench, TrenchSegment } from '@trenches/types';

const doSegmentsMatch =
  (segment1: TrenchSegment) =>
  (segment2: TrenchSegment): boolean =>
    hasArrayDuplicates([
      segment1.fromPointId,
      segment1.toPointId,
      segment2.fromPointId,
      segment2.toPointId,
    ]);

/**
 * This expects that the first element at the first call will stay in place.
 *
 * @param start
 * @param rest
 */
const sortSegmentsContinuously = ([start, ...rest]: TrenchSegment[]): TrenchSegment[] => {
  if (!start) return [];
  if (!rest.length) return [start];
  const [nextSegment, restOfSegments] = pickFromArray(doSegmentsMatch(start), rest);
  if (!nextSegment) {
    console.log(
      'There is a segment missing so we cannot build a continuous path of the element. The gap is between this segment ',
      start,
    );
    console.log('and the rest of the element segments ', restOfSegments);
    throw new Error('Segment missing!');
  }
  return [start, ...sortSegmentsContinuously([nextSegment, ...restOfSegments])];
};

const isSegmentEndpoint =
  (id?: string) =>
  (segment: TrenchSegment): boolean =>
    segment.fromPointId === id || segment.toPointId === id;

const getSortedFromAndToPoints = (
  { fromPointId, toPointId }: TrenchSegment,
  i: number,
  arr: TrenchSegment[],
): [string, string] => {
  const containsFromPoint = isSegmentEndpoint(fromPointId);
  const next = arr[i + 1];
  if (next) return containsFromPoint(next) ? [toPointId, fromPointId] : [fromPointId, toPointId];
  const prev = arr[i - 1];
  if (prev) return containsFromPoint(prev) ? [fromPointId, toPointId] : [toPointId, fromPointId];
  return [fromPointId, toPointId];
};

export const isSegmentStartCloserToElementStart = (
  theSegment: TrenchSegment,
  el: SplineElement,
  allTrenches: Trench[],
): boolean => {
  if (!el.fromPoint) return false;

  if (theSegment.fromPointId === el.fromPoint.id) {
    // segment start point IS selected element start point
    return true;
  }

  // get all segments with the selected element in it
  const allSegmentsWithElement = allTrenches
    .flatMap((t) => t.segments)
    .filter((s) => s.splineElementIds.includes(el.id));

  if (allSegmentsWithElement.length === 0) return false;
  if (allSegmentsWithElement.length === 1)
    return allSegmentsWithElement[0].fromPointId === el.fromPoint.id;

  const [startSegment, restOfSegments] = pickFromArray(
    isSegmentEndpoint(el.fromPoint.id),
    allSegmentsWithElement,
  );

  let sortedSegments: TrenchSegment[] = [];
  try {
    sortedSegments = sortSegmentsContinuously([startSegment!, ...restOfSegments]);
  } catch (e) {
    errorBox(
      "There is an inconsistency in the selected element's data. The element with the missing segment is: " +
        el.id +
        ' See console for further details. Please contact support.',
    );
    console.log(e);
    console.log('The element with the missing segment is:', el);
  }

  const sortedSegmentPoints = sortedSegments.flatMap(getSortedFromAndToPoints);

  return (
    sortedSegmentPoints.indexOf(theSegment.fromPointId) <
    sortedSegmentPoints.indexOf(theSegment.toPointId)
  );
};
