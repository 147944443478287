import { HelpCenterButton } from '@deepup/guide';
import { AppBar, Toolbar } from '@mui/material';
import { projectsHistoryActions, projectsHistorySelectors } from '@menu/redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeepUpLogoFull } from '@deepup/icons';
import { MenuButton } from './MenuButton';
import { UserAccount } from './UserAccount';

export const MenuBar = () => {
  const pinnedProjects = useSelector(projectsHistorySelectors.selectPinnedProjects);
  const recentProjects = useSelector(projectsHistorySelectors.selectRecentProjects);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(projectsHistoryActions.loadProjectsHistory());
  }, []);

  return (
    <AppBar position="sticky" sx={(theme) => ({ zIndex: theme.zIndex.modal })}>
      <Toolbar variant="dense" sx={{ display: 'flex', gap: 1 }}>
        <DeepUpLogoFull width={80} height="auto" />
        <MenuButton
          id="projects-menu"
          title="Projects"
          recentItems={recentProjects.map(({ title, id }) => ({
            title,
            href: `/projects/${id}`,
            id,
          }))}
          pinnedItems={pinnedProjects.map(({ title, id }) => ({
            title,
            href: `/projects/${id}`,
            id,
          }))}
        />
        <div style={{ flexGrow: 1 }} />
        <HelpCenterButton />
        <UserAccount />
      </Toolbar>
    </AppBar>
  );
};
