import { formatAddress, formatCreatedDate } from '@core/logic';
import { elementActions, elementSelectors } from '@elements/redux';
import { pointElementSelectors, pointElementThunks } from '@elements/redux/point-element.slice';
import type { PointElement, SplineElement } from '@elements/types';
import { IconButton, ListItemButton, ListItemText, Stack } from '@mui/material';
import { setEditMode } from '@projects/edit-modes';
import { EditMode } from '@projects/edit-modes/types';
import { trenchSelectors, trenchThunks } from '@trenches/redux';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch } from '@core/redux/store';
import { PencilOutline, TrashcanOutline } from '@deepup/icons';
import {
  ElementConfirmDeleteDialog,
  PointElementDialog,
  SplineElementDialog,
} from '../ElementDialog';

interface ElementListItem {
  title: string;
  subtitle: string;
  element: SplineElement | PointElement;
  isSelected: boolean;
  selectElement: () => void;
  deleteElement?: () => void;
  isLabeled: boolean;
}

export const useSplineElementConnect = (splineElementId: string): ElementListItem => {
  const dispatch = useDispatch<AppDispatch>();
  const splineElement = useSelector(elementSelectors.selectById(splineElementId))!;
  const trenchId = useSelector(trenchSelectors.findByElementId(splineElementId))?.id;
  const [selectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const isLabeled = !!trenchId;

  return {
    title: splineElement.name,
    subtitle:
      formatCreatedDate(splineElement.createdAt) + ' ' + (!isLabeled ? ' (not labeled!)' : ''),
    element: splineElement,
    isSelected: selectedElementId === splineElementId,
    isLabeled,
    selectElement: () => {
      if (selectedElementId === splineElementId) return;
      setEditMode(EditMode.Element);
      dispatch(viewer3dActions.setSelectedPointId());
      if (trenchId) {
        // also select the related trench
        dispatch(viewer3dActions.setSelectedTrenchId(trenchId));
      }
      // FIXME: this setSelectedElementIds-action needs to be executed last in order to the focussing feature working properly.
      //  This is a potential point of failure as order shouldn't matter! Refactor if possible.
      dispatch(viewer3dActions.setSelectedElementIds([splineElementId]));
    },
    deleteElement: async () => {
      const { payload } = await dispatch(
        trenchThunks.deleteElement({
          path: ['projects', projectId!, 'splineElements', splineElementId],
        }),
      );
      if (payload instanceof Error) return;
      dispatch(elementActions.removeOne(splineElementId));
      dispatch(viewer3dActions.removeElementIdFromSelection(splineElementId));
    },
  };
};

export const usePointElementConnect = (pointElementId: string): ElementListItem => {
  const dispatch = useDispatch<AppDispatch>();
  const pointElement = useSelector(pointElementSelectors.selectById(pointElementId))!;
  const projectId = useSelector(viewer3dSelectors.selectedProjectId)!;
  const hasPointsChildren = useSelector(pointElementSelectors.selectAll).some(
    (point) => point.parentPointElementId === pointElementId,
  );
  const hasSplinesChildren = useSelector(elementSelectors.selectAll).some(
    (element) => element.parentPointElementId === pointElementId,
  );
  const hasChildren = hasPointsChildren || hasSplinesChildren;
  const [selectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const isLabeled = pointElement.pointId !== undefined;
  return {
    title: formatAddress(pointElement.address),
    isLabeled,
    subtitle:
      (pointElement.name ? pointElement.name + ', ' : '') +
      formatCreatedDate(pointElement.createdAt) +
      ' ' +
      (!isLabeled ? ' (not labeled!)' : ''),
    element: pointElement,
    isSelected: selectedElementId === pointElementId,
    selectElement: () => {
      if (selectedElementId === pointElementId) return;
      setEditMode(EditMode.Element);
      dispatch(viewer3dActions.setSelectedElementIds([pointElementId]));
      pointElement.pointId && dispatch(viewer3dActions.setSelectedPointId(pointElement.pointId));
    },
    deleteElement: hasChildren
      ? undefined
      : async () => {
          const { payload } = await dispatch(
            pointElementThunks.delete({
              path: ['projects', projectId, 'pointElements', pointElementId],
              id: pointElementId,
            }),
          );
          if (payload instanceof Error) return;
          dispatch(viewer3dActions.setSelectedElementIds([]));
        },
  };
};

export const ElementListItem = ({ elementId }: { elementId: string }) => {
  const isPointElement = !!useSelector(pointElementSelectors.selectById(elementId));
  const { title, subtitle, element, isSelected, selectElement, deleteElement, isLabeled } = (
    isPointElement ? usePointElementConnect : useSplineElementConnect
  )(elementId);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [addUpdateDialogOpen, setAddUpdateDialogOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleDelete = async () => {
    setConfirmDeleteOpen(false);
    deleteElement?.();
  };

  useEffect(() => {
    if (!isSelected || !ref.current) return;
    // scroll selected element into view
    setTimeout(() => {
      // wait until all expanding animations are done
      ref.current?.scrollIntoView({ block: 'center', behavior: 'auto' });
    }, 500);
  }, [isSelected]);

  return (
    <>
      <ListItemButton
        ref={ref}
        dense
        aria-label="element-list-item"
        selected={isSelected}
        title={!isLabeled ? 'Element is not labeled on any trench' : undefined}
        autoFocus={isSelected}
        onClick={selectElement}
        sx={{
          backgroundColor: isLabeled
            ? undefined
            : (theme) => theme.palette.error.dark + ' !important',
        }}
      >
        <ListItemText
          primary={title}
          secondary={subtitle}
          primaryTypographyProps={{ title, noWrap: true }}
          secondaryTypographyProps={{ title: subtitle, noWrap: true }}
        />
        <Stack direction="row" sx={{ display: 'none', '*:hover > &': { display: 'inline-flex' } }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // prevent element selection side-effects
              setAddUpdateDialogOpen(true);
            }}
            aria-label="edit"
            title="Open edit dialog for Element"
          >
            <PencilOutline />
          </IconButton>
          {deleteElement && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // prevent element selection side-effects
                setConfirmDeleteOpen(true);
              }}
              aria-label="delete"
              title="Delete Element"
            >
              <TrashcanOutline />
            </IconButton>
          )}
        </Stack>
      </ListItemButton>
      {confirmDeleteOpen && (
        <ElementConfirmDeleteDialog
          onClose={() => setConfirmDeleteOpen(false)}
          onConfirm={handleDelete}
        />
      )}
      {addUpdateDialogOpen &&
        (isPointElement ? (
          <PointElementDialog
            elementToUpdate={element as PointElement}
            onClose={() => setAddUpdateDialogOpen(false)}
          />
        ) : (
          <SplineElementDialog
            elementToUpdate={element as SplineElement}
            onClose={() => setAddUpdateDialogOpen(false)}
            onDelete={() => {
              deleteElement?.();
              setAddUpdateDialogOpen(false);
            }}
          />
        ))}
    </>
  );
};
