import { Checkbox, FormControlLabel } from '@mui/material';
import { useExternalDataState } from '../ExternalData/hooks';

export const ExternalDataToggle = () => {
  const { isExternalVisible, toggleExternalVisible } = useExternalDataState();

  return (
    <FormControlLabel
      label="External data"
      control={
        <Checkbox color="primary" checked={isExternalVisible} onChange={toggleExternalVisible} />
      }
    />
  );
};
