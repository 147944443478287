import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { QaStateUpdate, Scan, SurfaceClassificationFeedback } from '../types';

export const {
  reducer: scanReducer,
  apiThunks: baseScanThunks,
  actions: scanActions,
  selectors: scanSelectors,
} = crudEntityFactory<Scan>({
  entity: ReduxEntities.Scans,
  removeKeys: [
    EntityRelationKey.Project,
    EntityRelationKey.Tags,
    EntityRelationKey.ProcessingResults,
    EntityRelationKey.SelectedProcessingResult,
  ],
});

export const scanThunks = {
  ...baseScanThunks,
  updateQaState: baseScanThunks.create<QaStateUpdate[]>('qa-state/update', 'PATCH'),
  updateClassification: baseScanThunks.create<SurfaceClassificationFeedback>(
    'surface-classification/update',
    'PATCH',
  ),
};
