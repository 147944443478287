import { Stack } from '@mui/material';
import { MarkerSphere } from './MarkerSphere/MarkerSphere';
import { PipeConnectors } from './PipeConnectors/PipeConnectors';

export const MarkersAndConnectorsSection = ({
  selectedPointId,
}: {
  selectedPointId: string | undefined;
}) => {
  return (
    <Stack padding={2} gap={1}>
      <MarkerSphere selectedPointId={selectedPointId} />
      <PipeConnectors selectedPointId={selectedPointId} />
    </Stack>
  );
};
