import { AppDispatch } from '@core/redux/store';
import { ArrowsCircle1CcwReset, ArrowsCircle2Cw } from '@deepup/icons';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { scanThunks } from '@scans/redux';
import {
  SurfaceClassification,
  SurfaceClassificationFeedbackReasons,
  surfaceClassificationFeedbackReasons,
} from '@scans/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const SurfaceDetails = ({
  scanId,
  surfaceClassification,
}: {
  scanId: string;
  surfaceClassification: SurfaceClassification;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [currentSurface, setCurrentSurface] = useState(surfaceClassification);
  const [feedbackReason, setFeedbackReason] = useState<SurfaceClassificationFeedbackReasons>();

  const hasChangedSurfaces = surfaceClassification !== currentSurface;
  const showFeedbackReasons = hasChangedSurfaces && surfaceClassification !== 'NOT_CLASSIFIED';

  const discardChanges = () => {
    setCurrentSurface(surfaceClassification);
    setFeedbackReason(undefined);
  };

  const sendUpdateSurfaceClassificationRequest = async () => {
    if (currentSurface === 'NOT_CLASSIFIED') {
      throw new Error('No classification specified');
    }

    await dispatch(
      scanThunks.updateClassification({
        path: ['scans', scanId, 'surfaceClassificationFeedback'],
        body: {
          newClassification: currentSurface,
          reason: feedbackReason,
        },
      }),
    );

    await dispatch(
      scanThunks.getOne({
        path: ['scans', scanId],
        params: { projection: 'scanDetail' },
      }),
    );

    setFeedbackReason(undefined);
  };

  useEffect(() => {
    discardChanges();
  }, [scanId]);

  return (
    <Stack gap={3}>
      <Stack direction="row" gap={1}>
        <Chip
          label="Surface"
          color={currentSurface === 'SURFACE' ? 'primary' : undefined}
          clickable
          variant={currentSurface === 'SURFACE' ? 'filled' : 'outlined'}
          onClick={() => setCurrentSurface('SURFACE')}
        />
        <Chip
          label="Trench"
          color={currentSurface === 'TRENCH' ? 'primary' : undefined}
          clickable
          variant={currentSurface === 'TRENCH' ? 'filled' : 'outlined'}
          onClick={() => setCurrentSurface('TRENCH')}
        />
      </Stack>
      <Stack gap={4}>
        {showFeedbackReasons && (
          <Stack gap={2}>
            <Typography>Why was it wrong?</Typography>
            <Stack flexWrap={'wrap'} direction="row" gap={1}>
              {Object.keys(surfaceClassificationFeedbackReasons).map((reason) => (
                <Chip
                  key={reason}
                  label={
                    surfaceClassificationFeedbackReasons[
                      reason as SurfaceClassificationFeedbackReasons
                    ]
                  }
                  color={feedbackReason === reason ? 'primary' : undefined}
                  clickable
                  variant={feedbackReason === reason ? 'filled' : 'outlined'}
                  onClick={() => setFeedbackReason(reason as SurfaceClassificationFeedbackReasons)}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {hasChangedSurfaces && (
          <Stack direction="row" gap={2}>
            <Button
              endIcon={<ArrowsCircle1CcwReset />}
              color="secondary"
              variant="outlined"
              onClick={discardChanges}
            >
              Discard
            </Button>
            <Button
              onClick={sendUpdateSurfaceClassificationRequest}
              endIcon={<ArrowsCircle2Cw />}
              disabled={surfaceClassification !== 'NOT_CLASSIFIED' && !feedbackReason}
              variant="contained"
            >
              Update
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
