import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { Tag } from '@tags/types';

export const {
  reducer: tagReducer,
  apiThunks: tagThunks,
  selectors: tagSelectors,
} = crudEntityFactory<Tag>({
  entity: ReduxEntities.Tags,
  parentEntities: [ReduxEntities.Scans],
  keysInParent: [EntityRelationKey.Tags],
});
