import { MergeA, PencilOutline, Split } from '@deepup/icons';
import {
  CircularProgress,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import { Icon } from '@core/types';
import { useEditModeState } from '@projects/edit-modes';
import { useSelector } from 'react-redux';
import { pointElementSelectors } from '@elements/redux/point-element.slice';
import {
  PointElementDialog,
  SplineElementDialog,
  usePointElementConnect,
  useSplineElementConnect,
} from '@elements/components';
import { PointElement, SplineElement } from '@elements/types';

export interface SelectableItem {
  canBeMerged: boolean;
  canBeSelected?: boolean;
  canBeSplit?: boolean;
  color?: string;
  Icon?: Icon;
  id: string;
  isLoading: boolean;
  isSelected: boolean;
  subtitle?: string;
  title: string;
}

const sortByTitle = (a: SelectableItem, b: SelectableItem): number => (a.title < b.title ? -1 : 1);

const SecondaryAction = ({
  id,
  canBeMerged,
  canBeSplit,
  isLoading,
  onMergeItems,
  onSplitItem,
}: {
  id: string;
  canBeMerged?: boolean;
  canBeSplit?: boolean;
  isLoading: boolean;
  onMergeItems?: (itemId: string) => () => void;
  onSplitItem?: (itemId: string) => () => void;
}) => {
  const secondaryAction = (canBeMerged ? onMergeItems : canBeSplit ? onSplitItem : undefined)?.(id);

  if (!secondaryAction) return null;

  const secondaryActionText = canBeMerged
    ? 'Merge with selected'
    : canBeSplit
    ? 'Split at selected point'
    : undefined;
  const secondaryActionIcon = isLoading ? (
    <CircularProgress color="inherit" size={18} />
  ) : canBeMerged ? (
    <MergeA fill="currentColor" />
  ) : canBeSplit ? (
    <Split fill="currentColor" />
  ) : undefined;

  return (
    <IconButton
      size="small"
      edge="end"
      onClick={(e) => {
        e.stopPropagation();
        secondaryAction();
      }}
      title={secondaryActionText}
      disabled={isLoading}
    >
      {secondaryActionIcon}
    </IconButton>
  );
};

// TODO: refactor to use a single dialog component on the page level.
// Currently, we have 3 different places with similar dialog logic.
export const EditElementDialog = ({
  elementId,
  onClose,
}: {
  elementId: string;
  isOpen?: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}) => {
  const isPointElement = !!useSelector(pointElementSelectors.selectById(elementId));

  const { element, deleteElement } = (
    isPointElement ? usePointElementConnect : useSplineElementConnect
  )(elementId);

  return (
    <>
      {isPointElement ? (
        <PointElementDialog
          elementToUpdate={element as PointElement}
          onClose={() => onClose(false)}
        />
      ) : (
        <SplineElementDialog
          elementToUpdate={element as SplineElement}
          onClose={() => onClose(false)}
          onDelete={() => {
            deleteElement?.();
            onClose(false);
          }}
        />
      )}
    </>
  );
};

export const Selectable3DObjectList = ({
  onMergeItems,
  onSplitItem,
  items,
  selectItem,
}: {
  items: SelectableItem[];
  onMergeItems?: (itemId: string) => () => void;
  onSplitItem?: (itemId: string) => () => void;
  selectItem: (itemId: string) => MouseEventHandler;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [elementEditID, setElementEditID] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' }), 100);
  }, [ref.current]);

  const { isElementMode } = useEditModeState();

  const handleItemClick = (elementId: string) => {
    setElementEditID(elementId);
  };

  if (!items.length) return null;

  return (
    <>
      <List disablePadding>
        {items
          .sort(sortByTitle)
          .map(
            ({
              isSelected,
              id,
              canBeSelected,
              Icon,
              title,
              canBeSplit,
              subtitle,
              color,
              canBeMerged,
              isLoading,
            }) => (
              <ListItemButton
                ref={isSelected ? ref : undefined}
                key={id}
                dense
                disabled={canBeSelected === false}
                selected={isSelected}
                onClick={selectItem(id)}
                divider
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon fill={color} fontSize={24} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true }}
                  primary={title}
                  secondary={subtitle}
                  title={title + (subtitle ? ' – ' + subtitle : '')}
                />
                <SecondaryAction
                  id={id}
                  canBeMerged={canBeMerged}
                  canBeSplit={canBeSplit}
                  isLoading={isLoading}
                  onMergeItems={onMergeItems}
                  onSplitItem={onSplitItem}
                />
                {isElementMode && (
                  <>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); //prevent element selection side-effects
                        handleItemClick(id);
                      }}
                      aria-label="edit"
                      title="Open edit dialog for Element"
                    >
                      <PencilOutline />
                    </IconButton>
                  </>
                )}
              </ListItemButton>
            ),
          )}
      </List>
      {isElementMode && elementEditID !== undefined && (
        <EditElementDialog elementId={elementEditID} onClose={() => setElementEditID(undefined)} />
      )}
    </>
  );
};
