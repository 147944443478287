import { proxy } from 'valtio';

interface FromPoint {
  id: string;
  trenchId: string;
}

/**
 * We use valtio here and not useState() because we use this state in multiple
 * AttributePoints to determine the start/end of a labeled section!
 *
 */
export const attributeFromPointState = proxy<Partial<FromPoint>>({});

export const setAttributeFromPoint = ({ id, trenchId }: Partial<FromPoint>): void => {
  attributeFromPointState.id = id;
  attributeFromPointState.trenchId = trenchId;
};

export const resetAttributeFromPoint = (): void => setAttributeFromPoint({});
