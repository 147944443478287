import { useEffectOnce } from '@core/logic';
import { CircularProgress, Typography } from '@mui/material';
import { userProfileActions, userProfileSelectors } from '@userProfile/redux';
import { UserRole } from '@userProfile/redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredComponent } from '@core/components';

export const Authorization = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector(userProfileSelectors.selectUserProfile);

  useEffectOnce(() => {
    dispatch(userProfileActions.fetchProfile());
  });

  if (!userProfile) {
    return (
      <CenteredComponent>
        <CircularProgress />
      </CenteredComponent>
    );
  }

  if (userProfile.role <= UserRole.NotSupported) {
    window.history.pushState('Unauthorized', '', '/403');
    return (
      <CenteredComponent>
        <Typography variant="h1" color="error" paragraph>
          403
        </Typography>
        <img
          alt="403 meme"
          src="https://media.tenor.com/8kuu11MN_KcAAAAC/you-shall-not-pass-lord-of-the-rings.gif"
        />
        {userProfile.role === UserRole.NotSupported && (
          <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ maxWidth: 498, paddingTop: '1rem' }}
          >
            <p>
              It seems you have been assigned both Editor AND Admin roles in different projects.
            </p>
            <p>This is not your mistake!</p>
            <p>Please contact Marco or Razvan from DeepUp Operations team to fix this.</p>
          </Typography>
        )}
      </CenteredComponent>
    );
  }

  return children;
};
