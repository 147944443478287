import { Button } from '@mui/material';
import { ChevronsDown, ChevronsUp } from '@deepup/icons';

export const LegendCollapseBtn = ({
  onClick,
  label,
  collapsed,
}: {
  onClick: () => void;
  label: string;
  collapsed: boolean;
}) => {
  return (
    <Button fullWidth startIcon={collapsed ? <ChevronsDown /> : <ChevronsUp />} onClick={onClick}>
      {label}
    </Button>
  );
};
