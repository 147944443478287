import { Checkbox, FormControlLabel } from '@mui/material';
import {
  togglePrelabeledTrenchVisibility,
  usePrelabeleTrenchesdState,
} from '@trenches/components/Prelabeled';

export const PrelabelsToggle = () => {
  const { show: showPrelabeledTrench } = usePrelabeleTrenchesdState();

  return (
    <FormControlLabel
      control={
        <Checkbox checked={showPrelabeledTrench} onClick={togglePrelabeledTrenchVisibility} />
      }
      label="Trench prelabels"
    />
  );
};
