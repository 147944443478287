import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { ProcessingResult } from '../types';

export const {
  reducer: processingResultReducer,
  actions: processingResultActions,
  apiThunks: processingResultThunks,
  selectors: processingResultSelectors,
} = crudEntityFactory<ProcessingResult>({
  entity: ReduxEntities.ProcessingResults,
  parentEntities: [ReduxEntities.Scans],
  keysInParent: [EntityRelationKey.ProcessingResults, EntityRelationKey.SelectedProcessingResult],
  removeKeys: [EntityRelationKey.MeshWithOffset],
});
