import { proxy } from 'valtio';

// TRANSFORM TRENCHPOINTS WITH SCAN

interface TransformTrenchPointsWithScanState {
  applyTransformation: boolean;
}

export const transformTrenchPointsWithScanState = proxy<TransformTrenchPointsWithScanState>({
  applyTransformation: true,
});

export const setTransformTrenchPointsWithScan = (applyTransformation: boolean): void => {
  transformTrenchPointsWithScanState.applyTransformation = applyTransformation;
};

export const triggerUndoUseUserTransformation = proxy<{ trigger: boolean }>({ trigger: false });

export const setTriggerUndoUseUserTransformation = (trigger: boolean): void => {
  triggerUndoUseUserTransformation.trigger = trigger;
};
