import { useKeys } from '@core/logic';
import type { ThreeEvent } from '@react-three/fiber';
import type { DeepupCatmullRomCurve } from '@trenches/components/Trench/DeepupCatmullRomCurve';
import type { PointTrenchMap } from '@trenches/components/types';
import type { Point } from '@trenches/types';
import { setTransformControl } from '@viewer3D/components/Controls';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import type { Vector3 } from 'three';
import { MAX_VISIBLE_TRENCH_ENDPOINT_RADIUS, MAX_VISIBLE_TRENCH_POINT_RADIUS } from '../../helper';
import { useConnect } from './connect';
import { TrenchPoint } from './TrenchPoint';

interface TrenchPointsProps {
  curve: DeepupCatmullRomCurve;
  pointTrenchMap: PointTrenchMap;
  trenchId: string;
}

export const TrenchPoints: FC<TrenchPointsProps> = ({ curve, trenchId, pointTrenchMap }) => {
  const {
    removePointFromTrench,
    selectFirstTrenchWithPoint,
    selectPoint,
    selectedTrenchId,
    selectedPointId,
    createOrExtendTrench,
    radius,
    movePointTo,
    hasConnector,
    hasMarker,
  } = useConnect({ trenchId });

  const [addKeyPressed, removeKeyPressed] = useKeys('a', 'r');

  const isTrenchSelected = selectedTrenchId === trenchId;

  const handlePointClick = (point: Point) => async (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    if (removeKeyPressed) {
      if (!isTrenchSelected) return;
      removePointFromTrench(point.id);
    } else if (addKeyPressed) {
      await createOrExtendTrench(point.id);
    } else {
      selectFirstTrenchWithPoint(point.id);
      if (selectedPointId !== point.id) selectPoint(point.id);
      setTransformControl({
        objectName: e.object.name,
        onAfterTranslate: ({ target }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          movePointTo(point, target.worldPosition as Vector3);
        },
      });
    }
  };

  return (
    <group
      name={Object3dNames.TrenchPoints}
      renderOrder={getRenderOrder(Object3dNames.TrenchPoints)}
    >
      {curve.points.map((vec, i) => {
        const point = curve.pointsData[i];
        const trenchPoint = pointTrenchMap[point.id] ?? [];
        const isEndPoint = trenchPoint.filter(({ isEndpoint }) => isEndpoint).length > 0;
        const isHidden =
          radius >
          (isEndPoint ? MAX_VISIBLE_TRENCH_ENDPOINT_RADIUS : MAX_VISIBLE_TRENCH_POINT_RADIUS);

        if (isHidden && !isTrenchSelected) return null;
        const hasPointMarker = hasMarker(point.id);
        return (
          <TrenchPoint
            hasConnector={hasConnector(point.id)}
            hasMarker={hasPointMarker}
            key={point.id}
            pointId={point.id}
            isEndPoint={isEndPoint}
            numberOfTrenches={trenchPoint.length}
            isPointSelected={point.id === selectedPointId}
            isTrenchSelected={isTrenchSelected}
            addKeyPressed={addKeyPressed}
            removeKeyPressed={removeKeyPressed}
            size={radius + (hasPointMarker ? 0.1 : 0)}
            position={vec}
            onClick={handlePointClick(point)}
          />
        );
      })}
    </group>
  );
};
