import { zIndexRange3dHtml } from '@core/constants';
import { getNeighborPairs, randomColor } from '@core/logic';
import type { TrenchPrelabel } from '@core/types';
import { Html } from '@react-three/drei';
import { DeepupCatmullRomCurve } from '@trenches/components/Trench/DeepupCatmullRomCurve';
import type { Point } from '@trenches/types';
import { getCachedMaterial, getRenderOrder, jsonToVector } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { range } from 'lodash';
import React, { FC } from 'react';
import { TubeBufferGeometry } from 'three';
import { usePrelabeleTrenchesdState } from './hooks';

const Segment: FC<{ segment: DeepupCatmullRomCurve; segmentWidth: number | null }> = ({
  segment,
  segmentWidth,
}) => (
  <mesh
    geometry={new TubeBufferGeometry(segment, 3, 0.01, 8, false)}
    material={getCachedMaterial(randomColor(), 0.7)}
  >
    <Html position={segment.getPoint(0.5)} zIndexRange={zIndexRange3dHtml}>
      {segmentWidth ?? 'null'}
    </Html>
  </mesh>
);

const PrelabeledTrench: FC<Pick<TrenchPrelabel, 'segmentWidths' | 'points'>> = ({
  segmentWidths,
  points,
}) => {
  if (!points || points.length === 0) {
    return null;
  }

  const worldPosition = jsonToVector(points[0]);
  const curve = new DeepupCatmullRomCurve();
  curve.updatePoints(points as Point[]);
  const segments = getNeighborPairs(range(0, points.length - 1)).map(
    ([indexFrom, indexTo]) => curve.cloneWithPointIndexBoundaries(indexFrom, indexTo)!,
  );
  return (
    <group position={worldPosition} name={Object3dNames.PrelabeledTrenchSegments}>
      {segments.map((seg, i) => (
        <Segment key={i} segment={seg} segmentWidth={segmentWidths[i]} />
      ))}
    </group>
  );
};

export const PrelabeledTrenches = () => {
  const { show, trenches } = usePrelabeleTrenchesdState();

  if (!((window.debugUserTrenchSegment || show) && trenches.length)) {
    return null;
  }

  return (
    <group renderOrder={getRenderOrder(Object3dNames.PrelabeledTrenches)}>
      {trenches.map(({ id, segmentWidths, points }, i) => (
        <PrelabeledTrench key={`${id}-${i}`} segmentWidths={segmentWidths} points={points} />
      ))}
    </group>
  );
};
