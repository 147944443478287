import { errorBox } from '@core/components/AlertBox';
import { useKey, useKeyCombination } from '@core/logic';
import { elementSelectors, elementThunks } from '@elements/redux';
import type { ScanFilters } from '@projects/pages/detail/hooks';
import { trenchToggleState } from '@projects/pages/detail/LayerToggles/TrenchToggle';
import { pointSelectors } from '@trenches/redux';
import { getRenderOrder } from '@viewer3D/helper';
import { viewer3dSelectors } from '@viewer3D/redux';
import { Object3dNames } from '@viewer3D/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';
import { useConnect } from './connect';
import { EndpointCheckboxes, togglePipeDirectionVisibility } from './PipeDirection';
import { Trench } from './Trench';

export const Trenches = ({ viewport }: Pick<ScanFilters, 'viewport'>) => {
  const { trenchIds, pointTrenchMap } = useConnect(viewport);
  const { areTrenchesVisible } = useSnapshot(trenchToggleState);
  const pipeDirectionVisibilityChanged = useKey('v');
  const shouldChangeDirection = useKeyCombination('Control', 'd');
  const [selectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const selectedElement = useSelector(elementSelectors.selectById(selectedElementId));
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pipeDirectionVisibilityChanged) {
      togglePipeDirectionVisibility();
    }
  }, [pipeDirectionVisibilityChanged]);

  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.fromPoint?.isFinished || selectedElement.toPoint?.isFinished) {
        errorBox(
          "One endpoint of the element is already finished. Please unchecked the 'Finish' checkbox to change the direction.",
        );
        return;
      }
      dispatch(
        elementThunks.patch({
          path: ['projects', projectId!, 'splineElements', selectedElement.id, 'swapDirection'],
        }),
      );
    }
  }, [shouldChangeDirection]);

  const fromPoint = useSelector(pointSelectors.selectById(selectedElement?.fromPoint?.id));
  const toPoint = useSelector(pointSelectors.selectById(selectedElement?.toPoint?.id));

  if (!areTrenchesVisible) {
    return null;
  }

  return (
    <>
      {selectedElement && fromPoint && toPoint && (
        <EndpointCheckboxes
          fromPoint={fromPoint}
          toPoint={toPoint}
          splineElement={selectedElement}
        />
      )}
      <group name={Object3dNames.Trenches} renderOrder={getRenderOrder(Object3dNames.Trenches)}>
        {trenchIds.map((id) => (
          <Trench key={id} trenchId={id} pointTrenchMap={pointTrenchMap} />
        ))}
      </group>
    </>
  );
};
