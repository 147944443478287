import { ReactEventHandler, useEffect, useState } from 'react';
import { useEffectOnce } from '@core/logic';

let blockAllShortcuts = false;

export const useKey = (targetKey: string, shouldStopEvent = false): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const handleBlur = () => {
      setKeyPressed(false);
    };

    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffectOnce(() => {
    const keyHandler = (pressed: boolean) => (e: KeyboardEvent) => {
      if (
        e.key !== targetKey ||
        (e.metaKey && e.key === 'c') || // Prevent Ctrl+c from being captured.
        (e.ctrlKey && e.key === 'c') || // Meta+c is the macos variant of Ctrl+c.
        (e.target instanceof HTMLInputElement && ['text', 'search'].includes(e.target.type)) ||
        (blockAllShortcuts && e.type !== 'keyup') // pass the event if key was released
      ) {
        // prevents unwanted side effects when User types in a text input
        return;
      }
      if (shouldStopEvent) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
      setKeyPressed(pressed);
    };
    const downHandler = keyHandler(true);
    const upHandler = keyHandler(false);

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export const useKeys = (...keys: string[]): boolean[] => keys.map((k) => useKey(k, false));

/**
 * Continues cancelled events.
 */
export const continueEventPropagation: ReactEventHandler = (e) =>
  requestAnimationFrame(() => window.dispatchEvent(e.nativeEvent));

export const useBlockAllKeyboardShortcuts = () => {
  useEffectOnce(() => {
    blockAllShortcuts = true;
    return () => {
      blockAllShortcuts = false;
    };
  });
};
