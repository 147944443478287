import React from 'react';
import { Typography, Chip, Divider, List, ListItemText, Box, Stack } from '@mui/material';
import { dateTimeFormatter, toHumanReadable } from '@core/logic';
import { useSnapshot } from 'valtio';
import { photosState } from './state';

export const PhotoDetails: React.FC = () => {
  const { selectedPhoto } = useSnapshot(photosState);

  if (!selectedPhoto) {
    return <Typography variant="h6">No Photo Selected</Typography>;
  }

  const photoProps: [string, string][] = [
    ['Date', selectedPhoto.recordedAt ? dateTimeFormatter(selectedPhoto.recordedAt) : 'Unknown'],
    selectedPhoto.address ? ['Location', toHumanReadable(selectedPhoto.address)] : undefined,
    selectedPhoto.category ? ['Category', toHumanReadable(selectedPhoto.category.name)] : undefined,
    selectedPhoto.comment ? ['Comment', selectedPhoto.comment] : undefined,
  ].filter(Boolean) as [string, string][];

  return (
    <>
      {selectedPhoto.url ? (
        <Box
          component="img"
          src={selectedPhoto.url}
          alt="Selected Photo"
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain',
          }}
          onError={() => console.error('Failed to load image.')}
        />
      ) : (
        <Typography variant="body1">Image not available.</Typography>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={2} pb={0}>
        <Typography variant="body2" fontWeight={'bold'}>
          GNSS
        </Typography>
        <Chip
          label={toHumanReadable(selectedPhoto.geoPositionSolution)}
          size="medium"
          sx={(theme) => ({
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: 'bold',
          })}
        />
      </Stack>
      <Stack p={2} pt={0}>
        <List dense>
          {photoProps.map(([label, value], index) => (
            <ListItemText key={index} primary={label} secondary={value} />
          ))}
        </List>
      </Stack>
      <Divider />
    </>
  );
};

export default PhotoDetails;
