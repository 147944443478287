import { useKey } from '@core/logic';
import { editModeState } from '@projects/edit-modes/state';
import { EditMode } from '@projects/edit-modes/types';
import { PointColors } from '@trenches/colors';
import { getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { sphereGeometry } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { ForwardedRef, forwardRef, ForwardRefRenderFunction } from 'react';
import type { BufferGeometry, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { useSnapshot } from 'valtio';

export type MouseSphereMesh = Mesh<BufferGeometry, MeshBasicMaterial>;
type MouseSphereRef = ForwardedRef<MouseSphereMesh>;

interface MouseSphereProps {
  position?: Vector3;
}

export const MouseSphereBase: ForwardRefRenderFunction<MouseSphereMesh, MouseSphereProps> = (
  { position },
  ref: MouseSphereRef,
) => {
  const { mode: editMode } = useSnapshot(editModeState);
  const addPressed = useKey('a');

  return (
    <mesh
      ref={ref}
      name={Object3dNames.MouseSphere}
      geometry={sphereGeometry}
      material={getCachedMaterial(PointColors.Endpoint, 0.5)}
      visible={addPressed && editMode === EditMode.Trench}
      position={position}
      scale={[0.03, 0.03, 0.03]}
      renderOrder={getRenderOrder(Object3dNames.MouseSphere)}
    />
  );
};

export const MouseSphere = forwardRef<MouseSphereMesh, MouseSphereProps>(MouseSphereBase);
