import { environment } from '@core/environment';
import { elementReducer, elementTypeReducer } from '@elements/redux';
import { pipeConnectorReducer } from '@elements/redux/connectors.slice';
import { markerSphereReducer } from '@elements/redux/markers.slice';
import { pipeFittingReducer } from '@elements/redux/pipe-fitting.slice';
import { pointElementReducer } from '@elements/redux/point-element.slice';
import { projectsHistoryReducer } from '@menu/redux';
import { photoReducer } from '@photos/slice';
import { processingResultReducer } from '@processingResults/redux';
import { projectReducer } from '@projects/redux';
import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { scanReducer } from '@scans/redux';
import { tagReducer } from '@tags/redux';
import { pointReducer, trenchReducer } from '@trenches/redux';
import { userProfileReducer } from '@userProfile/redux';
import { viewer3dMiddleware, viewer3dReducer } from '@viewer3D/redux';
import { ReduxEntities } from './enum';

const reducer = combineReducers({
  [ReduxEntities.Projects]: projectReducer,
  [ReduxEntities.Scans]: scanReducer,
  [ReduxEntities.SplineElementTypes]: elementTypeReducer,
  [ReduxEntities.SplineElements]: elementReducer,
  [ReduxEntities.Points]: pointReducer,
  [ReduxEntities.ProjectsHistory]: projectsHistoryReducer,
  [ReduxEntities.Tags]: tagReducer,
  [ReduxEntities.Trenches]: trenchReducer,
  [ReduxEntities.Viewer3d]: viewer3dReducer,
  [ReduxEntities.ProcessingResults]: processingResultReducer,
  [ReduxEntities.Photos]: photoReducer,
  [ReduxEntities.PointElements]: pointElementReducer,
  [ReduxEntities.PipeFittings]: pipeFittingReducer,
  [ReduxEntities.PipeConnectors]: pipeConnectorReducer,
  [ReduxEntities.MarkerSpheres]: markerSphereReducer,
  [ReduxEntities.UserProfile]: userProfileReducer,
});

export const store = configureStore({
  reducer,
  devTools: environment.isDev,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).prepend(
      viewer3dMiddleware.middleware as Middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
