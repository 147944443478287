import { AppBar, Stack, Toolbar, Typography } from '@mui/material';
import { usePermissions } from '@userProfile/hooks';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { AddressSearchField } from './AddressSearchField';
import { DownloadButton } from './DownloadButton';
import { UploadButton } from './ExternalData/UploadButton';
import { ScanStats } from './ScanStats';

export const ProjectBar = () => {
  const permissions = usePermissions();
  const project = useSelector(viewer3dSelectors.selectedProject)!;

  return (
    <AppBar position="relative" elevation={2}>
      <Toolbar sx={{ gap: 2 }}>
        <Stack flexGrow={1}>
          <Typography variant="h6" noWrap>
            {project.name}
          </Typography>
          <ScanStats />
        </Stack>
        <UploadButton />
        {permissions.canDownloadProjects && <DownloadButton />}
        <AddressSearchField />
      </Toolbar>
    </AppBar>
  );
};
