/**
 * https://wiki.openstreetmap.org/wiki/Zoom_levels
 */

export const viewportWidthToZoom = (viewportWidth: number): number => {
  // 1 Tile has 512x512px -> 4x4 Tiles -> 2048x2048 Texture;
  const targetTileWidth = viewportWidth / 4; // Width in meters
  // Start with zoom level of 19 which is roughly 38 meters
  let zoom = 19;
  let tileWidth = 38;
  // Increase tile width until we find the best match
  while (tileWidth < targetTileWidth) {
    zoom -= 1;
    tileWidth *= 2;
  }
  return zoom;
};
