import { ChevronsDown, ChevronsUp } from '@deepup/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { Scan } from '@scans/types';
import { DateTime } from 'luxon';
import { useSnapshot } from 'valtio';
import { checkboxSelectionState, toggleCheckedScans } from '../checkboxSelectionState';
import { ScanListItem } from './ScanListItem';

export const ScansDateGroupItem = ({
  date,
  scans,
  open,
  toggleOpen,
}: {
  date: DateTime;
  scans: Scan[];
  open: boolean;
  toggleOpen: () => void;
}) => {
  const { checkedScans } = useSnapshot(checkboxSelectionState);
  const checkedScansInGroup = scans.filter(({ id }) => checkedScans.some((s) => s.id === id));

  const onlySomeScansAreChecked =
    checkedScansInGroup.length > 0 && checkedScansInGroup.length < scans.length;
  const allScansAreChecked = checkedScansInGroup.length === scans.length;

  return (
    <Accordion
      elevation={8}
      expanded={open}
      onChange={toggleOpen}
      slotProps={{ transition: { unmountOnExit: true } }}
      data-testid="scan-grouping"
    >
      <AccordionSummary>
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <Checkbox
            indeterminate={onlySomeScansAreChecked}
            checked={onlySomeScansAreChecked || allScansAreChecked}
            onClick={(e) => {
              e.stopPropagation(); // prevents collapsing the list
              toggleCheckedScans(scans);
            }}
          />
          <Typography variant="subtitle2" flexGrow={1}>
            {date.isValid ? date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : 'Date unknown'}
          </Typography>
          <Typography variant="subtitle2" color="secondary">
            {scans.length}
          </Typography>
          <IconButton size="medium" disableRipple>
            {open ? <ChevronsUp /> : <ChevronsDown />}
          </IconButton>
        </Stack>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {scans.map((scan) => (
          <ScanListItem key={scan.id} scan={scan} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
