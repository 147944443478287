import { request } from '@core/api';
import { DateTime } from 'luxon';
import type { PossibleEventPayloads, TimeEvent, TrackingEvent, UserEvent } from './types';

export const sendTrackingEvent = <T extends TrackingEvent>(
  event: T,
  payload: PossibleEventPayloads<T>,
): void => {
  request('POST', {
    path: ['tracking-events'],
    body: {
      event,
      payload,
      timestamp: DateTime.now().toISO(),
    },
  });
};

/**
 * TODO
 *  This is a wrapper around sendTrackingEvent that automatically adds the projectId to the payload.
 *  In the future directly insert the projectId into the payload and use sendTrackingEvent() instead of this function.
 */
export const sendTrackingEventWithProjectId = <T extends TrackingEvent>(
  event: T,
  payload: Omit<PossibleEventPayloads<T>, 'projectId'>,
): void => {
  // TODO this only works for the project detail page. When implementing events for other pages where the projectId is not needed, find a better solution for this!
  const [, , projectId] = window.location.pathname.split('/');
  sendTrackingEvent(event, { projectId, ...payload } as PossibleEventPayloads<T>);
};

export const trackUserEvent = <T extends UserEvent>(
  event: T,
  payload: Omit<PossibleEventPayloads<T>, 'projectId'>, // TODO remove Omit
): void => {
  sendTrackingEventWithProjectId(event, payload);
};

export const trackTimeEvent = (
  event: TimeEvent,
  durationInMilliseconds: number,
  payload: Pick<PossibleEventPayloads<TimeEvent>, 'projectId'>,
): void => {
  const minutes = Math.round(durationInMilliseconds / 1000 / 60);
  if (minutes === 0) return; // don't track no time
  sendTrackingEvent(event, {
    ...payload,
    timeSpentInMinutes: minutes,
  });
};
