import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

export const LogoutButton = (): JSX.Element => {
  const { logout } = useAuth0();
  return (
    <Button
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      data-cy="auth__button-logout"
    >
      Logout
    </Button>
  );
};
