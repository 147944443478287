import React, { useState } from 'react';
import { QAState, qaStates } from '@scans/types';
import { IconButton, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { toHumanReadable } from '@core/logic';
import { CheckmarkCircleFilled } from '@deepup/icons';
import { uncheckAllScans } from './ScansInScopeList/checkboxSelectionState';
import { ReworkStatus } from './ScansInScopeList/ReworkStatus';
import { useConnectScans } from './ScansInScopeList/connect';

export const QAStateSelectorBar = () => {
  const { checkedScanIds, scans, updateScansQaState, isNotSetAsFilter } = useConnectScans();
  const [nextScanQaStatus, setNextScanQaStatus] = useState<QAState>('DONE');

  if (!checkedScanIds.length) return null;

  return (
    <Paper
      elevation={12}
      square
      sx={{
        position: 'sticky',
        bottom: 0,
        zIndex: 1, // z-index is needed in order to prevent overlap from ScanListItems behind the QAStateSelectorBar!
        mx: -2,
        mb: -2,
      }}
    >
      <Stack mx={2} my={1} gap={1} direction="row" alignItems="center">
        <Typography flexGrow={1}>
          {checkedScanIds.length} of {scans.length} selected
        </Typography>
        <TextField
          select
          value={nextScanQaStatus}
          label="Set status"
          SelectProps={{
            MenuProps: {
              disablePortal: true,
            },
          }}
        >
          {qaStates.filter(isNotSetAsFilter).map((status) => (
            <MenuItem key={status} value={status} onClick={() => setNextScanQaStatus(status)}>
              {toHumanReadable(status)}
            </MenuItem>
          ))}
        </TextField>
        <IconButton
          data-testid="apply-scans-qa-state"
          onClick={() => {
            updateScansQaState(nextScanQaStatus as QAState);
            uncheckAllScans();
            setNextScanQaStatus('DONE');
          }}
          color="primary"
          edge="end"
        >
          <CheckmarkCircleFilled />
        </IconButton>
      </Stack>

      {nextScanQaStatus === 'TO_REWORK' && <ReworkStatus />}
    </Paper>
  );
};
