import type { BoundingBoxWithHeight } from '@viewer3D/types';
import { clamp } from 'lodash';

// Factor to scale the viewport dimension to a good-looking radius
const VIEWPORT_TO_RADIUS_FACTOR = 1 / 1000;
const MIN_RADIUS = 0.01;
export const MAX_RADIUS = 2;
export const MAX_VISIBLE_DEFAULT_RADIUS = 0.25;

export const TRENCH_SEGMENT_DEFAULT_FACTOR = 1;
export const getTrenchSegmentDynamicFactor = (splineElementLen: number) =>
  // Divide by 2 (empiric number) to make the trench segment a bit thinner
  TRENCH_SEGMENT_DEFAULT_FACTOR + splineElementLen / 2;

export const INTERMEDIATE_POINT_FACTOR = 2;

export const TRENCH_POINT_FACTOR = 3;
export const MAX_VISIBLE_TRENCH_ENDPOINT_RADIUS = TRENCH_POINT_FACTOR * MAX_RADIUS - 1;
export const MAX_VISIBLE_TRENCH_POINT_RADIUS = MAX_VISIBLE_DEFAULT_RADIUS;

export const ELEMENT_POINT_FACTOR = 3;
export const MAX_VISIBLE_ELEMENT_ENDPOINT_RADIUS = ELEMENT_POINT_FACTOR * MAX_RADIUS - 0.1;
export const MAX_VISIBLE_ELEMENT_POINT_RADIUS = MAX_VISIBLE_DEFAULT_RADIUS;

export const ATTRIBUTE_POINT_FACTOR = 3;
export const MAX_VISIBLE_ATTRIBUTE_POINT_RADIUS = 0.5;

/* Visual comparation of the different point sizes :
  |....|         <-- trench segment
  |........|     <-- intermediate point
  |............| <-- trench point
  |............| <-- element point with default radius
  |............| <-- attribute point
*/

export const getTrenchRadiusForViewport = (
  factor: number,
  viewport?: BoundingBoxWithHeight,
): number => {
  let radius = MIN_RADIUS;
  if (viewport) {
    const { minX, minY, maxX, maxY } = viewport;
    const width = maxX - minX;
    const height = maxY - minY;
    radius = Math.min(width * VIEWPORT_TO_RADIUS_FACTOR, height * VIEWPORT_TO_RADIUS_FACTOR);
  }
  return clamp(radius, MIN_RADIUS, MAX_RADIUS) * factor;
};
