export enum ReduxEntities {
  SplineElementTypes = 'splineElementTypes',
  SplineElements = 'splineElements',
  PointElements = 'pointElements',
  Photos = 'photos',
  PipeFittings = 'pipeFittings',
  PipeConnectors = 'pipeConnectors',
  MarkerSpheres = 'markerSpheres',
  Points = 'points',
  ProcessingResults = 'processingResults',
  Projects = 'projects',
  ProjectsHistory = 'projectsHistory',
  Scans = 'scans',
  Tags = 'tags',
  Trenches = 'trenches',
  Viewer3d = 'viewer3d',
  UserProfile = 'userProfile',
}
