import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import type { BoundingBoxWithHeight } from '@viewer3D/types';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import type {
  CameraControlsProps,
  MapDispatchToProps,
  MapStateToProps,
  MergedProps,
} from './types';

const numbersMatch = (a: number, b: number, precision: number): boolean => {
  const smaller = Math.min(a, b);
  const bigger = Math.max(a, b);
  return bigger - smaller < precision;
};

const boundsMatch = (
  a: BoundingBoxWithHeight,
  b: BoundingBoxWithHeight,
  precision: number,
): boolean => {
  const keys = Object.keys(a) as (keyof BoundingBoxWithHeight)[];
  return keys.every((key) => numbersMatch(a[key], b[key], precision));
};

const mapStateToProps = (state: RootState): MapStateToProps => ({
  cameraViewport: viewer3dSelectors.cameraViewport(state),
  goTo: viewer3dSelectors.cameraGoto(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): MapDispatchToProps => ({
  setCameraViewport: (viewport) => dispatch(viewer3dActions.setCameraViewport(viewport)),
  setGoto: (goTo) => dispatch(viewer3dActions.cameraGoto(goTo)),
});

const mergeProps = (
  { cameraViewport, goTo }: MapStateToProps,
  { setCameraViewport, setGoto }: MapDispatchToProps,
  { setMapControls }: CameraControlsProps,
): MergedProps => ({
  goTo,
  setGoto,
  setMapControls,
  updateViewport: debounce((zoom: number, x: number, y: number, z: number) => {
    const viewer3d = document.getElementById('viewer3d');
    if (!viewer3d) return;
    const maxResolution = Math.max(viewer3d.offsetWidth, viewer3d.offsetHeight);
    let scale = ((1 / zoom) * maxResolution) / 2;
    const viewportPadding = scale / 3; // Additional space around the visible viewport (to account edges/tilted camera and reduce rerenders)
    scale += viewportPadding;
    const nextCameraViewport: BoundingBoxWithHeight = {
      minX: Math.round(x - scale),
      maxX: Math.round(x + scale),
      minY: Math.round(y - scale),
      maxY: Math.round(y + scale),
      z: Math.ceil(z * 10) / 10,
    };
    if (!cameraViewport || !boundsMatch(cameraViewport, nextCameraViewport, viewportPadding / 2)) {
      setCameraViewport(nextCameraViewport);
    }
  }, 500),
});

export const connectComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps);
