import { createReducer } from '@reduxjs/toolkit';
import type { CameraGoTo, CameraProps } from '../types';
import {
  addElementIdToSelection,
  cameraGoto,
  clearSelectedPoint,
  clearSelectedScan,
  clearSelectedTrenchId,
  clearSelection,
  removeElementIdFromSelection,
  setCamera,
  setCameraViewport,
  setMapStyle,
  setScanSelection,
  setSelectedElementIds,
  setSelectedPointId,
  setSelectedProjectId,
  setSelectedScanId,
  setSelectedTrenchId,
  setVisibleElementUsage,
  setVisibleScans,
} from './actions';
import type { Viewer3dState, VisibleElementUsage } from './interface';

const updateState =
  (prop: keyof Viewer3dState) => (state: Viewer3dState, update: { payload: unknown }) => {
    if (state[prop] === update.payload) return state;
    const updatedState: Viewer3dState = { ...state, [prop]: update.payload };
    return updatedState;
  };

const initialState: Viewer3dState = {
  selectedTrenchId: undefined,
  camera: {},
  initialCameraSet: false,
  visibleScans: 'ONLY_VISIBLE',
  visibleElementUsage: {
    SPEEDPIPE_BUNDLE: true,
    SPEEDPIPE: true,
    OTHER: true,
  },
  mapStyle: 'NONE',
  selectedElementIds: [],
};

export const viewer3dReducer = createReducer(initialState, {
  [setMapStyle.type]: updateState('mapStyle'),
  [setVisibleScans.type]: updateState('visibleScans'),
  [setCameraViewport.type]: updateState('cameraViewport'),
  [setSelectedProjectId.type]: updateState('selectedProjectId'),
  [setSelectedScanId.type]: updateState('selectedScanId'),
  [setScanSelection.type]: updateState('scanSelection'),
  [setSelectedPointId.type]: updateState('selectedPointId'),
  [setSelectedTrenchId.type]: updateState('selectedTrenchId'),

  [setVisibleElementUsage.type]: (
    state,
    { payload: elementsVisible }: { payload: VisibleElementUsage },
  ) => ({
    ...state,
    visibleElementUsage: {
      ...state.visibleElementUsage,
      ...elementsVisible,
    },
  }),
  [clearSelectedScan.type]: (state) => ({ ...state, selectedScanId: undefined }),
  [clearSelectedPoint.type]: (state) => ({ ...state, selectedPointId: undefined }),
  [clearSelectedTrenchId.type]: (state) => ({
    ...state,
    selectedTrenchId: undefined,
    selectedPointId: undefined, // when no trench is selected there can no point be selected either!
  }),
  [clearSelection.type]: (state) => ({
    ...state,
    selectedScanId: undefined,
    selectedTrenchId: undefined,
    selectedElementIds: [],
    selectedPointId: undefined,
  }),
  [setSelectedElementIds.type]: (
    state,
    { payload: selectedElementIds }: { payload: string[] },
  ) => ({
    ...state,
    selectedElementIds,
  }),
  [addElementIdToSelection.type]: (state, { payload: elementId }: { payload: string }) => ({
    ...state,
    selectedElementIds: [...state.selectedElementIds, elementId],
  }),
  [removeElementIdFromSelection.type]: (state, { payload: elementId }: { payload: string }) => ({
    ...state,
    selectedElementIds: state.selectedElementIds.filter((id) => id !== elementId),
  }),
  [setCamera.type]: (state, { payload: camera }: { payload: CameraProps }) => ({
    ...state,
    camera: {
      ...state.camera,
      ...camera,
    },
  }),
  [cameraGoto.type]: (state, { payload: goTo }: { payload: CameraGoTo }) => {
    if (goTo?.initial && state.initialCameraSet) return state;
    return {
      ...state,
      camera: {
        ...state.camera,
        goTo,
      },
      initialCameraSet: true,
    };
  },
});
