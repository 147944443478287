import { EnumSelect } from '@core/components';
import { MapboxStyle, mapboxStyles } from '@core/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';

export const MapStyleSelector = () => {
  const dispatch = useDispatch();
  const mapStyle = useSelector(viewer3dSelectors.mapStyle);
  return (
    <EnumSelect
      label="Map"
      value={mapStyle}
      onChange={(event) => dispatch(viewer3dActions.setMapStyle(event.target.value as MapboxStyle))}
      options={mapboxStyles}
    />
  );
};
