import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import { pointSelectors, trenchSelectors } from '@trenches/redux';
import type { Point } from '@trenches/types';
import { setTransformControl } from '@viewer3D/components/Controls';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import type { TrenchProps } from '../types';
import type { DispatchProps, MergedProps, StateProps } from './types';

const mapStateToProps = (state: RootState, { trenchId }: TrenchProps): StateProps => {
  const { pointIds = [], segments = [] } = trenchSelectors.selectById(trenchId)(state) ?? {};
  const points = pointIds
    .map((id) => pointSelectors.selectById(id)(state))
    .filter((point) => !!point) as Point[];
  const isSelected = viewer3dSelectors.isTrenchIdSelected(trenchId)(state);

  return {
    isSelected,
    points,
    segments,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  dispatch,
});

const mergeProps = (
  { points, segments, isSelected }: StateProps,
  { dispatch }: DispatchProps,
  { trenchId, pointTrenchMap }: TrenchProps,
): MergedProps => ({
  trenchId,
  pointTrenchMap,
  isSelected,
  points,
  segments,
  selectTrench: (e) => {
    e.stopPropagation();
    setTransformControl({ objectName: e.object.name });

    dispatch(viewer3dActions.clearSelection());
    dispatch(viewer3dActions.setSelectedTrenchId(trenchId));
  },
});

export const connectComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps, {
  areMergedPropsEqual: isEqual,
});
