import type { ReworkCategory } from '@scans/types';

export const categoryTitles: Record<ReworkCategory, string> = {
  ADDRESS: 'Address',
  CONNECTOR_OR_PIPE_FITTING: 'Connector/pipe fitting',
  DOUBLE_CHECK_PHOTOS: 'Doublecheck photos',
  ELEMENT_NAME: 'Element name',
  MISSING_ELEMENT: 'Missing element',
  PIPE_COLOUR: 'Pipe colour',
  PIPE_STRENGTH: 'Pipe strength',
  SCAN_POSITION: 'Scan position',
  TRENCH_OR_ELEMENT_PLACEMENT: 'Trench/element placement',
  LAYING_TYPE: 'Laying type',
  SURFACE_TYPE: 'Surface type',
  WIDTH: 'Width',
  DEPTH: 'Depth',
};

export const orderedProblemTitles: ReworkCategory[] = [
  'ADDRESS',
  'CONNECTOR_OR_PIPE_FITTING',
  'DOUBLE_CHECK_PHOTOS',
  'ELEMENT_NAME',
  'MISSING_ELEMENT',
  'PIPE_COLOUR',
  'PIPE_STRENGTH',
  'SCAN_POSITION',
  'TRENCH_OR_ELEMENT_PLACEMENT',
];

export const orderedAttributeTitles: ReworkCategory[] = [
  'LAYING_TYPE',
  'SURFACE_TYPE',
  'DEPTH',
  'WIDTH',
];
