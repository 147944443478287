import type { Trench } from '@trenches/types';
import { head, last } from 'lodash';

/**
 * Checks whether `pointId` is the id of the first or last point of `trench`.
 * Returns `false` if neither of them is defined or `trench` has no points.
 */
export const isEndPoint = (pointId?: string, trench?: Trench): boolean => {
  if (!trench?.pointIds.length || !pointId) return false;
  const { pointIds } = trench;
  return pointId === head(pointIds) || pointId === last(pointIds);
};

export const hasElement =
  (elementId: string) =>
  (trench: Trench): boolean =>
    !!trench.segments.find((s) => s.splineElementIds.includes(elementId));
