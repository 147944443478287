import { elementSelectors, elementTypeSelectors } from '@elements/redux';
import { pointElementSelectors } from '@elements/redux/point-element.slice';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { treesToBreadcrumbs, buildHierarchyViewFromElements } from './helper';

const selectElementHierarchy = createSelector(
  // perf: reselect only if an element is moved within the hierarchy
  elementSelectors.selectAllOnPropChange('parentPointElementId'),
  pointElementSelectors.selectAllOnPropChange('parentPointElementId'),
  elementTypeSelectors.selectAll,
  (splines, points, types) =>
    types.length === 0
      ? [] // prevent UI from crashing when types haven't been fetched yet
      : // perf: only recalculate the hierarchy (expensive!) when spline/points change
        buildHierarchyViewFromElements([
          ...splines.map((s) => ({
            ...s,
            // map element type to corresponding element
            type: types.find((t) => t.id === s.splineElementTypeId)!,
          })),
          ...points,
        ]),
);

export const useConnect = () => {
  const [selectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const hierarchy = useSelector(selectElementHierarchy);

  const [expanded, setExpanded] = useState<string[]>([]);

  const navigateToItem = (itemId: string) => {
    setExpanded(treesToBreadcrumbs(hierarchy, itemId));
  };

  useEffect(() => {
    // selectedElementId changes bc of 2 reasons:
    // 1. user clicks on an element in ElementSelect -> should navigate to that element in tree
    // 2. user clicks on element in TreeView -> Element should ONLY be selected but not navigated to
    if (!selectedElementId) return;
    navigateToItem(selectedElementId);
  }, [selectedElementId]);

  return {
    hierarchy,
    expanded,
    navigateToItem,
  };
};
