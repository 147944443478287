import { toHumanReadable } from '@core/logic';
import { CloudArrowUpFilled, DotFilled } from '@deepup/icons';
import { processingResultSelectors } from '@processingResults/redux';
import type { ProcessingStatus } from '@processingResults/types';
import { useSelector } from 'react-redux';
import { Chip, Stack, Tooltip } from '@mui/material';
import { getProcessingColor, getProcessingState } from './helper';

export const StatusIndicator = ({
  status,
  size,
  prefix,
}: {
  status: ProcessingStatus;
  size: number;
  prefix?: string;
}) => (
  <Tooltip placement="right" title={toHumanReadable(prefix ? `${prefix}: ${status}` : status)}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {status === 'PendingUpload' ? (
        <CloudArrowUpFilled
          role="graphics-symbol"
          fontSize={size}
          fill={getProcessingColor(status)}
          data-testid="UploadingIcon"
        />
      ) : (
        <DotFilled role="graphics-symbol" fontSize={size} fill={getProcessingColor(status)} />
      )}
    </div>
  </Tooltip>
);

export const ProcessingIndicator = ({
  processingResultId,
  size,
  uploadedAt,
}: {
  processingResultId?: string;
  size: number;
  uploadedAt?: string;
}) => {
  const processingResult = useSelector(processingResultSelectors.selectById(processingResultId));
  const { overallStatus, reconstructionStatus, georegistrationStatus } = getProcessingState(
    processingResult,
    uploadedAt,
  );

  const overallIndicator = <StatusIndicator status={overallStatus} size={size} key="overall" />;

  const recIndicator = (
    <StatusIndicator
      status={reconstructionStatus}
      key="reconstruction"
      size={size}
      prefix={'Reconstruction'}
    />
  );
  const geoIndicator = (
    <StatusIndicator
      status={georegistrationStatus}
      key="georegistration"
      size={size}
      prefix={'Georegistration'}
    />
  );

  return (
    <Chip
      size="small"
      label={
        <Stack flexDirection="row" gap={0.5} mx={-0.5}>
          {overallStatus === 'PendingUpload' || overallStatus === 'PendingProcessingResult'
            ? [overallIndicator]
            : overallStatus === 'PendingTexturedMesh'
            ? [overallIndicator, recIndicator, geoIndicator]
            : [recIndicator, geoIndicator]}
        </Stack>
      }
    />
  );
};
