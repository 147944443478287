import { hideTransformControl, ScanSelectionPopover } from '@viewer3D/components';
import { ReactReduxContext, useDispatch } from 'react-redux';
import { viewer3dActions } from '@viewer3D/redux';
import { useContextBridge } from '@react-three/drei';
import { Auth0Context } from '@auth0/auth0-react';
import { Canvas as ThreeCanvas } from '@react-three/fiber';
import { styled } from '@mui/material';
import { ThemeContext } from '@emotion/react';
import { setSelectedPhoto } from '@photos/components/PhotosInViewport/state';
import { MapLayers } from './MapLayers';
import { useScansInScope } from './menu/ScanOrganization';
import { useScanFilters } from './hooks';

const CanvasContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  overflow: 'hidden',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: theme.palette.grey[100],
  pointerEvents: 'auto',
}));

export const ProjectCanvas = () => {
  const dispatch = useDispatch();
  const scans = useScansInScope();
  const { viewport, dateRange } = useScanFilters();
  const ThreeContextBridge = useContextBridge(ReactReduxContext, Auth0Context, ThemeContext);

  return (
    <CanvasContainer>
      <ThreeCanvas
        id="viewer3d"
        flat
        linear
        orthographic
        mode="concurrent"
        frameloop="demand"
        onPointerMissed={(e) => {
          if (e.ctrlKey || e.button === 2) {
            // user pans camera, don't deselect stuff
            return;
          }
          dispatch(viewer3dActions.clearSelection());
          setSelectedPhoto(null);
          hideTransformControl();
        }}
        camera={{ near: -10000, far: 10000 }}
      >
        <ThreeContextBridge>
          {/* TODO unfortunately we have to pass the parameters to the MapLayers component explicitly because the
                react-router Context doesn't get passed to react-three-fiber Canvas, which has an own virtual DOM.
          */}
          <MapLayers scans={scans} viewport={viewport} dateRange={dateRange} />
        </ThreeContextBridge>
      </ThreeCanvas>
      <ScanSelectionPopover />
    </CanvasContainer>
  );
};
