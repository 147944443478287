import type { TrenchesProps } from '@trenches/components/types';
import { pointSelectors, trenchSelectors } from '@trenches/redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import type { BoundingBoxWithHeight } from '@viewer3D/types';
import { useSelector } from 'react-redux';
import { buildPointTrenchMap } from './Trench/Points/helper';

const useTrenchesInScope = (scanFilterViewport?: BoundingBoxWithHeight) => {
  const selectedProjectId = useSelector(viewer3dSelectors.selectedProjectId);
  const viewport = useSelector(viewer3dSelectors.cameraViewport);
  const pointEntities = useSelector(pointSelectors.selectEntities);
  const selectedTId = useSelector(viewer3dSelectors.selectedTrenchId);
  const allTrenches = useSelector(trenchSelectors.selectAll);

  if (!selectedProjectId || !viewport) return [];

  const intersectedViewport =
    viewport && scanFilterViewport
      ? {
          minX: Math.min(viewport.minX, scanFilterViewport?.minX),
          minY: Math.min(viewport.minY, scanFilterViewport?.minY),
          maxX: Math.max(viewport.maxX, scanFilterViewport?.maxX),
          maxY: Math.max(viewport.maxY, scanFilterViewport?.maxY),
        }
      : viewport;

  const { minX, maxX, minY, maxY } = intersectedViewport;
  return allTrenches.filter(({ id, projectId, pointIds }) => {
    if (projectId !== selectedProjectId) return false;
    if (id === selectedTId) return true; // Always show selected trench so elements in it can be selected
    // Using for loop for performance reasons
    for (let i = 0; i < pointIds.length; i += 1) {
      const { x, y } = pointEntities[pointIds[i]] ?? {};
      if (x && y && x >= minX && x <= maxX && y >= minY && y <= maxY) return true;
    }
    return false;
  });
};

export const useConnect = (viewport?: BoundingBoxWithHeight): TrenchesProps => {
  const trenchesInScope = useTrenchesInScope(viewport);
  const pointTrenchMap = buildPointTrenchMap(trenchesInScope); // TODO memoize?
  const trenchIds = trenchesInScope.map(({ id }) => id);

  return { trenchIds, pointTrenchMap };
};
