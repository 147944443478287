import type { NewPoint } from '@trenches/types';
import { proxy } from 'valtio';

interface FromPoint {
  fromPoint?: NewPoint;
}

/**
 * We use valtio here and not useState() because we use this state in multiple components!
 */
export const trenchFromPointState = proxy<FromPoint>({});

export const setTrenchFromPoint = (point?: NewPoint): void => {
  trenchFromPointState.fromPoint = point;
};

export const resetTrenchFromPoint = (): void => {
  trenchFromPointState.fromPoint = undefined;
};
