import { useAuth0 } from '@auth0/auth0-react';
import { SelectButton } from '@core/components/SelectButton';
import { environment } from '@core/environment';
import { useLocalStorage } from '@core/logic/hooks/useLocalStorage';
import { ArrowDownLine } from '@deepup/icons';
import { viewer3dSelectors } from '@viewer3D/redux';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

type GeopackagePath = 'topas' | 'dgn' | 'api';

// Construction of file name for default and topas projects
// project.name = DGN | Elbtal | 65627_2023 --> filename = DGN___Elbtal___65627_2023-01-26_14_04.zip
export const getTopasFileName = (projectName: string): string => {
  const date = DateTime.now().toFormat('yyyy-MM-dd_HH_mm');
  const projectNameSanitized = projectName.replaceAll(/[^\wäöü-]/g, '_');
  return `${projectNameSanitized}_${date}`;
};

// For DGN we need to extract the "real" project name
// project.name = DGN | Elbtal | 65627_2023 --> filename = Elbtal.zip
export const getDGNFileName = (projectName: string): string => {
  const splitted = projectName.split(' | ')?.[1] ?? projectName;
  return splitted
    .replace(/ä/g, 'ae')
    .replace(/Ä/g, 'Ae')
    .replace(/ü/g, 'ue')
    .replace(/Ü/g, 'Ue')
    .replace(/ö/g, 'oe')
    .replace(/Ö/g, 'Oe')
    .replace(/ß/g, 'ss')
    .replaceAll(/[^\w-]/g, '_');
};

export const getFileNameAndFormat = (
  path: GeopackagePath,
  projectName: string,
): { fileFormat: string; fileName: string } => {
  switch (path) {
    case 'topas': {
      const fileFormat = 'geopackage';
      const name = getTopasFileName(projectName);
      const fileName = `${name}.zip`;
      return { fileFormat, fileName };
    }
    case 'dgn': {
      const fileFormat = 'shapefile';
      const name = getDGNFileName(projectName);
      const fileName = `${name}.zip`;
      return { fileFormat, fileName };
    }
    case 'api': {
      const fileFormat = 'geopackage';
      const name = getTopasFileName(projectName);
      const fileName = `${name}.zip`;
      return { fileFormat, fileName };
    }
  }
};

export const useBlobData = (): {
  getData: (projectId: string, fileFormat: string, path: string) => Promise<Blob>;
} => {
  const { getAccessTokenSilently } = useAuth0();

  const getData = async (projectId: string, fileFormat: string, path: string) => {
    const token = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${token}` };

    const { data } = await axios.get<Blob>(
      `${environment.apiUrl}/projects/${projectId}/${fileFormat}/${path}`,
      {
        responseType: 'blob',
        headers,
      },
    );

    return data;
  };

  return { getData };
};

export const DownloadButton = () => {
  const [inProgress, setInProgress] = useState(false);
  const project = useSelector(viewer3dSelectors.selectedProject);
  const { getData } = useBlobData();
  const [value, setValue] = useLocalStorage<GeopackagePath>('DownloadButton-value', 'topas');

  const downloadGeoPackage = async (path: GeopackagePath) => {
    if (!project || inProgress) return;
    setInProgress(true);

    const { fileName, fileFormat } = getFileNameAndFormat(path, project.name);
    const data = await getData(project.id, fileFormat, path);

    const objectUrl = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    setInProgress(false);
  };

  return (
    <SelectButton<GeopackagePath>
      title="Download"
      options={[
        { value: 'topas', label: 'Standard' },
        { value: 'dgn', label: 'DGN' },
        { value: 'api', label: 'API' },
      ]}
      value={value}
      onClick={downloadGeoPackage}
      onChange={(value) => {
        setValue(value);
        downloadGeoPackage(value);
      }}
      isLoading={inProgress}
      labelIcon={<ArrowDownLine fill="white" />}
    />
  );
};
