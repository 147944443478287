import { PipeColor } from '@projects/pages/detail/PipeFittings/pipeColors';
import {
  EmptyCircle,
  EmptyCircleDashed,
  PipeBundle10Outline,
  PipeBundle12Outline,
  PipeBundle14Outline,
  PipeBundle18Outline,
  PipeBundle19Outline,
  PipeBundle221Outline,
  PipeBundle24Outline,
  PipeBundle2Outline,
  PipeBundle3Outline,
  PipeBundle4Outline,
  PipeBundle5Outline,
  PipeBundle6Outline,
  PipeBundle7Outline,
  PipeBundle8Outline,
  PipeBundleUnknown,
} from '@deepup/icons';

export const pipeIcons = {
  Unknown: PipeBundleUnknown,
  Single: EmptyCircle,
  SingleDashed: EmptyCircleDashed,
  2: PipeBundle2Outline,
  3: PipeBundle3Outline,
  4: PipeBundle4Outline,
  5: PipeBundle5Outline,
  6: PipeBundle6Outline,
  7: PipeBundle7Outline,
  8: PipeBundle8Outline,
  10: PipeBundle10Outline,
  12: PipeBundle12Outline,
  14: PipeBundle14Outline,
  18: PipeBundle18Outline,
  19: PipeBundle19Outline,
  22: PipeBundle221Outline,
  24: PipeBundle24Outline,
};
export const maintainGoodContrast = (color: string) =>
  color === PipeColor.sw ? 'drop-shadow(0 0 1px white)' : undefined;

export const getPipeIcon = (name: string, numberChildren = 0) =>
  name === 'Unknown'
    ? pipeIcons.Unknown
    : numberChildren > 0
    ? pipeIcons[numberChildren as keyof typeof pipeIcons] ?? pipeIcons.Unknown // falls back to "Unknown" icon if a bundle has no icon in pipeIcons hashmap. In this case add the icon to the component lib and the pipeIcons ASAP.
    : pipeIcons.Single;
