import { continueEventPropagation, useKeys } from '@core/logic';
import { ElementListItem, PointElementDialog, SplineElementDialog } from '@elements/components';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { useEffect, useState } from 'react';
import { ChevronsDown, ChevronsRight, Path, Point } from '@deepup/icons';
import { useConnect } from './connect';
import type { NetworkHierarchyView } from './helper';

const Label = ({ el }: { el: NetworkHierarchyView }) =>
  el.elementId ? (
    <ElementListItem elementId={el.id} key={el.id} />
  ) : (
    <Typography display="flex" gap={1} alignItems="baseline" noWrap>
      {el.name}
      <Typography variant="subtitle2" color="textSecondary">
        {el.children?.length}
      </Typography>
    </Typography>
  );

const Dialogs = () => {
  const [splineDialogOpen, setSplineDialogOpen] = useState(false);
  const [pointDialogOpen, setPointDialogOpen] = useState(false);
  const [ePressed, pPressed] = useKeys('e', 'p');

  useEffect(() => {
    ePressed && setSplineDialogOpen(true);
    pPressed && setPointDialogOpen(true);
  }, [ePressed, pPressed]);

  return (
    <>
      <ButtonGroup
        fullWidth
        variant="contained"
        color="secondary"
        sx={{ position: 'sticky', top: (theme) => theme.spacing(1), zIndex: 1 }}
      >
        <Button onClick={() => setSplineDialogOpen(true)} startIcon={<Path />}>
          Add Spline
        </Button>
        <Button onClick={() => setPointDialogOpen(true)} startIcon={<Point />}>
          Add Point
        </Button>
      </ButtonGroup>
      {splineDialogOpen && (
        <SplineElementDialog
          onClose={() => {
            setSplineDialogOpen(false);
          }}
        />
      )}
      {pointDialogOpen && (
        <PointElementDialog
          onClose={() => {
            setPointDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export const ElementOrganization = () => {
  const { expanded, navigateToItem, hierarchy } = useConnect();

  const handleNodeToggle = (_: unknown, nodeId: string) => {
    const [previousCrumb, activeCrumb] = expanded.slice(-2);
    const nextExpanded = activeCrumb === nodeId ? previousCrumb : nodeId;
    navigateToItem(nextExpanded);
  };

  const renderTree = (nodes?: NetworkHierarchyView[]) =>
    nodes?.map((n) => (
      <TreeItem
        key={n.id}
        nodeId={n.id}
        label={<Label el={n} />}
        onKeyDown={continueEventPropagation}
      >
        {renderTree(n.children)}
      </TreeItem>
    ));

  return (
    <Stack gap={2} padding={2}>
      <Dialogs />
      <TreeView
        defaultCollapseIcon={<ChevronsDown />}
        defaultExpandIcon={<ChevronsRight />}
        expanded={expanded}
        onNodeSelect={handleNodeToggle}
      >
        {renderTree(hierarchy)}
      </TreeView>
    </Stack>
  );
};
