import type { Point } from '@trenches/types';
import { jsonToVector, pointsAreEqual } from '@viewer3D/helper';
import { isEqual } from 'lodash';
import { CatmullRomCurve3, Vector3 } from 'three';

export interface IntermediatePoint {
  afterPointId: string;
  beforePointId: string;
  vec: Vector3;
  distanceToNextPoint: number;
}

/**
 * CatmullRomCurve3, that
 * - caches its interpolated points & tangents
 * - can be limited to a visible section (re-using the cache)
 * - knows when it needs an update
 */
export class DeepupCatmullRomCurve extends CatmullRomCurve3 {
  public needsUpdate = false;

  public worldPosition = new Vector3();

  public points = [] as Vector3[];

  public pointsData: Point[] = [];

  private visibleSection?: { offset: number; range: number };

  private cache = {
    maxIndex: 0,
    tangents: [] as Vector3[],
    interpolatedPoints: [] as Vector3[],
  };

  constructor(private interpolationFactor = 6) {
    super([], false, 'centripetal', 0.5);
  }

  public cloneWithPointIndexBoundaries(
    fromPointIndex: number,
    toPointIndex: number,
  ): DeepupCatmullRomCurve | null {
    if (fromPointIndex === toPointIndex) return null;
    let [fromIndex, toIndex] = [fromPointIndex, toPointIndex];
    if (fromIndex > toIndex) [fromIndex, toIndex] = [toIndex, fromIndex];
    const clonedCurve = this.clone();
    const indexRange = toIndex - fromIndex;
    const indexMax = this.points.length - 1;
    clonedCurve.visibleSection = {
      offset: fromIndex / indexMax,
      range: indexRange / indexMax,
    };
    return clonedCurve;
  }

  public cloneWithPointIdBoundaries(
    fromPointId: string,
    toPointId: string,
  ): DeepupCatmullRomCurve | null {
    if (fromPointId === toPointId) return null;
    const fromIndex = this.pointsData.findIndex(({ id }) => id === fromPointId);
    const toIndex = this.pointsData.findIndex(({ id }) => id === toPointId);
    return this.cloneWithPointIndexBoundaries(fromIndex, toIndex);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public clone(): DeepupCatmullRomCurve {
    const clonedCurve = new DeepupCatmullRomCurve(this.interpolationFactor);
    clonedCurve.pointsData = this.pointsData;
    clonedCurve.worldPosition = this.worldPosition;
    clonedCurve.points = this.points;
    clonedCurve.cache = this.cache;
    return clonedCurve;
  }

  public updatePoints(points: Point[]): void {
    // TODO: Only rebuild part of interpolation - not everything if anything has changed...
    if (pointsAreEqual(points, this.pointsData)) {
      this.needsUpdate = false;
      return;
    }
    this.pointsData = points;
    this.worldPosition = jsonToVector(points[0]);
    this.cache = {
      maxIndex: (points.length - 1) * this.interpolationFactor,
      // TODO: Pre-Compute interpolation and tangents in workers or on GPU
      interpolatedPoints: [],
      tangents: [],
    };
    // Move the points from world coordinates to local coordinates
    this.points = points.map((p) => jsonToVector(p).sub(this.worldPosition));
    this.needsUpdate = true;
  }

  public getSegments(): number {
    if (this.visibleSection) {
      return Math.round(this.cache.maxIndex * this.visibleSection.range);
    }
    return this.cache.maxIndex;
  }

  public getIntermediatePoints(): IntermediatePoint[] {
    const intermediatePoints: IntermediatePoint[] = [];
    this.pointsData.forEach((point, i, arr) => {
      if (i === 0) return;
      const prevCacheIndex = Math.round(i * this.interpolationFactor);
      const cacheIndex = Math.round(i * this.interpolationFactor - this.interpolationFactor / 2);
      const prevVec = this.getPointAtCachedIndex(prevCacheIndex);
      const vec = this.getPointAtCachedIndex(cacheIndex);
      intermediatePoints.push({
        vec,
        beforePointId: arr[i - 1].id,
        afterPointId: point.id,
        distanceToNextPoint: vec.distanceTo(prevVec),
      });
    });
    return intermediatePoints;
  }

  public getPoint(t: number): Vector3 {
    return this.getPointAtCachedIndex(this.tToCacheIndex(t));
  }

  /**
   * Gets curve evenly spaced points over the whole length
   */
  public getEvenlySpacedCurvePoints(offset: number, step: number, upperBound: number): Vector3[] {
    const vecArray = [];
    let pointAtI;
    for (let i = offset; i <= upperBound; i += step) {
      pointAtI = this.getPoint(i);
      if (isEqual(vecArray.at(-1), pointAtI)) {
        // Skip duplicate points
        continue;
      }
      vecArray.push(pointAtI);
    }
    return vecArray;
  }

  /**
   * This function overrides the function of its super-class. Usually it maps
   *  - input "u":
   *    a value between 0 and 1 describing a point on the curve with evenly distributed lengths
   *  - to output "t":
   *    a value between 0 and 1 describing a point, which is weighted based on the points on the curve
   * We actually only want to use the second, weighted case with "t".
   * As some external classes of Threejs rely on this function we get rid of the mapping and
   * return the initial value. Magic!
   */
  // eslint-disable-next-line class-methods-use-this
  public getUtoTmapping(u: number): number {
    return u;
  }

  public getTangent(t: number): Vector3 {
    const index = this.tToCacheIndex(t);
    const cachedTangent = this.cache.tangents[index];
    if (cachedTangent) return cachedTangent;

    let indexBefore = index - 1;
    let indexAfter = index + 1;

    // Capping in case of danger
    if (indexBefore < 0) indexBefore = 0;
    if (indexAfter > this.cache.maxIndex) indexAfter = this.cache.maxIndex;

    const pt1 = this.getPointAtCachedIndex(indexBefore);
    const pt2 = this.getPointAtCachedIndex(indexAfter);

    const tangent = new Vector3();
    tangent.copy(pt2).sub(pt1).normalize();
    this.cache.tangents[index] = tangent;
    return tangent;
  }

  private tToCacheIndex(t: number): number {
    let tInBounds = t;
    if (this.visibleSection) {
      tInBounds *= this.visibleSection.range;
      tInBounds += this.visibleSection.offset;
    }
    const cacheIndex = Math.round(tInBounds * this.cache.maxIndex);
    if (cacheIndex < 0) return 0;
    if (cacheIndex > this.cache.maxIndex) return this.cache.maxIndex;
    return cacheIndex;
  }

  private getPointAtCachedIndex(index: number): Vector3 {
    let point = this.cache.interpolatedPoints[index];
    if (point) return point;
    if (index % this.interpolationFactor === 0) {
      // Requested point is one of the original points -> no computation needed
      point = this.points[index / this.interpolationFactor];
    } else {
      // Interpolated point needs to be computed
      point = super.getPoint(index / this.cache.maxIndex);
    }
    if (!point) {
      // Fallback behaviour if no point could be found/built
      return new Vector3();
    }
    this.cache.interpolatedPoints[index] = point;
    return point;
  }
}
