import { ProjectCollectionOutline } from '@deepup/icons';
import { projectSelectors } from '@projects/redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProjectsTable } from './ProjectsTable';
import { ProjectListSection } from './ProjectListSection';

export const ProjectListAll = ({ search }: { search: string }) => {
  const { t } = useTranslation();

  const projects = useSelector(projectSelectors.selectAll);

  return (
    <div>
      <ProjectListSection
        primaryText={t('projects.list.title')}
        secondaryText={projects.length}
        Icon={ProjectCollectionOutline}
      />
      <ProjectsTable rows={projects} pagination search={search} />
    </div>
  );
};
