import { editModeState, setEditMode } from '@projects/edit-modes';
import { EditMode } from '@projects/edit-modes/types';
import { pointSelectors } from '@trenches/redux';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';

window.isPointDebugModeActive = false;

/**
 * This function is for debugging purposes only.
 * It allows to focus on a point by entering its UUID in the search field.
 */
export const useSelectAndFocusPointsById = () => {
  const dispatch = useDispatch();
  const { mode } = useSnapshot(editModeState);
  const allPoints = useSelector(pointSelectors.selectAll);
  const isPointInStore = (pointId: string) => !!allPoints.find(({ id }) => id === pointId);
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);

  useEffect(() => {
    window.isPointDebugModeActive &&
      selectedPointId &&
      console.log(
        'Enter the next line into the address search field to select this point:',
        selectedPointId,
      );
  }, [selectedPointId]);

  window.selectPointByUUID = (pointId: string) => {
    if (!window.isPointDebugModeActive) {
      return "Please set 'window.isPointDebugModeActive = true' to use this function.";
    }
    if (mode !== EditMode.Trench && mode !== EditMode.Element) {
      setEditMode(EditMode.Element);
    }

    if (!pointId.match(/^([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/))
      return 'No valid uuid given for point! UUIDs have the format [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}.';

    if (!isPointInStore(pointId)) {
      return `The entered UUID ${pointId} is no UUID of a point in the current project or points haven't been fully loaded yet.`;
    }
    if (pointId) {
      dispatch(viewer3dActions.setSelectedPointId(pointId));
      // press "f" to focus on the selected point
      window.dispatchEvent(new window.KeyboardEvent('keydown', { key: 'f' }));
      return `Selected the Point '${pointId}'. Press "f" to focus on it if necessary.`;
    }
  };
};
