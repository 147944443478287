/* eslint-disable class-methods-use-this */
import type { ApiOptions } from '@core/api/types';
import { errorBox } from '@core/components/AlertBox';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';

let baseUrl = '';
let getAccessToken = () => Promise.resolve('');

export const setup = (options: {
  baseUrl: string;
  getAccessToken: () => Promise<string>;
}): void => {
  baseUrl = options.baseUrl;
  getAccessToken = options.getAccessToken;
};

export const request = async <Response, Body = undefined, RawResponse = Response>(
  httpMethod: Method,
  options: ApiOptions<Body>,
  mapResponse: (res: RawResponse) => Response = (res: RawResponse) => res as unknown as Response,
): Promise<Response> => {
  const { path, body, params } = options;
  const config: AxiosRequestConfig = {
    method: httpMethod,
    url: typeof path === 'string' && path.match('^https?://') ? path : [baseUrl, ...path].join('/'),
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
    responseType: 'json',
    data: body,
    params,
  };
  return axios
    .request<RawResponse>(config)
    .then(({ data }) => mapResponse(data))
    .catch((error: AxiosError<{ detail: string }>) => {
      if (error.response?.data?.detail) {
        errorBox(error.response.data.detail);
      }
      return Promise.reject(error as unknown as Response);
    });
};
