import { HtmlProps } from '@react-three/drei/web/Html';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Html } from '@react-three/drei';

/**
 * This component fixes that the MUI5 theme is not passed correctly over to the ThreeCanvas context.
 */
export const HtmlWithTheme = ({ children, ...props }: HtmlProps) => {
  const theme = useTheme();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Html {...props}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Html>
  );
};
