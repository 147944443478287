import { request } from '@core/api';
import { errorBox } from '@core/components/AlertBox';
import { mapToUpdateT } from '@core/redux/factory/reducer';
import type { AppDispatch } from '@core/redux/store';
import type { Vector } from '@core/types';
import { processingResultActions } from '@processingResults/redux';
import type { ProcessingResult } from '@processingResults/types';
import type { Update } from '@reduxjs/toolkit';
import { transformTrenchPointsWithScanState } from '@scans/components/ScanDetailPanel/state';
import { scanActions } from '@scans/redux';
import type { Scan } from '@scans/types';
import { pointActions } from '@trenches/redux';
import type { Point } from '@trenches/types';
import { eulerToJson, vectorToJson } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { Euler, Quaternion, Scene, Vector3 } from 'three';
import type { RenderedScanData } from './Scans';

// https://stackoverflow.com/questions/62457529/how-do-you-get-the-axis-and-angle-representation-of-a-quaternion-in-three-js
export function getAxisAndAngleFromQuaternion(q: Quaternion): { axis: Vector3; angle: number } {
  const angle = 2 * Math.acos(q.w);
  let s;
  if (1 - q.w * q.w < 0.000001) {
    // test to avoid divide by zero, s is always positive due to sqrt
    // if s close to zero then direction of axis not important
    // http://www.euclideanspace.com/maths/geometry/rotations/conversions/quaternionToAngle/
    s = 1;
  } else {
    s = Math.sqrt(1 - q.w * q.w);
  }
  return { axis: new Vector3(q.x / s, q.y / s, q.z / s), angle };
}

export function createUndoTransformationRequestBody({
  scene,
  selectedScan,
  userTransformedScan,
}: {
  scene: Scene;
  selectedScan: Scan;
  userTransformedScan: RenderedScanData;
}): ScanTransformRequest | null {
  // renderedScan is the originalProcessingResult scan, the scan that shows up when the checkbox use user transformation is off
  const renderedScan = scene.getObjectByName(`${Object3dNames.Scan}:${selectedScan?.id}`);
  const renderedScanParent = renderedScan?.parent;
  if (!renderedScanParent) {
    return null;
  }

  const worldPosition = renderedScanParent.position.clone();
  const worldPositionStart = userTransformedScan?.position;
  if (!worldPositionStart) {
    return null;
  }

  const userTranslation = renderedScanParent.position.clone().add(renderedScan.position.clone());
  const endQuaternion = renderedScanParent.quaternion.clone();
  const startQuaternion = userTransformedScan.quaternion.clone().invert();
  const deltaQuaternion = endQuaternion.multiply(startQuaternion);
  const { axis, angle } = getAxisAndAngleFromQuaternion(deltaQuaternion);

  const processingResult = {
    userTranslation: vectorToJson(userTranslation),
    userRotation: eulerToJson(new Euler()),
  };

  const points = {
    userTranslation: vectorToJson(worldPosition),
    userRotation: {
      angle,
      axis,
    },
    transformOrigin: vectorToJson(worldPositionStart),
  };

  return transformTrenchPointsWithScanState.applyTransformation
    ? { processingResult, points }
    : { processingResult };
}

export interface ScanTransformRequest {
  processingResult: {
    userTranslation: Vector;
    userRotation: Vector;
  };
  points?: {
    userTranslation: Vector;
    userRotation: {
      angle: number;
      axis: Vector;
    };
    transformOrigin: Vector;
  };
}

interface ScanTransformResponse {
  processingResult: ProcessingResult;
  points?: Update<Point>[];
}

interface RawScanTransformResponse {
  processingResult: ProcessingResult;
  points?: Point[];
}

export const patchTransformScan = async (
  dispatch: AppDispatch,
  scanId: string,
  projectId: string,
  processingResultId: string,
  body: ScanTransformRequest,
): Promise<void> => {
  try {
    const { processingResult, points = [] as Update<Point>[] } = await request<
      ScanTransformResponse,
      ScanTransformRequest,
      RawScanTransformResponse
    >(
      'PATCH',
      {
        path: ['projects', projectId, 'processingResults', processingResultId, 'transform'],
        body,
      },
      (res) => ({
        processingResult: res.processingResult,
        points: res.points ? mapToUpdateT(res.points) : [],
      }),
    );
    await dispatch(pointActions.updateMany(points));
    await dispatch(processingResultActions.updateOne(processingResult));
  } catch (e) {
    errorBox(
      "Could not transform trench points together with scan. Don't worry, neither scan nor points will be transformed.",
    );
  }

  if (!scanId) return;

  dispatch(
    scanActions.updateOne({
      id: scanId,
      disableUserTransformation: false,
    }),
  );
};
