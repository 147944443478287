/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import type { Entity } from '@core/types';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { ReduxEntities } from '../enum';
import { EntityRelationKey } from './enum';
import { createKeyRemover, createParentEntityExtractor, createUpsert } from './helper';
import { createApiReducer, createBasicReducer, createParentApiReducer } from './reducer';
import { createSelectors } from './selectors';
import { createApiThunks } from './thunks';
import type { ApiThunks } from './thunks/api.interfaces';

export const crudEntityFactory = <T extends Entity>({
  entity,
  parentEntities = [],
  keysInParent = [],
  removeKeys = [],
}: {
  entity: ReduxEntities;
  keysInParent?: EntityRelationKey[];
  parentEntities?: ReduxEntities[];
  removeKeys?: EntityRelationKey[];
}) => {
  const adapter = createEntityAdapter<T>({});
  const apiThunks: ApiThunks<T> = createApiThunks(entity);

  const { actions, reducer } = createSlice({
    name: entity,
    initialState: adapter.getInitialState({}),
    reducers: createBasicReducer(adapter),
    extraReducers: (builder) => {
      const upsertIfExists = createUpsert(adapter);
      const removeSubEntities = createKeyRemover<T>([...removeKeys, EntityRelationKey.Version]);
      createApiReducer(builder, entity, removeSubEntities, adapter);
      if (parentEntities.length > 0) {
        const extractEntitiesFromParent = createParentEntityExtractor<T, Entity>(keysInParent);
        createParentApiReducer(
          builder,
          parentEntities,
          removeSubEntities,
          upsertIfExists,
          extractEntitiesFromParent,
        );
      }
    },
  });

  return {
    actions,
    apiThunks,
    reducer,
    selectors: createSelectors<T>(entity, adapter),
  };
};
