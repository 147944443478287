import { tileNumberToUtm } from '@viewer3D/components/Map/Tile/helper/tile-number-to-utm';
import { buildBufferAttribute } from '@viewer3D/helper';
import { meanBy } from 'lodash';
import { BufferGeometry, Vector3 } from 'three';
import type { TileProps } from '../types';

const normal = [0, 0, 1];
const normals = buildBufferAttribute(3, [normal, normal, normal, normal]);
const uvs = buildBufferAttribute(2, [
  [0, 1],
  [1, 1],
  [0, 0],
  [1, 0],
]);
const indices = [3, 1, 2, 2, 1, 0];

export const tileToGeometry = ({
  tileX,
  tileY,
  zoom,
  positionZ,
}: TileProps): {
  geometry: BufferGeometry;
  position: Vector3;
} => {
  const topLeft = tileNumberToUtm(tileX, tileY, zoom);
  const topRight = tileNumberToUtm(tileX + 1, tileY, zoom);
  const bottomLeft = tileNumberToUtm(tileX, tileY + 1, zoom);
  const bottomRight = tileNumberToUtm(tileX + 1, tileY + 1, zoom);
  const corners = [topLeft, topRight, bottomLeft, bottomRight];

  const positionX = meanBy(corners, 'x');
  const positionY = meanBy(corners, 'y');
  const vertices = corners.map(({ x, y }) => [x - positionX, y - positionY, 0]);

  const geometry = new BufferGeometry();
  geometry.setAttribute('position', buildBufferAttribute(3, vertices));
  geometry.setAttribute('normal', normals);
  geometry.setAttribute('uv', uvs);
  geometry.setIndex(indices);

  return {
    geometry,
    position: new Vector3(positionX, positionY, positionZ),
  };
};
