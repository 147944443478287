import { attributeState } from '@projects/edit-modes/Attributes';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import { useSnapshot } from 'valtio';
import { TrenchSegment } from './Segment';
import type { TrenchSegmentsProps } from './types';

export const TrenchSegments: FC<TrenchSegmentsProps> = ({
  isMouseOverTrench,
  isTrenchSelected,
  curve,
  segments,
  mode,
}) => {
  const { selectedAttributeType } = useSnapshot(attributeState);
  return (
    <group
      name={Object3dNames.TrenchSegments}
      renderOrder={getRenderOrder(Object3dNames.TrenchSegments)}
    >
      {segments.map((segment) => (
        <TrenchSegment
          key={`${segment.fromPointId}-${segment.toPointId}`}
          isMouseOverTrench={isMouseOverTrench}
          isTrenchSelected={isTrenchSelected}
          curve={curve}
          segment={segment}
          selectedAttributeType={selectedAttributeType}
          mode={mode}
        />
      ))}
    </group>
  );
};
