import {
  FormControlLabel,
  IconButton,
  Radio,
  Stack,
  Menu,
  ListItemIcon,
  MenuItem,
  ListItemText,
} from '@mui/material';
import {
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
  PickersShortcutsItem,
} from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useState, MouseEvent } from 'react';
import { CloudArrowUpOutline, KebabHorizontal, ScanDeviceBOutline } from '@deepup/icons';
import { useDateRangeFilterState } from '../hooks';

const DeepUpFoundingDate = DateTime.fromISO('2019-01-01'); // for date sanity check // TODO: invalidate from-dates less than first scan of selected project instead of company founding year!

const calendarShortCuts: PickersShortcutsItem<DateTime>[] = [
  { label: 'Today', getValue: () => DateTime.local() },
];

export const DateRangeFilter = () => {
  const {
    isDateModeActive,
    dateRangeFilter: { from, to, type },
    syncDateRange,
  } = useDateRangeFilterState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const syncDateIfValid =
    (field: 'from' | 'to') =>
    (value: DateTime | null, error: PickerChangeHandlerContext<DateValidationError>) => {
      if (value && value > DeepUpFoundingDate && !error.validationError) {
        // prevents unnecessary changing of URL / refetching with nonsense dates
        syncDateRange({ [field]: value?.toISODate() ?? '' });
      }
    };

  return (
    <FormControlLabel
      control={
        <Radio
          checked={isDateModeActive}
          onClick={() => {
            syncDateRange({ from, to, type });
          }}
        />
      }
      label={
        <Stack direction="row" gap={1} alignItems="center">
          <DatePicker
            label="From"
            value={DateTime.fromISO(from)}
            onChange={syncDateIfValid('from')}
            disabled={!isDateModeActive}
            minDate={DeepUpFoundingDate}
            disableFuture
            showDaysOutsideCurrentMonth
            slotProps={{
              shortcuts: {
                items: calendarShortCuts,
              },
            }}
          />
          <DatePicker
            label="To"
            value={DateTime.fromISO(to)}
            onChange={syncDateIfValid('to')}
            disabled={!isDateModeActive}
            minDate={DeepUpFoundingDate}
            disableFuture
            showDaysOutsideCurrentMonth
            slotProps={{
              shortcuts: {
                items: calendarShortCuts,
              },
            }}
          />
          <IconButton onClick={handleOpen} disabled={!isDateModeActive}>
            <KebabHorizontal />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              selected={type === 'recordedAt'}
              onClick={() => syncDateRange({ type: 'recordedAt' })}
            >
              <ListItemIcon>
                <ScanDeviceBOutline fontSize={24} fill="grey" />
              </ListItemIcon>
              <ListItemText>Recorded at</ListItemText>
            </MenuItem>
            <MenuItem
              selected={type === 'uploadedAt'}
              onClick={() => syncDateRange({ type: 'uploadedAt' })}
            >
              <ListItemIcon>
                <CloudArrowUpOutline fontSize={24} fill="grey" />
              </ListItemIcon>
              <ListItemText>Uploaded at</ListItemText>
            </MenuItem>
          </Menu>
        </Stack>
      }
    />
  );
};
