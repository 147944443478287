import React, { useState, useEffect } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { OrderNumber } from '@projects/types/orderNumbers';

interface SMADetailsProps {
  smaNumbers: OrderNumber[] | null;
}

export const SMADetails: React.FC<SMADetailsProps> = ({ smaNumbers }) => {
  const [displayedSMANumbers, setDisplayedSMANumbers] = useState<OrderNumber[]>([]);

  useEffect(() => {
    setDisplayedSMANumbers(smaNumbers ?? []);
  }, [smaNumbers]);

  return (
    <>
      <Stack p={2} spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="overline">SMA</Typography>
        </Stack>

        <Stack direction="row" flexWrap="wrap" gap={1}>
          {displayedSMANumbers.length > 0 ? (
            displayedSMANumbers.map((smaInfo, index) => (
              <Chip key={`${smaInfo.value}-${index}`} label={smaInfo.value} variant="outlined" />
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No SMA numbers selected.
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};
