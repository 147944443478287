import { environment } from '@core/environment';
import { useCachedAuth0Url } from '@core/logic';
import type { TexturedMesh } from '@processingResults/types';
import { useEffect, useState } from 'react';
import { ShaderMaterial, Texture, TextureLoader } from 'three';

const textureLoader = new TextureLoader();

const vertexShader = `
uniform float meshXMin;
uniform float meshXSpan;
uniform float meshYMin;
uniform float meshYSpan;
varying vec2 texUV;

void main()	{
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  texUV = vec2(
    (position.x - meshXMin) / meshXSpan,
    (position.y - meshYMin) / meshYSpan
  );
}
`;

const fragmentShader = `
uniform sampler2D tex;
varying vec2 texUV;
      
void main()	{
  gl_FragColor = texture2D(tex, texUV);
}
`;

const formatVal = (value: number) => ({ value, type: 'f' });

export const useTexture = (texturedMesh?: TexturedMesh): ShaderMaterial | undefined => {
  const [url, setUrl] = useState<string>();
  const chachedUrl = useCachedAuth0Url(url);
  const [texture, setTexture] = useState<Texture>();
  const [material, setMaterial] = useState<ShaderMaterial>();

  useEffect(() => {
    if (url || !texturedMesh) return;
    const gsPath = texturedMesh.texture?.location;
    if (!gsPath || !gsPath.startsWith('gs://')) return;
    setUrl(`${environment.apiUrl}/texturedMeshes/${texturedMesh.id}/downloads/texture`);
  }, [url, texturedMesh]);

  useEffect(() => {
    if (!chachedUrl) return;
    textureLoader.loadAsync(chachedUrl).then(setTexture).catch(console.error);
  }, [chachedUrl]);

  useEffect(() => {
    if (!texture || !texturedMesh?.texture?.boundingBox) return;
    const { minX, maxX, minY, maxY } = texturedMesh.texture.boundingBox;
    const uniforms = {
      meshXMin: formatVal(minX),
      meshXSpan: formatVal(maxX - minX),
      meshYMin: formatVal(minY),
      meshYSpan: formatVal(maxY - minY),
      tex: {
        type: 't',
        value: texture,
      },
    };
    setMaterial(
      new ShaderMaterial({
        uniforms,
        vertexShader,
        fragmentShader,
      }),
    );
  }, [texture, texturedMesh]);

  return material;
};
