import type { RootState } from '@core/redux/interface';
import { store } from '@core/redux/store';
import { trenchSelectors, trenchThunks } from '@trenches/redux';
import type { AttributeRequest, Trench } from '@trenches/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';
import { ATTRIBUTE_POINT_FACTOR, getTrenchRadiusForViewport } from '../../helper';

export interface ConnectProps {
  getCommonTrench: (pointA: string, pointB: string) => Trench | undefined;
  radius: number;
  setAttribute: (
    trenchId: string,
    fromPointId: string,
    toPointId: string,
    attributeRequest: AttributeRequest,
  ) => void;
}

export const useConnect = (): ConnectProps => {
  const cameraViewport = useSelector(viewer3dSelectors.cameraViewport);
  const radius = getTrenchRadiusForViewport(ATTRIBUTE_POINT_FACTOR, cameraViewport);
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const dispatch = useDispatch();

  return {
    radius,
    getCommonTrench: (pointA, pointB) =>
      trenchSelectors.getByCommonPoints(pointA, pointB)(store.getState() as RootState),
    setAttribute: (trenchId, fromPointId, toPointId, attributeRequest) => {
      dispatch(
        trenchThunks.setTrenchAttribute({
          path: ['projects', projectId!, 'trenches', trenchId, 'attributes'],
          body: { fromPointId, toPointId, [attributeRequest.key]: attributeRequest.value },
        }),
      );
    },
  };
};
