import {
  BoxGeometry,
  ConeGeometry,
  IcosahedronGeometry,
  PlaneBufferGeometry,
  SphereBufferGeometry,
  TorusGeometry,
} from 'three';

export const sphereGeometry = new SphereBufferGeometry(1, 10, 10);
export const donutGeometry = new TorusGeometry(1.5, 0.5, 10, 10);
export const icosahedronGeometry = new IcosahedronGeometry(1.5, 0);
export const boxGeometry = new BoxGeometry(2, 2, 2);
export const pyramidGeometry = new ConeGeometry(2.5, 2.5, 3);
export const plane = new PlaneBufferGeometry(1, 1);
