import { useKeys } from '@core/logic';
import { pipeConnectorSelectors, pipeConnectorThunks } from '@elements/redux/connectors.slice';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';

export const PipeConnectors = ({ selectedPointId }: { selectedPointId: string | undefined }) => {
  const dispatch = useDispatch();
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const connectorsInPoint = useSelector(
    pipeConnectorSelectors.selectByProps({ pointId: selectedPointId }),
  );
  const [addKeyPressed, removeKeyPressed] = useKeys('+', '-');

  const addConnector = () => {
    if (!selectedPointId || !projectId) return;
    dispatch(
      pipeConnectorThunks.post({
        path: ['projects', projectId, 'connectors'],
        body: { pointId: selectedPointId, projectId },
      }),
    );
  };

  const removeConnector = () => {
    const [connector] = connectorsInPoint;
    if (!connector || !projectId) return;
    dispatch(
      pipeConnectorThunks.delete({
        id: connector.id,
        path: ['projects', projectId, 'connectors', connector.id],
      }),
    );
  };

  useEffect(() => {
    if (addKeyPressed) addConnector();
    if (removeKeyPressed) removeConnector();
  }, [addKeyPressed, removeKeyPressed]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>Connectors</Typography>
      <ButtonGroup
        size="small"
        color={connectorsInPoint.length ? 'primary' : 'secondary'}
        variant="outlined"
      >
        <Button onClick={removeConnector} data-testid="connector:-">
          -
        </Button>
        <Button variant="contained">{connectorsInPoint.length}</Button>
        <Button onClick={addConnector} data-testid="connector:+">
          +
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
