import type { SegmentWithWidth } from '@core/logic';
import type { Vector } from '@core/types';
import { Vector3 } from 'three';
import { lngLatToXY } from './spacial-ref-converter';

export const vecFromLatLong = (lat: number, long: number, z: number): Vector3 => {
  const { x, y } = lngLatToXY({ lat, lng: long });
  return new Vector3(x, y, z);
};

export const getAngle = (p1: Vector3, p2: Vector3): number => Math.atan2(p2.y - p1.y, p2.x - p1.x);

export const add = (A: Vector, B: Vector): Vector => ({
  x: A.x + B.x,
  y: A.y + B.y,
  z: A.z + B.z,
});

const len2D = (A: Vector): number => Math.sqrt(A.x ** 2 + A.y ** 2);

export const sub = (A: Vector, B: Vector): Vector => ({
  x: A.x - B.x,
  y: A.y - B.y,
  z: A.z - B.z,
});

export const multiply = (V: Vector, s: number): Vector => ({
  x: V.x * s,
  y: V.y * s,
  z: V.z * s,
});

/**
 * https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line#Line_defined_by_two_points
 *
 * ATTENTION: this is only calculated in 2D space because we don't need the z coordinate for the pre-labeled width
 */
export const distancePointSegment =
  (P: Vector) =>
  ([A, B]: SegmentWithWidth): number => {
    // subtract P from A and B in order to get smaller float values and avoid rounding errors
    const a = sub(A, P);
    const b = sub(B, P);
    const lenAB = len2D(sub(b, a));
    const lenA = len2D(a);
    const lenB = len2D(b);
    if (lenAB <= lenA || lenAB <= lenB) {
      // P is outside the segment aka has no orthogonal vector to the segment
      return Math.min(lenA, lenB);
    }
    const numerator = Math.abs((b.x - a.x) * a.y - a.x * (b.y - a.y));
    const denominator = Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
    return numerator / denominator;
  };
