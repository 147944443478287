import type { Intersection } from '@react-three/fiber';
import { Object3dNames } from '@viewer3D/types';

interface ScanUserData {
  scanId: string;
  processingResultId: string;
}
export const getVisibleScans = (intersections: Intersection[]): ScanUserData[] =>
  [
    ...new Set(
      intersections
        .filter(
          ({ object }) =>
            object?.visible &&
            object?.name.startsWith(Object3dNames.Scan) &&
            !!object.userData.scanId,
        )
        .map(({ object }) => object.userData),
    ),
  ] as ScanUserData[];
