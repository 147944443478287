export enum EntityRelationKey {
  CreatedAt = 'createdAt',
  SplineElement = 'splineElement',
  SplineElements = 'splineElements',
  PointElements = 'pointElements',
  PipeFittings = 'pipeFittings',
  PipeConnectors = 'pipeConnectors',
  MarkerSpheres = 'markerSpheres',
  MeshWithOffset = 'meshWithOffset',
  Point = 'point',
  Points = 'points',
  ProcessingResults = 'processingResults',
  Project = 'project',
  Projects = 'projects',
  Scan = 'scan',
  Scans = 'scans',
  SelectedProcessingResult = 'selectedProcessingResult',
  Tag = 'tag',
  Tags = 'tags',
  UpdatedAt = 'updatedAt',
  Version = 'version',
}
