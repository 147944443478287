import type { RootState } from '@core/redux/interface';
import type { PointElement, SplineElement } from '@elements/types';
import type { TrenchPoint } from '@trenches/components/types';
import { trenchSelectors } from '@trenches/redux';
import type { Trench, TrenchSegment } from '@trenches/types';

export const getInsertIndex = (pointIds: string[], selectedPointId?: string): number => {
  if (pointIds.length === 0 || !selectedPointId) return 0;
  if (pointIds.length === 1) return 1;

  if (selectedPointId === pointIds[0]) return 0;
  return pointIds.length; // yes, actually last index + 1! In order to insert new point AFTER the last point
};

export const insertPointAtIndex = (
  newPointId: string,
  pointIds: string[] = [],
  index: number,
): string[] => [...pointIds.slice(0, index), newPointId, ...pointIds.slice(index)];

export const isPointInSegment =
  (pointIndex: number) =>
  ({ sequenceRange }: TrenchSegment): boolean =>
    (sequenceRange.fromIndex <= pointIndex && pointIndex <= sequenceRange.toIndex) ||
    (sequenceRange.fromIndex >= pointIndex && pointIndex >= sequenceRange.toIndex);

export const isPointOnTrench =
  (pointId: string) =>
  (t: Trench): boolean =>
    t.pointIds.includes(pointId);

export const indexOfPoint =
  (pointId: string) =>
  (t: Trench): number =>
    t.pointIds.indexOf(pointId);

export const getElementIdsForPoint =
  (state: RootState) =>
  (point: TrenchPoint): string[] =>
    trenchSelectors
      .selectById(point.trenchId)(state)!
      .segments // get all trench segments containing that point
      .filter(isPointInSegment(point.index))
      // get element ids in that segment
      .flatMap((s) => s.splineElementIds);

export const isSinglePointElement = (e?: PointElement | SplineElement): e is PointElement =>
  !!e && 'kind' in e && 'subtype' in e;

export const isElementEndPoint =
  (pointId?: string) =>
  (element?: SplineElement): boolean =>
    element?.toPoint?.id === pointId || element?.fromPoint?.id === pointId;
