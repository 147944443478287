import type { ThreeEvent } from '@react-three/fiber';
import { enableLayerOne, getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { ForwardedRef, forwardRef, memo, useEffect, useRef, useState } from 'react';
import type { BufferGeometry, Group, Mesh, ShaderMaterial, Vector3 } from 'three';
import { useDracoGeometry, useTexture, useTexturedMesh } from '../../hooks';

export type MeshType = Mesh<BufferGeometry, ShaderMaterial>;

export const ScanBase = (
  {
    scanId,
    processingResultId,
    onOver,
    onClick,
    onLoaded,
  }: {
    onClick?: (event: ThreeEvent<MouseEvent>) => void;
    onLoaded?: (geometryCenter: Vector3) => void;
    onOver?: (event: ThreeEvent<MouseEvent>) => void;
    processingResultId: string;
    scanId: string;
  },
  refToSelf: ForwardedRef<Group>,
) => {
  const userDataRef = useRef({ scanId, processingResultId, type: Object3dNames.Scan });
  const texturedMesh = useTexturedMesh(processingResultId);
  const geometry = useDracoGeometry(texturedMesh);
  const material = useTexture(texturedMesh);
  const meshRef = useRef<MeshType>(null);
  const [geometryCenterCorrection, setGeometryCenterCorrection] = useState<Vector3>();

  useEffect(() => {
    if (!geometry) return;
    geometry.computeBoundingSphere();
    const center = geometry.boundingSphere!.center.clone().negate();
    setGeometryCenterCorrection(center);
  }, [geometry]);

  useEffect(() => {
    if (!meshRef.current?.userData?.processingResultId) return;
    meshRef.current.userData.processingResultId = processingResultId;
  }, [meshRef, processingResultId]);

  useEffect(() => {
    if (!geometryCenterCorrection || !onLoaded) return;
    onLoaded(geometryCenterCorrection.clone());
  }, [geometryCenterCorrection, onLoaded]);

  return (
    <group
      ref={refToSelf}
      name={`${Object3dNames.ScanCentered}:${scanId}`}
      renderOrder={getRenderOrder(Object3dNames.ScanCentered)}
    >
      {geometry && material && geometryCenterCorrection && (
        <mesh
          ref={meshRef}
          name={`${Object3dNames.Scan}:${scanId}`}
          userData={userDataRef.current}
          geometry={geometry}
          material={material}
          onClick={onClick}
          position={geometryCenterCorrection}
          onPointerMove={onOver}
          onUpdate={enableLayerOne}
          renderOrder={getRenderOrder(Object3dNames.Scan)}
        />
      )}
    </group>
  );
};

export const Scan = memo(forwardRef(ScanBase));
