import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import type { MapboxStyle } from '@core/types';
import { createAction, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { trenchSelectors } from '@trenches/redux';
import type {
  BoundingBox,
  CameraGoTo,
  CameraProps,
  ScanSelection,
  VisibleScans,
} from '@viewer3D/types';
import { viewer3dSelectors } from '@viewer3D/redux/selectors';
import { proxy } from 'valtio';
import { Point } from '@trenches/types';
import type { VisibleElementUsage } from './interface';

function viewer3dAction<T>(name: string) {
  return createAction<T>(`viewer3d/${name}`);
}

export const setCamera = viewer3dAction<CameraProps>('camera');
export const setCameraViewport = viewer3dAction<BoundingBox & { z: number }>('cameraViewport');
export const cameraGoto = viewer3dAction<CameraGoTo | undefined>('cameraGoto');
export const setSelectedProjectId = viewer3dAction<string | undefined>('selectedProjectId');

export const setSelectedScanId = viewer3dAction<string | undefined>('selectedScanId');
export const clearSelectedScan = viewer3dAction<undefined>('clearSelectedScan');

export const setSelectedPointId = viewer3dAction<string | undefined>('selectedPointId');
export const clearSelectedPoint = viewer3dAction<undefined>('clearSelectedPoint');

export const setSelectedTrenchId = viewer3dAction<string>('setSelectedTrenchId');
export const clearSelectedTrenchId = viewer3dAction<undefined>('clearSelectedTrenchId');

export const setSelectedElementIds = viewer3dAction<string[]>('setSelectedElementIds');
export const addElementIdToSelection = viewer3dAction<string>('addElementIdToSelection');
export const removeElementIdFromSelection = viewer3dAction<string>('removeElementIdToSelection');
export const clearSelection = viewer3dAction<undefined>('clearSelection');

export const setVisibleElementUsage =
  viewer3dAction<Partial<VisibleElementUsage>>('visibleElementUsage');
export const setScanSelection = viewer3dAction<ScanSelection | undefined>('scanSelection');
export const setMapStyle = viewer3dAction<MapboxStyle>('mapStyle');
export const setVisibleScans = viewer3dAction<VisibleScans>('visibleScans');

const selectFirstTrenchWithPoint =
  (pointId: string) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const trenchToSelect = trenchSelectors
      .selectAll(getState())
      .find((t) => t.pointIds.includes(pointId))!;
    dispatch(setSelectedTrenchId(trenchToSelect.id));
  };

// TODO transition whole viewer3d state to Valtio to reduce complexity
export const viewer3dState = proxy<{
  getFocusCoordinate?: (state: RootState) => Point | undefined;
}>({
  getFocusCoordinate: undefined,
});

export const setFocusPointSelector = (selector?: typeof viewer3dState.getFocusCoordinate) => {
  viewer3dState.getFocusCoordinate = selector;
};

export const viewer3dMiddleware = createListenerMiddleware();

viewer3dMiddleware.startListening({
  matcher: isAnyOf(setSelectedScanId, clearSelectedScan),
  effect: () => {
    setFocusPointSelector(viewer3dSelectors.selectScanPoint);
  },
});

viewer3dMiddleware.startListening({
  matcher: isAnyOf(setSelectedElementIds),
  effect: () => {
    setFocusPointSelector(viewer3dSelectors.selectElementPoint);
  },
});

viewer3dMiddleware.startListening({
  matcher: isAnyOf(setSelectedPointId, clearSelectedPoint),
  effect: () => {
    setFocusPointSelector(viewer3dSelectors.selectedPoint);
  },
});

viewer3dMiddleware.startListening({
  matcher: isAnyOf(clearSelection),
  effect: () => {
    setFocusPointSelector();
  },
});

const focusSelectedEntity = (dispatch: AppDispatch, getState: () => RootState): void => {
  const nextFocusPoint = viewer3dState.getFocusCoordinate?.(getState());
  nextFocusPoint && dispatch(cameraGoto(nextFocusPoint));
};

export const viewer3dActions = {
  focusSelectedEntity,
  selectFirstTrenchWithPoint,
  clearSelectedScan,
  clearSelectedPoint,
  clearSelectedTrenchId,
  setSelectedTrenchId,
  setCamera,
  setCameraViewport,
  cameraGoto,
  setSelectedProjectId,
  setSelectedScanId,
  setSelectedElementIds,
  addElementIdToSelection,
  removeElementIdFromSelection,
  setSelectedPointId,
  setVisibleElementUsage,
  setScanSelection,
  clearSelection,
  setMapStyle,
  setVisibleScans,
};
