import { toHumanReadable } from '@core/logic';
import { elementSelectors, elementThunks, elementTypeSelectors } from '@elements/redux';
import { pipeFittingSelectors } from '@elements/redux/pipe-fitting.slice';
import { HouseLeadVariant, houseLeadVariants, SplineElement } from '@elements/types';
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { cloneDeep, set } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scanSelectors } from '@scans/redux';
import { ArrowDownBox, XOutline } from '@deepup/icons';

const useConnect = () => {
  const [firstSelectedElementId, secondSelectedElementId] = useSelector(
    viewer3dSelectors.selectedElementIds,
  );
  const selectedPipe = useSelector(elementSelectors.selectById(firstSelectedElementId));
  const selectedElementType = useSelector(
    elementTypeSelectors.selectById(selectedPipe?.splineElementTypeId),
  );

  const [speedPipe, setSpeedPipe] = useState<SplineElement>();

  const isOpen = !!speedPipe && !secondSelectedElementId; // prevent the Overlay from showing up when more than one element is selected

  const fittings = useSelector(
    pipeFittingSelectors.selectWithFilter(
      (p) =>
        p.outgoingSplineElementId === selectedPipe?.id && p.pointId === selectedPipe?.fromPoint?.id,
    ),
  );
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const selectedPoint = useSelector(viewer3dSelectors.selectedPoint);
  const scan = useSelector(scanSelectors.selectById(selectedPoint?.scanId));
  const [scanStreet] = scan?.location?.place?.split(',') ?? [];

  const [firstFitting] = fittings;
  const incomingElement = useSelector(
    elementSelectors.selectById(
      firstFitting?.incomingSplineElementId || firstFitting?.incomingColor,
    ),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setSpeedPipe(selectedElementType?.kind === 'SPEEDPIPE' ? selectedPipe : undefined);
  }, [selectedPipe]);

  const updateElement = () => {
    if (!speedPipe) return;
    dispatch(
      elementThunks.patch({
        path: ['projects', projectId!, 'splineElements', speedPipe.id],
        body: speedPipe,
      }),
    );
  };

  const updateProp = (path: string, value: unknown) => {
    setSpeedPipe((e) => set(cloneDeep(e!), path, value));
  };

  const useScanAddress = () => {
    if (!scanStreet) return;
    // for now simply replace the street. In the future we might want to replace the whole address.
    updateProp('address.street', scanStreet);
  };

  const toggleVariant = (variant: HouseLeadVariant) => {
    if (speedPipe?.houseLeadVariant === variant) {
      updateProp('houseLeadVariant', null);
    } else {
      updateProp('houseLeadVariant', variant);
    }
  };

  return {
    originalAddress: selectedPipe?.address,
    originalVariant: selectedPipe?.houseLeadVariant,
    incomingElement,
    fittings,
    speedPipe,
    isOpen,
    scanStreet,
    updateElement,
    updateProp,
    useScanAddress,
    toggleVariant,
  };
};

export const HouseLeadDetails = () => {
  const {
    fittings,
    incomingElement,
    speedPipe,
    isOpen,
    toggleVariant,
    updateProp,
    useScanAddress,
    updateElement,
    scanStreet,
    originalAddress,
    originalVariant,
  } = useConnect();
  const dispatch = useDispatch();

  const parentBundleName =
    fittings.length && incomingElement
      ? incomingElement.name
      : fittings.length && !incomingElement
      ? 'Unknown'
      : null;

  const pipeDataHasChanged =
    speedPipe?.address?.addition != originalAddress?.addition ||
    speedPipe?.address?.street != originalAddress?.street ||
    speedPipe?.address?.number !== originalAddress?.number ||
    speedPipe?.houseLeadVariant !== originalVariant;

  if (!isOpen) return null;

  const closeOverlay = () => {
    dispatch(viewer3dActions.setSelectedElementIds([]));
  };

  return (
    <Paper
      elevation={12}
      data-testid="houseLeadVariantDrawer"
      square
      sx={{ position: 'sticky', bottom: 0 }}
    >
      <Stack padding={2} gap={2} divider={<Divider variant="fullWidth" sx={{ mx: -2 }} />}>
        <Typography
          variant="overline"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {speedPipe?.name}
          <IconButton size="small" color="secondary" onClick={closeOverlay}>
            <XOutline fontSize={24} />
          </IconButton>
        </Typography>
        <Grid container gap={1}>
          {parentBundleName && (
            <>
              <Grid item xs={6}>
                <Typography variant="subtitle2" color="secondary">
                  Parent bundle
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle2">{parentBundleName}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="secondary">
              Fittings in From Point
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2">{fittings.length}</Typography>
          </Grid>
        </Grid>
        <Stack spacing={2}>
          <Typography variant="subtitle2" color="secondary">
            House Lead Variant
          </Typography>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {houseLeadVariants.map((variant) => (
              <Chip
                color={speedPipe?.houseLeadVariant === variant ? 'primary' : 'default'}
                variant={speedPipe?.houseLeadVariant === variant ? 'filled' : 'outlined'}
                key={variant}
                onClick={() => toggleVariant(variant)}
                label={variant ? toHumanReadable(variant).replace(' Plus', '+') : 'None'}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle2" color="secondary">
            Address
          </Typography>
          <Stack spacing={1}>
            <Tooltip
              arrow
              title={
                scanStreet
                  ? 'Use the underlying scan address as house lead address'
                  : 'Underlying scan is not loaded or has no address'
              }
            >
              {/* Tooltip component needs a child which can hold a ref https://mui.com/material-ui/react-tooltip/#disabled-elements*/}
              <span>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={useScanAddress}
                  disabled={!scanStreet || scanStreet === speedPipe?.address?.street}
                  startIcon={<ArrowDownBox fill="currentColor" />}
                >
                  Use Scan Address
                </Button>
              </span>
            </Tooltip>
            <Stack spacing={1} direction="row">
              <TextField
                inputProps={{ 'aria-label': 'Street' }}
                label="Street"
                value={speedPipe?.address?.street || ''}
                onChange={(e) => updateProp('address.street', e.target.value)}
                sx={{ flex: 0.77 }}
              />
              <TextField
                inputProps={{ 'aria-label': 'Number' }}
                label="Nr"
                value={speedPipe?.address?.number || ''}
                sx={{ flex: 0.23 }}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  updateProp('address.number', Number(e.target.value));
                }}
              />
            </Stack>
            <TextField
              inputProps={{ 'aria-label': 'Addition' }}
              label="Addition"
              value={speedPipe?.address?.addition || ''}
              onChange={(e) => updateProp('address.addition', e.target.value)}
            />
          </Stack>
          <Button
            variant="contained"
            color="primary"
            disabled={!pipeDataHasChanged}
            onClick={updateElement}
            sx={{ alignSelf: 'flex-end' }}
          >
            Save Changes
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
