import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory } from '@core/redux/factory';
import type { RootState } from '@core/redux/interface';
import type { GetElementType, SplineElementKind, SplineElementType } from '../types';
import { elementSelectors } from './element.slice';

export const {
  reducer: elementTypeReducer,
  apiThunks: elementTypeThunks,
  selectors: elementTypeBaseSelectors,
} = crudEntityFactory<SplineElementType>({
  entity: ReduxEntities.SplineElementTypes,
});

export const elementTypeSelectors = {
  ...elementTypeBaseSelectors,
  selectByElementId:
    (elementId?: string) =>
    (state: RootState): SplineElementType | undefined => {
      const element = elementSelectors.selectById(elementId)(state);
      if (!element) return;
      return elementTypeBaseSelectors.selectById(element.splineElementTypeId)(state);
    },
  selectByKind:
    <T extends SplineElementKind>(kind: T) =>
    (state: RootState): GetElementType<T>[] =>
      elementTypeBaseSelectors.selectByProps({ kind })(state) as GetElementType<T>[],
};
