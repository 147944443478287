import { LogoutButton } from '@core/components';
import { IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import { PersonFilled } from '@deepup/icons';

export const UserAccount = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        data-cy="mainmenu__useraccount__button-icon"
      >
        <PersonFilled />
      </IconButton>
      <Menu
        id="user-logout-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        autoFocus={false}
        keepMounted={false}
      >
        <LogoutButton data-cy="mainmenu__useraccount__button-logout" />
      </Menu>
    </>
  );
};
