import type { PointTrenchMap, TrenchPoint } from '@trenches/components/types';
import type { Trench } from '@trenches/types';

export const buildPointTrenchMap = (trenches: Trench[]): PointTrenchMap => {
  const pointTrenchMap: PointTrenchMap = {};
  for (let i = 0; i < trenches.length; i++) {
    const { id: trenchId, pointIds } = trenches[i];
    for (let j = 0; j < pointIds.length; j++) {
      const pointId = pointIds[j];
      const isEndpoint = j === 0 || j === pointIds.length - 1;
      const trenchPoint: TrenchPoint = { trenchId, isEndpoint, index: j };
      if (pointTrenchMap[pointId]) {
        pointTrenchMap[pointId].push(trenchPoint);
      } else {
        pointTrenchMap[pointId] = [trenchPoint];
      }
    }
  }
  return pointTrenchMap;
};
