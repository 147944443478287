import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const isDevelopment = import.meta.env?.DEV;
const environment = import.meta.env?.MODE;

const useSentry = !isDevelopment && (environment === 'test' || environment === 'production');

if (useSentry) {
  Sentry.init({
    dsn: import.meta.env?.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.01,
    release: import.meta.env?.PACKAGE_VERSION ?? 'unknown',
    environment,
  });
}
