import { Selectable3DObjectList } from '@projects/components';
import type { FC } from 'react';
import { useConnect } from './connect';

export const TrenchSelect: FC = () => {
  const { trenchItems, selectTrench, mergeTrenches, splitTrench } = useConnect();
  return (
    <Selectable3DObjectList
      items={trenchItems}
      selectItem={selectTrench}
      onMergeItems={mergeTrenches}
      onSplitItem={splitTrench}
    />
  );
};
