import { editModeState, setEditMode } from '@projects/edit-modes/state';
import { resetTrenchFromPoint } from '@scans/components/Scans/state';
import { resetAttributeFromPoint } from '@trenches/components/Trench/Points/helper';
import { setTransformControl } from '@viewer3D/components';
import { viewer3dActions } from '@viewer3D/redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnapshot } from 'valtio';
import type { EditMode } from './types';

export type Props = {
  mode: EditMode;
  changeEditMode: (nextMode: EditMode) => void;
};

export const useConnect = (): Props => {
  const { mode } = useSnapshot(editModeState);
  const dispatch = useDispatch();

  const changeEditMode = useCallback((nextMode: EditMode) => {
    setTransformControl({});
    resetAttributeFromPoint();
    resetTrenchFromPoint();
    setEditMode(nextMode);
    dispatch(viewer3dActions.clearSelection());
  }, []);

  return {
    mode,
    changeEditMode,
  };
};
