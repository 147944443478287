import type { SplineElementType } from '@elements/types';
import type { AttributeState } from '@projects/edit-modes/Attributes';
import { EditMode } from '@projects/edit-modes/types';
import { DepthColors, SegmentColors, TrenchOpacity, WidthColors } from '@trenches/colors';
import type { AttributeType, Depth, LayingType, SurfaceType, Width } from '@trenches/types';

const getAttributeColor = (
  selectedAttributeType: AttributeType,
  layingType: LayingType | null,
  surfaceType: SurfaceType | null,
  depth: Depth | null,
  width: Width | null,
  attributeState: AttributeState,
): string => {
  if (selectedAttributeType === 'LAYING_TYPE' && layingType) return SegmentColors[layingType];
  if (selectedAttributeType === 'SURFACE_TYPE' && surfaceType) return SegmentColors[surfaceType];
  if (selectedAttributeType === 'DEPTH' && depth) {
    if (attributeState.showOnlyCurrentDepth && attributeState.depth !== depth)
      return SegmentColors.Default;
    return DepthColors[depth];
  }
  if (selectedAttributeType === 'WIDTH' && width) {
    if (attributeState.showOnlyCurrentWidth && attributeState.width !== width)
      return SegmentColors.Default;
    return WidthColors[width];
  }
  return SegmentColors.Default;
};

export const getTrenchSegmentColor = (
  mode: EditMode,
  isMouseOverTrench: boolean,
  isSelected: boolean,
  isSelectedElementInSegment: boolean,
  containsElements: boolean,
  selectedAttributeType: AttributeType,
  layingType: LayingType | null,
  surfaceType: SurfaceType | null,
  depth: Depth | null,
  width: Width | null,
  attributeState: AttributeState,
  elementType?: SplineElementType,
): string => {
  if (isSelectedElementInSegment && elementType) return elementType.color;
  if (mode === EditMode.Element)
    return containsElements ? SegmentColors.WithElement : SegmentColors.MissingElement;
  if (isSelected) return SegmentColors.Selected;
  if (isMouseOverTrench) return SegmentColors.Hover;
  if (mode === EditMode.TrenchAttributes)
    return getAttributeColor(
      selectedAttributeType,
      layingType,
      surfaceType,
      depth,
      width,
      attributeState,
    );
  if (layingType === 'SOIL_DISPLACEMENT') return SegmentColors.SOIL_DISPLACEMENT;
  if (mode === EditMode.Trench) return SegmentColors.TrenchMode;
  if (mode === EditMode.Scan) return SegmentColors.ScanMode;
  return SegmentColors.Default;
};

export const getTrenchSegmentOpacity = (
  mode: EditMode,
  isTrenchSelected: boolean,
  layingType: LayingType | null,
  surfaceType: SurfaceType | null,
  width: Width | null,
  depth: Depth | null,
): number => {
  if (mode === EditMode.Element) return TrenchOpacity.Default;
  if (mode === EditMode.TrenchAttributes)
    return layingType || surfaceType || width || depth
      ? TrenchOpacity.Default
      : TrenchOpacity.Light;
  return isTrenchSelected ? TrenchOpacity.Selected : TrenchOpacity.Default;
};
