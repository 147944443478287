import { useEditModeState } from '@projects/edit-modes';
import { externalDataDefaultColor, getLayingTypeColor } from '@trenches/colors';
import { DeepupCatmullRomCurve } from '@trenches/components/Trench/DeepupCatmullRomCurve';
import type { LayingType, Point } from '@trenches/types';
import { getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { TubeBufferGeometry } from 'three';
import { useExternalDataState, useExternalDataTrenches } from './hooks';

const LineString = ({ points, layingType }: { points: Point[]; layingType: LayingType }) => {
  const { isAttributesMode } = useEditModeState();
  const color = isAttributesMode ? getLayingTypeColor(layingType) : externalDataDefaultColor;

  const curve = new DeepupCatmullRomCurve();
  curve.updatePoints(points as Point[]);

  return (
    <group position={curve.worldPosition} name={Object3dNames.ExternalDataTrenchSegments}>
      <mesh
        geometry={new TubeBufferGeometry(curve, points.length, 0.15, 8, false)}
        material={getCachedMaterial(color, 0.85)}
      />
    </group>
  );
};

export const ExternalDataTrenches = () => {
  const { trenches } = useExternalDataTrenches();

  if (!trenches.length) {
    return null;
  }

  return (
    <group renderOrder={getRenderOrder(Object3dNames.ExternalDataTrenchSegments)}>
      {trenches.map(({ id, points, type }) => (
        <LineString key={id} layingType={type} points={points.flat()} />
      ))}
    </group>
  );
};

export const ExternalData = () => {
  const { isExternalVisible } = useExternalDataState();

  // Avoid data fetching in case the component is not visible
  return isExternalVisible ? <ExternalDataTrenches /> : null;
};
