import { PipesFilled, PipesOutline, ScanFilled, ScanOutline } from '@deepup/icons';
import { Divider, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useKey } from '@core/logic';
import { CustomScrollContainer } from '@core/components';
import { Icon } from '@core/types';
import { ElementOrganization } from './ElementOrganization';
import { ScanOrganization } from './ScanOrganization';

export enum ProjectMenuTools {
  SCANS = 'Scans',
  ELEMENTS = 'Elements',
}

enum ProjectMenuToolsKeys {
  SCANS = 's',
  ELEMENTS = 'x',
}

const tools: Record<ProjectMenuTools, [Icon, Icon, ProjectMenuToolsKeys]> = {
  Scans: [ScanOutline, ScanFilled, ProjectMenuToolsKeys.SCANS],
  Elements: [PipesOutline, PipesFilled, ProjectMenuToolsKeys.ELEMENTS],
};

export const ProjectMenu = () => {
  const [selectedTool, setSelectedTool] = useState<ProjectMenuTools>(ProjectMenuTools.SCANS);
  const xPressed = useKey(ProjectMenuToolsKeys.ELEMENTS);
  const yPressed = useKey(ProjectMenuToolsKeys.SCANS);

  useEffect(() => {
    xPressed && setSelectedTool(ProjectMenuTools.ELEMENTS);
  }, [xPressed]);

  useEffect(() => {
    yPressed && setSelectedTool(ProjectMenuTools.SCANS);
  }, [yPressed]);

  return (
    <>
      <Tabs
        value={selectedTool}
        onChange={(e, nextValue) => setSelectedTool(nextValue as ProjectMenuTools)}
        variant="fullWidth"
        sx={{
          flexShrink: 0, // prevents the tabs from being squeezed to the top of the container when container below overflows
        }}
      >
        {Object.entries(tools).map(([name, [IconDefault, IconSelected, shortcutKey]]) => {
          const Icon = selectedTool === name ? IconSelected : IconDefault;
          return (
            <Tab
              key={name}
              value={name}
              icon={<Icon fontSize={24} fill="currentColor" />}
              label={`${name} [${shortcutKey}]`}
            />
          );
        })}
      </Tabs>
      <Divider />
      <CustomScrollContainer flexGrow={1}>
        {selectedTool === ProjectMenuTools.SCANS && <ScanOrganization />}
        {selectedTool === ProjectMenuTools.ELEMENTS && <ElementOrganization />}
      </CustomScrollContainer>
    </>
  );
};
