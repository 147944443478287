import type { AppDispatch } from '@core/redux/store';
import type { SelectableItem } from '@projects/components';
import { isEndPoint } from '@trenches/components/Trench';
import { trenchActions, trenchSelectors, trenchThunks } from '@trenches/redux';
import type { Trench } from '@trenches/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { intersection } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useQueue } from '@core/logic/hooks';
import { DotFilled } from '@deepup/icons';

export const useConnect = () => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const selectedTrenchId = useSelector(viewer3dSelectors.selectedTrenchId);
  const selectedTrench = useSelector(trenchSelectors.selectById(selectedTrenchId));
  const isSelectedPointEndpointOfSelectedTrench = isEndPoint(selectedPointId, selectedTrench);
  const trenchesInSelectedPoint = useSelector(trenchSelectors.filterByPointId(selectedPointId));
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const dispatch = useDispatch<AppDispatch>();
  const { queue, addToQueue, removeFromQueue } = useQueue<string>();

  const selectTrench = (trenchId: string) => () =>
    dispatch(viewer3dActions.setSelectedTrenchId(trenchId));

  const mergeTrenches = (trenchTwoId?: string) => async () => {
    const trenchOneId = selectedTrench?.id;
    if (!selectedPointId || !trenchOneId || !trenchTwoId) return;
    addToQueue(trenchOneId, trenchTwoId);
    await dispatch(
      trenchThunks.mergeTrenches({
        path: ['projects', projectId!, 'trenches', 'merge'],
        body: {
          mergePointId: selectedPointId,
          trenchOneId,
          trenchTwoId,
        },
      }),
    );
    dispatch(trenchActions.removeMany([trenchOneId, trenchTwoId]));
    removeFromQueue(trenchOneId, trenchTwoId);
  };

  const splitTrench = (trenchId?: string) => async () => {
    if (!trenchId || !selectedPointId) return;
    addToQueue(trenchId);
    const { payload } = await dispatch(
      trenchThunks.splitTrench({
        path: ['projects', projectId!, 'trenches', trenchId, 'split'],
        body: {
          pointId: selectedPointId,
        },
      }),
    );
    dispatch(trenchActions.upsertMany(payload as Trench[]));
    removeFromQueue(trenchId);
  };

  const trenchToSelectableItem = (trench: Trench, i: number): SelectableItem => {
    const isSelectedPointEndpoint = isEndPoint(selectedPointId, trench);
    const trenchesMeetInOnePoint =
      intersection(selectedTrench?.pointIds, trench.pointIds).length === 1;
    return {
      id: trench.id,
      isSelected: selectedTrench?.id === trench.id,
      title: `Trench ${i + 1}`, // just render some big ol' number
      canBeMerged:
        isSelectedPointEndpointOfSelectedTrench &&
        selectedTrench?.id !== trench.id && // can't merge with itself
        trenchesMeetInOnePoint &&
        isSelectedPointEndpoint,
      canBeSplit: !isSelectedPointEndpoint,
      isLoading: queue.includes(trench.id),
      Icon: DotFilled,
    };
  };

  return {
    selectTrench,
    trenchItems: trenchesInSelectedPoint.map(trenchToSelectableItem),
    mergeTrenches,
    splitTrench,
  };
};
