import type { FullAddress } from '@core/types/address';

export const toHumanReadable = (str?: string): string =>
  str
    ? str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // seperate camel cases
        .replace(/_/g, ' ') // seperate snake case
        .split(' ') // build array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // capitalize first letter
        .join(' ')
    : '';

export const formatAddress = (address: Partial<FullAddress> | null): string =>
  !address
    ? ''
    : [
        address.street + ' ' + address.number + (address.addition ? ' ' + address.addition : ''),
        address.zip + ' ' + address.city,
        address.district,
      ]
        .filter((x) => !!x)
        .join(', ');
