import { EditMode } from '@projects/edit-modes/types';
import type { DeepupCatmullRomCurve } from '../DeepupCatmullRomCurve';

// TODO replace with `Pick<Object3dNames.TrenchSegments, blablabla>`
export type ObjectToRender =
  | 'segments'
  | 'intermediatePoints'
  | 'attributePoints'
  | 'trenchPoints'
  | 'elementPoints'
  | 'pipeDirection';

interface TrenchConfig {
  events: {
    group: boolean;
  };
  render: Record<ObjectToRender, boolean>;
}

type TrenchConfigFn = (props: { numberOfPoints: number }) => TrenchConfig;

const scanModeConfig: TrenchConfigFn = ({ numberOfPoints }) => ({
  events: {
    group: false,
  },
  render: {
    segments: numberOfPoints > 1,
    trenchPoints: false,
    intermediatePoints: false,
    attributePoints: false,
    elementPoints: false,
    pipeDirection: false,
  },
});

const trenchModeConfig: TrenchConfigFn = ({ numberOfPoints }) => ({
  events: {
    group: true,
  },
  render: {
    segments: numberOfPoints > 1,
    trenchPoints: true,
    intermediatePoints: numberOfPoints > 1,
    attributePoints: false,
    elementPoints: false,
    pipeDirection: false,
  },
});

const elementModeConfig: TrenchConfigFn = ({ numberOfPoints }) => ({
  events: {
    group: false,
  },
  render: {
    segments: numberOfPoints > 1,
    trenchPoints: false,
    intermediatePoints: false,
    attributePoints: false,
    elementPoints: true,
    pipeDirection: true,
  },
});

const attributeModeConfig: TrenchConfigFn = ({ numberOfPoints }) => ({
  events: {
    group: false,
  },
  render: {
    segments: numberOfPoints > 1,
    trenchPoints: false,
    intermediatePoints: false,
    attributePoints: numberOfPoints > 1,
    elementPoints: false,
    pipeDirection: false,
  },
});

const configMap: Record<EditMode, TrenchConfigFn> = {
  [EditMode.Scan]: scanModeConfig,
  [EditMode.Trench]: trenchModeConfig,
  [EditMode.Element]: elementModeConfig,
  [EditMode.TrenchAttributes]: attributeModeConfig,
};

export const getTrenchConfiguration = (
  mode: EditMode,
  curve: DeepupCatmullRomCurve,
): TrenchConfig =>
  configMap[mode]({
    numberOfPoints: curve.points.length,
  });
