import { toHumanReadable } from '@core/logic';
import { DotFilled, EyeFilled, EyeSlashedFilled, XOutline } from '@deepup/icons';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { attributeState } from './state';
import type { LegendItemsProps } from './types';

export const LegendItems = ({
  attributeList,
  attribute,
  callback,
  colors,
  toggleVisibility,
  currentType,
  onRemove,
  selected,
}: LegendItemsProps) => {
  const { showOnlyCurrentDepth, showOnlyCurrentWidth, depth, width } = useSnapshot(attributeState);
  const [showVisibilityIcon, setShowVisibilityIcon] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    switch (currentType as string) {
      case 'DEPTH':
        setIsActive(showOnlyCurrentDepth);
        setShowVisibilityIcon(true);
        break;
      case 'WIDTH':
        setIsActive(showOnlyCurrentWidth);
        setShowVisibilityIcon(true);
        break;
      default:
        setShowVisibilityIcon(false);
        break;
    }
  }, [currentType, width, depth, showOnlyCurrentDepth, showOnlyCurrentWidth]);

  return (
    <List>
      {attributeList.map((currentAttribute) => (
        <ListItemButton
          key={currentAttribute}
          onClick={() => callback(currentAttribute)}
          selected={currentAttribute === attribute}
          dense
        >
          <ListItemIcon>
            <DotFilled fill={colors[currentAttribute]} />
          </ListItemIcon>
          <ListItemText primary={toHumanReadable(currentAttribute)} />
          {showVisibilityIcon && currentAttribute === attribute && (
            <IconButton
              size="small"
              onClick={toggleVisibility}
              data-testid={`selectShowOnlySelected${toHumanReadable(currentType)}`}
            >
              {isActive ? <EyeSlashedFilled /> : <EyeFilled />}
            </IconButton>
          )}
        </ListItemButton>
      ))}
      <ListItemButton selected={selected} onClick={onRemove} dense>
        <ListItemIcon>
          <XOutline fill={theme.palette.error.main} />
        </ListItemIcon>
        <ListItemText primary={`Remove ${toHumanReadable(currentType)}`} />
      </ListItemButton>
    </List>
  );
};
