import { DateFilterType, useScanFilters } from '@projects/pages/detail/hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { Checkbox, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { toggleCheckedScans, uncheckAllScans } from './checkboxSelectionState';
import { sortAndGroupScans } from './group.helper';
import { ScansDateGroupItem } from './ScanDateGroupItem';
import { scanGroupingState, setScanGrouping } from './scanGroupingState';
import { useConnectScans } from './connect';

/**
 * Source: https://www.figma.com/file/bzTko0Vn3oJrMplbKr7rZW/BO-Date-Picker-Improvements?type=design&node-id=6-10689&mode=design&t=FnoBOwUVo4Twf4JU-4
 */
const NoScansIcon = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="39" stroke="#666666" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.4075 46.3408C47.1981 44.8124 48.3334 42.5388 48.3334 40C48.3334 35.3976 44.6024 31.6667 40 31.6667C37.4613 31.6667 35.1877 32.8019 33.6592 34.5925L27.2227 28.156C27.82 27.2583 28.8409 26.6667 30 26.6667H33.3334C34.2538 26.6667 35 25.9205 35 25C35 24.0795 34.2538 23.3333 33.3334 23.3333H30C27.9214 23.3333 26.0649 24.2846 24.8423 25.7756L24.5637 25.4971C23.901 24.8343 22.8265 24.8343 22.1637 25.4971C21.501 26.1598 21.501 27.2343 22.1637 27.8971L52.103 57.8363C52.7657 58.499 53.8402 58.499 54.503 57.8363C55.1657 57.1735 55.1657 56.099 54.503 55.4363L54.2244 55.1577C55.7154 53.9351 56.6667 52.0786 56.6667 50V46.6667C56.6667 45.7462 55.9205 45 55 45C54.0795 45 53.3334 45.7462 53.3334 46.6667V50C53.3334 51.1591 52.7417 52.18 51.844 52.7773L45.4075 46.3408ZM36.0286 36.9619C36.9424 35.7691 38.3814 35 40 35C42.7614 35 45 37.2386 45 40C45 41.6186 44.2309 43.0576 43.0381 43.9715L36.0286 36.9619ZM56.6667 30C56.6667 26.3181 53.6819 23.3333 50 23.3333H46.6667C45.7462 23.3333 45 24.0795 45 25C45 25.9205 45.7462 26.6667 46.6667 26.6667H50C51.841 26.6667 53.3334 28.1591 53.3334 30V33.3333C53.3334 34.2538 54.0795 35 55 35C55.9205 35 56.6667 34.2538 56.6667 33.3333V30ZM30 56.6667C26.3181 56.6667 23.3334 53.6819 23.3334 50V46.6667C23.3334 45.7462 24.0795 45 25 45C25.9205 45 26.6667 45.7462 26.6667 46.6667V50C26.6667 51.841 28.1591 53.3333 30 53.3333H33.3334C34.2538 53.3333 35 54.0795 35 55C35 55.9205 34.2538 56.6667 33.3334 56.6667H30Z"
      fill="#666666"
    />
  </svg>
);

export const ScansInScopeList = () => {
  const { loadScans, scans, selectedScanId, checkedScanIds } = useConnectScans();
  const [openDates, setOpenDates] = useState<string[]>([]);
  const toggleGroup = useCallback(
    // we use useCallback here to prevent rerendering of the groups which leads to React rendering different items on each toggle.
    (date: string) => () =>
      setOpenDates((old) => (old.includes(date) ? old.filter((d) => d !== date) : [...old, date])),
    [scans],
  );
  const scanFilters = useScanFilters();

  useEffect(() => {
    loadScans();
    uncheckAllScans();
  }, [scanFilters]);

  const { scanGrouping } = useSnapshot(scanGroupingState);

  if (!scans.length) {
    return (
      <Stack padding={2} gap={3} alignItems="center" alignContent="center" minHeight={500}>
        <NoScansIcon />
        <Typography variant="subtitle2" color="secondary.dark">
          No scans available for this filter combination
        </Typography>
      </Stack>
    );
  }

  const scansDateGroups = sortAndGroupScans(scanGrouping, scans);
  const allScansChecked = checkedScanIds.length > 0 && checkedScanIds.length <= scans.length;
  const someScansChecked = checkedScanIds.length > 0 && checkedScanIds.length < scans.length;
  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={2}
        alignItems="center"
        paddingLeft={2}
      >
        <Checkbox
          color="primary"
          indeterminate={someScansChecked}
          checked={allScansChecked}
          onChange={() => toggleCheckedScans(scans)}
          inputProps={{
            'aria-label': 'Select all',
          }}
        />
        <Typography variant="subtitle2" flexGrow={1}>
          {allScansChecked ? 'Deselect all' : 'Select all'}
        </Typography>
        <TextField
          select
          label="Sort by"
          value={scanGrouping}
          onChange={(e) => setScanGrouping(e.target.value as DateFilterType)}
        >
          <MenuItem value={'recordedAt'}>Recorded at</MenuItem>
          <MenuItem value={'uploadedAt'}>Uploaded at</MenuItem>
        </TextField>
      </Stack>

      <div>
        {scansDateGroups.map(([date, scans]) => (
          <ScansDateGroupItem
            key={date}
            scans={scans}
            date={DateTime.fromISO(date)}
            open={openDates.includes(date) || scans.some((s) => s.id === selectedScanId)}
            toggleOpen={toggleGroup(date)}
          />
        ))}
      </div>
    </Stack>
  );
};
