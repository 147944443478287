/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Page } from '@core/api/types';
import type { EntityAdapter } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isMatch } from 'lodash';
import type { ReduxEntities } from '../../enum';
import type { RootState } from '../../interface';
import type { SliceState } from '../interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createSelectors = <T>(entity: ReduxEntities, adapter: EntityAdapter<T>) => {
  const selectSliceState = (state: RootState) => (state as any)[entity] as SliceState<T>;
  const { selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectSliceState);

  const selectById = (id?: string | null) =>
    id ? createSelector([selectEntities], (entities) => entities[id]) : () => undefined;

  const selectByIds = (ids: string[]) =>
    createSelector([selectEntities], (entities) =>
      ids.reduce(
        (acc, id) => (entities[id] === undefined ? acc : [...acc, entities[id] as T]),
        [] as T[],
      ),
    );

  const selectWithFilter = (filterFn: (entity: T) => boolean) =>
    createSelector([selectAll], (entityArray) => entityArray.filter(filterFn));

  const findFirst = (findFn: (entity: T) => boolean) =>
    createSelector([selectAll], (entityArray) => entityArray.find(findFn));

  const selectByProps = (props: Partial<T> = {}) =>
    isEmpty(props) ? () => [] as T[] : selectWithFilter((e: T) => isMatch(e as never, props));

  /**
   * This selector is a performance optimization. It will only recompute if the props change.
   */
  const selectAllOnPropChange = (...props: (keyof T)[]) =>
    createSelector([selectAll], (x) => x, {
      memoizeOptions: (a: T[], b: T[]): boolean =>
        a.length === b.length && a.every((e1, i) => props.every((p) => e1[p] === b[i][p])),
    });

  const selectPage = (state: RootState): Page | undefined => selectSliceState(state).page;

  return {
    findFirst,
    selectEntities,
    selectAll,
    selectAllOnPropChange,
    selectTotal,
    selectById,
    selectByIds,
    selectByProps,
    selectWithFilter,
    selectPage,
  };
};
