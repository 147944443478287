export enum Object3dNames {
  AttributePoint = 'ATTRIBUTE-POINT',
  AttributePoints = 'ATTRIBUTE-POINTS',
  BoxHelper = 'BOX-HELPER',
  ElementPoint = 'ELEMENT-POINT',
  ElementPoints = 'ELEMENT-POINTS',
  EndpointsCheckboxFrom = 'ENDPOINTS-CHECKBOX-FROM',
  EndpointsCheckboxTo = 'ENDPOINTS-CHECKBOX-TO',
  ExternalDataTrenchSegments = 'EXTERNAL-DATA-TRENCH-SEGMENTS',
  IntermediatePoint = 'INTERMEDIATE-POINT',
  IntermediatePoints = 'INTERMEDIATE-POINTS',
  Map = 'MAP',
  Measurement = 'MEASUREMENT',
  MeasurementDiv = 'MEASUREMENT-DIV',
  MeasurementLine = 'MEASUREMENT-LINE',
  MouseSphere = 'MOUSE-SPHERE',
  Photo = 'PHOTO',
  PhotoCluster = 'PHOTO-CLUSTER',
  Photos = 'PHOTOS',
  PipeDirection = 'PIPE-DIRECTION',
  Scan = 'SCAN',
  ScanCentered = 'SCAN-CENTERED',
  Scans = 'SCANS',
  Transform = 'TRANSFORM',
  Trench = 'TRENCH',
  PrelabeledTrenches = 'PRELABELED-TRENCHES',
  TrenchPointFromIndicator = 'TRENCH-POINT-FROM-INDICATOR',
  TrenchPoint = 'TRENCH-POINT',
  TrenchPoints = 'TRENCH-POINTS',
  TrenchSegment = 'TRENCH-SEGMENT',
  TrenchSegments = 'TRENCH-SEGMENTS',
  Trenches = 'TRENCHES',
  ViewportHelper = 'VIEWPORT-HELPER',
  PrelabeledTrenchSegments = 'PRELABELED-TRENCH-SEGMENTS',
}
