import {
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  type PopoverOrigin,
} from '@mui/material';
import { scanToDateTime, sortScansByCreatedDate } from '@scans/helper';
import { scanSelectors, scanThunks } from '@scans/redux';
import type { Scan } from '@scans/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import type { ScanSelection } from '@viewer3D/types';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EyeOutline, EyeSlashedOutline } from '@deepup/icons';

export const ScanSelectionPopover = () => {
  const dispatch = useDispatch();
  const selectedScanId = useSelector(viewer3dSelectors.selectedScanId);
  const selection = useSelector(viewer3dSelectors.scanSelection);
  const scans = useSelector(scanSelectors.selectAll);

  const toggleVisibility = useCallback(
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>, { id }: Scan) =>
      dispatch(
        scanThunks.patch({
          path: ['scans', id],
          body: { id, visible: checked },
        }),
      ),
    [dispatch],
  );

  const setSelectedScanId = useCallback(
    (scanId: string) => dispatch(viewer3dActions.setSelectedScanId(scanId)),
    [dispatch],
  );

  const setScanSelectionProps = useCallback(
    (sel?: ScanSelection) => dispatch(viewer3dActions.setScanSelection(sel)),
    [dispatch],
  );

  if (!selection) return null;
  const { top, left, scanIds } = selection;

  const selectedScans = scanIds
    .map((scanId) => scans.find(({ id }) => scanId === id) as Scan)
    .filter((scan) => scan)
    .sort(sortScansByCreatedDate);

  const origin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
  };

  return (
    <Popover
      open
      anchorReference="anchorPosition"
      anchorPosition={{ top, left }}
      anchorOrigin={origin}
      transformOrigin={origin}
      transitionDuration={0}
      onClose={() => setScanSelectionProps()}
      disableRestoreFocus
    >
      <List dense disablePadding>
        {selectedScans.map((scan) => (
          <ListItemButton
            sx={{
              opacity: scan.visible ? 1 : 0.5,
            }}
            key={scan.id}
            selected={selectedScanId === scan.id}
            onClick={() => setSelectedScanId(scan.id)}
          >
            <Checkbox
              edge="start"
              color="secondary"
              checked={scan.visible}
              inputProps={{ 'aria-labelledby': `visibility-checkbox-for-scan-${scan.id}` }}
              icon={<EyeSlashedOutline fontSize={24} />}
              checkedIcon={<EyeOutline fontSize={24} />}
              onChange={(e) => toggleVisibility(e, scan)}
            />
            {scan.metadata.beginDate && (
              <ListItemText>
                {scanToDateTime(scan)?.toLocaleString(DateTime.DATETIME_MED)}
              </ListItemText>
            )}
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};
