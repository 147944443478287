import { toolsState } from '@core/components';
import type { ThreeEvent } from '@react-three/fiber';
import { Object3dNames } from '@viewer3D/types';
import { throttle } from 'lodash';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { Group, LineCurve3, Mesh, MeshBasicMaterial, TubeBufferGeometry, Vector3 } from 'three';
import { proxy, useSnapshot } from 'valtio';

type MeasurementPoints = Partial<{ from: Vector3; to: Vector3 }>;
const measurementPoints = proxy<MeasurementPoints>({});
const setMeasurementPoints = ({ from, to }: MeasurementPoints): void => {
  measurementPoints.from = from;
  measurementPoints.to = to;
};

interface UseMeasurementProps {
  invalidate: () => void;
}

interface UseMeasurement {
  divRef: RefObject<HTMLDivElement>;
  groupRef: RefObject<Group>;
  isActive: boolean;
  onClick: (event: ThreeEvent<MouseEvent>) => void;
  onOver: (event: ThreeEvent<MouseEvent>) => void;
}

export const useMeasurement = ({ invalidate }: UseMeasurementProps): UseMeasurement => {
  const { measurementActive } = useSnapshot(toolsState);

  const groupRef = useRef<Group>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const alignMeasurementLine = ({ stopPropagation, point }: Partial<ThreeEvent<MouseEvent>>) => {
    stopPropagation?.();
    // eslint-disable-next-line prefer-const
    let { from, to } = measurementPoints;
    if (!divRef.current || !groupRef.current || !from || to || !point) return;
    to = point;

    // Setup Threejs Group
    const midPoint = from.clone().add(to).divideScalar(2);
    groupRef.current.position.copy(midPoint);

    // Build Line
    const line = groupRef.current.children.find(
      (c) => c.name === Object3dNames.MeasurementLine,
    ) as Mesh<TubeBufferGeometry, MeshBasicMaterial>;

    if (!line) return;

    const newGeometry = new TubeBufferGeometry(
      new LineCurve3(from.clone().sub(midPoint), to.clone().sub(midPoint)),
      2,
      0.02,
      4,
      false,
    );
    line.geometry.copy(newGeometry);

    // Build div measurement content
    const distance = new Vector3().copy(from).distanceTo(to);
    const distanceZ = new Vector3()
      .copy(new Vector3(0, 0, from.z))
      .distanceTo(new Vector3(0, 0, to.z));
    const distanceInCm = Math.round(distance * 100);
    const distanceZInCm = Math.round(distanceZ * 100);
    divRef.current.innerText = `${distanceInCm} cm | Z:${distanceZInCm} cm`;
    invalidate();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOver = useCallback(throttle(alignMeasurementLine, 30), []);

  const onClick = useCallback(
    ({ intersections, point, stopPropagation }: ThreeEvent<MouseEvent>) => {
      stopPropagation();
      if (!intersections.length) return;
      const { from, to } = measurementPoints;
      if (!from || (from && to)) {
        setMeasurementPoints({ from: point });
        alignMeasurementLine({ point });
      } else {
        setMeasurementPoints({ from, to: point });
      }
    },
    [],
  );

  useEffect(() => {
    if (measurementActive) return;
    setMeasurementPoints({});
  }, [measurementActive]);

  return { isActive: measurementActive, groupRef, divRef, onClick, onOver };
};
