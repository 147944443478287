import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import type { MapStateToProps } from './connect';
import { connectComponent } from './connect';
import { Tile } from './Tile';

const MapBase: FC<MapStateToProps> = ({ tiles }) => (
  <group name={Object3dNames.Map} renderOrder={getRenderOrder(Object3dNames.Map)}>
    {tiles.map((tile) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Tile key={`${tile.tileX}-${tile.tileY}`} {...tile} />
    ))}
  </group>
);

export const Map = connectComponent(MapBase);
