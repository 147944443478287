export enum TimeEvent {
  TimeSpentProjectDetail = 'time_spent_project_detail',
}

export enum UserEvent {
  TrenchMeterLabeled = 'trench_meter_labeled',
  ScanFinished = 'scan_finished',
}

export type TrackingEvent = TimeEvent | UserEvent;
export type PossibleEventPayloads<Event extends TrackingEvent> =
  Event extends UserEvent.ScanFinished
    ? { projectId: string; scanId: string; hoursInProgress: number }
    : Event extends UserEvent.TrenchMeterLabeled
    ? { projectId: string; labeledMeters: number }
    : Event extends TimeEvent.TimeSpentProjectDetail
    ? { projectId: string; timeSpentInMinutes: number }
    : undefined;
