import { formatAddress, splitArray, toHumanReadable } from '@core/logic';
import type { AppDispatch } from '@core/redux/store';
import { isElementEndPoint, isSinglePointElement } from '@elements/helper';
import { elementActions, elementSelectors, elementTypeSelectors } from '@elements/redux';
import { pipeFittingThunks } from '@elements/redux/pipe-fitting.slice';
import { pointElementSelectors } from '@elements/redux/point-element.slice';
import type { BundleType, PointElement, SplineElement } from '@elements/types';
import { trenchSelectors, trenchThunks } from '@trenches/redux';
import type { Trench } from '@trenches/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';
import { useQueue } from '@core/logic/hooks';
import { type SelectableItem } from '@projects/components';
import { getPipeIcon } from '../helper';

const haveCommonSegment = (el1Id: string, el2Id: string, allTrenches: Trench[]): boolean =>
  allTrenches.some(({ segments }) =>
    segments.some((s) => s.splineElementIds.includes(el1Id) && s.splineElementIds.includes(el2Id)),
  );

export const useConnect = (shiftPressed: boolean) => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const selectedElementIds = useSelector(viewer3dSelectors.selectedElementIds);
  const [firstSelectedElementId] = selectedElementIds;
  const selectedElement = useSelector(elementSelectors.selectById(firstSelectedElementId));
  const elementsInPoint = useSelector(elementSelectors.selectByPointId(selectedPointId));
  const allElementTypes = useSelector(elementTypeSelectors.selectAll);
  const allPointElements = useSelector(pointElementSelectors.selectAll);
  const allTrenches = useSelector(trenchSelectors.selectAll);
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const dispatch = useDispatch<AppDispatch>();
  const { queue, addToQueue, removeFromQueue } = useQueue<string>();

  const hasKindOther = (e: SplineElement) =>
    allElementTypes.some((t) => t.kind === 'OTHER' && t.id === e.splineElementTypeId);

  const isSelectedPointEndpoint = isElementEndPoint(selectedPointId) as ReturnType<
    typeof isElementEndPoint
  >;

  const canItemBeMerged = (element: SplineElement): boolean => {
    if (
      !selectedElement ||
      selectedElement.splineElementTypeId !== element.splineElementTypeId ||
      !isSelectedPointEndpoint(selectedElement) ||
      !isSelectedPointEndpoint(element)
    ) {
      return false;
    }
    return !haveCommonSegment(selectedElement.id, element.id, allTrenches);
  };

  const canItemBeSelected = (element: SplineElement): boolean => {
    if (!shiftPressed || selectedElementIds.includes(element.id)) return true;
    //prevent to select other elements if firstSelected element is not an endpoint
    if (!isSelectedPointEndpoint(selectedElement) || !selectedElement) return false;
    return isSelectedPointEndpoint(element) && !hasKindOther(element);
  };

  const mapPointToItem = (el: PointElement | SplineElement) => {
    const { id, name, address, parentPointElementId } = el as PointElement | SplineElement;
    const parent = allPointElements.find((p) => p.id === parentPointElementId);
    const subtitle =
      (isSinglePointElement(el) ? toHumanReadable((el as PointElement).subtype) : name) +
      (parent ? ' 🔗 ' + parent.name : '');

    return {
      id,
      isSelected: id === firstSelectedElementId,
      title: name || formatAddress(address), // name can be empty string so we actually need the || operator instead of ??
      subtitle,
      canBeMerged: false,
      canBeSelected: !shiftPressed,
      isLoading: queue.includes(id),
    };
  };

  const mapSplineToItem = (el: SplineElement): SelectableItem => {
    const { name: title, id, parentPointElementId } = el;
    const elementType = allElementTypes.find((t) => t.id === el.splineElementTypeId);
    const { color, name, children } = elementType
      ? (elementType as BundleType)
      : { color: undefined, name: '', children: [] };
    const isThisElementSelected = selectedElementIds.includes(id);
    const canBeMerged = !isThisElementSelected && canItemBeMerged(el);
    const canBeSplit = !isSelectedPointEndpoint(el);
    const parent = allPointElements.find((p) => p.id === parentPointElementId);

    return {
      id,
      isSelected: isThisElementSelected,
      color,
      Icon: getPipeIcon(name, children?.length),

      subtitle: name + (parent ? ' 🔗 ' + parent.name : ''),
      title,
      canBeMerged,
      canBeSplit,
      canBeSelected: canItemBeSelected(el),
      isLoading: queue.includes(id),
    };
  };

  const pointElementsInSelectedPoint = allPointElements.filter(
    (p) => p.pointId === selectedPointId,
  );
  const [splineElsWithKindOther, splineEls] = splitArray(elementsInPoint, hasKindOther);

  return {
    selectedElementIds,
    splines: splineEls.map(mapSplineToItem),
    points: [...pointElementsInSelectedPoint, ...splineElsWithKindOther].map(mapPointToItem),
    mergeElements: (elId: string) => async () => {
      addToQueue(elId, firstSelectedElementId);
      const { payload, meta } = await dispatch(
        trenchThunks.mergeElements({
          path: ['projects', projectId!, 'splineElements', 'merge'],
          body: {
            elementOneId: firstSelectedElementId,
            elementTwoId: elId,
            mergePointId: selectedPointId!,
          },
        }),
      );
      if (meta.requestStatus === 'rejected' || !payload) {
        return;
      }
      const allSplineElements = (payload as Trench[]).flatMap((t) =>
        t.segments.flatMap((s) => s.splineElements),
      );
      const splineElement = allSplineElements.find((el) => el.id === firstSelectedElementId)!;

      dispatch(elementActions.updateOne(splineElement));
      // remove the second element
      dispatch(elementActions.removeOne(elId));

      // refetch all pipe fittings as the element ids in some fitting might have changed
      dispatch(
        pipeFittingThunks.getMany({
          path: ['projects', projectId!, 'splineElementConnections'],
        }),
      );
      removeFromQueue(elId, firstSelectedElementId);
    },
    splitElement: (elId: string) => async () => {
      if (!selectedPointId) return;
      addToQueue(elId);
      const { payload, meta } = await dispatch(
        trenchThunks.splitElement({
          path: ['projects', projectId!, 'splineElements', elId, 'split'],
          body: {
            pointId: selectedPointId,
          },
        }),
      );
      if (meta.requestStatus === 'rejected' || !payload) {
        return;
      }
      const allSplineElements = (payload as Trench[]).flatMap((t) =>
        t.segments.flatMap((s) => s.splineElements),
      );
      dispatch(elementActions.upsertMany(allSplineElements));

      // refetch all pipe fittings as the element ids in some fitting might have changed
      dispatch(
        pipeFittingThunks.getMany({
          path: ['projects', projectId!, 'splineElementConnections'],
        }),
      );
      removeFromQueue(elId);
    },
    selectElement: (elementId: string) => () => {
      if (shiftPressed) {
        // toggle item selection
        dispatch(
          selectedElementIds.includes(elementId)
            ? viewer3dActions.removeElementIdFromSelection(elementId)
            : viewer3dActions.addElementIdToSelection(elementId),
        );
        return;
      }
      dispatch(viewer3dActions.setSelectedElementIds([elementId]));
    },
  };
};
