// TODO: Add following options again as soon as required
//  (...they are in the TOPAS standard and will come back...)
// export type LayingType =
//   'CLOSED' |
//   'JETTING' |
//   'OPEN' |
//   'OTHER' |
//   'PLOUGH' |
//   'SOIL_DISPLACEMENT' |
//   'TRENCH_MT1' |
//   'TRENCH_MT2' |
//   'TRENCH_MT3' |
//   'TRENCH_MT4' |
// }

export const layingTypes = [
  'SOIL_DISPLACEMENT',
  'PULL',
  'HORIZONTAL_DIRECTIONAL_DRILLING',
  'PLOUGH',
  'LAYJET',
  'MICRO_TRENCHING',
] as const;

export type LayingType = typeof layingTypes[number];

export const surfaceTypes = [
  'ASPHALT',
  'UNPAVED_GRASS',
  'SIMPLE_BRICK',
  'CONCRETE',
  'HIGH_QUALITY_BRICK',
  'MISSING',
] as const;

export const defaultDepths = [
  'SIZE_45_CM',
  'SIZE_50_CM',
  'SIZE_60_CM',
  'SIZE_70_CM',
  'SIZE_80_CM',
  'SIZE_90_CM',
  'SIZE_100_CM',
] as const;

export const smallerDepths = [
  'NOT_MEASURABLE',
  'SIZE_0_CM',
  'SIZE_5_CM',
  'SIZE_10_CM',
  'SIZE_15_CM',
  'SIZE_20_CM',
  'SIZE_25_CM',
  'SIZE_30_CM',
  'SIZE_35_CM',
  'SIZE_40_CM',
] as const;

export const biggerDepths = [
  'SIZE_110_CM',
  'SIZE_120_CM',
  'SIZE_150_CM',
  'SIZE_200_CM',
  'SIZE_250_CM',
  'SIZE_300_CM',
] as const;

export const depths = [...defaultDepths, ...smallerDepths, ...biggerDepths] as const;

export const defaultWidths = ['SIZE_0_CM', 'SIZE_15_CM', 'SIZE_30_CM', 'SIZE_45_CM'] as const;

export const biggerWidths = [
  'SIZE_60_CM',
  'SIZE_80_CM',
  'SIZE_100_CM',
  'SIZE_150_CM',
  'SIZE_200_CM',
] as const;

export const widths = [...defaultWidths, ...biggerWidths] as const;

export type SurfaceType = typeof surfaceTypes[number];

export const attributeTypes = ['LAYING_TYPE', 'SURFACE_TYPE', 'DEPTH', 'WIDTH'] as const;

export type AttributeType = typeof attributeTypes[number];

export type Depth = typeof depths[number];

export type Width = typeof widths[number];
