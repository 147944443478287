import { SelectBoxMulti } from '@core/components/SelectBoxMulti/SelectBoxMulti';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import {
  Category,
  photosState,
  setSelectedCategories,
  togglePhotos,
} from '@photos/components/PhotosInViewport/state';
import { useSnapshot } from 'valtio';

export const PhotosToggle = () => {
  const { showPhotos, possibleCategories, selectedCategories } = useSnapshot(photosState);

  return (
    <Stack direction="row">
      <FormControlLabel
        label="Photos"
        control={
          <Checkbox
            color="primary"
            checked={showPhotos}
            onChange={(event) => togglePhotos(event.target.checked)}
          />
        }
      />
      <SelectBoxMulti<Category>
        label="Photo category"
        availableItems={possibleCategories}
        items={selectedCategories}
        setItems={setSelectedCategories}
        disabled={!showPhotos}
      />
    </Stack>
  );
};
