import { Snackbar, Alert, AlertProps, AlertTitle } from '@mui/material';
import { proxy, useSnapshot } from 'valtio';

export const alertState = proxy<{
  message: string;
  open: boolean;
  type: Exclude<AlertProps['severity'], undefined>;
}>({
  open: false,
  message: '',
  type: 'info',
});

export const infoBox = (content: string): void => {
  alertState.open = true;
  alertState.message = content;
  alertState.type = 'info';
};

export const errorBox = (content: string): void => {
  alertState.open = true;
  alertState.message = content;
  alertState.type = 'error';
};

export const successBox = (content: string): void => {
  alertState.open = true;
  alertState.message = content;
  alertState.type = 'success';
};

const closeAlertBox = () => {
  alertState.open = false;
  alertState.message = '';
  alertState.type = 'info';
};

export const AlertBox = () => {
  const { open, message, type } = useSnapshot(alertState);
  if (!open) return null;
  return (
    <Snackbar
      open={open}
      onClose={closeAlertBox}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ maxWidth: '600px' }}
      autoHideDuration={type === 'success' ? 5000 : undefined}
    >
      <Alert severity={type} onClose={closeAlertBox}>
        <AlertTitle>{type.toUpperCase()}</AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};
