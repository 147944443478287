import type { Entity } from '@core/types';
import { isEmpty } from 'lodash';
import type { EntityRelationKey } from '../enum';

export type IExtractEntitiesFunction<T extends Entity, Parent extends Entity> = (
  obj?: Parent | Parent[],
) => T[];

export const createParentEntityExtractor = <T extends Entity, Parent extends Entity>(
  keysInParent: EntityRelationKey[],
): IExtractEntitiesFunction<T, Parent> => {
  const extractEntities = (parent?: Parent | Parent[]): T[] => {
    // Parent is neither array nor object -> no entities can be found here -> return empty array
    if (!parent || typeof parent !== 'object') return [];

    if (Array.isArray(parent)) {
      // Parent is an array -> extracting entities recursively
      return parent.flatMap(extractEntities);
    }

    // Parent is an object -> extracting entities from properties
    return Object.entries(parent).flatMap(([key, value]) => {
      // No matter what: Always return an array of T!
      if (isEmpty(value)) {
        return [] as T[];
      }
      if (keysInParent.includes(key as EntityRelationKey) && 'id' in value) {
        return [value as T];
      }
      if (keysInParent.includes(key as EntityRelationKey) && Array.isArray(value)) {
        return value.filter((v) => 'id' in v) as T[];
      }
      if (typeof value === 'object') {
        return extractEntities(value as Parent);
      }
      return [] as T[];
    });
  };
  return extractEntities;
};
