import { ArrowBoxOut, PinFilled, PinOutline } from '@deepup/icons';
import { projectsHistoryActions, projectsHistorySelectors } from '@menu/redux/projectsHistory';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DataGrid,
  GridCellClassNamePropType,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetter,
} from '@mui/x-data-grid';
import { useScanQaColors } from '@projects/pages/detail/menu/ScanOrganization/hooks';
import type { Project } from '@projects/types';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const PinButton = ({ row }: GridCellParams<Project>) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const pinnedProjects = useSelector(projectsHistorySelectors.selectPinnedProjects);

  const isPinned = pinnedProjects.some((p) => p.id === row.id);

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        if (isPinned) {
          dispatch(projectsHistoryActions.dropFromPinned(row.id));
        } else {
          dispatch(projectsHistoryActions.addToPinned({ id: row.id, title: row.name }));
        }
      }}
    >
      {isPinned ? (
        <PinFilled fill={theme.palette.primary.main} />
      ) : (
        <PinOutline fill={theme.palette.grey[600]} />
      )}
    </IconButton>
  );
};

const NewTabButton = ({ params }: { params: GridRenderCellParams<Project> }) => {
  const theme = useTheme();
  const { row } = params;

  return (
    <IconButton
      onClick={() => {
        window.open(`/projects/${row.id}`, '_blank');
      }}
    >
      <ArrowBoxOut fill={theme.palette.grey[600]} />
    </IconButton>
  );
};

const getQAStateFromField = (field: string) => field.split('.')[1] as keyof Project['scanCounts'];

const getScanCountValue: GridValueGetter<Project> = (_, project, { field }) =>
  project.scanCounts[getQAStateFromField(field)] || 0;

const getCellClassName: GridCellClassNamePropType<Project> = (params) =>
  params.value !== 0 ? getQAStateFromField(params.field) : 'qa_empty';

const columns: GridColDef<Project>[] = [
  { field: 'name', headerName: 'Project Name', flex: 1 },
  {
    field: 'newTab',
    renderHeader: () => null,
    width: 40,
    type: 'actions',
    renderCell: (params) => <NewTabButton params={params} />,
  },
  {
    field: 'scanCounts.qaToLabel',
    headerName: 'To label',
    type: 'number',
    width: 134,
    valueGetter: getScanCountValue,
    cellClassName: getCellClassName,
  },
  {
    field: 'scanCounts.qaToRework',
    headerName: 'To rework',
    type: 'number',
    width: 134,
    valueGetter: getScanCountValue,
    cellClassName: getCellClassName,
  },
  {
    field: 'scanCounts.qaToClarify',
    headerName: 'To clarify',
    type: 'number',
    width: 124,
    valueGetter: getScanCountValue,
    cellClassName: getCellClassName,
  },
  {
    field: 'scanCounts.qaReady',
    headerName: 'QA ready',
    type: 'number',
    width: 134,
    valueGetter: getScanCountValue,
    cellClassName: getCellClassName,
  },
  {
    field: 'scanCounts.qaDone',
    headerName: 'Done',
    type: 'number',
    width: 102,
    valueGetter: getScanCountValue,
    cellClassName: getCellClassName,
  },
  {
    field: 'scanCounts.total',
    headerName: 'Total',
    type: 'number',
    width: 107,
    valueGetter: getScanCountValue,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated at',
    type: 'date',
    width: 165,
    valueFormatter: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
  },
  {
    field: 'pin',
    headerName: 'Pinned Projects',
    renderHeader: () => null,
    width: 60,
    sortable: false,
    filterable: false,
    renderCell: PinButton,
  },
];

// max page size in xGrid free tier
const MAX_PAGE_SIZE = 100;

export const ProjectsTable = ({
  rows,
  pagination,
  hideFooter,
  search,
}: {
  rows: Project[];
  pagination?: true;
  hideFooter?: true;
  search: string;
}) => {
  const navigate = useNavigate();
  const { qaColumnColors } = useScanQaColors();

  const overlapsMaxPageSize = rows.length > MAX_PAGE_SIZE ? true : undefined;

  const initialState = pagination
    ? {
        pagination: { paginationModel: { pageSize: 12 } },
      }
    : overlapsMaxPageSize
    ? { pagination: { paginationModel: { pageSize: MAX_PAGE_SIZE } } }
    : {};

  return (
    <DataGrid<Project>
      loading={!rows.length}
      disableColumnMenu
      sortingMode="client"
      filterModel={{ items: [{ field: 'name', operator: 'contains', value: search }] }}
      onRowClick={({ row }, event) => {
        const url = `/projects/${(row as Project).id}`;
        event.ctrlKey || event.metaKey ? window.open(url, '_blank') : navigate(url);
      }}
      sx={(theme) => ({
        '& .qaToLabel': {
          color: qaColumnColors.TO_LABEL,
        },
        '& .qaToRework': {
          color: qaColumnColors.TO_REWORK,
        },
        '& .qaToClarify': {
          color: qaColumnColors.TO_CLARIFY,
        },
        '& .qaReady': {
          color: qaColumnColors.QA_READY,
        },
        '& .qaDone': {
          color: qaColumnColors.DONE,
        },
        '& .qa_empty': {
          color: theme.palette.text.secondary,
        },
      })}
      rows={rows}
      columns={columns}
      pagination={pagination || overlapsMaxPageSize}
      hideFooter={hideFooter && !overlapsMaxPageSize}
      autoHeight
      initialState={initialState}
      pageSizeOptions={[12, 25, 50, 100]}
    />
  );
};
