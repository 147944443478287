import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import {
  getTrenchRadiusForViewport,
  INTERMEDIATE_POINT_FACTOR,
} from '@trenches/components/Trench/helper';
import { trenchThunks } from '@trenches/redux';
import type { Trench } from '@trenches/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { connect } from 'react-redux';
import type { IntermediatePointsProps, MapDispatchToProps, MapStateToProps } from './types';

const mapStateToProps = (
  state: RootState,
  { trenchId }: IntermediatePointsProps,
): MapStateToProps => {
  const cameraViewport = viewer3dSelectors.cameraViewport(state);
  const radius = getTrenchRadiusForViewport(INTERMEDIATE_POINT_FACTOR, cameraViewport);
  return {
    radius,
    isTrenchSelected: viewer3dSelectors.isTrenchIdSelected(trenchId)(state),
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { trenchId }: IntermediatePointsProps,
): MapDispatchToProps => ({
  insertPointOnSelectedTrench: async (x, y, z, pointOneId, pointTwoId, projectId) => {
    const { payload } = await dispatch(
      trenchThunks.insertPointInTrench({
        path: ['projects', projectId!, 'trenches', trenchId, 'insertPoint'],
        body: { pointOneId, pointTwoId, newPoint: { x, y, z } },
      }),
    );
    const { pointIds } = payload as Trench;
    const beforeIndex = pointIds.indexOf(pointOneId);
    const afterIndex = pointIds.indexOf(pointTwoId);
    dispatch(viewer3dActions.setSelectedPointId(pointIds[(beforeIndex + afterIndex) / 2]));
  },
});

export const connectComponent = connect(mapStateToProps, mapDispatchToProps);
