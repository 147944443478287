import { request } from '@core/api';
import { errorBox } from '@core/components/AlertBox';
import type { ExternalDataTrench } from '@core/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { proxy, useSnapshot } from 'valtio';

interface ExternalDataState {
  isExternalVisible: boolean;
  cachedTrenches: ExternalDataTrench[];
}

const toggleState = proxy<ExternalDataState>({ isExternalVisible: false, cachedTrenches: [] });

const toggleExternalVisible = (): void => {
  toggleState.isExternalVisible = !toggleState.isExternalVisible;
};

const setCachedTrenches = (trenches: ExternalDataTrench[]) => {
  toggleState.cachedTrenches = trenches;
};

export const useExternalDataState = () => ({
  ...useSnapshot(toggleState),
  toggleExternalVisible,
});

export const cleanExternalDataCache = () => setCachedTrenches([]);

export const useExternalDataTrenches = () => {
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const { isExternalVisible } = useExternalDataState();

  useEffect(() => {
    if (!isExternalVisible || toggleState.cachedTrenches.length) {
      // Skip fetching data if can use cached external data or the toggle is OFF
      return;
    }

    async function fetchData() {
      try {
        const response = await request<ExternalDataTrench[]>('GET', {
          path: ['projects', projectId!, 'externalData'],
        });
        if (response.length) {
          setCachedTrenches(response);
        }
      } catch (error) {
        errorBox('Error fetching external data');
      }
    }

    fetchData();
  }, [projectId, toggleState.cachedTrenches]);

  return {
    trenches: toggleState.cachedTrenches,
  };
};
