import { request } from '@core/api';
import { successBox } from '@core/components/AlertBox';
import { ArrowUpLine } from '@deepup/icons';
import { Button, CircularProgress, FormLabel } from '@mui/material';
import { viewer3dSelectors } from '@viewer3D/redux';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cleanExternalDataCache } from './hooks';

export const UploadButton = () => {
  const [inProgress, setInProgress] = useState(false);
  const projectId = useSelector(viewer3dSelectors.selectedProjectId)!;

  const uploadGeoJson = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    setInProgress(true);

    const formData = new FormData();
    formData.append('file', file);
    const response = await request('POST', {
      path: ['projects', projectId, 'externalData'],
      body: formData,
    });
    if (!(response instanceof Error)) {
      cleanExternalDataCache();
      successBox('Success uploading file!');
    }
    e.target.value = '';
    setInProgress(false);
  };

  return (
    <FormLabel htmlFor="upload-geojson">
      <Button
        variant="outlined"
        color="secondary"
        component="span"
        startIcon={
          inProgress ? (
            <CircularProgress color="inherit" size="1rem" />
          ) : (
            <ArrowUpLine fill="white" />
          )
        }
      >
        Upload
      </Button>
      <input
        id="upload-geojson"
        hidden
        accept="application/geo+json"
        type="file"
        onChange={uploadGeoJson}
      />
    </FormLabel>
  );
};
