import type { Vector } from '@core/types';
import { Euler, Vector3 } from 'three';

export const jsonToVector = (coordinate?: Partial<Vector>): Vector3 =>
  new Vector3(coordinate?.x, coordinate?.y, coordinate?.z);

export const jsonToEuler = (coordinate?: Vector | null): Euler =>
  new Euler(coordinate?.x, coordinate?.y, coordinate?.z);

export const vectorToJson = (vector: Vector3 | null): Vector => {
  const [x, y, z] = (vector ?? new Vector3()).toArray();
  return { x, y, z };
};

export const eulerToJson = (euler: Euler | null): Vector => {
  const [x, y, z] = (euler ?? new Euler()).toArray();
  return { x, y, z };
};

export const pointsAreEqual = (a: Vector[], b: Vector[]): boolean =>
  a.length === b.length && a.every((p, i) => p.x === b[i].x && p.y === b[i].y && p.z === b[i].z);
