import { enqueue, clearQueue, processQueue } from '@core/api/queue';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getItem, setItem } from 'localforage';
import { useEffect, useState } from 'react';

/**
 * Workaround for caching blobs for google's signed urls.
 * TODO [https://gitlab.com/deepup-gmbh/team-backoffice/-/issues/19]: Remove as soon as signed cookie mechanism exists
 */

const QUEUE_KEY = 'cashed-url-queue';

export const useCachedUrl = (
  url?: string,
  requestConfig?: () => Promise<AxiosRequestConfig>,
): string | undefined => {
  const [cachedUrl, setCachedUrl] = useState<string>();

  useEffect(() => {
    if (!url) return;

    const simplifiedPath = url.split('?')[0];
    let urlToRevoke: string | null = null;

    const processRequest = async (buffer: ArrayBuffer) => {
      const blob = new Blob([buffer]);
      urlToRevoke = URL.createObjectURL(blob);
      setCachedUrl(urlToRevoke);
    };

    const fetchData = async () => {
      try {
        const config: AxiosRequestConfig = {
          responseType: 'arraybuffer',
          ...(requestConfig ? await requestConfig() : {}),
        };
        const response: AxiosResponse<ArrayBuffer> = await axios.get(url, config);
        await setItem(simplifiedPath, response.data);
        processRequest(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const loadData = async () => {
      const cache = (await getItem(simplifiedPath)) as ArrayBuffer;
      if (cache && cache instanceof ArrayBuffer) {
        processRequest(cache);
        return;
      }

      enqueue(QUEUE_KEY, fetchData);
      processQueue(QUEUE_KEY);
    };

    loadData();

    return () => {
      clearQueue(QUEUE_KEY);
      if (!urlToRevoke) return;
      URL.revokeObjectURL(urlToRevoke);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, requestConfig]);

  return cachedUrl;
};
