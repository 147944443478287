import { useEffectOnce } from '@core/logic/hooks/useEffectOnce';
import { useState } from 'react';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState#modifier_keys_on_gecko
 */
export type Modifiers = 'Control' | 'Alt' | 'Shift' | 'Meta'; // extend if you wanna

const isModifierPressed: Record<Modifiers, (e: KeyboardEvent) => boolean> = {
  Control: (e) => e.ctrlKey,
  Alt: (e) => e.altKey,
  Shift: (e) => e.shiftKey,
  Meta: (e) => e.metaKey,
};

/**
 * This hook works as a toggle: when the key combination is pressed the first time it enters the mode, when it is pressed
 * the second time it exits the mode.
 */
export const useKeyCombination = (modifier: Modifiers, key: string): boolean => {
  const [combinationPressed, setCombinationPressed] = useState(false);

  const downHandler = (e: KeyboardEvent) => {
    if (isModifierPressed[modifier](e) && e.key === key) {
      e.preventDefault();
      e.stopPropagation();
      // key combination has been pressed by user
      setCombinationPressed((state) => !state);
    }
  };

  // Add event listeners
  useEffectOnce(() => {
    window.addEventListener('keydown', downHandler);
    return () => window.removeEventListener('keydown', downHandler);
  });

  return combinationPressed;
};
