import { reworkCategories, ReworkCategory, Scan } from '@scans/types';
import { countBy } from 'lodash';

export type ReworkCategoryState = 'Indeterminate' | 'Selected' | 'Default';

export type ReworkCategoriesMap = Record<ReworkCategory, ReworkCategoryState>;

export const getCategoriesMap = (scans: Scan[]): ReworkCategoriesMap => {
  // collect how many matches each problem has across the checked scans
  const allSelectedCategories = scans.flatMap((s) => s.reworkCategories);
  const occurrences = countBy(allSelectedCategories);
  const categoriesWithState = reworkCategories.map((category) => [
    category,
    !occurrences[category]
      ? 'Default'
      : occurrences[category] < scans.length
      ? 'Indeterminate'
      : 'Selected',
  ]);
  return Object.fromEntries(categoriesWithState);
};
