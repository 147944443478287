import { Container, styled } from '@mui/material';

export const CenteredComponent = styled(Container)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
