import type { ScanFilters } from '@projects/pages/detail/hooks';
import { buildBufferAttribute, getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { BufferGeometry, Vector3 } from 'three';

export const FilterViewport = ({ viewport }: Pick<ScanFilters, 'viewport'>) => {
  if (!viewport) return null;

  const { minX, minY, maxX, maxY, z } = viewport;

  const midX = (minX + maxX) / 2;
  const midY = (minY + maxY) / 2;
  const width = maxX - minX;
  const height = maxY - minY;

  const position = new Vector3(midX, midY, z);
  const geometry = new BufferGeometry();
  const vertices = [
    { x: -0.5, y: -0.5 }, // Relative top left
    { x: 0.5, y: -0.5 }, // Relative top right
    { x: 0.5, y: 0.5 }, // Relative bottom right
    { x: -0.5, y: 0.5 }, // Relative bottom left
  ].map(({ x, y }) => [width * x, height * y, 0]);
  geometry.setAttribute('position', buildBufferAttribute(3, vertices));

  return (
    <lineLoop
      name={Object3dNames.ViewportHelper}
      position={position}
      geometry={geometry}
      renderOrder={getRenderOrder(Object3dNames.ViewportHelper)}
    >
      <lineBasicMaterial color="#5555ff" />
    </lineLoop>
  );
};
