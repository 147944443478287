import { Checkbox, FormControlLabel } from '@mui/material';
import type { VisibleScans } from '@viewer3D/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';

const getNextVisibleScansMode = (current: VisibleScans): VisibleScans => {
  if (current === 'ALL') return 'ONLY_VISIBLE';
  if (current === 'ONLY_VISIBLE') return 'NONE';
  return 'ALL';
};

export const ScansToggle = () => {
  const dispatch = useDispatch();
  const visibleScans = useSelector(viewer3dSelectors.visibleScans);

  return (
    <FormControlLabel
      label="Scans"
      control={
        <Checkbox
          color="primary"
          checked={visibleScans === 'ALL' || visibleScans === 'ONLY_VISIBLE'}
          indeterminate={visibleScans === 'ONLY_VISIBLE'}
          onChange={() =>
            dispatch(viewer3dActions.setVisibleScans(getNextVisibleScansMode(visibleScans)))
          }
        />
      }
    />
  );
};
