import { userProfileSelectors } from '@userProfile/redux';
import { UserRole } from '@userProfile/redux/types';
import { useSelector } from 'react-redux';

/**
 * This is a custom hook that can be used to get the user's permissions.
 * We use it in order to be able to handle more complex permission logic later where there can be GLOBAL permissions and PROJECT permissions.
 */
export const usePermissions = () => {
  const role = useSelector(userProfileSelectors.selectUserRole);
  return {
    canSeeAllPages: role === UserRole.Admin,
    canDownloadProjects: role === UserRole.Admin,
    canViewScanJobs: role === UserRole.Admin,
  };
};
