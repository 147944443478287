/* eslint-disable react/jsx-props-no-spreading */
import { environment } from '@core/environment';
import { lngLatToXY } from '@core/logic';
import { SearchOutline } from '@deepup/icons';
import type MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useConnect } from './connect';

const urlParams = new URLSearchParams({
  access_token: environment.mapBox.accessToken,
  language: 'de',
  types: 'address',
});

interface Option {
  primaryText: string;
  secondaryText: string;
  center: number[];
}

const toOptions = (searchResults: MapboxGeocoder.Result[]): Option[] =>
  searchResults.map((result) => ({
    primaryText: result.text,
    secondaryText: result.place_name.replace(result.text + ', ', ''),
    center: result.center,
  }));

export const AddressSearchField = () => {
  const { cameraGoto, boundingBox } = useConnect();
  const [options, setOptions] = useState<Option[]>([]);
  const theme = useTheme();

  const fetchResults = async (searchTerm: string) => {
    if (searchTerm === '') {
      setOptions([]);
      return;
    }
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    const { data } = await axios.get<{ features: MapboxGeocoder.Result[] }>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedSearchTerm}.json?${urlParams}`,
    );

    setOptions(toOptions(data.features));
  };

  // We use throttle here in order to prevent too many requests to the mapbox api. Also, we use useCallback to prevent
  // the function from being recreated on every render thus preventing the throttling because of new function references every rerender.
  const fetchResultsThrottled = useCallback(
    throttle(fetchResults, 250, { leading: false, trailing: true }),
    [],
  );

  useEffect(() => {
    boundingBox && urlParams.set('bbox', boundingBox.join(','));
  }, [boundingBox]);

  return (
    <Autocomplete
      freeSolo
      id="location-search-box"
      options={options}
      onChange={(_, option, reason) => {
        if (reason !== 'selectOption') return;

        const [lng, lat] = (option as Option).center;
        const { x, y } = lngLatToXY({ lng, lat });
        cameraGoto({ x, y });
        setOptions([]);
      }}
      onInputChange={(_, newText) => {
        fetchResultsThrottled(newText);
      }}
      getOptionLabel={(option) =>
        typeof option === 'object' ? option?.primaryText + ', ' + option?.secondaryText : option
      }
      noOptionsText="No results"
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutline fontSize={24} fill={theme.palette.text.primary} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(params, option) => (
        <ListItem {...params} key={`${option.primaryText}-${option.secondaryText}`}>
          <ListItemText primary={option.primaryText} secondary={option.secondaryText} />
        </ListItem>
      )}
      filterOptions={(options) => options}
      sx={(theme) => ({ minWidth: theme.spacing(30) })}
    />
  );
};
