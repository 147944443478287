import { Divider, Stack } from '@mui/material';
import { QAStateSelectorBar } from './QAStateSelectorBar';
import { DateRangeFilter, QAFilter, SurfaceTypeFilter, ViewportFilter } from './Filters';
import { ScansInScopeList } from './ScansInScopeList';

export const ScanOrganization = () => {
  return (
    <Stack gap={3} p={2}>
      <Stack gap={2}>
        <DateRangeFilter />
        <ViewportFilter />
      </Stack>
      <Stack direction={'row'} gap={2}>
        <QAFilter />
        <SurfaceTypeFilter />
      </Stack>
      <Divider />
      <ScansInScopeList />
      <QAStateSelectorBar />
    </Stack>
  );
};
