import {
  distanceBetweenCoordinates,
  isDateInRange,
  lngLatToXY,
  splitArray,
  vecFromLatLong,
} from '@core/logic';
import type { GeoPositionSolution, Photo } from '@photos/types';
import { ascQualitySortedGeoPositionSolutions } from '@photos/types';
import type { DateRange } from '@projects/pages/detail/hooks';
import type { BoundingBox } from '@viewer3D/types';
import { DateTime } from 'luxon';
import type { Vector3 } from 'three';
import { areAllCategoriesSelected, type Category, type Photos } from './state';

interface PhotoWithPosition extends Photo {
  position: Vector3;
}

export const sortByGeoPositionSolution = (cluster: Photo[]): Photo[] =>
  cluster.toSorted(
    (photoCurrent, photoNext) =>
      ascQualitySortedGeoPositionSolutions.indexOf(photoNext.geoPositionSolution) -
      ascQualitySortedGeoPositionSolutions.indexOf(photoCurrent.geoPositionSolution),
  );

const getPhotoWithBestGeoPosition = (cluster: Photo[]): Photo =>
  sortByGeoPositionSolution(cluster)[0];

export const gnssColor: Record<GeoPositionSolution, 'primary' | 'error' | 'info'> = {
  FIXED: 'primary',
  FLOAT: 'info',
  CORELOCATION: 'error',
};

const splitSinglesAndClusters = (photoGroups: Photo[][]): [Photo[], Photo[][]] => {
  const [withOnlyOneItem, withMultipleItems] = splitArray(photoGroups, (p) => p.length === 1);
  return [withOnlyOneItem.flat(), withMultipleItems];
};

export const withPhotoPosition = (photo: Photo): PhotoWithPosition => ({
  ...photo,
  position: vecFromLatLong(photo.lat, photo.long, 0),
});

export const getPhotoClusters = (photos: Photo[], threshold: number): [Photo[], Photo[][]] => {
  const sortedPhotos = sortByGeoPositionSolution(photos);
  let photosWithPosition = sortedPhotos.map(withPhotoPosition);
  const groups: Photo[][] = [];

  while (photosWithPosition.length) {
    const [photo] = photosWithPosition;
    const [cluster, nextRest] = splitArray(
      photosWithPosition,
      (p) => distanceBetweenCoordinates(photo.position, p.position) < threshold,
    );
    photosWithPosition = nextRest;
    groups.push(cluster);
  }

  return splitSinglesAndClusters(groups);
};

export const getClusterPosition = (cluster: Photo[], z: number): Vector3 => {
  const { lat, long } = getPhotoWithBestGeoPosition(cluster);
  return vecFromLatLong(lat, long, z);
};

const isPhotoInCategories = (categories: Category[]) => (p: Photo) =>
  areAllCategoriesSelected(categories) ||
  (p.category?.name && categories.includes(p.category?.name));

const filterPhotosInCategory =
  (categories: Category[]) =>
  (photos: Photo[]): Photo[] =>
    photos.filter(isPhotoInCategories(categories));

const isPhotoInDateRange =
  (dateRange?: DateRange) =>
  (photo: Photo): boolean => {
    // TODO use same fn when filtering scans too! (see useScansInScope())
    if (!dateRange) return true;
    const normalizedRecordedAt = DateTime.fromISO(photo.recordedAt).toISODate()!;
    return isDateInRange(dateRange.from, dateRange.to, normalizedRecordedAt);
  };

const filterPhotosInDateRange =
  (dateRange?: DateRange) =>
  (photos: Photo[]): Photo[] =>
    photos.filter(isPhotoInDateRange(dateRange));

const isPhotoWithinBbox =
  (bbox: BoundingBox) =>
  ({ long, lat }: Photo): boolean => {
    const { x, y } = lngLatToXY({ lng: long, lat: lat });
    return bbox.minY <= y && y <= bbox.maxY && bbox.minX <= x && x <= bbox.maxX;
  };

const isClusterWithinBbox =
  (bbox: BoundingBox) =>
  (cluster: Photo[]): boolean =>
    // clusters are positioned after their best positioned photo -> we can just check the photo with the best geoposition solution
    isPhotoWithinBbox(bbox)(getPhotoWithBestGeoPosition(cluster));

const hasPhotos = (cluster: Photo[]) => cluster.length > 0;

/**
 * Filter photos by category, bounding box and date range or any combination of these parameters.
 * (This can filter photos by bounding box AND date range, which is currently not a use case, actually. But it was the simpler implementation instead of mutually exclusive bounding box XOR date range parameters.)
 */
export const filterClustersAndSingles = (
  { clusters, singles }: Pick<Photos, 'singles' | 'clusters'>,
  selectedCategories: Category[],
  viewport?: BoundingBox,
  dateRange?: DateRange,
): Pick<Photos, 'singles' | 'clusters'> => {
  if (!viewport) {
    return { clusters: [], singles: [] };
  }

  return {
    clusters: clusters
      .filter(isClusterWithinBbox(viewport)) // first reduce the number of clusters to check by bounding box
      .map(filterPhotosInCategory(selectedCategories))
      .map(filterPhotosInDateRange(dateRange))
      .filter(hasPhotos), // throw away now empty clusters
    singles: singles
      .filter(isPhotoWithinBbox(viewport))
      .filter(isPhotoInCategories(selectedCategories))
      .filter(isPhotoInDateRange(dateRange)),
  };
};
