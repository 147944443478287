export enum OrderNumberType {
  SMA = 'SMA',
}

export enum OrderNumberSubType {
  APL = 'APL',
  HK = 'HK',
  NVT = 'NVT',
}

export interface OrderNumber {
  type: OrderNumberType;
  value: string;
  subType: OrderNumberSubType;
}
