export const equalLengthAndValues = <T>(a: T[], b: T[]): boolean =>
  a.length === b.length && a.every((val) => b.includes(val));

/**
 * Checks if two arrays are equal in length, values and order.
 */
const isStrictlyEqual =
  <T extends unknown[]>(a: T) =>
  (b: T): boolean =>
    a.length === b.length && a.every((val, i) => val === b[i]);

/**
 * Checks if at least one item is the same in both arrays.
 */
export const doArraysIntersect = <T>(a: T[], b: T[]): boolean => a.some((x) => b.includes(x));
/**
 * Splits an array into two groups by a decider function.
 *
 * @param array     The Array to split
 * @param decider   The function to decide in which group each item will be. All items with truthy return value will be
 *                  in the first group, all items with falsy return value will be in the second.
 */
export const splitArray = <T>(array: T[], decider: (item: T) => boolean): [T[], T[]] => {
  const group1 = [];
  const group2 = [];
  for (const item of array) {
    if (decider(item)) {
      group1.push(item);
    } else {
      group2.push(item);
    }
  }
  return [group1, group2];
};

/**
 * Finds the first item in an array that satisfies the decider fn. Then returns it and the rest of the array.
 */
export const pickFromArray = <T>(
  decider: (item: T) => boolean,
  array: T[],
): [T | undefined, T[]] => {
  const item = array.find(decider);
  return !item ? [undefined, array] : [item, array.filter((i) => i !== item)];
};

/**
 * This will remove all duplicates from an array.
 * NOTE: it will return NONE of the duplicates!
 */
export const keepOnlyUniques = <T>(thing: T, _: unknown, arr: T[]): boolean =>
  // Note: you need to check indexOf AND lastIndexOf and cannot use the item index (2nd argument)
  arr.indexOf(thing) === arr.lastIndexOf(thing);

export const hasArrayDuplicates = <T>(arr: T[]): boolean =>
  !!arr.find((id, i, arr) => arr.lastIndexOf(id) !== i);

/**
 * Can group array items by a group size, while every group start will be a defined step further from the last group start.
 *
 * @param groupSize The size of the groups
 * @param step      How many items to skip before starting a new group
 */
export const groupsOfWithStep =
  <T>(groupSize: number, step: number) =>
  (items: T[]): T[][] => {
    const groups: T[][] = [];
    let i = 0;
    while (i + groupSize <= items.length) {
      groups.push(items.slice(i, i + groupSize));
      i += step;
    }
    return groups;
  };

/**
 * This groups every two neighboring items together.
 */
export const getNeighborPairs = groupsOfWithStep(2, 1) as <T>(items: T[]) => [T, T][];
export const pairNeighborsNonOverlapping = groupsOfWithStep(2, 2) as <T>(items: T[]) => [T, T][];

/**
 * Detect whether two items are in the array and are directly next to each other.
 */
export const areItemsNeighbors = <T>(item1: T, item2: T, items: T[]): boolean => {
  const item1Index = items.indexOf(item1);
  const item2Index = items.indexOf(item2);
  return item1Index !== -1 && item2Index !== -1 && Math.abs(item1Index - item2Index) === 1;
};

export const countDuplicates =
  <T extends unknown[]>(haystack: T[]) =>
  (needle: T) =>
    haystack.filter(isStrictlyEqual(needle)).length;
