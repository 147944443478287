import type { OrthographicCamera, Vector3 } from 'three';
import type { MapControls } from 'three-stdlib/controls/OrbitControls';

export const updateControlsCameraPosition = (
  { object, target, update }: MapControls,
  { x, y, z }: Vector3,
): void => {
  if (!object || !target || !update) return;
  const camera = object as OrthographicCamera;
  let nextZ = z;
  if (z === 0 && x !== 0 && y !== 0) {
    // Only z was zero/missing -> reuse current z value
    nextZ = target.z;
  }
  camera.position.set(x, y, nextZ + 100);
  camera.zoom = 25;
  camera.updateProjectionMatrix();
  target.set(x, y, nextZ);
  update();
};
