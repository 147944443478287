import { attributeState } from '@projects/edit-modes/Attributes';
import type { ThreeEvent } from '@react-three/fiber';
import type { DeepupCatmullRomCurve } from '@trenches/components/Trench/DeepupCatmullRomCurve';
import type { PointTrenchMap } from '@trenches/components/types';
import type { AttributeRequest } from '@trenches/types';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { MAX_VISIBLE_ATTRIBUTE_POINT_RADIUS } from '../../helper';
import { attributeFromPointState, setAttributeFromPoint } from '../helper/attributeFromPointState';
import { AttributePoint } from './AttributePoint';
import { useConnect } from './connect';

export interface AttributePointsProps {
  curve: DeepupCatmullRomCurve;
  pointTrenchMap: PointTrenchMap;
  trenchId: string;
}

export const AttributePoints: FC<AttributePointsProps> = memo(
  ({ trenchId, curve, pointTrenchMap }) => {
    const fromPoint = useSnapshot(attributeFromPointState);
    const { layingType, surfaceType, selectedAttributeType, width, depth } =
      useSnapshot(attributeState);

    const { setAttribute, radius, getCommonTrench } = useConnect();

    const handlePointClick =
      (clickedPointId: string) =>
      (e: ThreeEvent<MouseEvent>): void => {
        e.stopPropagation();
        if (!fromPoint.id || !fromPoint.trenchId) {
          setAttributeFromPoint({ id: clickedPointId, trenchId });
          return;
        }
        const newLayingTypeProps = {
          key: 'layingType',
          value: layingType,
        };
        const newSurfaceTypeProps = {
          key: 'surfaceType',
          value: surfaceType,
        };
        const newDepthProps = {
          key: 'depth',
          value: depth,
        };
        const newWidthProps = {
          key: 'width',
          value: width,
        };

        const propsToSet =
          selectedAttributeType === 'LAYING_TYPE'
            ? newLayingTypeProps
            : selectedAttributeType === 'SURFACE_TYPE'
            ? newSurfaceTypeProps
            : selectedAttributeType === 'DEPTH'
            ? newDepthProps
            : newWidthProps;

        const trenchToLabel = getCommonTrench(clickedPointId, fromPoint.id);
        if (!trenchToLabel) {
          // different trench! -> set new fromPoint
          setAttributeFromPoint({ id: clickedPointId, trenchId });
          return;
        }
        setAttribute(
          trenchToLabel.id,
          fromPoint.id,
          clickedPointId,
          propsToSet as AttributeRequest,
        );
        setAttributeFromPoint({});
      };

    return (
      <group
        name={Object3dNames.AttributePoints}
        renderOrder={getRenderOrder(Object3dNames.AttributePoints)}
      >
        {radius < MAX_VISIBLE_ATTRIBUTE_POINT_RADIUS &&
          curve.points.map((vec, i) => {
            const point = curve.pointsData[i];
            const trenchPoint = pointTrenchMap[point.id] ?? [];
            const isEndPoint = trenchPoint.filter(({ isEndpoint }) => isEndpoint).length > 0;
            return (
              <AttributePoint
                pointId={point.id}
                key={point.id}
                position={vec}
                size={radius}
                isEndPoint={isEndPoint}
                numberOfTrenches={trenchPoint.length}
                isFromPoint={fromPoint.id === point.id}
                layingType={layingType}
                surfaceType={surfaceType}
                depth={depth}
                width={width}
                selectedAttributeType={selectedAttributeType}
                onClick={handlePointClick(point.id)}
              />
            );
          })}
      </group>
    );
  },
);
