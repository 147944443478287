import { useUserActivity } from '@core/useUserActivity';
import React, { useEffect } from 'react';
import { trackTimeEvent } from './sendEvent';
import type { PossibleEventPayloads, TimeEvent } from './types';

const timeEvents = new Map<TimeEvent, number>();

const startEvent = (event: TimeEvent): void => {
  timeEvents.set(event, Date.now());
};

const restartAllEvents = (): void => {
  timeEvents.forEach((_, event) => startEvent(event));
};

const sendAllTimeEvents = (payload: Pick<PossibleEventPayloads<TimeEvent>, 'projectId'>): void => {
  timeEvents.forEach((startTime, event) => trackTimeEvent(event, Date.now() - startTime, payload));
};

const clearAllEvents = (): void => timeEvents.clear();

export const useTimeEvents = (
  events: TimeEvent[],
  deps: React.DependencyList = [],
  payload: Pick<PossibleEventPayloads<TimeEvent>, 'projectId'>, // optional payload that will be sent with all time events
): void => {
  useUserActivity({
    secondsToUserInactive: 5 * 60,
    onUserActivePulse: (hasUserBeenActive: boolean) => {
      if (hasUserBeenActive) sendAllTimeEvents(payload);
      restartAllEvents();
    },
    onUnmount: (hasUserBeenActive: boolean) => {
      if (hasUserBeenActive) sendAllTimeEvents(payload);
      clearAllEvents();
    },
    onTabVisibilityChange: (hasUserBeenActive: boolean, isTabVisible: boolean) => {
      if (isTabVisible) {
        restartAllEvents();
      } else if (hasUserBeenActive) sendAllTimeEvents(payload);
    },
  });

  useEffect(() => {
    clearAllEvents();
    events.forEach((e) => startEvent(e));
  }, deps);
};
