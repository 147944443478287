/**
 * User roles from our backend.
 */
export type ApiUserRole = 'ADMIN' | 'EDITOR' | 'NONE' | 'NOT_SUPPORTED';

/**
 * These are the possible User roles represented as a hierarchy.
 * Order matters, as the higher the number, the more privileges the user has.
 * With this enum, you can do algebraic operations on the roles. For example: `if (user.role >= UserRole.Editor) { ... }`
 */
export enum UserRole {
  None,
  NotSupported,
  Editor,
  Admin,
}

export const UserRoleApiToEnum: Record<ApiUserRole, UserRole> = {
  ADMIN: UserRole.Admin,
  EDITOR: UserRole.Editor,
  NONE: UserRole.None,
  NOT_SUPPORTED: UserRole.NotSupported,
};

export interface UserProfile {
  role: UserRole;
}
