import { useAuth0 } from '@auth0/auth0-react';
import { setup } from '@core/api';
import { CenteredComponent, LogoutButton } from '@core/components';
import { environment } from '@core/environment';
import { Alert, CircularProgress, Stack, Typography } from '@mui/material';

const isCypressEnvironment = !!window.Cypress;

const {
  isDev,
  apiUrl,
  auth0: {
    authorizationParams: { audience, scope },
  },
} = environment;

export const Authentication = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  if (isCypressEnvironment) {
    // !!!CAUTION!!!
    // This hack injects the access token from cypress into the backoffice without doing the redirect dance 💃
    // It only works for api calls, which use our @core/api/api-service.
    // Fortunately all redux slices use this service and hence most of our application works with it.
    // However it WILL NOT WORK in other scopes that use getAccessTokenSilently() from the useAuth0 hook.
    // TODO: Find a generic approach that support all possible e2e tests
    //  https://docs.cypress.io/guides/testing-strategies/auth0-authentication#Adapting-the-front-end
    const accessToken = localStorage.getItem('Auth0-Cypress-AccessToken');
    if (accessToken) {
      setup({
        baseUrl: apiUrl,
        getAccessToken: () => Promise.resolve(accessToken),
      });
      return children;
    }
  }

  if (isLoading) {
    return (
      <CenteredComponent>
        <CircularProgress />
      </CenteredComponent>
    );
  }

  if (!isAuthenticated && error) {
    return (
      <CenteredComponent>
        <Alert severity="error">{error.message}</Alert>
        <Stack direction="row" alignItems="center">
          <Typography color="white">Please </Typography>
          <LogoutButton />
          <Typography color="white">
            , make sure to have the correct permission set and try again
          </Typography>
        </Stack>
      </CenteredComponent>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect().catch((e) => isDev && console.error(e));
    return (
      <CenteredComponent>
        <CircularProgress />
      </CenteredComponent>
    );
  }

  setup({
    baseUrl: apiUrl,
    getAccessToken: () => getAccessTokenSilently({ authorizationParams: { audience, scope } }),
  });

  return children;
};
