import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { RootState } from '@core/redux/interface';
import type { Selector } from 'react-redux';
import { hasElement } from '../components/Trench/helper';
import type {
  CreateElementOnTrench,
  CreateTrench,
  ExtendShrinkElement,
  ExtendShrinkMultipleElements,
  ExtendTrench,
  InsertPointInTrench,
  MergeElements,
  MergeTrenches,
  SetTrenchAttribute,
  SplitElement,
  SplitTrench,
  Trench,
} from '../types';

export const {
  reducer: trenchReducer,
  apiThunks: trenchApiThunks,
  actions: trenchActions,
  selectors: trenchBaseSelectors,
} = crudEntityFactory<Trench>({
  entity: ReduxEntities.Trenches,
  removeKeys: [EntityRelationKey.Points],
});

export const trenchThunks = {
  ...trenchApiThunks,
  createTrench: trenchApiThunks.create<CreateTrench>('create', 'POST'),
  extendTrench: trenchApiThunks.create<ExtendTrench>('extend', 'POST'),
  insertPointInTrench: trenchApiThunks.create<InsertPointInTrench>(
    'insert-point-in-trench',
    'POST',
  ),
  mergeTrenches: trenchApiThunks.create<MergeTrenches>('merge', 'POST'),
  splitTrench: trenchApiThunks.create<SplitTrench>('split', 'POST'),
  addElementOnTrench: trenchApiThunks.create<CreateElementOnTrench>('element/add', 'POST'),

  // element thunks are here since we get back properly segmented trenches from the backend for each element action!
  extendElement: trenchApiThunks.create<ExtendShrinkElement>('element/extend', 'PATCH'),
  extendMultipleElements: trenchApiThunks.create<ExtendShrinkMultipleElements>(
    'splineElements/extend',
    'PATCH',
  ),
  shrinkMultipleElements: trenchApiThunks.create<ExtendShrinkMultipleElements>(
    'splineElements/shrink',
    'PATCH',
  ),
  shrinkElement: trenchApiThunks.create<ExtendShrinkElement>('element/shrink', 'PATCH'),
  mergeElements: trenchApiThunks.create<MergeElements>('element/merge', 'POST'),
  splitElement: trenchApiThunks.create<SplitElement>('element/split', 'POST'),
  deleteElement: trenchApiThunks.create('element/delete', 'DELETE'),
  setTrenchAttribute: trenchApiThunks.create<SetTrenchAttribute>('attribute/set', 'POST'),
};

export const trenchSelectors = {
  ...trenchBaseSelectors,
  findByElementId:
    (elementId?: string) =>
    (state: RootState): Trench | undefined => {
      if (!elementId) return;
      return trenchBaseSelectors.findFirst(hasElement(elementId))(state);
    },
  filterByPointId: (pointId?: string): Selector<RootState, Trench[]> =>
    !pointId
      ? () => []
      : trenchSelectors.selectWithFilter(({ pointIds }) => pointIds.includes(pointId)),
  getByCommonPoints:
    (pointIdA: string, pointIdB: string) =>
    (state: RootState): Trench | undefined =>
      trenchSelectors.findFirst(
        (t) => t.pointIds.includes(pointIdA) && t.pointIds.includes(pointIdB),
      )(state),
};
