import proj4 from 'proj4';
import { proxy } from 'valtio';

// TODO rename to GeodeticCoordinates
export interface LngLat {
  lat: number;
  // TODO refactor to "long" instead of "lng" because we use "long" on Photo-interface. Will reduce some unnecessary destructuring and will make code cleaner.
  lng: number;
}

// TODO rename to CartesianCoordinates
export interface Vector2d {
  x: number;
  y: number;
}

const state = proxy<{ lngLatToUTM: null | proj4.Converter }>({
  lngLatToUTM: null,
});

export const setProj4Projection = (epsgCode: string, projection: string): void => {
  proj4.defs(epsgCode, projection);
  state.lngLatToUTM = proj4(`EPSG:4326`, epsgCode);
};

export const lngLatToXY = ({ lng, lat }: LngLat): Vector2d => {
  if (!state.lngLatToUTM) {
    throw new Error('No projection set');
  }
  const [x, y] = state.lngLatToUTM.forward([lng, lat]);
  return { x, y };
};

export const xyToLngLat = ({ x, y }: Vector2d): LngLat => {
  if (!state.lngLatToUTM) {
    throw new Error('No projection set');
  }
  const [lng, lat] = state.lngLatToUTM.inverse([x, y]);
  return { lng, lat };
};
