import { useKeys } from '@core/logic';
import { FC, useEffect } from 'react';
import { AttributeLegend } from './Attributes';
import { useConnect } from './connect';
import { EditModeTabs } from './Tabs';
import { EditMode } from './types';

export const EditModes: FC = () => {
  const { mode, changeEditMode } = useConnect();
  const [scanModePressed, trenchModePressed, attributeModePressed, elementModePressed] = useKeys(
    '1',
    '2',
    '3',
    '4',
  );

  useEffect(() => {
    if (!(scanModePressed || trenchModePressed || attributeModePressed || elementModePressed))
      return;

    const nextMode: EditMode | undefined = scanModePressed
      ? EditMode.Scan
      : trenchModePressed
      ? EditMode.Trench
      : attributeModePressed
      ? EditMode.TrenchAttributes
      : elementModePressed
      ? EditMode.Element
      : undefined;

    if (!nextMode || mode === nextMode) return;

    changeEditMode(nextMode);
  }, [
    attributeModePressed,
    changeEditMode,
    elementModePressed,
    mode,
    scanModePressed,
    trenchModePressed,
  ]);

  return (
    <>
      <EditModeTabs mode={mode} onChangeEditMode={changeEditMode} />
      {mode === EditMode.TrenchAttributes && <AttributeLegend />}
    </>
  );
};
