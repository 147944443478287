import { store, type AppDispatch } from '@core/redux/store';
import { projectSelectors } from '@projects/redux';
import type { AnyAction } from '@reduxjs/toolkit';
import { pointThunks, trenchSelectors, trenchThunks } from '@trenches/redux';
import type { Point, Trench } from '@trenches/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';
import type { Vector3 } from 'three';
import { markerSphereSelectors } from '@elements/redux/markers.slice';
import { pipeConnectorSelectors } from '@elements/redux/connectors.slice';
import { RootState } from '@core/redux/interface';
import { getTrenchRadiusForViewport, isEndPoint, TRENCH_POINT_FACTOR } from '../../helper';

export type Props = {
  radius: number;
  selectedPointId?: string;
  selectedTrenchId?: string;
  movePointTo: (point: Point, v: Vector3) => void;
  createOrExtendTrench: (pointId: string) => Promise<unknown>;
  createTrench: (projectId: string, fromPointId: string, toPointId: string) => Promise<unknown>;
  extendTrench: (
    selectedTrenchId: string,
    fromPointId: string,
    newPointId: string,
  ) => Promise<unknown>;
  removePointFromTrench: (pointId: string) => void;
  selectFirstTrenchWithPoint: (pointId: string) => void;
  selectPoint: (pointId: string) => void;
  hasMarker: (pointId: string) => boolean;
  hasConnector: (pointId: string) => boolean;
};

export const useConnect = ({ trenchId }: { trenchId: string }): Props => {
  const dispatch = useDispatch<AppDispatch>();
  const projectId = useSelector(viewer3dSelectors.selectedProjectId)!;
  const project = useSelector(projectSelectors.selectById(projectId))!;
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);
  const selectedTrenchId = useSelector(viewer3dSelectors.selectedTrenchId);
  const selectedTrench = useSelector(trenchSelectors.selectById(selectedTrenchId));
  const cameraViewport = useSelector(viewer3dSelectors.cameraViewport);
  const radius = getTrenchRadiusForViewport(TRENCH_POINT_FACTOR, cameraViewport);

  const extendTrench = async (
    selectedTrenchId: string,
    fromPointId: string,
    newPointId: string,
  ) => {
    await dispatch(
      trenchThunks.extendTrench({
        path: ['projects', projectId, 'trenches', selectedTrenchId, 'extend'],
        body: {
          fromPointId,
          newPointId,
        },
      }),
    );
    dispatch(viewer3dActions.setSelectedPointId(newPointId));
  };

  const createTrench = async (projectId: string, fromPointId: string, toPointId: string) => {
    const { payload: trench } = await dispatch(
      trenchThunks.createTrench({
        path: ['projects', projectId, 'trenches'],
        body: {
          projectId,
          fromPointId,
          toPointId,
        },
      }),
    );
    dispatch(viewer3dActions.setSelectedPointId(toPointId));
    dispatch(viewer3dActions.setSelectedTrenchId((trench as Trench).id));
  };

  return {
    radius,
    selectedPointId,
    selectedTrenchId,
    extendTrench,
    hasMarker: (pointId) =>
      !!markerSphereSelectors.findFirst((e) => e.pointId === pointId)(
        store.getState() as RootState,
      ),
    hasConnector: (pointId) =>
      !!pipeConnectorSelectors.findFirst((e) => e.pointId === pointId)(
        store.getState() as RootState,
      ),
    createTrench,
    removePointFromTrench: async (pointId) => {
      await dispatch(
        trenchThunks.delete({
          path: ['projects', projectId, 'trenches', trenchId, 'points', pointId],
        }),
      );
      dispatch(viewer3dActions.clearSelectedPoint());
    },

    selectFirstTrenchWithPoint: (pointId) =>
      dispatch(viewer3dActions.selectFirstTrenchWithPoint(pointId) as unknown as AnyAction),

    selectPoint: (nextPointId) => dispatch(viewer3dActions.setSelectedPointId(nextPointId)),

    movePointTo: async (point, nextPosition) => {
      if (nextPosition.equals(point as unknown as Vector3)) return;

      await dispatch(
        pointThunks.movePoint({
          path: ['projects', projectId, 'points', point.id],
          body: { ...nextPosition, utmZone: project.utmZone },
        }),
      );
    },
    createOrExtendTrench: async (toPointId) => {
      if (!selectedPointId) {
        throw new Error('No point selected');
      }
      if (isEndPoint(selectedPointId, selectedTrench)) {
        await extendTrench(selectedTrench!.id, selectedPointId, toPointId);
      } else {
        await createTrench(project.id, selectedPointId, toPointId);
      }
    },
  };
};
