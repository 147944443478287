import type { RootState } from '@core/redux/interface';
import type { MapboxStyle } from '@core/types';
import { projectSelectors } from '@projects/redux/slice';
import type { Project } from '@projects/types';
import { createSelector } from '@reduxjs/toolkit';
import { scanSelectors } from '@scans/redux/slice';
import type { Scan } from '@scans/types';
import type { BoundingBoxWithHeight, ScanSelection, VisibleScans } from '@viewer3D/types';
import type { Selector } from 'react-redux';
import { Vector3 } from 'three';
import { Point } from '@trenches/types';
import { pointSelectors } from '@trenches/redux';
import { processingResultSelectors } from '@processingResults/redux';
import { vecFromLatLong } from '@core/logic';
import { elementSelectors } from '@elements/redux';
import { pointElementSelectors } from '@elements/redux/point-element.slice';
import { PointElement, SplineElement } from '@elements/types';
import type { Viewer3dState, VisibleElementUsage } from './interface';

const viewer3dSelector: Selector<RootState, Viewer3dState> = (state) => state.viewer3d;

const getViewer3dProp = <T>(prop: keyof Viewer3dState) =>
  createSelector([viewer3dSelector], (viewer3d) => viewer3d[prop] as unknown as T);

const selectProjectId = getViewer3dProp<string | undefined>('selectedProjectId');
const selectedProject = (state: RootState): Project | undefined => {
  const id = selectProjectId(state);
  return projectSelectors.selectById(id)(state);
};
const selectedScanId = getViewer3dProp<string | undefined>('selectedScanId');
const selectedTrenchId = getViewer3dProp<string | undefined>('selectedTrenchId');
const selectedElementIds = getViewer3dProp<string[]>('selectedElementIds');

const selectCameraViewport = getViewer3dProp<BoundingBoxWithHeight | undefined>('cameraViewport');
const selectVisibleElementUsage = getViewer3dProp<VisibleElementUsage>('visibleElementUsage');
const selectedPointId = getViewer3dProp<string | undefined>('selectedPointId');

const selectedScan = (state: RootState): Scan | undefined => {
  const id = selectedScanId(state);
  return scanSelectors.selectById(id)(state);
};

const firstSelectedElement = (state: RootState) => {
  const id = selectedElementIds(state)[0];
  return elementSelectors.selectById(id)(state) ?? pointElementSelectors.selectById(id)(state);
};

const selectScanPoint = (state: RootState) => {
  const scan = selectedScan(state);
  const processingResult = processingResultSelectors.selectById(scan?.selectedProcessingResultId)(
    state,
  );
  if (!processingResult || !scan?.location) return;
  const z = processingResult.userTranslation?.z ?? processingResult.texturedMeshes[0].mesh.offset.z;
  const scanCenter = vecFromLatLong(scan?.location?.latitudeMean, scan?.location?.longitudeMean, z);
  return scanCenter as unknown as Point;
};

const selectElementPoint = (state: RootState) => {
  const element = firstSelectedElement(state);
  const elementPointId =
    (element as PointElement)?.pointId ?? (element as SplineElement)?.fromPoint?.id;
  return pointSelectors.selectById(elementPointId)(state);
};

export const viewer3dSelectors = {
  selectScanPoint,
  selectElementPoint,
  cameraViewport: selectCameraViewport,
  selectedPointId,
  selectedPoint: (state: RootState): Point | undefined => {
    const pointId = selectedPointId(state);
    if (!pointId) return;
    return pointSelectors.selectById(pointId)(state);
  },
  cameraGoto: (state: RootState): Vector3 | undefined => {
    const { goTo } = state.viewer3d.camera;
    return !goTo ? undefined : new Vector3(goTo.x, goTo.y, goTo.z);
  },
  initialCameraSet: getViewer3dProp<boolean>('initialCameraSet'),
  mapStyle: getViewer3dProp<MapboxStyle>('mapStyle'),
  visibleScans: getViewer3dProp<VisibleScans>('visibleScans'),
  selectedProjectId: selectProjectId,
  selectedProject,
  selectedScanId,
  selectedScan,
  selectedTrenchId,
  selectedElementIds,
  scanSelection: getViewer3dProp<ScanSelection | undefined>('scanSelection'),
  visibleElementUsage: selectVisibleElementUsage,
  scanIdIsSelected:
    (scanId?: string) =>
    (state: RootState): boolean =>
      scanId === state.viewer3d.selectedScanId,
  isTrenchIdSelected:
    (trenchId?: string) =>
    (state: RootState): boolean =>
      !!trenchId && state.viewer3d.selectedTrenchId === trenchId,
};
