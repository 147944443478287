import { xyToLngLat } from '@core/logic';
import type { Scan } from '@scans/types';
import type { BoundingBox } from '@viewer3D/types';
import { DateTime } from 'luxon';

export const scanToDateTime = (scan: Scan): DateTime | undefined => {
  const { metadata, createdAt } = scan;
  if (metadata?.beginDate) return DateTime.fromISO(metadata.beginDate);
  if (createdAt) return DateTime.fromISO(createdAt);
  return undefined;
};

export const sortScansByCreatedDate = (a: Scan, b: Scan): number => {
  const dateA = a.metadata?.beginDate || a.createdAt;
  const dateB = b.metadata?.beginDate || b.createdAt;
  return dateA.localeCompare(dateB);
};

export const sortScansByUploadedDate = (a: Scan, b: Scan): number => {
  const dateA = a.uploadedAt;
  const dateB = b.uploadedAt;
  return dateA && dateB ? dateA.localeCompare(dateB) : -1;
};

export const scanWithinBounds = (bounds: BoundingBox): ((scan: Scan) => boolean) => {
  if (!bounds) return () => true; // Return all scans if filter mode is Bounds but no bounds property set

  const { minX, minY, maxX, maxY } = bounds;
  try {
    const { lng: lngMin, lat: latMin } = xyToLngLat({ x: minX, y: minY });
    const { lng: lngMax, lat: latMax } = xyToLngLat({ x: maxX, y: maxY });

    return (scan: Scan): boolean => {
      const { boundingBox } = scan.metadata;
      if (!boundingBox) {
        return false;
      }

      // Use same implementation as backend:
      // https://gamedev.stackexchange.com/questions/586/what-is-the-fastest-way-to-work-out-2d-bounding-box-intersection/913#913
      return (
        boundingBox.latitudeMax >= latMin &&
        boundingBox.latitudeMin <= latMax &&
        boundingBox.longitudeMax >= lngMin &&
        boundingBox.longitudeMin <= lngMax
      );
    };
  } catch (error) {
    return () => false;
  }
};
