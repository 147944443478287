import { projectSelectors } from '@projects/redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { proxy, useSnapshot } from 'valtio';

export const isPipeDirectionVisibleState = proxy<{ visible: boolean }>({ visible: true });

export const togglePipeDirectionVisibility = (): void => {
  isPipeDirectionVisibleState.visible = !isPipeDirectionVisibleState.visible;
};

export const useRenderPipeDirection = (): boolean => {
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const { hasPipeDirections } = useSelector(projectSelectors.selectById(projectId))!;
  const { visible } = useSnapshot(isPipeDirectionVisibleState);
  return hasPipeDirections && visible;
};
