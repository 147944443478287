import { sortAscByCreatedAt } from '@core/logic';
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChipOutline, CubeMesh } from '@deepup/icons';
import { environment } from '@core/environment';
import { usePermissions } from '@userProfile/hooks';
import { ProcessingResult } from '@processingResults/types';
import { ProcessingIndicator } from '@processingResults/components/ProcessingIndicator';
import { DateTime } from 'luxon';

export const ProcessingResultsList = ({
  selectedProcessingResultId,
  processingResults,
  onSelectResult,
}: {
  onSelectResult: (result: ProcessingResult) => void;
  processingResults: ProcessingResult[];
  selectedProcessingResultId?: string;
}) => {
  const permissions = usePermissions();

  if (!selectedProcessingResultId || !processingResults.length) {
    return <Typography variant="subtitle2">No processing results available</Typography>;
  }

  return (
    <RadioGroup value={selectedProcessingResultId}>
      {processingResults.sort(sortAscByCreatedAt).map((result) => (
        <FormControlLabel
          key={result.id}
          value={result.id}
          onClick={() => onSelectResult(result)}
          control={<Radio />}
          label={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="subtitle2">
                {DateTime.fromISO(result.createdAt).toLocaleString(
                  DateTime.DATETIME_MED_WITH_WEEKDAY,
                )}
              </Typography>
              <ProcessingIndicator processingResultId={result.id} size={16} />
              <Tooltip title="Go to HD-Mesh">
                <IconButton
                  color="secondary"
                  href={`${environment.trenchviewerFrontendUrl}/hd-mesh/${result.scanId}`}
                  target="_blank"
                  role="link"
                >
                  <CubeMesh />
                </IconButton>
              </Tooltip>
              {permissions.canViewScanJobs && (
                <Tooltip title="Inspect Scan processing status">
                  <IconButton
                    color="secondary"
                    href={`${environment.scanDashboardUrl}${result.scanId}`}
                    target="_blank"
                    role="link"
                    aria-label="Job"
                  >
                    <ChipOutline />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          }
        />
      ))}
    </RadioGroup>
  );
};
