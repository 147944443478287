import { Auth0Provider } from '@auth0/auth0-react';
import { Authentication } from '@core/components';
import { Authorization } from '@core/components/Auth/Authorization';
import { environment } from '@core/environment';
import { store } from '@core/redux/store';
import { HelpCenterProvider } from '@deepup/guide';
import { darkTheme, ThemeProvider } from '@deepup/mui-theme-deepup';
import { Provider } from 'react-redux';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import { Settings } from 'luxon';
import { Router } from './Router';

const { authorizationParams, domain, clientId } = environment.auth0;

Settings.defaultLocale = 'en'; // Set global locale

export const App = () => (
  <LocalizationProvider dateAdapter={AdapterLuxon}>
    <Provider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          ...authorizationParams,
          redirect_uri: window.location.origin,
        }}
      >
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <HelpCenterProvider appIdentifier="backoffice" userLanguage="en">
            <Authentication>
              <Authorization>
                <Router />
              </Authorization>
            </Authentication>
          </HelpCenterProvider>
        </ThemeProvider>
      </Auth0Provider>
    </Provider>
  </LocalizationProvider>
);
