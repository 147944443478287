import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { TrashcanOutline } from '@deepup/icons';

export const ElementConfirmDeleteDialog = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Dialog maxWidth="xs" open onClose={onClose}>
    <DialogTitle>Delete Confirmation</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Do you really want to delete this element? This action cannot be undone.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary" variant="outlined">
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        autoFocus
        variant="contained"
        color="error"
        endIcon={<TrashcanOutline />}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);
