import { Object3dNames } from '../types';

const renderOrder = [
  Object3dNames.Map,
  Object3dNames.ScanCentered,
  Object3dNames.Scan,
  Object3dNames.Scans,
  Object3dNames.Photo,
  Object3dNames.PhotoCluster,
  Object3dNames.Photos,
  Object3dNames.TrenchSegment,
  Object3dNames.TrenchSegments,
  Object3dNames.IntermediatePoints,
  Object3dNames.IntermediatePoint,
  Object3dNames.TrenchPoint,
  Object3dNames.TrenchPoints,
  Object3dNames.ElementPoint,
  Object3dNames.ElementPoints,
  Object3dNames.AttributePoint,
  Object3dNames.AttributePoints,
  Object3dNames.Trench,
  Object3dNames.Trenches,
  Object3dNames.PrelabeledTrenches,
  Object3dNames.PipeDirection,
  Object3dNames.MeasurementDiv,
  Object3dNames.MeasurementLine,
  Object3dNames.Measurement,
  Object3dNames.BoxHelper,
  Object3dNames.ViewportHelper,
  Object3dNames.Transform,
  Object3dNames.MouseSphere,
];

export const getRenderOrder = (name: Object3dNames): number => renderOrder.indexOf(name) + 1;
