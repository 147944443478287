import type { Vector } from '@core/types';

export const distanceBetweenCoordinates = (point1: Vector, point2: Vector): number => {
  const { x: p1x, y: p1y, z: p1z } = point1;
  const { x: p2x, y: p2y, z: p2z } = point2;
  const diffX = p1x - p2x;
  const diffY = p1y - p2y;
  const diffZ = p1z - p2z;
  return Math.sqrt(diffX ** 2 + diffY ** 2 + diffZ ** 2);
};

export const getClosestPoint = <T extends Vector>(points: T[], referencePoint: Vector): T =>
  points.reduce((a, b) =>
    distanceBetweenCoordinates(referencePoint, a) < distanceBetweenCoordinates(referencePoint, b)
      ? a
      : b,
  );
