import { Paper, Snackbar, Tab, Tabs } from '@mui/material';
import {
  PipesFilled,
  PipesOutline,
  ScanFilled,
  ScanOutline,
  TagFilled,
  TagOutline,
  TrenchDepthFilled,
  TrenchDepthOutline,
} from '@deepup/icons';
import { Icon } from '@core/types';
import { EditMode } from '../types';

const tabs: [EditMode, Icon, Icon, string][] = [
  [EditMode.Scan, ScanFilled, ScanOutline, '1'],
  [EditMode.Trench, TrenchDepthFilled, TrenchDepthOutline, '2'],
  [EditMode.TrenchAttributes, TagFilled, TagOutline, '3'],
  [EditMode.Element, PipesFilled, PipesOutline, '4'],
];

export const EditModeTabs = ({
  mode,
  onChangeEditMode,
}: {
  mode: EditMode;
  onChangeEditMode: (mode: EditMode) => void;
}) => (
  <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open sx={{ zIndex: 1300 }}>
    <Paper elevation={12} sx={{ overflow: 'hidden' }}>
      <Tabs value={mode} onChange={(_, value) => onChangeEditMode(value as EditMode)}>
        {tabs.map(([value, FilledIcon, Icon, key]) => (
          <Tab
            key={value}
            label={`${value} [${key}]`}
            value={value}
            icon={mode === value ? <FilledIcon fontSize={24} /> : <Icon fontSize={24} />}
          />
        ))}
      </Tabs>
    </Paper>
  </Snackbar>
);
