import { useDispatch, useSelector } from 'react-redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import { projectSelectors } from '@projects/redux';
import { useSnapshot } from 'valtio';
import { encodeScanFilters, useScanFilters } from '@projects/pages/detail/hooks';
import { scanThunks } from '@scans/redux';
import { useCallback } from 'react';
import { QAState } from '@scans/types';
import { DateTime } from 'luxon';
import { trackUserEvent, UserEvent } from '@core/analytics';
import { useScansInScope } from '../hooks';
import { checkboxSelectionState } from './checkboxSelectionState';

export const useConnectScans = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const scans = useScansInScope();
  const { checkedScans } = useSnapshot(checkboxSelectionState);
  const scanFilters = useScanFilters();
  const utmZone = useSelector(projectSelectors.selectById(projectId))?.utmZone;
  const selectedScanId = useSelector(viewer3dSelectors.selectedScanId);

  const loadScans = useCallback(() => {
    if (!scanFilters.viewport && !scanFilters.dateRange) {
      // prevent loading a lot of scans when there are no filters in place yet
      return;
    }
    const encodedFilters = encodeScanFilters(scanFilters);
    dispatch(
      scanThunks.getMany({
        path: ['scans', 'search', 'withFilters'],
        params: {
          projectId,
          ...encodedFilters,
          utmZone,
          projection: 'projectDetail',
          size: 1000,
        },
      }),
    );
  }, [scanFilters]);

  const isNotSetAsFilter = (state: QAState): boolean => state !== scanFilters.qaState;

  const updateScansQaState = (nextQaState: QAState) => {
    const checkedScans = checkboxSelectionState.checkedScans; // ATTENTION: we need to read the Valtio state here like this! Otherwise if we access it via useSnapshot() it will be stale! (bug DP-6856)
    dispatch(
      scanThunks.updateQaState({
        path: ['projects', projectId!, 'scans', 'qaState'],
        body: checkedScans.map((scan) => ({
          id: scan.id,
          qaState: nextQaState,
          reworkCategories: nextQaState === 'TO_REWORK' ? scan.reworkCategories : undefined,
        })),
      }),
    );
    if (nextQaState === 'DONE') {
      // Note: This event is fired EVERY TIME a scan is set to "DONE".
      // You could set the scan to "DONE" then back to "QA_READY"/"TO_LABEL" and then back to "DONE" again etc.
      checkedScans.forEach((scan) => {
        if (!scan.uploadedAt) return;
        const diff = DateTime.fromISO(scan.uploadedAt).diffNow('hours');
        trackUserEvent(UserEvent.ScanFinished, {
          scanId: scan.id,
          hoursInProgress: Math.abs(diff.hours),
        });
      });
    }
  };

  return {
    loadScans,
    scans,
    selectedScanId,
    checkedScanIds: checkedScans.map((s) => s.id),
    updateScansQaState,
    isNotSetAsFilter,
  };
};
