export interface DispatchProps {
  clearSelection: () => void;
}

/**
 * We use this as enum because we use it in the EditModes tabs as a value={} prop
 */
export enum EditMode {
  Element = 'ELEMENT',
  Scan = 'SCAN',
  Trench = 'TRENCH',
  TrenchAttributes = 'ATTRIBUTES',
}

export interface EditModeState {
  mode: EditMode;
}
