type FetchFunction = () => void;
type RequestQueue = {
  queue: FetchFunction[];
  isRequestInProgress: boolean;
  pending: ReturnType<typeof setTimeout>[];
};

const queue: Record<string, RequestQueue> = {};

export const enqueue = (key: string, request: FetchFunction) => {
  if (queue[key]) {
    queue[key].queue.push(request);
    return;
  }
  queue[key] = { queue: [request], isRequestInProgress: false, pending: [] };
};

const PARALLEL_REQUESTS_LIMIT = 10; // limit per queue
const THROTTLE_TIMEOUT_MS = 100;

export const processQueue = async (key: string) => {
  const { isRequestInProgress, queue: requestQueue } = queue[key];
  if (isRequestInProgress || requestQueue.length === 0) return;

  queue[key].isRequestInProgress = true;

  // process full queue if its length less than limit
  const requestsBatchSize = Math.min(PARALLEL_REQUESTS_LIMIT, requestQueue.length);
  const requests = requestQueue.splice(0, requestsBatchSize);
  await Promise.all(requests.map((request) => request()));

  queue[key].isRequestInProgress = false;
  const timeout = setTimeout(() => {
    processQueue(key);
  }, THROTTLE_TIMEOUT_MS);
  queue[key].pending.push(timeout);
};

export const clearQueue = (key: string) => {
  if (queue[key]) {
    queue[key].queue = [];
    queue[key].pending.forEach((timeout) => clearTimeout(timeout));
    queue[key].pending = [];
  }
};
