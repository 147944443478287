export const PipeColor = {
  rt: '#ff0000',
  gn: '#00ff00',
  bl: '#0000ff',
  ge: '#ffff00',
  ws: '#ffffff',
  gr: '#cccccc',
  br: '#663300',
  vi: '#ff00ff',
  tk: '#00ffff',
  sw: '#000000',
  or: '#ff9900',
  rs: '#ff99ff',
};

// There are max 24 speedpipes colors
export const pipeColors = [
  ...Object.entries(PipeColor),
  ...Object.entries(PipeColor).map(([code, color]) => [code + '—', color]),
] as const;
