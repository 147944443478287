import {
  add,
  distanceBetweenCoordinates,
  distancePointSegment,
  getNeighborPairs,
  multiply,
  sub,
} from '@core/logic';
import type { TrenchPrelabel, Vector } from '@core/types';
import { isNumber, range } from 'lodash';

/**
 * Algo:
 *
 * * make intermediate sampling points along user-labeled segment
 * * for each point P, find closest pre-labeled segment (A, B)
 *   - distance between Point and line
 * * for all those pre-labeled segments widths find median
 */

const samplingPointDistance = 0.15;

export const createIntermediateSamplingPoints = (
  fromPoint: Vector,
  toPoint: Vector,
  step = samplingPointDistance,
): Vector[] => {
  const distance = distanceBetweenCoordinates(fromPoint, toPoint);
  if (distance <= step) return [fromPoint, toPoint];
  const numberOfPoints = Math.floor(distance / step);
  const direction = multiply(sub(toPoint, fromPoint), 1 / numberOfPoints);
  const intermediatePoints = range(1, numberOfPoints).map((i) =>
    add(fromPoint, multiply(direction, i)),
  );
  return [fromPoint, ...intermediatePoints, toPoint];
};

export type SegmentWithWidth = [Vector, Vector, number | null];

export const getClosestSegment = (P: Vector, segments: SegmentWithWidth[]): SegmentWithWidth => {
  const distances = segments.map(distancePointSegment(P));
  const closestIndex = distances.indexOf(Math.min(...distances));
  return segments[closestIndex];
};

const getMedian = (arr: number[]) => {
  const sorted = arr.sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);
  return sorted[middle];
};

export const toSegmentWithWidth = (tp?: TrenchPrelabel): SegmentWithWidth[] =>
  !tp ? [] : getNeighborPairs(tp.points).map(([from, to], i) => [from, to, tp.segmentWidths[i]]);

export const toSegmentsWithWidth = (prelabels: TrenchPrelabel[]): SegmentWithWidth[] =>
  prelabels.map(toSegmentWithWidth).flat(1);

export const getPrelabeledWidth = (
  fromPoint?: Vector,
  toPoint?: Vector,
  segments?: SegmentWithWidth[],
): number | undefined => {
  if (!fromPoint || !toPoint || !segments?.length) return undefined;
  const samplingPoints = createIntermediateSamplingPoints(fromPoint, toPoint);
  const closestSegments = samplingPoints.map((p) => getClosestSegment(p, segments));
  const widths = closestSegments.map(([, , width]) => width).filter(isNumber);

  if (!widths.length) return undefined;

  return getMedian(widths);
};
