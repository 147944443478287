import { toHumanReadable, useBlockAllKeyboardShortcuts, useEffectOnce } from '@core/logic';
import type { AppDispatch } from '@core/redux/store';
import type { FullAddress } from '@core/types/address';
import { Point, XOutline } from '@deepup/icons';
import { DIALOG_WIDTH, ElementDialogProps, ParentSelectAutocomplete } from '@elements/components';
import { elementSelectors } from '@elements/redux';
import { pointElementSelectors, pointElementThunks } from '@elements/redux/point-element.slice';
import type { PartialPointEl, PointElement } from '@elements/types';
import { pointElementSubtype } from '@elements/types';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { setEditMode } from '@projects/edit-modes';
import { EditMode } from '@projects/edit-modes/types';
import { type ProjectMetadata } from '@projects/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { set } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialSelectAutocomplete from './MaterialSelectAutocomplete';
import { namingSchemes } from './namingSchemesCustomers';
import { useMaterials } from './useMaterials';

const initialAddress: FullAddress = {
  street: '',
  number: 0,
  zip: '',
  city: '',
};

const intialPointElementState: PartialPointEl = {
  kind: 'NETWORK_NODE',
  name: '',
  subtype: 'HAUPTVERTEILER',
  parentPointElementId: null,
  pointId: null,
  address: initialAddress,
};

const NameTextField = ({
  element,
  setName,
  namingScheme,
  hasNameError = false,
  ...autocompleteProps
}: {
  element: PartialPointEl;
  setName: (nextVal: string) => void;
  namingScheme?: RegExp;
  hasNameError?: boolean;
} & Partial<AutocompleteRenderInputParams>) => (
  <TextField
    label="Name"
    inputProps={{ 'aria-label': 'Name' }}
    fullWidth
    required={!!namingScheme}
    value={element.name ?? ''}
    onChange={(e) => setName(e.target.value)}
    error={namingScheme && hasNameError}
    helperText={
      namingScheme &&
      hasNameError &&
      `Please enter a name for ${toHumanReadable(
        element.subtype,
      )} in the following regex-format: ${namingScheme.source.replace(/\^\$/, '')}`
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...autocompleteProps}
  />
);

const useConnect = ({ elementToUpdate, onClose }: ElementDialogProps<PointElement>) => {
  const dispatch: AppDispatch = useDispatch();
  const hasPointChildren = useSelector(
    pointElementSelectors.findFirst((p) => p.parentPointElementId === elementToUpdate?.id),
  );
  const hasSplineChildren = useSelector(
    elementSelectors.findFirst((p) => p.parentPointElementId === elementToUpdate?.id),
  );
  const hasChildren = hasPointChildren || hasSplineChildren;

  const [element, setElement] = useState<PartialPointEl>(
    elementToUpdate ?? intialPointElementState,
  );

  const project = useSelector(viewer3dSelectors.selectedProject)!;

  const updateProp = (path: string, value: unknown) => {
    if (path === 'subtype') {
      setElement((s) => set({ ...s, name: '' }, path, value));
    } else {
      setElement((s) => set({ ...s, address: { ...s.address } }, path, value));
    }
  };

  const addElement = async () => {
    const { payload } = await dispatch(
      pointElementThunks.post({
        path: ['projects', project.id, 'pointElements'],
        body: { ...element, projectId: project.id } as PointElement,
      }),
    );
    return payload as PointElement;
  };

  const updateElement = async () => {
    const { payload } = await dispatch(
      pointElementThunks.patch({
        path: ['projects', project.id, 'pointElements', elementToUpdate!.id],
        body: element as PointElement,
      }),
    );
    return payload as PointElement;
  };

  const onSubmit = async () => {
    if (element === intialPointElementState) return;
    const nextElement = await (elementToUpdate ? updateElement : addElement)();

    // reselect element to navigate to it again (dirty hack!)
    dispatch(viewer3dActions.setSelectedElementIds([]));
    dispatch(viewer3dActions.setSelectedElementIds([nextElement.id]));
    onClose();
  };

  const autocompleteOptions = project.metadata.filter((d) => d.subtype === element.subtype);

  const namingScheme = project.exportTarget
    ? namingSchemes[project.exportTarget][element.subtype]
    : undefined;

  const hasNameError =
    // ATTENTION: need to explicitly check for name !== undefined, because an empty string is falsy
    !!namingScheme && element.name !== undefined && !namingScheme.test(element.name);

  const isAddressValid =
    element.subtype === 'HAUPTKABEL' || // Hauptkabel doesn't need an address
    element.subtype === 'KABELSCHACHT' || // Kabelschacht doesn't need an address
    (!isNaN(element.address.number) &&
      element.address.number !== initialAddress.number &&
      element.address.city !== initialAddress.city &&
      element.address.zip !== initialAddress.zip &&
      element.address.street !== initialAddress.street);

  return {
    hasChildren,
    element,
    updateProp,
    onSubmit,
    autocompleteOptions,
    namingScheme,
    hasNameError,
    isAddressValid,
  };
};

export const PointElementDialog = ({ ...props }: ElementDialogProps<PointElement>) => {
  useBlockAllKeyboardShortcuts();

  useEffectOnce(() => {
    setEditMode(EditMode.Element);
  });

  const {
    element,
    isAddressValid,
    updateProp,
    onSubmit,
    autocompleteOptions,
    namingScheme,
    hasNameError,
    hasChildren,
  } = useConnect(props);

  const { materials } = useMaterials({ subType: element.subtype });

  const { elementToUpdate, onClose } = props;
  const isAddressRequired = !(
    element.subtype === 'HAUPTKABEL' || element.subtype === 'KABELSCHACHT'
  );

  const [isMaterialSet, setIsMaterialSet] = useState(!!element.materialId);

  const isMaterialRequired =
    (element.subtype === 'NETZVERTEILER' || element.subtype === 'GLASFASER_MUFFE') &&
    materials.length > 0;

  const needsMaterialSelected = !isMaterialSet && isMaterialRequired;
  const canSubmit = !hasNameError && isAddressValid && !needsMaterialSelected;

  return (
    <Dialog
      open
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{ sx: { width: DIALOG_WIDTH }, elevation: 4 }}
    >
      <DialogTitle variant="h6" gap={2} display="flex" alignItems="center">
        <Point fill="currentColor" fontSize={24} />
        {(elementToUpdate ? 'Edit ' : 'Add ') + toHumanReadable(element.kind)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
          })}
          size="large"
        >
          <XOutline fontSize={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} hidden={!!hasChildren} container gap={1}>
            {pointElementSubtype.map((s) => (
              <Chip
                label={toHumanReadable(s)}
                key={s}
                onClick={() => updateProp('subtype', s)}
                color={s === element.subtype ? 'primary' : 'default'}
                variant={s === element.subtype ? 'filled' : 'outlined'}
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            {autocompleteOptions.length ? (
              <Autocomplete
                options={autocompleteOptions}
                getOptionLabel={(option) => option.name ?? ''}
                renderOption={(props, option) => (
                  <ListItem
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    key={option.id}
                    disableGutters
                  >
                    <ListItemText>{option.name}</ListItemText>
                    <Typography variant="body2" color="secondary">
                      {option.smaNumber}
                    </Typography>
                  </ListItem>
                )}
                componentsProps={{ paper: { elevation: 24 } }}
                noOptionsText="No available element names found"
                inputValue={element.name}
                onChange={(_, nextOption, reason) => {
                  if (reason === 'selectOption')
                    updateProp('name', (nextOption as ProjectMetadata).name);
                }}
                onInputChange={(e, newInputValue) => {
                  if (!e || e.type === 'blur') return; // avoid erase of input value on initial rendering
                  updateProp('name', newInputValue);
                }}
                renderInput={(renderParams) => (
                  <NameTextField
                    element={element}
                    setName={(nextVal) => updateProp('name', nextVal)}
                    namingScheme={namingScheme}
                    hasNameError={hasNameError}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...renderParams}
                  />
                )}
              />
            ) : (
              <NameTextField
                element={element}
                setName={(nextVal) => updateProp('name', nextVal)}
                namingScheme={namingScheme}
                hasNameError={hasNameError}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <ParentSelectAutocomplete elementToUpdate={elementToUpdate} updateProp={updateProp} />
          </Grid>
          {isMaterialRequired && (
            <Grid item xs={12}>
              <MaterialSelectAutocomplete
                required
                subType={element.subtype}
                updateProp={updateProp}
                currentMaterialId={element.materialId}
                setIsMaterialSet={setIsMaterialSet}
                materials={materials}
              />
            </Grid>
          )}
          <Grid item xs={10}>
            <TextField
              required={isAddressRequired}
              fullWidth
              inputProps={{ 'aria-label': 'Street' }}
              label="Street"
              value={element.address.street || ''}
              onChange={(e) => updateProp('address.street', e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              required={isAddressRequired}
              fullWidth
              label="Nr."
              inputProps={{ 'aria-label': 'Number' }}
              value={element.address.number || ''}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return;
                updateProp('address.number', Number(e.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Addition' }}
              label="Addition"
              value={element.address.addition || ''}
              onChange={(e) => updateProp('address.addition', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              required={isAddressRequired}
              fullWidth
              inputProps={{ 'aria-label': 'Zip' }}
              label="Zip"
              value={element.address.zip || ''}
              onChange={(e) => updateProp('address.zip', e.target.value)}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              required={isAddressRequired}
              fullWidth
              inputProps={{ 'aria-label': 'City' }}
              label="City"
              value={element.address.city || ''}
              onChange={(e) => updateProp('address.city', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'District' }}
              label="District"
              value={element.address.district || ''}
              onChange={(e) => updateProp('address.district', e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing>
        <Stack direction="row" gap={2} paddingRight={2} paddingBottom={2}>
          <Button onClick={onClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            disabled={!canSubmit}
            data-testid="submit-button"
          >
            {(elementToUpdate ? 'Update ' : 'Add ') + toHumanReadable(element.kind)}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
