import { dateTimeFormatter, useKey } from '@core/logic';
import { Card, Fade, Stack, Typography, ButtonBase, IconButton } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio';
import { useTheme } from '@mui/material/styles';
import { ArrowBoxOut } from '@deepup/icons';
import {
  openPhotoInNewTab,
  photosState,
  showNextPhoto,
  setPreviewPosition,
  setIsHoveringPreview,
} from './state';

export const PhotoPreview = () => {
  const {
    showPreview,
    previewPhotos,
    currentPhotoIndex,
    isLoading,
    previewPosition,
    hoverPreviewPosition,
  } = useSnapshot(photosState);
  const photoRefs = useRef<HTMLDivElement[]>([]);
  const nextPhotoPressed = useKey(' ', true);
  const theme = useTheme();

  useEffect(() => {
    if (previewPhotos.length > 1 && nextPhotoPressed) {
      showNextPhoto();
    }
  }, [nextPhotoPressed, previewPhotos.length]);

  useEffect(() => {
    photoRefs.current[currentPhotoIndex]?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [currentPhotoIndex]);

  const photoWidth = theme.spacing(37);
  const maxWidth = theme.spacing(45);

  const positioningStyles = hoverPreviewPosition
    ? {
        left: hoverPreviewPosition.x + 5,
        top: hoverPreviewPosition.y + 5,
      }
    : previewPosition
    ? {
        left: previewPosition.x + 5,
        top: previewPosition.y + 5,
      }
    : {
        right: theme.spacing(1),
        bottom: theme.spacing(1),
      };

  const handleMouseEnter = () => {
    setIsHoveringPreview(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringPreview(false);
  };

  const currentPhoto = previewPhotos[currentPhotoIndex];

  return (
    <Fade in={showPreview} timeout={300} onExited={() => setPreviewPosition(null)}>
      <Card
        elevation={12}
        sx={{
          position: 'fixed',
          zIndex: theme.zIndex.modal,
          ...positioningStyles,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {currentPhoto && (
          <ButtonBase
            onClick={() => openPhotoInNewTab(currentPhoto)} // Updated to open image in a new tab
            sx={{
              width: '100%',
              padding: theme.spacing(1),
              color: 'white',
            }}
          >
            <IconButton>
              <ArrowBoxOut />
            </IconButton>
            <Typography variant="body2">Open in new tab</Typography>
          </ButtonBase>
        )}
        <Stack
          direction="row"
          overflow="hidden"
          maxWidth={maxWidth}
          sx={{ scrollSnapType: 'x mandatory' }}
        >
          {!isLoading &&
            previewPhotos.map((p, i) => (
              <Stack
                key={p.id}
                sx={{
                  scrollSnapAlign: i < previewPhotos.length - 1 ? 'start' : 'end',
                  width: photoWidth,
                  transition: theme.transitions.create('opacity', { easing: 'ease-in-out' }),
                  opacity: i === currentPhotoIndex ? 1 : 0.3,
                  '& img': {
                    minHeight: 400,
                  },
                }}
                ref={(ref) => (photoRefs.current[i] = ref!)}
              >
                <img
                  src={p.url}
                  alt="preview"
                  width={photoWidth}
                  onClick={previewPhotos.length > 0 ? undefined : () => openPhotoInNewTab(p)}
                  onError={() => console.error('Failed to load image.')}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 1, textAlign: 'center', p: 1 }}
                >
                  {dateTimeFormatter(p.recordedAt)}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Card>
    </Fade>
  );
};
