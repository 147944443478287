import { Object3dNames } from '@viewer3D/types';
import type { Photo } from '@photos/types';
import { HtmlWithTheme } from '@core/components';
import { zIndexRange3dHtml } from '@core/constants';
import { Badge, IconButton } from '@mui/material';
import { CameraFilled } from '@deepup/icons';
import { vecFromLatLong } from '@core/logic';
import {
  setPreviewPosition,
  setIsHoveringIcon,
  photosState,
  setPreviewPhotos,
  setSelectedPhoto,
  clearPreviewPhotos,
} from './state';
import { getClusterPosition, gnssColor } from './helper';

export const PhotoIcon = ({ photos, z }: { photos: Photo[]; z: number }) => {
  if (!photos || photos.length === 0) {
    console.warn('PhotoIcon received an empty photos array.');
    return null;
  }

  const isSinglePhoto = photos.length === 1;
  const position = isSinglePhoto
    ? vecFromLatLong(photos[0].lat, photos[0].long, z)
    : getClusterPosition(photos, z);

  const handleClick = async () => {
    const signedUrl = photosState.previewPhotos.find(
      (p) => p.id === photos[photosState.currentPhotoIndex].id,
    )?.url;

    setSelectedPhoto(
      signedUrl
        ? { ...photos[photosState.currentPhotoIndex], url: signedUrl }
        : photos[photosState.currentPhotoIndex],
    );
  };

  const handleMouseEnter = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { clientX, clientY } = event;
    setIsHoveringIcon(true);
    setPreviewPosition({ x: clientX, y: clientY });
    photosState.hoverPreviewPosition = { x: clientX, y: clientY };
    await setPreviewPhotos(photos);
  };

  const handleMouseLeave = () => {
    setIsHoveringIcon(false);

    if (!photosState.selectedPhoto && photosState.previewPhotos.length === 0) {
      clearPreviewPhotos();
    }
  };

  return (
    <HtmlWithTheme
      position={position}
      center
      name={`${isSinglePhoto ? Object3dNames.Photo : Object3dNames.PhotoCluster}:${photos[0].id}`}
      zIndexRange={zIndexRange3dHtml}
    >
      <Badge
        overlap="circular"
        badgeContent={photos.length}
        color="secondary"
        sx={{
          filter: 'drop-shadow(0 0 3px #000000)',
        }}
        invisible={isSinglePhoto}
      >
        <IconButton
          sx={{
            'div:has(> div > .MuiBadge-root > &:hover)': {
              /*
                Brings the icon above all others on hover.
                TODO: This is highly specific to the react-three/drei + MUI
                  element nesting and might not work in future drei/MUI versions!
              */
              zIndex: zIndexRange3dHtml[0] + 1 + ' !important',
            },
          }}
          size="small"
          color={isSinglePhoto ? gnssColor[photos[0].geoPositionSolution] ?? 'white' : undefined}
          title="Open Photo Details"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <CameraFilled fontSize={isSinglePhoto ? 24 : 32} />
        </IconButton>
      </Badge>
    </HtmlWithTheme>
  );
};
