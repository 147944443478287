import { useState } from 'react';
import type { MapControls } from 'three-stdlib/controls/OrbitControls';
import { CameraControls } from './CameraControls';
import { TransformControl } from './TransformControl';

export const Controls = (): JSX.Element => {
  const [mapControls, setMapControls] = useState<MapControls>();

  const mouseDownHandler = () => {
    if (!mapControls) return;
    mapControls.enabled = false;
  };

  const mouseUpHandler = () => {
    if (!mapControls) return;
    mapControls.enabled = true;
  };

  return (
    <>
      <CameraControls setMapControls={setMapControls} />
      <TransformControl onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler} />
    </>
  );
};
