import { useState } from 'react';

export const useQueue = <T>(initialValue: T[] = []) => {
  const [queue, setQueue] = useState(initialValue);

  const addToQueue = (...els: T[]) => {
    setQueue((prev) => [...prev, ...els]);
  };

  const removeFromQueue = (...els: T[]) => {
    setQueue((prev) => prev.filter((item) => !els.includes(item)));
  };

  return { queue, addToQueue, removeFromQueue };
};
