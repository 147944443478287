import type { RootState } from '@core/redux/interface';
import type { TileProps } from '@viewer3D/components/Map/Tile/types';
import { connect } from 'react-redux';
import { buildTiles } from './helper';

export interface MapStateToProps {
  tiles: TileProps[];
}

const mapStateToProps = ({
  viewer3d: { mapStyle, cameraViewport },
}: RootState): MapStateToProps => ({
  tiles: mapStyle === 'NONE' || !cameraViewport ? [] : buildTiles(mapStyle, cameraViewport),
});

export const connectComponent = connect(mapStateToProps);
