import { useSnapshot } from 'valtio';
import { Button, ToggleButton } from '@mui/material';
import { MeasurementHorizontal } from '@deepup/icons';
import { toggleMeasurement, toolsState } from './state';

export const MeasurementToggle = () => {
  const { measurementActive } = useSnapshot(toolsState);
  return (
    <ToggleButton
      component={Button}
      selected={measurementActive}
      value="measurement"
      onClick={toggleMeasurement}
      title="Toggle measurement mode"
      startIcon={<MeasurementHorizontal />}
    >
      Measurement
    </ToggleButton>
  );
};
