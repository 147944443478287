import { errorBox } from '@core/components/AlertBox';
import { useEffectOnce, useKeys } from '@core/logic';
import { useThree } from '@react-three/fiber';
import { Object3dNames } from '@viewer3D/types';
import { FC, useEffect, useRef } from 'react';
import type { Event } from 'three';
import { Frustum, Matrix4, Vector3 } from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { proxy, useSnapshot } from 'valtio';

type TransformCtrl = TransformControls & { worldPosition: Vector3; _offset: Vector3 };

type EventHandler = (event: Event) => void;

interface TransformControlState {
  objectName?: string;
  onAfterRotate?: EventHandler;
  onAfterTranslate?: EventHandler;
}

const state = proxy<TransformControlState>({
  objectName: undefined,
  onAfterTranslate: undefined,
  onAfterRotate: undefined,
});

export const setTransformControl = ({
  objectName,
  onAfterRotate,
  onAfterTranslate,
}: Partial<TransformControlState>): void => {
  state.objectName = objectName;
  state.onAfterTranslate = onAfterTranslate;
  state.onAfterRotate = onAfterRotate;
};

export const hideTransformControl = (): void => setTransformControl({});

const frustum = new Frustum();

export const TransformControl: FC<{
  onMouseDown: EventHandler;
  onMouseUp: EventHandler;
}> = ({ onMouseDown, onMouseUp }) => {
  const { onAfterTranslate, onAfterRotate, objectName } = useSnapshot(state);
  const { scene, invalidate, camera, gl } = useThree((three) => three);
  const instance = useRef(new TransformControls(camera, gl.domElement) as TransformCtrl);
  const [translatePressed, rotatePressed] = useKeys('t', 'r');

  const restrictTranslationToViewport = (callback: EventHandler | undefined) => (e: Event) => {
    const MAX_TRANSFORM_DISTANCE_M = 200;
    const transformCtrl = instance.current;
    const nextPosition = transformCtrl.worldPosition;

    if (nextPosition.equals(new Vector3(0, 0, 0)) || !transformCtrl.object) return;

    camera.updateMatrix();
    camera.updateMatrixWorld();
    camera.updateProjectionMatrix();

    transformCtrl.updateMatrix();
    transformCtrl.updateMatrixWorld();

    frustum.setFromProjectionMatrix(
      new Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse),
    );

    const distance = transformCtrl._offset.length();
    if (distance > MAX_TRANSFORM_DISTANCE_M) {
      transformCtrl.reset();
      invalidate();
      errorBox(
        `Objects cannot be moved more than ${MAX_TRANSFORM_DISTANCE_M}m at once. We made this restriction in order to prevent a glitch with the transform tool. 
        Don't worry, ${transformCtrl.object.name} will not be translated!`,
      );
      return;
    }

    if (frustum.containsPoint(nextPosition)) {
      callback?.(e);
    } else {
      // transform control is outside camera frustum
      transformCtrl.reset();
      invalidate();
      errorBox(
        `Transform control is outside viewport. This could have been due to a glitch. 
        Don't worry, ${transformCtrl.object.name} will not be translated!`,
      );
    }
  };

  const toggleTransformControl = () => {
    const transformCtrl = instance.current;

    const showTool = (show: boolean) => {
      transformCtrl.enabled = show;
      transformCtrl.visible = show;
    };

    if (
      transformCtrl.visible &&
      ((translatePressed && transformCtrl.mode === 'translate') ||
        (rotatePressed && transformCtrl.mode === 'rotate') ||
        (transformCtrl.mode === 'rotate' && !onAfterRotate) ||
        (transformCtrl.mode === 'translate' && !onAfterTranslate))
    ) {
      // User pressed same key again to deactivate transform tool
      // or transform tool was visible on object X and User clicked on object Y which doesn't support the current mode
      showTool(false);
      return;
    }

    if (
      (!translatePressed && !rotatePressed) ||
      (translatePressed && !onAfterTranslate) ||
      (rotatePressed && !onAfterRotate)
    ) {
      // user pressed transform key but no related event handler exists
      return;
    }

    // user pressed transform key and the correct event handler exists
    transformCtrl.mode = translatePressed ? 'translate' : 'rotate';
    showTool(true);
  };

  useEffect(toggleTransformControl, [
    rotatePressed,
    onAfterRotate,
    onAfterTranslate,
    translatePressed,
  ]);

  const addEventHandlers = () => {
    const transformCtrl = instance.current;

    const onAfterTransform = (e: Event) =>
      transformCtrl.visible &&
      (transformCtrl.mode === 'translate'
        ? restrictTranslationToViewport(onAfterTranslate)
        : onAfterRotate)?.(e);

    transformCtrl.addEventListener('mouseDown', onMouseDown);
    transformCtrl.addEventListener('mouseUp', onMouseUp);

    // always unregister to prevent other objects from transform previously attached objects
    transformCtrl.addEventListener('mouseUp', onAfterTransform);
    return () => {
      transformCtrl.removeEventListener('mouseUp', onAfterTransform);
    };
  };

  useEffect(addEventHandlers, [onAfterRotate, onAfterTranslate, onMouseDown, onMouseUp]);

  const init = () => {
    const transformCtrl = instance.current;
    transformCtrl.name = Object3dNames.Transform;
    transformCtrl.enabled = false;
    transformCtrl.visible = false;
    transformCtrl.addEventListener('change', invalidate);
    scene.add(transformCtrl);
  };

  useEffectOnce(init);

  const attachToolToObject = () => {
    const transformCtrl = instance.current;

    if (!transformCtrl) return;
    if (!objectName) {
      transformCtrl.object = undefined;
      return;
    }
    const nextObject = scene.getObjectByName(objectName);
    if (nextObject === transformCtrl.object) return;

    // object is new!
    transformCtrl.object = nextObject;
    transformCtrl.position.set(0, 0, 0);

    invalidate();
  };

  useEffect(attachToolToObject, [invalidate, scene, objectName]);

  // Since all available TransformControls JSX-components at this point have non-functioning mouse event listeners we
  // need to return null here and instead instantiate this Control the classic Three.js way.
  // TODO refactor into hook and maybe put it into @core/components/Tools as well!
  return null;
};
