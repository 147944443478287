import { lngLatToXY } from '@core/logic';

/**
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
 */
const tile2lng = (x: number, zoom: number): number => (x / 2 ** zoom) * 360 - 180;
const tile2lat = (y: number, zoom: number): number => {
  const n = Math.PI - (2 * Math.PI * y) / 2 ** zoom;
  return (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
};

export const tileNumberToUtm = (
  tileX: number,
  tileY: number,
  zoom: number,
): { x: number; y: number } =>
  lngLatToXY({
    lng: tile2lng(tileX, zoom),
    lat: tile2lat(tileY, zoom),
  });
