import { elementSelectors, elementTypeSelectors } from '@elements/redux';
import { editModeState } from '@projects/edit-modes';
import { attributeState } from '@projects/edit-modes/Attributes';
import { EditMode } from '@projects/edit-modes/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';
import {
  getTrenchRadiusForViewport,
  getTrenchSegmentDynamicFactor,
  TRENCH_SEGMENT_DEFAULT_FACTOR,
} from '../../helper';
import { getTrenchSegmentColor, getTrenchSegmentOpacity } from './helper';
import type { TrenchSegmentAttributes, TrenchSegmentProps } from './types';

export const useTrenchSegmentData = ({
  isMouseOverTrench,
  isTrenchSelected,
  segment,
  mode,
  selectedAttributeType,
}: Omit<TrenchSegmentProps, 'curve'>): TrenchSegmentAttributes => {
  const { layingType, surfaceType, splineElementIds, depth, width } = segment;
  const cameraViewport = useSelector(viewer3dSelectors.cameraViewport);
  const trenchSegmentFactor =
    editModeState.mode === EditMode.Element
      ? getTrenchSegmentDynamicFactor(splineElementIds.length)
      : TRENCH_SEGMENT_DEFAULT_FACTOR;
  const radius = getTrenchRadiusForViewport(trenchSegmentFactor, cameraViewport);
  const [firstSelectedElementId] = useSelector(viewer3dSelectors.selectedElementIds);
  const selectedElement = useSelector(elementSelectors.selectById(firstSelectedElementId));
  const elementType = useSelector(elementTypeSelectors.selectByElementId(firstSelectedElementId));
  const isSelectedElementInSegment = splineElementIds.includes(firstSelectedElementId);
  const currentAttributeState = useSnapshot(attributeState);

  const color = getTrenchSegmentColor(
    mode,
    isMouseOverTrench,
    isTrenchSelected,
    isSelectedElementInSegment,
    splineElementIds.length > 0,
    selectedAttributeType,
    layingType,
    surfaceType,
    depth,
    width,
    currentAttributeState,
    elementType,
  );

  const opacity = getTrenchSegmentOpacity(
    mode,
    isTrenchSelected,
    layingType,
    surfaceType,
    width,
    depth,
  );

  return {
    selectedElement,
    isSelectedElementInSegment,
    radius,
    color,
    opacity,
  };
};
