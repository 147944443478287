import { request } from '@core/api';
import { clearQueue, enqueue, processQueue } from '@core/api/queue';
import { errorBox } from '@core/components/AlertBox';
import { equalLengthAndValues } from '@core/logic';
import type { TrenchPrelabel } from '@core/types';
import { scanSelectors } from '@scans/redux';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { proxy, useSnapshot } from 'valtio';

// PRELABELED TRENCHES
export const prelabeledTrenchesState = proxy<{
  show: boolean;
  trenches: TrenchPrelabel[];
  fetchedScanIds: string[];
}>({
  show: false,
  trenches: [],
  fetchedScanIds: [],
});

export const togglePrelabeledTrenchVisibility = (): void => {
  prelabeledTrenchesState.show = !prelabeledTrenchesState.show;
};

export const hidePrelabeledTrenches = (): void => {
  prelabeledTrenchesState.show = false;
};

export const setPrelabels = (trenches: TrenchPrelabel[]): void => {
  prelabeledTrenchesState.trenches = trenches;
};

const setFetchedScanIds = (scanIds: string[]) => {
  prelabeledTrenchesState.fetchedScanIds = scanIds;
};

export const usePrelabeleTrenchesdState = () => useSnapshot(prelabeledTrenchesState);

export const useFetchPrelabeledTrenches = async (scanIds: string[]): Promise<void> => {
  const scans = useSelector(scanSelectors.selectByIds(scanIds));
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const { fetchedScanIds } = usePrelabeleTrenchesdState();

  const isScanIdListsUpdated =
    !scanIds.length || (scanIds.length && !equalLengthAndValues(scanIds, fetchedScanIds));

  if (isScanIdListsUpdated) {
    // clear queue when new scans are filtered or search has no result
    clearQueue('prelabels-queue');
  }

  if (!scanIds.length || equalLengthAndValues(scanIds, fetchedScanIds)) {
    return;
  }

  setFetchedScanIds(scanIds);
  const requests: Promise<TrenchPrelabel>[] = [];
  scans.forEach((scan) => {
    enqueue('prelabels-queue', () => {
      const queuedRequest = request<TrenchPrelabel>('GET', {
        path: [
          'projects',
          projectId!,
          'processingResults',
          scan.selectedProcessingResultId!,
          'prelabels',
          'trench',
        ],
      });
      requests.push(queuedRequest);
    });
  });

  try {
    processQueue('prelabels-queue');
    const prelabels = await Promise.all(requests);
    if (!prelabels.length) {
      errorBox('No prelabeled trenches found for visible scans');
    } else {
      setPrelabels(prelabels.flat());
    }
  } catch (error) {
    errorBox('Error fetching prelabeled trenches');
  }
};
