import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import { mapToUpdateT } from '@core/redux/factory/reducer';
import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import {
  getElementIdsForPoint,
  indexOfPoint,
  isPointInSegment,
  isPointOnTrench,
} from '@elements/helper';
import type { SplineElement } from '@elements/types';
import type { TrenchPoint } from '@trenches/components/types';
import { trenchSelectors } from '@trenches/redux';
import { isObject, uniq } from 'lodash';

export const {
  reducer: elementReducer,
  apiThunks: elementThunks,
  actions: elementBaseActions,
  selectors: elementBaseSelectors,
} = crudEntityFactory<SplineElement>({
  entity: ReduxEntities.SplineElements,
  removeKeys: [EntityRelationKey.Points],
  keysInParent: [EntityRelationKey.SplineElements],
});

export const elementActions = {
  ...elementBaseActions,
  updateElementEndpoints:
    (elementIds: string[], oldEndpointId: string, nextEndpointId: string) =>
    (dispatch: AppDispatch, getState: () => RootState): void => {
      const elements = elementBaseSelectors.selectByIds(elementIds)(getState());
      const nextElements = elements.map((e) => {
        const endPointToChange = e.fromPoint?.id === oldEndpointId ? 'fromPoint' : 'toPoint';
        const otherEndpoint = endPointToChange === 'fromPoint' ? 'toPoint' : 'fromPoint';

        if (!e[endPointToChange]) return e;

        const nextElement = {
          ...e,
          [endPointToChange]: {
            ...e[endPointToChange],
            id: nextEndpointId,
          },
        };
        const shouldRemoveElementFromAllTrenches =
          // element from and to points are the same! -> remove element from all trenches
          nextElement[endPointToChange]!.id === nextElement[otherEndpoint]!.id;

        if (shouldRemoveElementFromAllTrenches) {
          return {
            ...nextElement,
            fromPoint: undefined,
            toPoint: undefined,
          };
        }
        return nextElement;
      });
      dispatch(elementBaseActions.updateMany(mapToUpdateT(nextElements)));
    },
};

export const elementSelectors = {
  ...elementBaseSelectors,
  selectByTrenchPoints:
    (trenchPoints?: TrenchPoint[]) =>
    (state: RootState): SplineElement[] => {
      if (!trenchPoints) return [];
      // for each point get elements within that point
      const uniqElementIds = uniq(trenchPoints.flatMap(getElementIdsForPoint(state)));
      return elementSelectors.selectByIds(uniqElementIds)(state);
    },
  selectByPointId:
    (pointId?: string) =>
    (state: RootState): SplineElement[] => {
      if (!pointId) return [];

      const trenchesWithPoint = trenchSelectors.selectWithFilter(isPointOnTrench(pointId))(state);
      const splineElementIds: string[] = trenchesWithPoint.flatMap((trench) => {
        const pointIndexOnTrench = indexOfPoint(pointId)(trench);
        return trench.segments
          .filter(isPointInSegment(pointIndexOnTrench))
          .flatMap((segment) => segment.splineElementIds);
      });

      return uniq(splineElementIds)
        .map((id) => elementSelectors.selectById(id)(state)!)
        .filter(isObject);
    },
};
