import { toHumanReadable } from '@core/logic';
import { MenuItem, TextField } from '@mui/material';
import { useScanFilters } from '@projects/pages/detail/hooks';
import { qaStates } from '@scans/types';

export const filterableQAStates = ['ALL', ...qaStates] as const;

export const QAFilter = () => {
  const { qaState, setQaState, removeQaState } = useScanFilters();

  return (
    <TextField
      select
      size="small"
      value={qaState ?? 'ALL'}
      label="QA Status"
      fullWidth
      SelectProps={{
        MenuProps: {
          disablePortal: true,
        },
      }}
    >
      {filterableQAStates.map((status) => (
        <MenuItem
          key={status}
          value={status}
          onClick={status === 'ALL' ? removeQaState : () => setQaState(status)}
        >
          {toHumanReadable(status)}
        </MenuItem>
      ))}
    </TextField>
  );
};
