import { RefObject, useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = (refs: RefObject<HTMLElement>[]) => {
  const [intersections, setIntersections] = useState<IntersectionObserverEntry[]>([]);
  const observer = useRef(new IntersectionObserver(setIntersections));

  useEffect(() => {
    refs.forEach((ref) => {
      if (ref.current) {
        observer.current?.observe(ref.current);
      }
    });

    return () => {
      observer.current.disconnect();
    };
  }, [refs]);

  return intersections;
};
