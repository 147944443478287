import { AlertBox } from '@core/components/AlertBox';
import { MenuBar } from '@menu/components';
import { ProjectDetail, ProjectsList } from '@projects/pages';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export const Router = () => (
  <BrowserRouter>
    <MenuBar />
    <Routes>
      <Route path="/projects/list" element={<ProjectsList />} />
      <Route path="/projects/:projectId" element={<ProjectDetail />} />
      <Route path="*" element={<Navigate replace to="/projects/list" />} />
    </Routes>
    <AlertBox />
  </BrowserRouter>
);
