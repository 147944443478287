import type { ApiOptions, EntitiesPageResponse } from '@core/api/types';
import type { Entity } from '@core/types';
import type { Action, ActionReducerMapBuilder, Draft, EntityAdapter } from '@reduxjs/toolkit';
import type { ReduxEntities } from '../../enum';
import type { SliceState } from '../interfaces';

export const createApiReducer = <T extends Entity>(
  builder: ActionReducerMapBuilder<SliceState<T>>,
  entity: ReduxEntities,
  removeSubEntities: (entity: T) => T,
  adapter: EntityAdapter<T>,
): void => {
  const updateState = (
    state: Draft<SliceState<T>>,
    {
      payload,
      meta,
    }: {
      meta: { arg: ApiOptions };
      payload: T | T[] | EntitiesPageResponse<T>;
    },
  ) => {
    const entities: T[] = [];

    if (Array.isArray(payload)) {
      entities.push(...payload);
    }
    if ((payload as T).id) {
      entities.push(payload as T);
    }
    // check if payload is a pageble response (= has a content property)
    if ((payload as EntitiesPageResponse<T>).content) {
      entities.push(...((payload as EntitiesPageResponse<T>).content as T[]));
      state.page = {
        number: (payload as EntitiesPageResponse<T>).number,
        size: (payload as EntitiesPageResponse<T>).size,
        totalPages: (payload as EntitiesPageResponse<T>).totalPages,
        totalElements: (payload as EntitiesPageResponse<T>).totalElements,
      };
    }

    adapter.upsertMany(state as SliceState<T>, entities.map(removeSubEntities));
    if (meta.arg.id) adapter.removeOne(state as SliceState<T>, meta.arg.id);
  };

  builder.addMatcher(
    ({ type }: Action<string>) => type.startsWith(`${entity}/api`) && type.endsWith('fulfilled'),
    updateState as never,
  );
};
