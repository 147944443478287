import { xyToLngLat } from '@core/logic';

/**
 * https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29
 */
const calcTile = (val: number, zoom: number): number => Math.floor(val * 2 ** zoom);
const lng2tile = (lng: number, zoom: number): number => calcTile((lng + 180) / 360, zoom);
const lat2tile = (lat: number, zoom: number): number =>
  calcTile(
    (1 -
      Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) / Math.PI) /
      2,
    zoom,
  );

const lngLatToTile = (lng: number, lat: number, zoom: number): { x: number; y: number } => ({
  x: lng2tile(lng, zoom),
  y: lat2tile(lat, zoom),
});

export const utmToTileNumber = (x: number, y: number, zoom: number): { x: number; y: number } => {
  const { lng, lat } = xyToLngLat({ x, y });
  return lngLatToTile(lng, lat, zoom);
};
