import { Checkmark, Minus } from '@deepup/icons';
import { Chip } from '@mui/material';
import type { ReworkCategory } from '@scans/types';
import React from 'react';
import { toggleReworkCategory } from '../checkboxSelectionState';
import { categoryTitles } from './constants';
import type { ReworkCategoryState } from './utils';

export const ReworkChip = ({
  categoryState,
  name,
}: {
  categoryState: ReworkCategoryState;
  name: ReworkCategory;
}) => {
  switch (categoryState) {
    case 'Indeterminate':
      return (
        <Chip
          color="primary"
          label={categoryTitles[name]}
          onClick={() => toggleReworkCategory(name)}
          icon={<Minus fontSize="large" />}
        />
      );
    case 'Selected':
      return (
        <Chip
          color="primary"
          label={categoryTitles[name]}
          onClick={() => toggleReworkCategory(name)}
          icon={<Checkmark fontSize="large" />}
        />
      );
    case 'Default':
      return (
        <Chip
          variant="outlined"
          label={categoryTitles[name]}
          onClick={() => toggleReworkCategory(name)}
        />
      );
  }
};
