import {
  Arrows,
  isSegmentStartCloserToElementStart,
  useRenderPipeDirection,
} from '@trenches/components/PipeDirection';
import { trenchSelectors } from '@trenches/redux';
import { enableLayerOne, getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TubeBufferGeometry } from 'three';
import type { TrenchSegmentProps } from './types';
import { useTrenchSegmentData } from './useTrenchSegmentData';

export const TrenchSegment: FC<TrenchSegmentProps> = ({
  isMouseOverTrench,
  isTrenchSelected,
  segment,
  curve,
  mode,
  selectedAttributeType,
}) => {
  const { selectedElement, opacity, color, radius, isSelectedElementInSegment } =
    useTrenchSegmentData({
      isMouseOverTrench,
      isTrenchSelected,
      segment,
      mode,
      selectedAttributeType,
    });
  const renderPipeDirection = useRenderPipeDirection();

  const segmentCurve = useMemo(
    () =>
      curve.cloneWithPointIndexBoundaries(
        segment.sequenceRange.fromIndex,
        segment.sequenceRange.toIndex,
      ),
    [curve, segment.sequenceRange],
  );
  const geometry = useMemo(
    () =>
      segmentCurve &&
      new TubeBufferGeometry(segmentCurve, segmentCurve.getSegments(), radius, 8, false),
    [segmentCurve, radius],
  );
  const allTrenches = useSelector(trenchSelectors.selectAll);

  if (!geometry || !segmentCurve) return null;

  const isPipeDirectionAscending =
    isSelectedElementInSegment &&
    !!selectedElement &&
    !isSegmentStartCloserToElementStart(segment, selectedElement, allTrenches);

  const material = getCachedMaterial(color, opacity);
  const shouldRenderArrows = isSelectedElementInSegment && renderPipeDirection;
  return (
    <>
      {shouldRenderArrows && (
        <Arrows curve={segmentCurve} isPipeDirectionAscending={isPipeDirectionAscending} />
      )}
      <mesh
        name={`${Object3dNames.TrenchSegment}:${segment.fromPointId}-${segment.toPointId}`}
        material={material}
        geometry={geometry}
        renderOrder={getRenderOrder(Object3dNames.TrenchSegment)}
        onUpdate={enableLayerOne}
      />
    </>
  );
};
