import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useLocalStorage = <ValueType extends string>(
  storageKey: string,
  fallbackValue: ValueType | null = null,
): [ValueType | null, Dispatch<SetStateAction<ValueType | null>>] => {
  const [value, setValue] = useState<ValueType | null>(
    (localStorage.getItem(storageKey) as ValueType) ?? fallbackValue,
  );

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, value);
    }
  }, [value, storageKey]);

  return [value, setValue];
};
