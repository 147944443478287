import type { ScanFilters } from '@projects/pages/detail/hooks';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { useEffect } from 'react';
import { useConnect } from './useConnect';
import { togglePhotos } from './state';
import { PhotoIcon } from './PhotoIcon';

export const PhotoIcons = (params: Pick<ScanFilters, 'dateRange' | 'viewport'>) => {
  const {
    showPhotos,
    photosInViewport: { singles, clusters },
    cameraViewport,
    projectId,
    fetchAllPhotosForProject,
  } = useConnect(params);

  useEffect(() => {
    fetchAllPhotosForProject();
    togglePhotos(false);
  }, [projectId]);

  if (!showPhotos || !cameraViewport) return null;

  return (
    <group name={Object3dNames.Photos} renderOrder={getRenderOrder(Object3dNames.Photos)}>
      {clusters
        .filter((cluster) => cluster.length > 0)
        .map((cluster, index) => (
          <PhotoIcon
            key={`cluster-${index}-${cluster.map((p) => p.id).join('-')}`}
            photos={cluster}
            z={cameraViewport.z}
          />
        ))}
      {singles
        .filter((photo) => photo)
        .map((photo) => (
          <PhotoIcon key={photo.id} photos={[photo]} z={cameraViewport.z} />
        ))}
    </group>
  );
};
