import type { EntityAdapter } from '@reduxjs/toolkit';
import type { SliceState } from '../interfaces';

export const createUpsert =
  <T>(adapter: EntityAdapter<T>) =>
  (state: SliceState<T>, extractedEntities: T[]): void => {
    if (
      !extractedEntities ||
      typeof extractedEntities !== 'object' ||
      !Array.isArray(extractedEntities) ||
      extractedEntities.length < 1
    )
      return;
    adapter.upsertMany(state, extractedEntities);
  };
