import { request } from '@core/api';
import type { ApiOptions } from '@core/api/types';
import type { Entity } from '@core/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ReduxEntities } from '../../enum';
import type { ApiThunk, ApiThunks } from './api.interfaces';

export const createApiThunks = <T extends Entity>(name: ReduxEntities): ApiThunks<T> => {
  const apiThunk = <Request, Response>(
    actionType: string,
    action: (req: Request) => Promise<Response>,
  ): ApiThunk<Request, Response> =>
    createAsyncThunk<Response, Request>(`${name}/api/${actionType}`, (req) => action(req));

  return {
    getMany: apiThunk('get/many', (req: ApiOptions) => request<T[]>('GET', req)),
    getOne: apiThunk('get/one', (req) => request('GET', req)),
    post: apiThunk('post', (req) => request('POST', req)),
    put: apiThunk('put', (req) => request('PUT', req)),
    patch: apiThunk('patch', (req) => request('PATCH', req)),
    delete: apiThunk('delete', (req) => request('DELETE', req)),
    create: (actionType, httpMethod, mapResponse) =>
      apiThunk(actionType, (req) => request(httpMethod, req, mapResponse)),
  };
};
