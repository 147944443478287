/* eslint-disable react/jsx-props-no-spreading */

import { equalLengthAndValues, toHumanReadable, useKeys } from '@core/logic';
import { Divider, Paper, Stack } from '@mui/material';
import { DepthColors, PointColors, WidthColors } from '@trenches/colors';
import {
  Depth,
  Width,
  attributeTypes,
  biggerDepths,
  biggerWidths,
  defaultDepths,
  defaultWidths,
  layingTypes,
  smallerDepths,
  surfaceTypes,
} from '@trenches/types';
import { FC, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { LegendCollapseBtn } from './LegendCollapseBtn';
import { LegendItems } from './LegendItems';
import { LegendNav } from './LegendNav';
import {
  attributeState,
  setDepth,
  setLayingType,
  setSelectedAttributeType,
  setSurfaceType,
  setWidth,
  toggleShowOnlyCurrentDepth,
  toggleShowOnlyCurrentWidth,
} from './state';
import type { LegendItemsDataProps } from './types';

export const AttributeLegend: FC = () => {
  const { layingType, surfaceType, selectedAttributeType, depth, width } =
    useSnapshot(attributeState);
  const [soilDisplacementKeyPressed, removeKeyPressed, attributeSwitchKey] = useKeys('s', 'r', '3');

  const [visibleDepths, setVisibleDepths] = useState<Depth[]>([...defaultDepths]);
  const [visibleWidths, setVisibleWidths] = useState<Width[]>([...defaultWidths]);

  const areSmallerDepthsVisible = visibleDepths.includes(smallerDepths[0]);
  const areBiggerDepthsVisible = visibleDepths.includes(biggerDepths[0]);

  const areDefaultWidthsVisible = equalLengthAndValues(visibleWidths, [...defaultWidths]);

  const setToSoilDisplacement = (): void => setLayingType('SOIL_DISPLACEMENT');

  const legendItemsProps: LegendItemsDataProps = {
    SURFACE_TYPE: {
      attributeList: surfaceTypes,
      attribute: surfaceType,
      callback: setSurfaceType as unknown as (surfaceType: string | null) => void,
      colors: PointColors,
      selected: surfaceType === null,
      onRemove: () => setSurfaceType(null),
    },
    LAYING_TYPE: {
      attributeList: layingTypes,
      attribute: layingType,
      callback: setLayingType as unknown as (layingType: string | null) => void,
      colors: PointColors,
      selected: layingType === null,
      onRemove: () => setLayingType(null),
    },
    DEPTH: {
      attributeList: visibleDepths,
      attribute: depth,
      callback: setDepth as unknown as (depth: string | null) => void,
      colors: DepthColors,
      selected: depth === null,
      onRemove: () => setDepth(null),
    },
    WIDTH: {
      attributeList: visibleWidths,
      attribute: width,
      callback: setWidth as unknown as (width: string | null) => void,
      colors: WidthColors,
      selected: width === null,
      onRemove: () => setWidth(null),
    },
  };

  const setToRemoveAttribute = legendItemsProps[selectedAttributeType].onRemove;

  const nextAttributeType = () => {
    const currentAttributeIndex = attributeTypes.indexOf(selectedAttributeType);
    if (currentAttributeIndex === attributeTypes.length - 1) {
      setSelectedAttributeType(attributeTypes[0]);
    } else {
      setSelectedAttributeType(attributeTypes[currentAttributeIndex + 1]);
    }
  };

  const previousAttributeType = () => {
    const currentAttributeIndex = attributeTypes.indexOf(selectedAttributeType);
    if (currentAttributeIndex === 0) {
      setSelectedAttributeType(attributeTypes[attributeTypes.length - 1]);
    } else {
      setSelectedAttributeType(attributeTypes[currentAttributeIndex - 1]);
    }
  };

  const toggleDepthItems = (isSmaller: boolean) => {
    if (isSmaller) {
      setVisibleDepths([
        ...(areSmallerDepthsVisible ? [] : smallerDepths),
        ...defaultDepths,
        ...(areBiggerDepthsVisible ? biggerDepths : []),
      ]);
    } else {
      setVisibleDepths([
        ...(areSmallerDepthsVisible ? smallerDepths : []),
        ...defaultDepths,
        ...(areBiggerDepthsVisible ? [] : biggerDepths),
      ]);
    }
  };

  const toggleWidthItems = () =>
    setVisibleWidths([...defaultWidths, ...(areDefaultWidthsVisible ? biggerWidths : [])]);

  const toggleVisibility = () => {
    if (selectedAttributeType === 'DEPTH') {
      toggleShowOnlyCurrentDepth();
    }
    if (selectedAttributeType === 'WIDTH') {
      toggleShowOnlyCurrentWidth();
    }
  };

  useEffect(() => {
    if (!soilDisplacementKeyPressed) return;
    setToSoilDisplacement();
  }, [soilDisplacementKeyPressed]);

  useEffect(() => {
    if (attributeSwitchKey) {
      nextAttributeType();
    }
  }, [attributeSwitchKey]);

  useEffect(() => {
    if (!removeKeyPressed) return;
    setToRemoveAttribute();
  }, [removeKeyPressed]);

  return (
    <Stack gap={1} width={250} position="absolute" bottom={24} right={24}>
      <Paper elevation={2}>
        {selectedAttributeType === 'DEPTH' && (
          <>
            <LegendCollapseBtn
              onClick={() => toggleDepthItems(true)}
              label="< 45cm"
              collapsed={areSmallerDepthsVisible}
            />
            <Divider />
          </>
        )}

        <LegendItems
          toggleVisibility={toggleVisibility}
          currentType={selectedAttributeType}
          {...legendItemsProps[selectedAttributeType]}
        />

        {selectedAttributeType === 'DEPTH' && (
          <>
            <Divider />
            <LegendCollapseBtn
              onClick={() => toggleDepthItems(false)}
              label="> 100cm"
              collapsed={!areBiggerDepthsVisible}
            />
          </>
        )}

        {selectedAttributeType === 'WIDTH' && (
          <>
            <Divider />
            <LegendCollapseBtn
              onClick={toggleWidthItems}
              label="> 45cm"
              collapsed={areDefaultWidthsVisible}
            />
          </>
        )}
      </Paper>
      <LegendNav
        label={toHumanReadable(selectedAttributeType)}
        onPrevClick={previousAttributeType}
        onNextClick={nextAttributeType}
      />
    </Stack>
  );
};
