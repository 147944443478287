import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { MeasurementToggle } from '@core/components';
import { LayersOutline } from '@deepup/icons';
import { useState } from 'react';
import { ExternalDataToggle } from './ExternalDataToggle';
import { TrenchToggle } from './TrenchToggle';
import { ScansToggle } from './ScansToggle';
import { PhotosToggle } from './PhotosToggle';
import { MapStyleSelector } from './MapStyleSelector';
import { PrelabelsToggle } from './PrelabelsToggle';

export const MapLayersBox = () => {
  const [open, setOpen] = useState(true);
  return (
    <Accordion
      elevation={2}
      disableGutters
      expanded={open}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary onClick={() => setOpen(!open)}>
        <Stack direction="row" gap={2} alignItems="center">
          <LayersOutline fontSize={24} />
          <Typography variant="body1" fontWeight="bold" hidden={!open}>
            Map Layers
          </Typography>
        </Stack>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Stack gap={2} divider={<Divider sx={{ mx: -2 }} />}>
          <Stack gap={1}>
            <PrelabelsToggle />
            <TrenchToggle />
            <ExternalDataToggle />
            <ScansToggle />
            <PhotosToggle />
          </Stack>
          <MapStyleSelector />
          <MeasurementToggle />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
