import { errorBox } from '@core/components/AlertBox';
import { mapToUpdateT } from '@core/redux/factory/reducer';
import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import { isSinglePointElement } from '@elements/helper';
import { elementActions, elementSelectors, elementTypeSelectors } from '@elements/redux';
import { pipeConnectorSelectors } from '@elements/redux/connectors.slice';
import { markerSphereSelectors } from '@elements/redux/markers.slice';
import { pipeFittingSelectors } from '@elements/redux/pipe-fitting.slice';
import { pointElementSelectors, pointElementThunks } from '@elements/redux/point-element.slice';
import type { SplineElement } from '@elements/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { trenchSelectors, trenchThunks } from '@trenches/redux';
import type { Trench } from '@trenches/types';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import { connect } from 'react-redux';
import { ELEMENT_POINT_FACTOR, getTrenchRadiusForViewport } from '../../helper';
import type { DispatchProps, ElementPointsProps, MergedProps, StateProps } from './types';

const mapStateToProps = (state: RootState, { pointTrenchMap }: ElementPointsProps): StateProps => {
  const selectedPointId = viewer3dSelectors.selectedPointId(state);
  const selectedElementIds = viewer3dSelectors.selectedElementIds(state);
  const [firstSelectedElementId] = selectedElementIds;
  const cameraViewport = viewer3dSelectors.cameraViewport(state);
  const radius = getTrenchRadiusForViewport(ELEMENT_POINT_FACTOR, cameraViewport);
  const getElementsInPoint = (pointId: string) => [
    ...elementSelectors.selectByTrenchPoints(pointTrenchMap[pointId])(state),
    ...pointElementSelectors.selectWithFilter((p) => p.pointId === pointId)(state),
  ];
  const projectId = viewer3dSelectors.selectedProjectId(state);

  return {
    projectId,
    selectedElement:
      pointElementSelectors.selectById(firstSelectedElementId)(state) ??
      elementSelectors.selectById(firstSelectedElementId)(state),
    selectedElementType: elementTypeSelectors.selectByElementId(firstSelectedElementId)(state),
    selectedPointId,
    selectedElementIds,
    radius,
    getCommonTrenchId: (pointIdA, pointIdB) =>
      trenchSelectors.getByCommonPoints(pointIdA, pointIdB)(state)?.id,
    getElementCount: (pointId) => getElementsInPoint(pointId).length,
    getElementsInPoint,
    isSelectedElementInPoint: (pointId) =>
      !!firstSelectedElementId &&
      !!getElementsInPoint(pointId).find((e) => selectedElementIds.includes(e.id)),
    isSelectedElementSinglePoint: !!pointElementSelectors.selectById(firstSelectedElementId)(state),
    hasPointElement: (pointId) =>
      !!pointElementSelectors.findFirst((p) => p.pointId == pointId)(state),
    hasPipeFitting: (pointId) =>
      !!pipeFittingSelectors.findFirst((e) => e.pointId === pointId)(state),
    hasConnector: (pointId) =>
      !!pipeConnectorSelectors.findFirst((e) => e.pointId === pointId)(state),
    hasMarker: (pointId) => !!markerSphereSelectors.findFirst((e) => e.pointId === pointId)(state),
    isPointElementAlreadyLabeled: (elementId) =>
      !!pointElementSelectors.selectById(elementId)(state)?.pointId,
    getPointIdByElementId: (elementId) =>
      pointElementSelectors.selectById(elementId)(state)?.pointId,
    hasPassedPlusAndIsToPoint: (elementId, pointId) => {
      const element = elementSelectors.selectById(elementId)(state);
      return !!element && !!element.houseLeadVariant && element.toPoint?.id === pointId;
    },
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  dispatch,
});

const mergeProps = (
  { projectId, selectedElement, getCommonTrenchId, getElementsInPoint, ...stateProps }: StateProps,
  { dispatch, ...dispatchProps }: DispatchProps,
  ownProps: ElementPointsProps,
): MergedProps => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    selectFirstElementInPoint: (pointId) => {
      const [firstElement] = getElementsInPoint(pointId);
      if (!firstElement) return;
      dispatch(viewer3dActions.setSelectedElementIds([firstElement.id]));
    },
    selectPoint: (pointId) => {
      dispatch(viewer3dActions.setSelectedPointId(pointId));
    },
    extendSelectedElements: async (toPointId) => {
      if (!stateProps.selectedElementIds.length || !stateProps.selectedPointId) {
        throw new Error('Cannot extend element since either no point or no element selected.');
      }

      const trenchId = getCommonTrenchId(toPointId, stateProps.selectedPointId);

      if (!trenchId) {
        throw new Error('From and to point have no trench in common. Cannot extend.');
      }

      const { payload, meta } = await dispatch(
        trenchThunks.extendMultipleElements({
          path: ['projects', projectId!, 'trenches', trenchId, 'splineElements', 'extend'],
          body: {
            elementIds: stateProps.selectedElementIds,
            toPointId,
            endPointId: stateProps.selectedPointId,
          },
        }),
      );
      if (meta.requestStatus === 'rejected' || !payload) {
        return;
      }
      const allElements = (payload as Trench).segments.flatMap((s) => s.splineElements);
      dispatch(elementActions.updateMany(mapToUpdateT(allElements)));
    },
    labelSelectedPointElement: async (pointId) => {
      const [singlePointElementId] = stateProps.selectedElementIds;
      // prevent moving point element from one point to another
      if (pointId !== null && stateProps.isPointElementAlreadyLabeled(singlePointElementId)) {
        errorBox(
          'The selected single point element has already been labeled. Please first remove it from the point and then label it again.',
        );
        return;
      }
      await dispatch(
        pointElementThunks.patch({
          path: ['projects', projectId!, 'pointElements', singlePointElementId],
          body: { pointId },
        }),
      );
    },
    addSelectedElementToTrench: async (fromPointId, toPointId) => {
      if (!stateProps.selectedElementIds.length) return;

      const trenchId = getCommonTrenchId(fromPointId, toPointId);

      if (!trenchId) {
        throw new Error('From and to points have no trench in common. Cannot label element.');
      }

      const { payload } = await dispatch(
        trenchThunks.addElementOnTrench({
          path: ['projects', projectId!, 'trenches', trenchId, 'splineElements'],
          body: {
            fromPointId,
            toPointId,
            elementId: stateProps.selectedElementIds[0],
          },
        }),
      );
      const allElements = (payload as Trench).segments.flatMap((s) => s.splineElements);
      dispatch(elementActions.updateMany(mapToUpdateT(allElements)));
    },
    shrinkSelectedElements: async (point1Id, point2Id) => {
      if (!selectedElement) return;

      if (isSinglePointElement(selectedElement) && point1Id === point2Id) {
        errorBox('Please remove single point elements from element list left-hand side.');
        return;
      }

      const trenchId = getCommonTrenchId(point1Id, point2Id);

      if (!trenchId) {
        throw new Error('From and to points have no trench in common. Cannot shrink element.');
      }

      const { fromPoint, toPoint } = selectedElement as SplineElement;

      if (!fromPoint || !toPoint) {
        throw new Error(
          'Selected element cannot be removed from a trench since it is not yet labeled on any trench.',
        );
      }

      const endPointId = [fromPoint.id, toPoint.id].find((p) => p === point1Id || p === point2Id);

      if (!endPointId) {
        throw new Error(
          "None of the selected points to remove are one of the selected element's endpoints. Shrink an element only from its ends!",
        );
      }

      // Use the other point id as toPointId.
      const toPointId = endPointId === point1Id ? point2Id : point1Id;

      await dispatch(
        trenchThunks.shrinkMultipleElements({
          path: ['projects', projectId!, 'trenches', trenchId, 'splineElements', 'shrink'],
          body: {
            elementIds: stateProps.selectedElementIds,
            endPointId,
            toPointId,
          },
        }),
      );

      dispatch(
        // if elements are removed from a trench completely we need to update their fromPoints and toPoints.
        elementActions.updateElementEndpoints(
          stateProps.selectedElementIds,
          endPointId,
          toPointId,
        ) as unknown as PayloadAction,
      );
    },
  };
};

export const connectComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps);
