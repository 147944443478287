import {
  getContinuousNumber,
  resetContinuousNumber,
  splitArray,
  toHumanReadable,
} from '@core/logic';
import { isSinglePointElement } from '@elements/helper';
import type { PointElement, SplineElement, SplineElementType } from '@elements/types';

export interface NetworkHierarchyView {
  id: string;
  name: string;
  elementId?: string;
  children?: NetworkHierarchyView[];
}
export type El = PointElement | SplineElementWithType;
export type NetworkHierarchy = [El, NetworkHierarchy[]];
type SplineElementWithType = SplineElement & { type: SplineElementType };

/**
 * Builds a simple hierarchy of parent and child elements, based on the parentPointElementId-prop.
 */
export const buildHierarchy = (parentId: string | null, elements: El[]): NetworkHierarchy[] => {
  if (elements.length === 0) {
    return [];
  }
  const [children, rest] = splitArray(elements, (e) => e.parentPointElementId === parentId);
  return children.map((e) => [e, buildHierarchy(e.id, rest)]);
};

/**
 * Recursively groups children of parents with the same name.
 * This is needed to avoid duplicate names in the tree view.
 */
const groupByNodeName = (hierarchy: NetworkHierarchyView[]): NetworkHierarchyView[] => {
  // also sort so that the tree view is always in the same order, even if items get deleted/added:
  const [firstNode, ...rest] = hierarchy.sort((a, b) => a.name.localeCompare(b.name));

  if (!firstNode) {
    return [];
  }

  if (!firstNode.children?.length || !!firstNode.elementId) {
    // either a leaf (no children) or a single point element
    return [firstNode, ...groupByNodeName(rest)];
  }

  const [nodesWithSameName, nodesWithDifferentName] = splitArray(
    rest,
    ({ name }) => name === firstNode.name,
  );
  const groupedChildren = [firstNode, ...nodesWithSameName].flatMap((t) => t.children ?? []);
  return [
    {
      ...firstNode,
      children: groupByNodeName(groupedChildren), // group grandchildren as well
    },
    ...groupByNodeName(nodesWithDifferentName),
  ];
};

/**
 * Maps the hierarchy to a tree view.
 */
const mapToTreeView = (hierarchy: NetworkHierarchy[]): NetworkHierarchyView[] => {
  const unnormalizedTreeView = hierarchy.map(([e, children]) =>
    isSinglePointElement(e)
      ? {
          id: getContinuousNumber(),
          name: toHumanReadable(e.kind) + 's',
          children: [
            {
              id: getContinuousNumber(),
              name: toHumanReadable(e.subtype),
              children: [
                {
                  id: e.id,
                  name: e.name!,
                  elementId: e.id,
                  children: mapToTreeView(children),
                },
              ],
            },
          ],
        }
      : {
          id: getContinuousNumber(),
          name: toHumanReadable(e.type.kind) + 's',
          children: [
            {
              id: getContinuousNumber(),
              name: e.type.name,
              children: [
                {
                  id: e.id,
                  elementId: e.id,
                  name: e.name!,
                },
              ],
            },
          ],
        },
  );

  return groupByNodeName(unnormalizedTreeView);
};

export const buildElementTrees = (hierarchy: NetworkHierarchy[]): NetworkHierarchyView[] => {
  const [assigned, unassigned] = splitArray(hierarchy, ([, children]) => children.length > 0);
  resetContinuousNumber();
  return [
    { id: 'assigned-elements', name: 'Assigned', children: mapToTreeView(assigned) },
    {
      id: 'unassigned-elements',
      name: 'Unassigned',
      children: mapToTreeView(unassigned),
    },
  ];
};

export const buildHierarchyViewFromElements = (elements: El[]): NetworkHierarchyView[] =>
  buildElementTrees(buildHierarchy(null, elements));

const findPathToId = (
  hierarchy: NetworkHierarchyView,
  searchedId: string,
  path: string[],
): string[] => {
  if (!hierarchy.children && hierarchy.id !== searchedId) {
    return [];
  }
  if (hierarchy.id === searchedId) {
    return path;
  }
  return hierarchy.children!.flatMap((child) =>
    !child.children?.length && child.id === searchedId
      ? path // exclude leafs from the path!
      : findPathToId(child, searchedId, [...path, child.id]),
  );
};

/**
 * Find the selected element in the hierarchy and create a breadcrumb path to it.
 */
export const treesToBreadcrumbs = (
  hierarchies: NetworkHierarchyView[],
  searchedId: string,
): string[] =>
  hierarchies.flatMap((hierarchy) => findPathToId(hierarchy, searchedId, [hierarchy.id]));
