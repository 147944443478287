import { useKey } from '@core/logic';
import { useDispatch } from 'react-redux';
import { viewer3dActions } from '@viewer3D/redux';
import { useEffect } from 'react';

export const useFocusEntity = () => {
  const fPressed = useKey('f');
  const dispatch = useDispatch();

  useEffect(() => {
    fPressed && dispatch(viewer3dActions.focusSelectedEntity);
  }, [fPressed]);
};
