import { PointElementSubtype } from '@elements/types';
import { ExportTarget } from '@projects/types';

type NamingScheme = Record<PointElementSubtype, RegExp>;
/**
 * These naming schemes are customer-specific and are used to validate the User-input for point element names of certain subtypes.
 */
export const namingSchemes: Record<ExportTarget, Partial<NamingScheme>> = {
  [ExportTarget.Telekom]: {
    HAUPTKABEL: /^\d+R\/\d+$/,
    NETZVERTEILER: /^\d+V\d+$/,
  },
};
