import { PinOutline } from '@deepup/icons';
import { projectsHistorySelectors } from '@menu/redux';
import { useSelector } from 'react-redux';
import { projectSelectors } from '@projects/redux';
import { ProjectsTable } from './ProjectsTable';
import { ProjectListSection } from './ProjectListSection';

export const ProjectListPinned = ({ search }: { search: string }) => {
  const pinned = useSelector(projectsHistorySelectors.selectPinnedProjects);
  const projects = useSelector(projectSelectors.selectByIds(pinned.map((p) => p.id)));

  if (projects.length === 0) return null;

  return (
    <div>
      <ProjectListSection
        primaryText="Pinned Projects"
        secondaryText={projects.length}
        Icon={PinOutline}
      />
      <ProjectsTable rows={projects} hideFooter search={search} />
    </div>
  );
};
