import type { MapboxStyle } from '@core/types';
import type { BoundingBoxWithHeight } from '@viewer3D/types';
import type { TileProps } from '../Tile/types';
import { utmToTileNumber } from './utm-to-tile-number';
import { viewportWidthToZoom } from './viewport-width-to-zoom';

export const buildTiles = (
  style: MapboxStyle,
  { minX, minY, maxX, maxY, z }: BoundingBoxWithHeight,
): TileProps[] => {
  const zoom = viewportWidthToZoom(maxX - minX);
  const minTile = utmToTileNumber(minX, minY, zoom);
  const maxTile = utmToTileNumber(maxX, maxY, zoom);
  const tiles: TileProps[] = [];
  const minTileX = Math.min(minTile.x, maxTile.x) - 1;
  const maxTileX = Math.max(minTile.x, maxTile.x) + 2;
  const minTileY = Math.min(minTile.y, maxTile.y) - 1;
  const maxTileY = Math.max(minTile.y, maxTile.y) + 2;
  for (let x = minTileX; x < maxTileX; x += 1) {
    for (let y = minTileY; y < maxTileY; y += 1) {
      tiles.push({
        style,
        zoom,
        tileX: x,
        tileY: y,
        positionZ: z,
      });
    }
  }
  return tiles;
};
