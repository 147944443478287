import { Button, FormControlLabel, Radio } from '@mui/material';
import { useScanFilters } from '@projects/pages/detail/hooks';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { ArrowsCircle1Ccw } from '@deepup/icons';
import { infoBox } from '@core/components/AlertBox';

export const ViewportFilter = () => {
  const cameraViewport = useSelector(viewer3dSelectors.cameraViewport);
  const { viewport, setViewport } = useScanFilters();

  const isViewportModeActive = !!viewport;

  const updateViewport = () => {
    if (!viewport && !cameraViewport) {
      // TODO: initially jump the camera to project bbox if available and remove this info!
      infoBox(
        'The camera is currently at (0,0). Please first load scans by setting a valid date range so the camera can jump to the project.',
      );
    }
    if (!cameraViewport) return;
    setViewport(cameraViewport);
  };

  return (
    <FormControlLabel
      onClick={updateViewport}
      control={<Radio checked={isViewportModeActive} />}
      label={
        <Button variant="outlined" color="secondary" endIcon={<ArrowsCircle1Ccw />}>
          Filter by Viewport
        </Button>
      }
    />
  );
};
