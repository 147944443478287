import { useEffect, useState } from 'react';
import { DoubleSide, MeshBasicMaterial, Texture, TextureLoader } from 'three';

const materialCache: Record<string, Record<number, MeshBasicMaterial>> = {};

const textureLoader = new TextureLoader();
const loadTexture = async (path: string) =>
  new Promise((resolve, reject) => {
    textureLoader.load(path, resolve, undefined, reject);
  });

export const getCachedMaterial = (color: string, opacity: number): MeshBasicMaterial => {
  let material = materialCache[color] && materialCache[color][opacity];
  if (!material) {
    material = new MeshBasicMaterial({
      transparent: opacity < 1,
      color: color,
      opacity,
    });
    materialCache[color] = {
      ...materialCache[color],
      [opacity]: material,
    };
  }
  return material;
};

export const getCachedMaterialAsync = async (
  textureUrl: string,
  opacity: number,
): Promise<MeshBasicMaterial> => {
  let material = materialCache[textureUrl] && materialCache[textureUrl][opacity];
  if (!material) {
    const texture = (await loadTexture('/assets/arrowWhite.png')) as Texture;
    if (!texture) throw new Error('no texture found');
    material = new MeshBasicMaterial({
      map: texture as Texture,
      side: DoubleSide,
      transparent: true,
      depthTest: false,
      opacity,
    });
    materialCache[textureUrl] = {
      ...materialCache[textureUrl],
      [opacity]: material,
    };
  }
  return material;
};

export const useLoadTexturedMaterial = (
  textureUrl: string,
  opacity: number,
): MeshBasicMaterial | undefined => {
  const [material, setMaterial] = useState<MeshBasicMaterial | undefined>(undefined);
  useEffect(() => {
    getCachedMaterialAsync(textureUrl, opacity).then(setMaterial);
  }, [textureUrl, opacity]);
  return material;
};
