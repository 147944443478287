import { environment } from '@core/environment';
import { useCachedUrl } from '@core/logic';
import { mapboxStyleLookup } from '@core/types';
import { useEffect, useState } from 'react';
import { Texture, TextureLoader } from 'three';
import type { TileProps } from '../types';

const loader = new TextureLoader();

const buildTileUrl = ({ tileX, tileY, zoom, style }: TileProps): string => {
  const styleString = mapboxStyleLookup[style] as string;
  return `https://api.mapbox.com/styles/v1/mapbox/${styleString}/tiles/${zoom}/${tileX}/${tileY}?access_token=${environment.mapBox.accessToken}`;
};

export const useTileTexture = (tile: TileProps): Texture | undefined => {
  const tileUrl = buildTileUrl(tile);
  const textureUrl = useCachedUrl(tileUrl);
  const [texture, setTexture] = useState<Texture>();

  useEffect(() => {
    if (!textureUrl) return;
    loader.loadAsync(textureUrl).then(setTexture).catch(console.error);
  }, [textureUrl]);

  return texture;
};
