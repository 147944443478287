import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { MarkerSphere } from '@projects/pages/detail/MarkerSphere/types';

export const {
  reducer: markerSphereReducer,
  apiThunks: markerSphereThunks,
  actions: markerSphereActions,
  selectors: markerSphereSelectors,
} = crudEntityFactory<MarkerSphere>({
  entity: ReduxEntities.MarkerSpheres,
  removeKeys: [],
  keysInParent: [EntityRelationKey.MarkerSpheres],
});
