import { useKey } from '@core/logic';
import { useEffect } from 'react';
import { toggleMeasurement } from './state';

/**
 * Makes tools like measurement globally usable via key combinations.
 * Hook can be extended with new tool functionality.
 */
export const useTools = (): void => {
  const measurementPressed = useKey('m', true);

  useEffect(() => {
    measurementPressed && toggleMeasurement();
  }, [measurementPressed]);
};
