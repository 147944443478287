import { ChevronsDown } from '@deepup/icons';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSnapshot } from 'valtio';
import { checkboxSelectionState } from '../checkboxSelectionState';
import { orderedAttributeTitles, orderedProblemTitles } from './constants';
import { ReworkChip } from './ReworkStatusChip';
import { getCategoriesMap } from './utils';

export const ReworkStatus = () => {
  const { checkedScans } = useSnapshot(checkboxSelectionState);
  const categoriesMap = getCategoriesMap(checkedScans);

  return (
    <Accordion disableGutters square elevation={12}>
      <AccordionSummary expandIcon={<ChevronsDown fontSize={24} />}>
        <Typography variant="body1">Select problem areas</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1} mx={-0.5}>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {orderedProblemTitles.map((name) => (
              <ReworkChip key={name} name={name} categoryState={categoriesMap[name]} />
            ))}
          </Stack>
          <Typography mx={0.5} variant="body2">
            Attributes:
          </Typography>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {orderedAttributeTitles.map((name) => (
              <ReworkChip key={name} name={name} categoryState={categoriesMap[name]} />
            ))}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
