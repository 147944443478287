import { markerSphereSelectors, markerSphereThunks } from '@elements/redux/markers.slice';
import { Stack, Switch, Typography } from '@mui/material';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useDispatch, useSelector } from 'react-redux';

export const MarkerSphere = ({ selectedPointId }: { selectedPointId: string | undefined }) => {
  const dispatch = useDispatch();
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const [marker] = useSelector(markerSphereSelectors.selectByProps({ pointId: selectedPointId }));
  const addMarker = () => {
    if (!projectId || !selectedPointId) return;
    dispatch(
      markerSphereThunks.post({
        path: ['projects', projectId, 'markers'],
        body: { pointId: selectedPointId, projectId },
      }),
    );
  };
  const removeMarker = () => {
    if (!marker || !projectId) return;
    dispatch(
      markerSphereThunks.delete({
        id: marker.id,
        path: ['projects', projectId, 'markers', marker.id],
      }),
    );
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>Has marker sphere</Typography>
      <Switch
        edge="end"
        color="primary"
        onClick={marker ? removeMarker : addMarker}
        checked={!!marker}
      />
    </Stack>
  );
};
