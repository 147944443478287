import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { PointElement } from '@elements/types';

export const {
  reducer: pointElementReducer,
  apiThunks: pointElementThunks,
  actions: pointElementActions,
  selectors: pointElementSelectors,
} = crudEntityFactory<PointElement>({
  entity: ReduxEntities.PointElements,
  removeKeys: [],
  keysInParent: [EntityRelationKey.PointElements],
});
