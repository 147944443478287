import { getAngle, pairNeighborsNonOverlapping } from '@core/logic';
import { useThree } from '@react-three/fiber';
import type { DeepupCatmullRomCurve } from '@trenches/components/Trench/DeepupCatmullRomCurve';
import { getRenderOrder, useLoadTexturedMaterial } from '@viewer3D/helper';
import { plane } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { MeshBasicMaterial, Vector3 } from 'three';

export const Arrow: FC<{
  position: Vector3;
  lookAt: Vector3;
  size: number;
  material: MeshBasicMaterial;
}> = ({ position, lookAt, size, material }) => {
  return (
    <mesh
      name={`${Object3dNames.PipeDirection}:${JSON.stringify(position)}`}
      material={material}
      geometry={plane}
      position={position}
      rotation={[0, 0, getAngle(position, lookAt)]}
      scale={[size, size, size]}
    />
  );
};

export const Arrows: FC<{ curve: DeepupCatmullRomCurve; isPipeDirectionAscending: boolean }> = ({
  curve,
  isPipeDirectionAscending,
}) => {
  const { camera } = useThree();
  const mat = useLoadTexturedMaterial('/assets/arrowWhite.png', 1);

  const arrowSize = (1 / camera.zoom) * 50;

  const linearZoom = Math.sqrt(camera.zoom);
  const curveLen = curve.getLength();
  const step = 20 / (curveLen * linearZoom);
  const pointPairs = useMemo(() => {
    const points = curve.getEvenlySpacedCurvePoints(0, step / 2, 1);
    if (isPipeDirectionAscending) {
      points.reverse();
    }
    return pairNeighborsNonOverlapping(points);
  }, [curve, isPipeDirectionAscending, step]);

  if (!mat) return null;

  return (
    <group renderOrder={getRenderOrder(Object3dNames.PipeDirection)}>
      {pointPairs.map(([pos, lookAt], i) => {
        if (!lookAt) return null;
        return <Arrow key={i} position={pos} lookAt={lookAt} size={arrowSize} material={mat} />;
      })}
    </group>
  );
};
