import type { Entity } from '@core/types';
import type { Action, ActionReducerMapBuilder, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import type { ReduxEntities } from '../../enum';
import type { IExtractEntitiesFunction } from '../helper';
import type { SliceState } from '../interfaces';

export const createParentApiReducer = <T extends Entity>(
  builder: ActionReducerMapBuilder<SliceState<T>>,
  parentEntities: ReduxEntities[],
  removeSubEntities: (entity: T) => T,
  upsertIfExists: (state: SliceState<T>, entities: T[]) => void,
  extractEntitiesFromParent: IExtractEntitiesFunction<T, T>,
): void => {
  const reducer: CaseReducer<SliceState<T>, PayloadAction<T | T[]>> = (state, { payload }) => {
    const extractedEntities = extractEntitiesFromParent(payload).map(removeSubEntities);
    upsertIfExists(state as SliceState<T>, extractedEntities);
  };

  builder.addMatcher(
    (action: Action<string>) =>
      action.type.endsWith('fulfilled') &&
      parentEntities.some((entity) => action.type.startsWith(`${entity}/api`)),
    reducer,
  );
};
