export const layingTypeColors = {
  SOIL_DISPLACEMENT: '#d5b60a',
  PULL: '#FF50F5',
  HORIZONTAL_DIRECTIONAL_DRILLING: '#7CFC00',
  PLOUGH: '#4597C9',
  LAYJET: '#943e94',
  MICRO_TRENCHING: '#ed6d6d',
};

export const externalDataDefaultColor = '#3272f7';

export const getLayingTypeColor = (layingType: keyof typeof layingTypeColors) => {
  return layingTypeColors[layingType] || externalDataDefaultColor;
};

const surfaceTypeColors = {
  UNPAVED_GRASS: '#7CFC00',
  SIMPLE_BRICK: '#BF40BF',
  HIGH_QUALITY_BRICK: '#570861',
  CONCRETE: '#FFA500',
  ASPHALT: '#006ee6',
  MISSING: '#f28c8a',
};

export const DepthColors = {
  NOT_MEASURABLE: '#000000',
  SIZE_0_CM: '#DAD3DB',
  SIZE_5_CM: '#CBBDCD',
  SIZE_10_CM: '#AC90AF',
  SIZE_15_CM: '#9C79A0',
  SIZE_20_CM: '#8C6392',
  SIZE_25_CM: '#7D4C83',
  SIZE_30_CM: '#6D3674',
  SIZE_35_CM: '#5E2066',
  SIZE_40_CM: '#570861',
  SIZE_45_CM: '#d455a3',
  SIZE_50_CM: '#f478fb',
  SIZE_60_CM: '#bfcf29',
  SIZE_70_CM: '#00FF00',
  SIZE_80_CM: '#3272f7',
  SIZE_90_CM: '#FFA500',
  SIZE_100_CM: '#2a03d6',
  SIZE_110_CM: '#91E03C',
  SIZE_120_CM: '#77BC2E',
  SIZE_150_CM: '#65A224',
  SIZE_200_CM: '#53881A',
  SIZE_250_CM: '#406E10',
  SIZE_300_CM: '#244600',
};

export const WidthColors = {
  SIZE_0_CM: '#d455a3',
  SIZE_15_CM: '#bfcf29',
  SIZE_30_CM: '#3272f7',
  SIZE_45_CM: '#642CB3',
  SIZE_60_CM: '#5127B7',
  SIZE_80_CM: '#3F23BB',
  SIZE_100_CM: '#2D1EBF',
  SIZE_150_CM: '#1B19C3',
  SIZE_200_CM: '#0012C9',
};

export const PointColors = {
  Default: '#000',
  Delete: '#ff0000',
  Endpoint: '#fff',
  HasMarker: '#ff6a00',
  Hover: '#fff',
  Selected: '#00ff00',
  ...layingTypeColors,
  ...surfaceTypeColors,
};

export const SegmentColors = {
  Default: '#000',
  WithElement: '#000',
  ScanMode: '#f84700',
  Hover: '#009600',
  Selected: '#00ff00',
  TrenchMode: '#000',
  MissingElement: '#ff0000',
  ...layingTypeColors,
  ...surfaceTypeColors,
};

export const PointOpacity = {
  Hover: 0.75,
  Default: 0.5,
  Endpoint: 0.25,
};

export const TrenchOpacity = {
  Hover: 1,
  Light: 0.25,
  Selected: 0.5,
  Default: 1,
};
