import { Divider } from '@mui/material';
import { viewer3dSelectors } from '@viewer3D/redux';
import { useSelector } from 'react-redux';
import { ElementSelect } from './ElementSelect';
import { MarkersAndConnectorsSection } from './MarkersAndConnectorsSection';
import { PipeFittings } from './PipeFittings';
import { TrenchSelect } from './TrenchSelect';

export const TrenchPointDetails = () => {
  return <TrenchSelect />;
};

export const ElementPointDetails = () => {
  const selectedPointId = useSelector(viewer3dSelectors.selectedPointId);

  return (
    <>
      <MarkersAndConnectorsSection selectedPointId={selectedPointId} />
      <Divider />
      <ElementSelect />
      <Divider />
      <PipeFittings />
    </>
  );
};
