import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, ButtonGroup, CircularProgress, Menu, MenuItem } from '@mui/material';
import React, { ReactElement, ReactNode, useRef, useState } from 'react';

type SelectButtonProps<ValueType> = {
  onChange?: (value: ValueType) => void;
  onClick?: (value: ValueType) => void;
  value?: ValueType | null;
  options: { value: ValueType; label: string }[];
  isLoading?: boolean;
  labelIcon?: ReactNode;
  title?: string;
};

export const SelectButton = <ValueType extends string>({
  options,
  value: initialValue,
  onClick,
  onChange,
  isLoading,
  labelIcon = null,
  title,
}: SelectButtonProps<ValueType>): ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue ?? options[0].value);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleArrowClick = () => {
    setOpen(true);
  };

  const handleItemClick = (value: ValueType) => () => {
    setOpen(false);
    setSelectedValue(value);
    onChange?.(value);
  };

  const handleButtonClick = () => {
    onClick?.(selectedValue);
  };

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        sx={{ pointerEvents: 'auto' }}
        disabled={isLoading}
        variant="outlined"
        color="secondary"
      >
        <Button
          onClick={handleButtonClick}
          title={title}
          startIcon={isLoading ? <CircularProgress color="inherit" size="1rem" /> : labelIcon}
        >
          {options.find(({ value }) => value === selectedValue)?.label}
        </Button>
        <Button onClick={handleArrowClick}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={handleItemClick(value)}
            selected={selectedValue === value}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
