import type { DateFilterType } from '@projects/pages/detail/hooks';
import { sortScansByCreatedDate, sortScansByUploadedDate } from '@scans/helper';
import type { Scan } from '@scans/types';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';

export type ScanDateGroup = [string, Scan[]];

const sortDateAscending = ([dateA]: ScanDateGroup, [dateB]: ScanDateGroup): number =>
  dateA.localeCompare(dateB);

export const sortAndGroupScans = (grouping: DateFilterType, scans?: Scan[]): ScanDateGroup[] => {
  if (!scans) return [];
  scans.sort(
    // sort scans (in-place) to have them ordered by their date AND time!
    grouping === 'recordedAt' ? sortScansByCreatedDate : sortScansByUploadedDate,
  );
  const groupMap = groupBy(scans, (scan) =>
    // group by date
    DateTime.fromISO(
      grouping === 'recordedAt'
        ? scan.metadata?.beginDate ?? scan.createdAt
        : scan.uploadedAt ?? '',
    ).toISODate(),
  );
  return (
    Object.entries(groupMap)
      // sort here to have the groups ordered by date
      .sort(sortDateAscending)
  );
};
