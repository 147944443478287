import { environment } from '@core/environment';
import { GrpcRestFetchTransport, useAuth0Interceptor } from '@deepup/api-utils';
import { inetworkClientApi } from '@deepup/internal-apis';
import {
  ListPointElementMaterialsRequest,
  PointElementMaterial,
} from '@deepup/internal-apis/dist/typescript/network/v1/inetwork';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

export const useMaterials = ({
  subType,
}: {
  subType: ListPointElementMaterialsRequest['subType'];
}) => {
  const { projectId } = useParams();

  const [materials, setMaterials] = useState<PointElementMaterial[]>([]);

  const authInterceptor = useAuth0Interceptor();
  const client = useMemo(() => {
    const transportRest = new GrpcRestFetchTransport({
      baseUrl: environment.unifiedApiUrl,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });

    return new inetworkClientApi.InternalNetworkServiceClient(transportRest);
  }, [authInterceptor]);

  useEffect(() => {
    if (subType !== 'NETZVERTEILER' && subType !== 'GLASFASER_MUFFE') return;

    const fetchMaterials = async () => {
      if (!projectId) {
        return [];
      }
      const fetchedMaterials = await client.listPointElementMaterials({ projectId, subType })
        .response;
      setMaterials(fetchedMaterials.items);
    };
    fetchMaterials();
  }, [subType]);

  return {
    materials,
  };
};
