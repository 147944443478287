import type { ThreeEvent } from '@react-three/fiber';
import { DepthColors, PointColors, PointOpacity, WidthColors } from '@trenches/colors';
import type { AttributeType, Depth, LayingType, SurfaceType, Width } from '@trenches/types';
import { enableLayerOne, getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { sphereGeometry } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { FC, useState } from 'react';
import type { Vector3 } from 'three';

interface AttributePointProps {
  layingType: LayingType | null;
  surfaceType: SurfaceType | null;
  depth: Depth | null;
  width: Width | null;
  selectedAttributeType: AttributeType;
  isEndPoint: boolean;
  isFromPoint: boolean;
  numberOfTrenches: number;
  onClick: (e: ThreeEvent<MouseEvent>) => void;
  pointId: string;
  position: Vector3;
  size: number;
}

const getColor = (
  containsTrenchEnd: boolean,
  mouseOver: boolean,
  isFromPoint: boolean,
  layingType: LayingType | null,
  surfaceType: SurfaceType | null,
  depth: Depth | null,
  width: Width | null,
  selectedAttributeType: AttributeType,
) => {
  if (selectedAttributeType === 'SURFACE_TYPE' && surfaceType && (isFromPoint || mouseOver)) {
    return PointColors[surfaceType];
  }
  if (selectedAttributeType === 'DEPTH' && depth && (isFromPoint || mouseOver)) {
    return DepthColors[depth];
  }
  if (selectedAttributeType === 'WIDTH' && width && (isFromPoint || mouseOver)) {
    return WidthColors[width];
  }
  if (selectedAttributeType === 'LAYING_TYPE' && layingType && (isFromPoint || mouseOver)) {
    return PointColors[layingType];
  }
  if (selectedAttributeType === 'LAYING_TYPE' && layingType === null && (isFromPoint || mouseOver))
    return PointColors.Delete;
  if (selectedAttributeType === 'WIDTH' && width === null && (isFromPoint || mouseOver))
    return PointColors.Delete;
  if (selectedAttributeType === 'DEPTH' && depth === null && (isFromPoint || mouseOver))
    return PointColors.Delete;
  if (
    selectedAttributeType === 'SURFACE_TYPE' &&
    surfaceType === null &&
    (isFromPoint || mouseOver)
  )
    return PointColors.Delete;
  if (containsTrenchEnd) return PointColors.Endpoint;
  return PointColors.Default;
};

const getOpacity = (
  isFromPoint: boolean,
  isMouseOver: boolean,
  numberOfTrenches: number,
): number => {
  const opacity = isFromPoint || isMouseOver ? PointOpacity.Hover : PointOpacity.Default;
  return opacity / numberOfTrenches;
};

export const AttributePoint: FC<AttributePointProps> = ({
  layingType,
  surfaceType,
  depth,
  width,
  selectedAttributeType,
  pointId,
  position,
  size,
  isFromPoint,
  onClick,
  numberOfTrenches,
  isEndPoint,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const color = getColor(
    isEndPoint || numberOfTrenches > 1,
    isMouseOver,
    isFromPoint,
    layingType,
    surfaceType,
    depth,
    width,
    selectedAttributeType,
  );
  const opacity = getOpacity(isFromPoint, isMouseOver, numberOfTrenches);
  const material = getCachedMaterial(color, opacity);

  return (
    <mesh
      name={`${Object3dNames.AttributePoint}:${pointId}`}
      scale={[size, size, size]}
      position={position}
      geometry={sphereGeometry}
      material={material}
      onClick={onClick}
      onPointerOver={() => setIsMouseOver(true)}
      onPointerOut={() => setIsMouseOver(false)}
      onUpdate={enableLayerOne}
      renderOrder={getRenderOrder(Object3dNames.AttributePoint)}
    />
  );
};
