import { request } from '@core/api';
import type { RootState } from '@core/redux/interface';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ApiUserRole, UserProfile } from './types';
import { UserRoleApiToEnum } from './types';

const fetchProfile = createAsyncThunk(
  `userprofile/api/get/one`,
  async () => await request<UserProfile>('GET', { path: ['userprofile'] }),
);

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: null as UserProfile | null,
  reducers: {},
  extraReducers: {
    [fetchProfile.fulfilled as unknown as string]: (
      state,
      action: PayloadAction<{ role: ApiUserRole }>,
    ) => ({
      role: UserRoleApiToEnum[action.payload.role], // role is an enum value, which evaluates to a positive integer at runtime
    }),
  },
});

export const userProfileReducer = userProfileSlice.reducer;
export const userProfileActions = { ...userProfileSlice.actions, fetchProfile };
export const userProfileSelectors = {
  selectUserRole: ({ userProfile }: RootState) => userProfile?.role,
  selectUserProfile: ({ userProfile }: RootState) => userProfile,
};
