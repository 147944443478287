import { useEffectOnce } from '@core/logic';

let timeoutTillUserInactive: number | undefined;
let pulse: number | undefined;

const clearUserActivePulse = () => {
  window.clearTimeout(pulse);
  pulse = undefined;
};

const clearTimeoutTillUserInactive = () => {
  window.clearTimeout(timeoutTillUserInactive);
  timeoutTillUserInactive = undefined;
};

export const useUserActivity = ({
  secondsToUserInactive,
  onUserActivePulse,
  onUnmount,
  onTabVisibilityChange,
}: {
  secondsToUserInactive: number;
  onUserActivePulse: (hasUserBeenActive: boolean) => void;
  onUnmount: (hasUserBeenActive: boolean) => void;
  onTabVisibilityChange: (hasUserBeenActive: boolean, isTabVisible: boolean) => void;
}): void => {
  const clearAllTimeouts = () => {
    onUserActivePulse(false);
    clearUserActivePulse();
    clearTimeoutTillUserInactive();
  };

  const resetTimeoutTillUserInactive = () => {
    clearTimeoutTillUserInactive(); // this is important to stop the timeout from running multiple times
    timeoutTillUserInactive = window.setTimeout(clearAllTimeouts, secondsToUserInactive * 1000);
  };

  const startUserActivePulse = () => {
    onUserActivePulse(!!pulse);
    pulse = window.setTimeout(startUserActivePulse, secondsToUserInactive * 1000);
  };

  const handleUserActivity = () => {
    resetTimeoutTillUserInactive();
    if (!pulse) startUserActivePulse();
  };

  const handleVisibilityChange = () => {
    onTabVisibilityChange(!!pulse, !document.hidden);

    if (document.hidden) {
      clearAllTimeouts();
    } else {
      resetTimeoutTillUserInactive();
    }
  };

  useEffectOnce(() => {
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      onUnmount(!!pulse); // attention: order here is important since pulse is being reset by clearAllTimeouts()
      clearAllTimeouts();
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });
};
