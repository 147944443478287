import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory, EntityRelationKey } from '@core/redux/factory';
import type { PipeConnector } from '@projects/pages/detail/PipeConnectors/types';

export const {
  reducer: pipeConnectorReducer,
  apiThunks: pipeConnectorThunks,
  actions: pipeConnectorActions,
  selectors: pipeConnectorSelectors,
} = crudEntityFactory<PipeConnector>({
  entity: ReduxEntities.PipeConnectors,
  removeKeys: [],
  keysInParent: [EntityRelationKey.PipeConnectors],
});
