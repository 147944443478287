function* generateContinuousInt() {
  let n = 0;
  while (true) {
    yield n++;
  }
}

let numberGenerator = generateContinuousInt();

export const getContinuousNumber = () => numberGenerator.next().value + '';

export const resetContinuousNumber = () => {
  numberGenerator = generateContinuousInt();
};
