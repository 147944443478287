import { toHumanReadable } from '@core/logic';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';

export const EnumSelect = <T extends string>({
  label,
  options,
  required,
  value,
  onChange,
  multiple,
}: {
  label?: string;
  options: readonly T[];
  onChange: SelectProps<T | T[]>['onChange'];
  required?: boolean;
  value: T | T[];
  multiple?: boolean;
}) => (
  <FormControl fullWidth>
    {label && <InputLabel id="enum-select-label">{label}</InputLabel>}
    <Select
      required={required}
      label={label}
      labelId="enum-select-label"
      value={value}
      onChange={onChange}
      multiple={multiple}
    >
      {options.map((val) => (
        <MenuItem key={val} value={val}>
          {toHumanReadable(val)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
