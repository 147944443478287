import { useScanStats } from '@projects/pages/detail/hooks';
import { memo } from 'react';
import { Typography } from '@mui/material';

const ScanStatsBase = () => {
  const { scope, hidden, loading } = useScanStats();

  const visible = scope - hidden - loading;
  const scanStats: string[] = [`${visible} visible`];
  if (hidden > 0) scanStats.push(`${String(hidden)} hidden`);
  if (loading > 0) scanStats.push(`${loading} loading`);

  return <Typography variant="subtitle1">Scans: {scanStats.join(' | ')}</Typography>;
};

export const ScanStats = memo(ScanStatsBase);
