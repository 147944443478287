import { DateTime } from 'luxon';

export const dateTimeFormatter = (value: string | undefined): string => {
  if (!value) return '';
  const dateTime = DateTime.fromISO(value);
  const dateString = dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  const timeString = dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  return `${dateString} / ${timeString}`;
};

export const formatCreatedDate = (createdAt: string): string =>
  DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);

export const compareDateStrings = (a: string, b: string): number => a.localeCompare(b);

const sortByCreatedAt =
  (ascending: boolean) =>
  <T extends { createdAt: string }>({ createdAt: a }: T, { createdAt: b }: T): number => {
    const sortNum = compareDateStrings(a, b);
    return ascending ? sortNum : -sortNum;
  };
export const sortAscByCreatedAt = sortByCreatedAt(true);
export const sortDescByCreatedAt = sortByCreatedAt(false);

export const isDateInRange = (minDate: string, maxDate: string, date: string): boolean =>
  compareDateStrings(minDate, date) <= 0 && compareDateStrings(date, maxDate) <= 0;
