import { useAuth0 } from '@auth0/auth0-react';
import type { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useCachedUrl } from './useCachedUrl';

export const useCachedAuth0Url = (url?: string): string | undefined => {
  const { getAccessTokenSilently } = useAuth0();

  const getRequestConfig = useCallback(
    async (): Promise<AxiosRequestConfig> => ({
      headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` },
    }),
    [getAccessTokenSilently],
  );

  return useCachedUrl(url, getRequestConfig);
};
