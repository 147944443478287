import { processingResultSelectors } from '@processingResults/redux';
import type { Scan } from '@scans/types';
import { useSelector } from 'react-redux';

export const useScanIdsWithTexturedMeshInScope = (scans: Scan[]): string[] => {
  return useSelector(
    processingResultSelectors.selectWithFilter(
      (p) =>
        scans.some((s) => s.selectedProcessingResultId === p.id) && p?.texturedMeshes?.length > 0,
    ),
  ).map(({ scanId }) => scanId);
};
