import { xyToLngLat } from '@core/logic';
import type { RootState } from '@core/redux/interface';
import type { AppDispatch } from '@core/redux/store';
import { projectSelectors } from '@projects/redux';
import { viewer3dActions, viewer3dSelectors } from '@viewer3D/redux';
import type { MapBoxBoundingBox } from '@viewer3D/types';
import { useDispatch, useSelector } from 'react-redux';

const bboxCache: { [projectId: string]: MapBoxBoundingBox | undefined } = {};

export const useConnect = (): {
  boundingBox?: MapBoxBoundingBox;
  cameraGoto: (goto: { x: number; y: number }) => void;
} => {
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);
  const dispatch = useDispatch<AppDispatch>();

  const boundingBox = useSelector((state: RootState) => {
    if (!projectId) return undefined;

    if (bboxCache[projectId]) return bboxCache[projectId];

    const utmBoundingBox = projectSelectors.boundingBox(projectId, 1000)(state);

    if (!utmBoundingBox) return undefined;

    const { minX, minY, maxX, maxY } = utmBoundingBox;
    const { lng: minLng, lat: minLat } = xyToLngLat({ x: minX, y: minY });
    const { lng: maxLng, lat: maxLat } = xyToLngLat({ x: maxX, y: maxY });
    bboxCache[projectId] = [minLng, minLat, maxLng, maxLat];
    return bboxCache[projectId];
  });

  return { boundingBox, cameraGoto: ({ x, y }) => dispatch(viewer3dActions.cameraGoto({ x, y })) };
};
