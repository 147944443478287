import { NavButtons } from '@menu/types';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ChevronsDown, ClockOutline, PinOutline } from '@deepup/icons';
import { Button, Divider, Popover, Stack } from '@mui/material';
import { ProjectsMenuList } from '@menu/components/MenuButton/ProjectsMenuList';

const MenuButtonBase = ({
  id,
  title,
  recentItems,
  pinnedItems,
}: {
  id: string;
  recentItems: NavButtons[];
  pinnedItems: NavButtons[];
  title: string;
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);
  const goTo = (href: string) => {
    navigate(href);
    handleClose();
  };

  return (
    <>
      <Button
        endIcon={<ChevronsDown />}
        aria-controls={id}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {title}
      </Button>
      <Popover
        elevation={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted={false}
        disableAutoFocus
        id={id}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Stack direction="row" gap={3} p={3} divider={<Divider flexItem orientation="vertical" />}>
          <Button
            variant="outlined"
            color={'secondary'}
            onClick={() => goTo('/projects/list')}
            sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}
          >
            Project List
          </Button>
          {recentItems && (
            <ProjectsMenuList
              items={recentItems}
              id={`${id}-recent`}
              goTo={goTo}
              headerIcon={<ClockOutline fill="white" fontSize={24} />}
              headerTitle={'Recently Opened'}
              showClearButton={recentItems.length > 0}
            />
          )}
          {pinnedItems?.length && (
            <ProjectsMenuList
              items={pinnedItems}
              id={`${id}-pinned`}
              goTo={goTo}
              headerIcon={<PinOutline fill="white" fontSize={24} />}
              headerTitle="Pinned"
              showClearButton={false}
            />
          )}
        </Stack>
      </Popover>
    </>
  );
};

export const MenuButton = memo(MenuButtonBase);
