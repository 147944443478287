import { useScanFilters } from '@projects/pages/detail/hooks';
import { EnumSelect } from '@core/components';
import { surfaceClassificationCategories, SurfaceClassification } from '@scans/types';

export const SurfaceTypeFilter = () => {
  const { surfaceClassifications, setSurfaceClassification, removeSurfaceClassification } =
    useScanFilters();

  const handleChange = (value: SurfaceClassification[]) => {
    if (value.length === 0) {
      removeSurfaceClassification();
    } else {
      setSurfaceClassification(value);
    }
  };

  return (
    <EnumSelect
      label="Surface type"
      multiple
      value={surfaceClassifications || []}
      onChange={(event) => handleChange(event.target.value as unknown as SurfaceClassification[])}
      options={surfaceClassificationCategories}
    />
  );
};
