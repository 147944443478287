import type { RootState } from '@core/redux/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ProjectItem, ProjectsHistoryState } from './types';

const MAX_HISTORY_LENGTH = 8;
export const RECENT_LABEL = 'recent:projects';
export const PINNED_LABEL = 'pinned:projects';

const toMaxLen = (array: ProjectItem[], maxLen: number) => {
  if (array.length <= maxLen) return array;
  return array.slice(0, maxLen);
};
export const loadFromStorageByItem = (itemName: string): ProjectItem[] => {
  const itemString = localStorage.getItem(itemName);
  return typeof itemString === 'string' ? JSON.parse(itemString) : [];
};

export const loadToStorage = (itemName: string, content: ProjectItem[]) => {
  localStorage.setItem(itemName, JSON.stringify(content));
};

const projectsHistorySlice = createSlice({
  name: 'projectsHistory',
  initialState: { recent: [], pinned: [] } as ProjectsHistoryState,
  reducers: {
    addToRecent(state: ProjectsHistoryState, { payload }: PayloadAction<ProjectItem>) {
      const recent = loadFromStorageByItem(RECENT_LABEL);
      const updatedRecent = toMaxLen(
        [payload, ...recent.filter((p) => p.id !== payload.id)],
        MAX_HISTORY_LENGTH,
      );
      loadToStorage(RECENT_LABEL, updatedRecent);
      state.recent = updatedRecent;
    },
    addToPinned(state: ProjectsHistoryState, { payload }: PayloadAction<ProjectItem>) {
      const pinned = loadFromStorageByItem(PINNED_LABEL);
      const updatedPinned = [payload, ...pinned.filter((p) => p.id !== payload.id)];
      loadToStorage(PINNED_LABEL, updatedPinned);
      state.pinned = updatedPinned;
    },
    dropFromPinned(state: ProjectsHistoryState, { payload }: PayloadAction<string>) {
      const updatedPinned = state.pinned.filter((p) => p.id !== payload);
      loadToStorage(PINNED_LABEL, updatedPinned);
      state.pinned = updatedPinned;
    },
    cleanRecentProjects(state: ProjectsHistoryState) {
      state.recent = [];
      localStorage.removeItem(RECENT_LABEL);
    },
    loadProjectsHistory(state: ProjectsHistoryState) {
      state.recent = loadFromStorageByItem(RECENT_LABEL);
      state.pinned = loadFromStorageByItem(PINNED_LABEL);
    },
  },
});

export const projectsHistoryReducer = projectsHistorySlice.reducer;
export const projectsHistoryActions = projectsHistorySlice.actions;

export const projectsHistorySelectors = {
  selectRecentProjects: ({ projectsHistory }: RootState) => {
    return projectsHistory.recent;
  },
  selectPinnedProjects: ({ projectsHistory }: RootState) => {
    return projectsHistory.pinned;
  },
};
