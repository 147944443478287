import { tileToGeometry } from '@viewer3D/components/Map/Tile/helper';
import { useTileTexture } from '@viewer3D/components/Map/Tile/hooks';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import type { FC } from 'react';
import type { TileProps } from './types';

export const Tile: FC<TileProps> = (tile) => {
  const texture = useTileTexture(tile);
  if (!texture) return null;

  const { tileX, tileY, zoom } = tile;
  const { geometry, position } = tileToGeometry(tile);

  return (
    <mesh
      name={`TILE:${tileX}-${tileY}-${zoom}`}
      position={position}
      geometry={geometry}
      renderOrder={getRenderOrder(Object3dNames.Map)}
    >
      <meshBasicMaterial map={texture} depthTest={false} depthWrite={false} />
    </mesh>
  );
};
