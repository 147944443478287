import type { ReworkCategory, Scan } from '@scans/types';
import { intersectionBy, pullAllBy } from 'lodash';
import { proxy } from 'valtio';

interface CheckboxSelectionState {
  checkedScans: Scan[];
}

export const checkboxSelectionState = proxy<CheckboxSelectionState>({
  checkedScans: [], // TODO I guess the whole thing could be even more simplified by just using scanIds and selectedReworkCategories as another state. Not whole scans.
});

export const toggleReworkCategory = (reworkCategory: ReworkCategory) => {
  const { checkedScans } = checkboxSelectionState;
  const currentReworkCategories = checkedScans.flatMap((s) => s.reworkCategories);

  checkboxSelectionState.checkedScans = checkedScans.map((scan) => {
    const { reworkCategories } = scan;
    return {
      ...scan,
      reworkCategories: currentReworkCategories.includes(reworkCategory)
        ? reworkCategories.filter((item) => item !== reworkCategory)
        : reworkCategories.concat(reworkCategory),
    };
  });
};

export const toggleCheckedScans = (scans: Scan[]): void => {
  const { checkedScans } = checkboxSelectionState;
  const commonScans = intersectionBy(scans, checkedScans, 'id');
  if (commonScans.length > 0) {
    checkboxSelectionState.checkedScans = pullAllBy(checkedScans, scans, 'id');
  } else {
    checkboxSelectionState.checkedScans = [...checkedScans, ...scans];
  }
};

export const uncheckAllScans = (): void => {
  checkboxSelectionState.checkedScans = [];
};
