import { environment } from '@core/environment';
import { useCachedAuth0Url } from '@core/logic';
import type { TexturedMesh } from '@processingResults/types';
import { useEffect, useState } from 'react';
import type { BufferGeometry } from 'three';
import { DRACOLoader } from 'three-stdlib/loaders/DRACOLoader';

const dracoLoader = new DRACOLoader();
/**
 * Using CDN of google as a runtime external dependency for draco setup here.
 * If this may break in the future, me may replicate
 * the "/javascript" dir of the drao repo @ https://github.com/google/draco
 * into out frontend repo at /public/assets/draco/ and set the decoder path to it
 */
dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');

export const useDracoGeometry = (texturedMesh?: TexturedMesh): BufferGeometry | undefined => {
  const [url, setUrl] = useState<string>();
  const cachedUrl = useCachedAuth0Url(url);
  const [geometry, setGeometry] = useState<BufferGeometry>();

  useEffect(() => {
    if (url || !texturedMesh) return;
    const gsPath = texturedMesh.mesh?.location;
    if (!gsPath || !gsPath.startsWith('gs://')) return;
    setUrl(`${environment.apiUrl}/texturedMeshes/${texturedMesh.id}/downloads/mesh`);
  }, [url, texturedMesh]);

  useEffect(() => {
    if (!cachedUrl) return;
    dracoLoader.loadAsync(cachedUrl).then(setGeometry).catch(console.error);
  }, [cachedUrl]);

  return geometry;
};
