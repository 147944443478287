import type { Auth0ProviderOptions, AuthorizationParams } from '@auth0/auth0-react';

interface RequiredAuthorizationParams extends AuthorizationParams {
  audience: Required<AuthorizationParams['audience']>;
  scope: Required<AuthorizationParams['scope']>;
  redirect_uri: Required<AuthorizationParams['redirect_uri']>;
}

interface Auth0Environment extends Auth0ProviderOptions {
  authorizationParams: RequiredAuthorizationParams;
}

export interface Environment {
  apiUrl: string;
  auth0: Auth0Environment;
  dataCy: boolean;
  isDev: boolean;
  mapBox: { accessToken: string };
  scanDashboardUrl: string;
  useMockApi?: boolean;
  trenchviewerFrontendUrl: string;
  unifiedApiUrl: string;
}

// All custom variables defined in import.meta.env can be found in your local
// .env.[mode] file with mode = development | test | production

const getBuildEnvironment = () => {
  return {
    VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_SCOPE: import.meta.env.VITE_AUTH0_SCOPE,
    VITE_MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    VITE_API_URL: import.meta.env.VITE_API_URL,
    VITE_GRAFANA_SCAN_DASHBOARD_URL: import.meta.env.VITE_GRAFANA_SCAN_DASHBOARD_URL,
    VITE_DATACY: import.meta.env.VITE_DATACY,
    VITE_ENABLE_MOCK_API: import.meta.env.VITE_ENABLE_MOCK_API,
    VITE_TRENCHVIEWER_FRONTEND_URL: import.meta.env.VITE_TRENCHVIEWER_FRONTEND_URL,
    VITE_API_BACKOFFICE: import.meta.env.VITE_API_BACKOFFICE,
    MODE: import.meta.env.MODE,
  };
};

const getRuntimeEnvironment = () => {
  const env = window.__env__;

  const envVars = {
    VITE_AUTH0_DOMAIN: env.VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID: env.VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE: env.VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_SCOPE: env.VITE_AUTH0_SCOPE,
    VITE_MAPBOX_ACCESS_TOKEN: env.VITE_MAPBOX_ACCESS_TOKEN,
    VITE_API_URL: env.VITE_API_URL,
    VITE_GRAFANA_SCAN_DASHBOARD_URL: env.VITE_GRAFANA_SCAN_DASHBOARD_URL,
    VITE_DATACY: env.VITE_DATACY,
    VITE_ENABLE_MOCK_API: env.VITE_ENABLE_MOCK_API
      ? env.VITE_ENABLE_MOCK_API === 'true'
      : undefined,
    VITE_TRENCHVIEWER_FRONTEND_URL: env.VITE_TRENCHVIEWER_FRONTEND_URL,
    VITE_API_BACKOFFICE: env.VITE_API_BACKOFFICE,
    MODE: env.MODE,
  };

  // Filter out entries with undefined values
  const definedVars = Object.fromEntries(
    Object.entries(envVars).filter(([, value]) => value !== undefined),
  );

  return definedVars;
};

const dotEnv = {
  ...getBuildEnvironment(),
  ...getRuntimeEnvironment(),
};

export const environment: Environment = {
  auth0: {
    domain: dotEnv.VITE_AUTH0_DOMAIN,
    clientId: dotEnv.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: dotEnv.VITE_AUTH0_AUDIENCE,
      scope: dotEnv.VITE_AUTH0_SCOPE,
      redirect_uri: window.location.origin,
    },
  },
  mapBox: {
    accessToken: dotEnv.VITE_MAPBOX_ACCESS_TOKEN,
  },
  isDev: dotEnv.MODE === 'development',
  apiUrl: dotEnv.VITE_API_BACKOFFICE,
  scanDashboardUrl: dotEnv.VITE_GRAFANA_SCAN_DASHBOARD_URL,
  dataCy: !!dotEnv.VITE_DATACY,
  useMockApi: dotEnv.VITE_ENABLE_MOCK_API === 'true',
  trenchviewerFrontendUrl: dotEnv.VITE_TRENCHVIEWER_FRONTEND_URL,
  unifiedApiUrl: dotEnv.VITE_API_URL,
};
