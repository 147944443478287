import { PointColors } from '@trenches/colors';
import { enableLayerOne, getCachedMaterial, getRenderOrder } from '@viewer3D/helper';
import { sphereGeometry } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { FC, useState } from 'react';
import type { IntermediatePointProps } from './types';

export const IntermediatePoint: FC<IntermediatePointProps> = ({
  name,
  size,
  position,
  onClick,
}) => {
  const [mouseOver, setMouseOver] = useState(false);

  const color = mouseOver ? PointColors.Hover : PointColors.Default;
  const material = getCachedMaterial(color, 0.25);

  return (
    <mesh
      name={name}
      scale={[size, size, size]}
      position={position}
      geometry={sphereGeometry}
      material={material}
      onClick={onClick}
      onPointerOver={() => setMouseOver(true)}
      onPointerOut={() => setMouseOver(false)}
      onUpdate={enableLayerOne}
      renderOrder={getRenderOrder(Object3dNames.IntermediatePoint)}
    />
  );
};
