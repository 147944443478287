import { useIntersectionObserver } from '@core/useIntersectionObserver';
import clsx from 'clsx';
import { RefObject, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { countDuplicates } from '@core/logic';
import { Fade } from '@mui/material';
import { useDeleteFitting } from './usePipeFittings';
import type { Connection } from './types';

export const ConnectionView = ({
  connection: { id, inPipe, outPipe },
  containerRef,
  canvasRef,
  label,
  isHighlighted,
}: {
  connection: Connection;
  containerRef: RefObject<HTMLDivElement>;
  canvasRef: RefObject<SVGSVGElement>;
  isHighlighted: boolean;
  label?: string;
}) => {
  const deleteFitting = useDeleteFitting();
  // The y-coordinates are what changes, while the x-coordinates are static (the connections always have the same width in the layout).
  const startX = 0;
  const endX = canvasRef.current?.clientWidth ?? 0;
  const [startY, setStartY] = useState(0);
  const [endY, setEndY] = useState(0);

  const movedRefs = useIntersectionObserver([inPipe.ref, outPipe.ref]); // strangely, the refs need to be copied in new array otherwise recalculation of lines is lagging

  useEffect(() => {
    const offsetTop = containerRef.current?.getBoundingClientRect().top ?? 0;
    for (const { target, boundingClientRect } of movedRefs) {
      const nextY = boundingClientRect.top + boundingClientRect.height / 2 - offsetTop;
      if (target === inPipe.ref.current) setStartY(nextY);
      if (target === outPipe.ref.current) setEndY(nextY);
    }
  }, [movedRefs]);

  return (
    <>
      <path
        className="hoverZone"
        onClick={() => deleteFitting(id)}
        d={`
          M ${[startX, startY]} 
          C ${[endX / 2, startY]} 
            ${[endX / 2, endY]}
            ${[endX, endY]}
        `}
        data-testid={`connection-${inPipe.id}:${outPipe.id}`}
      />
      <Fade in>
        <path
          className={clsx('connection', isHighlighted && 'highlighted')}
          d={`
          M ${[startX, startY]} 
          C ${[endX / 2, startY]} 
            ${[endX / 2, endY]}
            ${[endX, endY]}
        `}
        />
      </Fade>

      {label && (
        <text x={endX / 2} y={startY + (endY - startY) / 2}>
          {label}
        </text>
      )}
    </>
  );
};

export const ConnectionsView = ({
  connections,
  highlightedConnection,
  containerRef,
}: {
  containerRef: RefObject<HTMLDivElement>;
  connections: Connection[];
  highlightedConnection?: number;
}) => {
  const theme = useTheme();
  const canvasRef = useRef<SVGSVGElement>(null);
  const countConnectionDuplicates = countDuplicates(
    connections.map(({ inPipe, outPipe }) => [inPipe, outPipe]),
  );

  return (
    <Grid
      item
      container
      xs={1.6}
      rowSpacing={2}
      sx={{
        '.connections': {
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          boxSizing: 'border-box',
        },
        '& .connection': {
          fill: 'none',
          strokeWidth: 1,
          pointerEvents: 'none',
          transition: theme.transitions.create(['stroke', 'stroke-width']),
          stroke: theme.palette.secondary.main,
          '&.isHighlighted': {
            strokeWidth: 3,
          },
        },
        '& .hoverZone': {
          fill: 'none',
          strokeWidth: 10,
          stroke: 'transparent',
          cursor: 'pointer',
        },
        '& .hoverZone:hover + .connection': {
          cursor: 'pointer',
          strokeWidth: 3,
          stroke: theme.palette.error.main,
        },
        '& .highlighted': {
          strokeWidth: 2,
          stroke: theme.palette.success.main,
        },
        '& text': {
          ...theme.typography.caption,
          fill: theme.palette.text.primary,
          pointerEvents: 'none',
          userSelect: 'none',
          textShadow: '0 0 3px black',
        },
      }}
    >
      <svg className="connections" ref={canvasRef}>
        {connections.map((connection, index) => {
          const count = countConnectionDuplicates([connection.inPipe, connection.outPipe]);
          const isHighlighted = highlightedConnection === index;
          return (
            <ConnectionView
              label={count > 1 ? String(count) : undefined}
              connection={connection}
              containerRef={containerRef}
              canvasRef={canvasRef}
              key={index}
              isHighlighted={isHighlighted}
            />
          );
        })}
      </svg>
    </Grid>
  );
};
