import type {
  GeoregistrationStatus,
  Mesh,
  ProcessingResult,
  ProcessingState,
  ProcessingStatus,
  ReconstructionStatus,
} from '@processingResults/types';
import { useTheme } from '@mui/material/styles';

export const getProcessingColor = (
  key: ProcessingStatus | GeoregistrationStatus | ReconstructionStatus,
) => {
  const theme = useTheme();
  const colors = {
    PendingProcessingResult: theme.palette.secondary.main,
    PendingTexturedMesh: theme.palette.secondary.main,
    HighQuality: theme.palette.primary.main,
    LowQuality: theme.palette.warning.main,
    UnknownQuality: theme.palette.warning.main,
    Failed: theme.palette.error.main,
    PendingUpload: theme.palette.grey[500],
  };
  return colors[key] as string;
};

const isGeoregistrationValid = ({ hasValidGeoregistration, offset, zone }: Mesh): boolean =>
  hasValidGeoregistration &&
  typeof zone?.value === 'number' &&
  zone.value !== 0 &&
  Object.values(offset).every((val) => val !== 0);

const pendingProcessingState: ProcessingState = {
  overallStatus: 'PendingProcessingResult',
  georegistrationStatus: 'UnknownQuality',
  reconstructionStatus: 'UnknownQuality',
};

const pendingTexturedMeshState = ({
  georegistrationStatus = 'UnknownQuality',
  reconstructionStatus = 'UnknownQuality',
}: Partial<ProcessingState>): ProcessingState => ({
  overallStatus: 'PendingTexturedMesh',
  georegistrationStatus,
  reconstructionStatus,
});

const generalProcessingStatus = ({
  georegistrationStatus: geo,
  reconstructionStatus: rec,
}: Partial<ProcessingState>): ProcessingStatus => {
  if (geo === 'HighQuality' && rec === 'HighQuality') return 'HighQuality';
  if (geo === 'UnknownQuality' && rec === 'UnknownQuality') return 'UnknownQuality';
  if (geo === 'Failed' || rec === 'Failed') return 'Failed';
  return 'LowQuality';
};

export const getProcessingState = (
  processingResult?: ProcessingResult,
  uploadedAt?: string,
): ProcessingState => {
  if (!processingResult && !uploadedAt)
    return { ...pendingProcessingState, overallStatus: 'PendingUpload' };

  if (!processingResult) return pendingProcessingState;

  const { metrics, texturedMeshes: [texturedMesh] = [] } = processingResult;
  if (!texturedMesh) return pendingTexturedMeshState(metrics ?? {});

  if (metrics) {
    return {
      overallStatus: generalProcessingStatus(metrics),
      georegistrationStatus: metrics.georegistrationStatus,
      reconstructionStatus: metrics.reconstructionStatus,
    };
  }

  const georegistrationStatus = isGeoregistrationValid(texturedMesh.mesh)
    ? 'HighQuality'
    : 'Failed';
  return {
    overallStatus: georegistrationStatus,
    georegistrationStatus,
    reconstructionStatus: 'HighQuality',
  };
};
