import { Html } from '@react-three/drei';
import { getRenderOrder } from '@viewer3D/helper';
import { Object3dNames } from '@viewer3D/types';
import { CSSProperties, memo, MutableRefObject } from 'react';
import { DoubleSide, Group } from 'three';

interface MeasurementProps {
  divRef: MutableRefObject<HTMLDivElement | null>;
  groupRef: MutableRefObject<Group | null>;
}

const measurementStyle: CSSProperties = {
  position: 'relative',
  width: 'auto',
  pointerEvents: 'none',
  fontSize: 20,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textShadow: '0 0 6px black',
  lineHeight: 0,
  left: '-50%',
};

const MeasurementBase = ({ divRef, groupRef }: MeasurementProps): JSX.Element => (
  <group
    ref={groupRef}
    name={Object3dNames.Measurement}
    renderOrder={getRenderOrder(Object3dNames.Measurement)}
  >
    <mesh
      name={Object3dNames.MeasurementLine}
      renderOrder={getRenderOrder(Object3dNames.Measurement)}
    >
      <meshBasicMaterial
        transparent
        color="white"
        depthWrite={false}
        depthTest={false}
        side={DoubleSide}
      />
    </mesh>
    <Html ref={divRef} style={measurementStyle} name={Object3dNames.MeasurementDiv} />
  </group>
);

export const Measurement = memo(MeasurementBase);
