import type { ThreeEvent } from '@react-three/fiber';
import type { IntermediatePoint as InterPoint } from '@trenches/components/Trench';
import { getRenderOrder } from '@viewer3D/helper';
import { viewer3dSelectors } from '@viewer3D/redux';
import { Object3dNames } from '@viewer3D/types';
import { once } from 'lodash';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import type { Vector3 } from 'three';
import { connectComponent } from './connect';
import { IntermediatePoint } from './IntermediatePoint';
import type { IntermediatePointsProps, MapDispatchToProps, MapStateToProps } from './types';

const IntermediatePointsBase: FC<
  MapStateToProps & IntermediatePointsProps & MapDispatchToProps
> = ({ curve, isTrenchSelected, radius, insertPointOnSelectedTrench }) => {
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);

  if (!isTrenchSelected) return null;

  const insertPoint =
    (vec: Vector3, beforePointId: string, afterPointId: string) => (e: ThreeEvent<MouseEvent>) => {
      e.stopPropagation();
      const { x, y, z } = vec.clone().add(curve.worldPosition);

      insertPointOnSelectedTrench(x, y, z, beforePointId, afterPointId, projectId!);
    };

  const intermediateVectors = curve.getIntermediatePoints();

  const hasEnoughSpace = ({ distanceToNextPoint }: InterPoint) => {
    if (!distanceToNextPoint) return true;
    // this is the minimum space between intermediate point and the next trench point so the sphere geometries don't overlap:
    // (Point) ---- space ---- (IntermediatePoint) ---- space ---- (Point)
    return distanceToNextPoint > radius * 3;
  };

  return (
    <group
      name={Object3dNames.IntermediatePoints}
      renderOrder={getRenderOrder(Object3dNames.IntermediatePoints)}
    >
      {intermediateVectors.filter(hasEnoughSpace).map(({ vec, beforePointId, afterPointId }) => {
        return (
          <IntermediatePoint
            key={beforePointId + '-' + afterPointId}
            position={vec}
            size={radius}
            name={`${Object3dNames.IntermediatePoint}:${beforePointId}-${afterPointId}`}
            onClick={once(insertPoint(vec, beforePointId, afterPointId))}
          />
        );
      })}
    </group>
  );
};

export const IntermediatePoints = connectComponent(IntermediatePointsBase);
