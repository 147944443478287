import type { Patch } from '@core/api/types';
import type { Draft, EntityAdapter, Update } from '@reduxjs/toolkit';
import type { SliceState } from '../interfaces';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createBasicReducer = <T>(adapter: EntityAdapter<T>) => ({
  upsertOne: (state: Draft<SliceState<T>>, { payload }: { payload: T }) =>
    adapter.upsertOne(state as SliceState<T>, payload),
  updateOne: (state: Draft<SliceState<T>>, { payload }: { payload: Patch<T> }) =>
    adapter.updateOne(state as SliceState<T>, { id: payload.id, changes: payload }),
  updateMany: (state: Draft<SliceState<T>>, { payload }: { payload: Update<T>[] }) =>
    adapter.updateMany(state as SliceState<T>, payload),
  upsertMany: (state: Draft<SliceState<T>>, { payload }: { payload: T[] }) =>
    adapter.upsertMany(state as SliceState<T>, payload),
  removeOne: (state: Draft<SliceState<T>>, { payload }: { payload: string }) =>
    adapter.removeOne(state as SliceState<T>, payload),
  removeMany: (state: Draft<SliceState<T>>, { payload }: { payload: string[] }) =>
    adapter.removeMany(state as SliceState<T>, payload),
  removeAll: (state: Draft<SliceState<T>>) => adapter.removeAll(state as SliceState<T>),
});

export const mapToUpdateT = <T extends { id: string }>(ts: T[]): Update<T>[] =>
  ts.map((t: T) => ({ id: t.id, changes: { ...t } }));
