import { proxy } from 'valtio';

interface Tools {
  measurementActive: boolean;
}

export const toolsState = proxy<Tools>({
  measurementActive: false,
});

export const toggleMeasurement = (): void => {
  toolsState.measurementActive = !toolsState.measurementActive;
};

export const setTools = ({ measurementActive }: Tools): void => {
  toolsState.measurementActive = measurementActive;
};
