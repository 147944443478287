import { zIndexRange3dHtml } from '@core/constants';
import { elementThunks } from '@elements/redux';
import { pipeFittingSelectors } from '@elements/redux/pipe-fitting.slice';
import type { SplineElement } from '@elements/types';
import { Checkbox, FormControlLabel, Paper, styled } from '@mui/material';
import type { Point } from '@trenches/types';
import { viewer3dSelectors } from '@viewer3D/redux';
import { Object3dNames } from '@viewer3D/types';
import { useDispatch, useSelector } from 'react-redux';
import { HtmlWithTheme } from '@core/components';
import { jsonToVector } from '@viewer3D/helper';
import { useRenderPipeDirection } from './state';

const PointInfo = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  transform: 'translateY(-100%)',
  pointerEvents: 'auto', // reactivates pointer events for the PointInfo only!
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: 'inherit',
    transform: 'translate(-50%,50%) rotate(45deg)',
  },
}));

export const EndpointCheckboxes = ({
  fromPoint,
  toPoint,
  splineElement,
}: {
  fromPoint: Point;
  toPoint: Point;
  splineElement: SplineElement;
}) => {
  const renderPipeDirection = useRenderPipeDirection();
  // A pipe fitting requires a fromPoint isFinished true, once the pipe fitting is created we forbid the user to unfinish the fromPoint
  const fromPointHasFitting = !!useSelector(
    pipeFittingSelectors.findFirst(
      (p) => p.outgoingSplineElementId === splineElement.id && p.pointId === fromPoint.id,
    ),
  );
  const projectId = useSelector(viewer3dSelectors.selectedProjectId);

  const dispatch = useDispatch();

  if (!splineElement || !renderPipeDirection) return null;

  const toggleFinishedStatus = async (key: 'fromPoint' | 'toPoint') => {
    const body = {
      [key]: { isFinished: !splineElement[key]!.isFinished },
    };
    dispatch(
      elementThunks.patch({
        path: ['projects', projectId!, 'splineElements', splineElement.id],
        body: body as Partial<SplineElement>,
      }),
    );
  };

  return (
    <>
      <HtmlWithTheme
        center
        position={jsonToVector(fromPoint)}
        name={Object3dNames.EndpointsCheckboxFrom}
        zIndexRange={[-2, 1]}
        style={{ pointerEvents: 'none' }} // Lets mouse clicks pass through to the Canvas. These have been blocked by the invisible actual layout position of the css-translated PointInfo.
      >
        <PointInfo elevation={8}>
          <FormControlLabel
            disabled={fromPointHasFitting}
            label="From finished"
            slotProps={{ typography: { variant: 'body2', noWrap: true } }}
            control={
              <Checkbox
                checked={splineElement?.fromPoint?.isFinished}
                onChange={() => toggleFinishedStatus('fromPoint')}
              />
            }
          />
        </PointInfo>
      </HtmlWithTheme>

      <HtmlWithTheme
        center
        position={jsonToVector(toPoint)}
        name={Object3dNames.EndpointsCheckboxTo}
        zIndexRange={zIndexRange3dHtml}
      >
        <PointInfo elevation={8}>
          <FormControlLabel
            label="To finished"
            slotProps={{ typography: { variant: 'body2', noWrap: true } }}
            control={
              <Checkbox
                checked={splineElement?.toPoint?.isFinished}
                onChange={() => toggleFinishedStatus('toPoint')}
              />
            }
          />
        </PointInfo>
      </HtmlWithTheme>
    </>
  );
};
