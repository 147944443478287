export const mapboxStyles = ['DARK', 'NONE', 'SATELLITE', 'SATELLITE_STREETS', 'STREETS'] as const;

export type MapboxStyle = typeof mapboxStyles[number];

export const mapboxStyleLookup: Record<MapboxStyle, string | null> = {
  // LIGHT: 'light-v10',
  // NAVIGATION_DAY: 'navigation-day-v1',
  // NAVIGATION_NIGHT: 'navigation-night-v1',
  // OUTDOORS: 'outdoors-v11',
  DARK: 'dark-v10',
  SATELLITE: 'satellite-v9',
  NONE: null,
  SATELLITE_STREETS: 'satellite-streets-v11',
  STREETS: 'streets-v11',
};
