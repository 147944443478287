import { ReduxEntities } from '@core/redux/enum';
import { crudEntityFactory } from '@core/redux/factory';
import type { Photo } from '@photos/types';

export const {
  reducer: photoReducer,
  apiThunks: photoThunks,
  actions: photoActions,
  selectors: photoSelectors,
} = crudEntityFactory<Photo>({
  entity: ReduxEntities.Photos,
});
