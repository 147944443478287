import { Measurement } from '@core/components';
import {
  createIntermediateSamplingPoints,
  getClosestSegment,
  getPrelabeledWidth,
  SegmentWithWidth,
  toSegmentWithWidth,
  useKey,
} from '@core/logic';
import type { Vector } from '@core/types';
import { editModeState } from '@projects/edit-modes';
import { EditMode } from '@projects/edit-modes/types';
import { Html } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { useConnect } from '@scans/components/Scans/connect';
import { useScanIdsWithTexturedMeshInScope } from '@scans/components/Scans/hooks';
import type { Scan } from '@scans/types';
import { PointColors } from '@trenches/colors';
import {
  hidePrelabeledTrenches,
  useFetchPrelabeledTrenches,
  usePrelabeleTrenchesdState,
} from '@trenches/components/Prelabeled';
import type { MouseSphereMesh } from '@viewer3D/components';
import { setTransformControl } from '@viewer3D/components/Controls';
import { getCachedMaterial, getRenderOrder, getVisibleScans } from '@viewer3D/helper';
import { sphereGeometry } from '@viewer3D/helper/geometry-cache';
import { Object3dNames } from '@viewer3D/types';
import { throttle } from 'lodash';
import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import type { Euler, Event, Object3D, Quaternion } from 'three';
import { BufferGeometry, Vector3 } from 'three';
import type { TransformControls } from 'three/TransformControls';
import { useSnapshot } from 'valtio';
import {
  setTriggerUndoUseUserTransformation,
  triggerUndoUseUserTransformation,
} from '../ScanDetailPanel/state';
import { TransformableScan } from '../TransformableScan';
import { createUndoTransformationRequestBody } from './helper';
import { setTrenchFromPoint, trenchFromPointState } from './state';
import { useMeasurement } from './useMeasurement';

export interface RenderedScanData {
  scanId: string;
  position: Vector3;
  rotation: Euler;
  quaternion: Quaternion;
}

const lineGeometry = new BufferGeometry();
lineGeometry.setFromPoints([new Vector3(), new Vector3()]);

/**
 * This is a debug component in order to see the invisible sampling points on the segment the User is labeling.
 * All sampling points are rendered as spheres and show their respective calculated pre-labeled width as text.
 */
const SamplingPointSpheres: FC<{
  fromPoint?: Vector;
  toPoint: Vector;
  segmentsWithWidth: SegmentWithWidth[];
}> = ({ fromPoint, toPoint, segmentsWithWidth }) => {
  const { invalidate } = useThree();
  const [areSegmentEndsVisible, setAreSegmentEndsVisible] = useState(false);

  if (!fromPoint || segmentsWithWidth.length === 0) return null;

  const samplingPoints = createIntermediateSamplingPoints(fromPoint, toPoint);
  const closestSegments = samplingPoints.map((p) => getClosestSegment(p, segmentsWithWidth));

  const setSegmentEndsVisibility = (nextVisibility: boolean) => () => {
    setAreSegmentEndsVisible(nextVisibility);
    invalidate();
  };

  return (
    <>
      <mesh
        geometry={sphereGeometry}
        material={getCachedMaterial(PointColors.Endpoint, 0.5)}
        scale={[0.03, 0.03, 0.03]}
        position={[fromPoint.x, fromPoint.y, fromPoint.z]}
        renderOrder={getRenderOrder(Object3dNames.TrenchPoint)}
        visible={areSegmentEndsVisible}
      />
      <mesh
        geometry={sphereGeometry}
        material={getCachedMaterial(PointColors.Endpoint, 0.5)}
        scale={[0.03, 0.03, 0.03]}
        position={[toPoint.x, toPoint.y, toPoint.z]}
        renderOrder={getRenderOrder(Object3dNames.TrenchPoint)}
        visible={areSegmentEndsVisible}
      />
      {samplingPoints.map((p, i) => (
        <mesh
          key={i}
          geometry={sphereGeometry}
          material={getCachedMaterial('red', 0.5)}
          scale={[0.03, 0.03, 0.03]}
          position={[p.x, p.y, p.z]}
          renderOrder={getRenderOrder(Object3dNames.TrenchPoint)}
        >
          <Html>
            <div
              onMouseEnter={setSegmentEndsVisibility(true)}
              onMouseLeave={setSegmentEndsVisibility(false)}
            >
              {closestSegments[i][2]}
            </div>
          </Html>
        </mesh>
      ))}
    </>
  );
};

export const Scans = ({
  mouseSphereRef,
  scans,
}: {
  mouseSphereRef: MutableRefObject<MouseSphereMesh | null>;
  scans: Scan[]; // we use this to pass scans directly. Otherwise, we would have conflicts with the <ThreeContextBridge /> and url-query params in Html-components.
}) => {
  const {
    createTrench,
    extendOrBranchTrench,
    resetScanToUserTransformation,
    resetUserTransformation,
    selectScansUnderMouseCursor,
    selectedPoint,
    selectedScan,
    selectedTrenchId,
    transformScan,
    visibleScans,
  } = useConnect();
  const scanIds = useScanIdsWithTexturedMeshInScope(scans);
  const { scene, invalidate } = useThree((three) => three);
  const { mode: editMode } = useSnapshot(editModeState);
  const addPressed = useKey('a');
  const { fromPoint } = useSnapshot(trenchFromPointState);
  const [userTransformedScan, setUserTransformedScan] = useState<RenderedScanData | null>(null);
  const { trenches: prelabeledTrenches } = usePrelabeleTrenchesdState();
  const { trigger } = useSnapshot(triggerUndoUseUserTransformation);

  useFetchPrelabeledTrenches(scanIds);

  useEffect(() => {
    if (userTransformedScan && selectedScan?.id !== userTransformedScan?.scanId) {
      resetScanToUserTransformation(userTransformedScan.scanId);
      setUserTransformedScan(null);
    }
    // Save button is pressed and all data is available to make the undo
    if (trigger && selectedScan?.selectedProcessingResultId && userTransformedScan) {
      const body = createUndoTransformationRequestBody({
        scene,
        selectedScan,
        userTransformedScan,
      });
      if (body) {
        resetUserTransformation(selectedScan.id, selectedScan.selectedProcessingResultId, body);
      }
      setTriggerUndoUseUserTransformation(false);
      setUserTransformedScan(null);
    }
    if (selectedScan?.disableUserTransformation) {
      setTransformControl({});
    }
  }, [trigger, selectedScan]);

  const measurement = useMeasurement({ invalidate });

  const addPointOverHandler = useCallback(
    throttle((event: ThreeEvent<MouseEvent>) => {
      event.stopPropagation();
      if (!mouseSphereRef.current || !event.intersections[0]) return;
      const { point: hoverPoint } = event.intersections[0];
      mouseSphereRef.current.position.copy(hoverPoint);
      invalidate();
    }, 30),
    [invalidate, fromPoint],
  );

  // TODO: just getting the prelabel via the fromPoint's processingResultId is a cheap implementation for now. S. https://deepup-gmbh.atlassian.net/browse/DP-3280?focusedCommentId=13432
  const prelabelForScan = !fromPoint?.processingResultId
    ? undefined
    : prelabeledTrenches.find((t) => t.processingResultId === fromPoint.processingResultId);

  const addPointClickHandler = useCallback(
    async ({ intersections, point, stopPropagation }: ThreeEvent<MouseEvent>) => {
      stopPropagation();
      if (!intersections.length) return;
      const [firstScan] = getVisibleScans(intersections);
      if (!firstScan) return;
      const newPoint = {
        ...point,
        scanId: firstScan.scanId,
        processingResultId: firstScan.processingResultId,
      };

      if (!selectedTrenchId && !fromPoint) {
        // User is creating new trench
        setTrenchFromPoint(newPoint);
        return;
      }

      const width = getPrelabeledWidth(
        selectedPoint ?? fromPoint,
        newPoint,
        toSegmentWithWidth(prelabelForScan),
      );

      if (fromPoint) {
        await createTrench(fromPoint, newPoint, prelabelForScan?.id, width);
        setTrenchFromPoint();
      } else {
        await extendOrBranchTrench(newPoint, prelabelForScan?.id, width);
      }
    },
    [
      selectedTrenchId,
      createTrench,
      extendOrBranchTrench,
      fromPoint,
      selectedPoint,
      prelabelForScan,
      setTrenchFromPoint,
    ],
  );
  const updateUserTransformedScan = (e: Event) => {
    const object = (e.target as TransformControls).object as Object3D;
    setUserTransformedScan({
      scanId: object.name.split(':')[1],
      position: object.position.clone(),
      rotation: object.rotation.clone(),
      quaternion: object.quaternion.clone(),
    });
    transformScan(e);
  };

  const selectScansClickHandler = useCallback(
    ({
      intersections,
      stopPropagation,
      nativeEvent,
      delta, // Distance between mouse down and mouse up event -> in three-js units, doesn't scale with zoom! Should prevent accidentally selecting scans when User wants to rotate with left mouse.
      object,
    }: ThreeEvent<MouseEvent>) => {
      stopPropagation();
      if (!intersections.length || delta > 1) return;
      const visibleScanIds = getVisibleScans(intersections).map((s) => s.scanId);
      if (!visibleScanIds.length) return;
      selectScansUnderMouseCursor(nativeEvent, visibleScanIds);
      if (object.parent) {
        if (!userTransformedScan && !selectedScan?.disableUserTransformation) {
          setUserTransformedScan({
            scanId: visibleScanIds[0],
            position: object.parent.position.clone(),
            rotation: object.parent.rotation.clone(),
            quaternion: object.parent.quaternion.clone(),
          });
        }
        setTransformControl({
          objectName: object.parent.name,
          onAfterTranslate: updateUserTransformedScan,
          onAfterRotate: updateUserTransformedScan,
        });
      }
      invalidate();
    },
    [invalidate, selectScansUnderMouseCursor, transformScan, selectedScan],
  );

  useEffect(() => {
    if (!selectedScan) {
      hidePrelabeledTrenches();
    }
  }, [selectedScan]);

  const onOverHandler = measurement.isActive
    ? measurement.onOver
    : editMode === EditMode.Trench && addPressed
    ? addPointOverHandler
    : undefined;

  const clickHandler = measurement.isActive
    ? measurement.onClick
    : editMode === EditMode.Scan
    ? selectScansClickHandler
    : editMode === EditMode.Trench && addPressed
    ? addPointClickHandler
    : undefined;
  return (
    <>
      {measurement.isActive && (
        <Measurement divRef={measurement.divRef} groupRef={measurement.groupRef} />
      )}
      {fromPoint && (
        <mesh
          name={Object3dNames.TrenchPointFromIndicator}
          geometry={sphereGeometry}
          material={getCachedMaterial(PointColors.Endpoint, 0.5)}
          scale={[0.03, 0.03, 0.03]}
          position={[fromPoint.x, fromPoint.y, fromPoint.z]}
          renderOrder={getRenderOrder(Object3dNames.TrenchPoint)}
        />
      )}
      {window.debugUserTrenchSegment && mouseSphereRef.current && (fromPoint || selectedPoint) && (
        <SamplingPointSpheres
          fromPoint={fromPoint ?? selectedPoint}
          toPoint={mouseSphereRef.current.position}
          segmentsWithWidth={toSegmentWithWidth(prelabelForScan)}
        />
      )}
      <group name={Object3dNames.Scans} renderOrder={getRenderOrder(Object3dNames.Scans)}>
        {visibleScans !== 'NONE' &&
          scanIds.map((id) => (
            <TransformableScan
              key={id}
              scanId={id}
              visibleScans={visibleScans}
              onClick={clickHandler}
              onOver={onOverHandler}
            />
          ))}
      </group>
    </>
  );
};
